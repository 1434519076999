/**
 * Directive for a Spectrum style slider.
 *
 * @example
 *
 *  Default variant:
 *
 *    <spectrum-slider
 *      label="Default slider"
 *      min="0"
 *      max="1000"
 *      on-change="parentController.handleSliderChange($data)"
 *      step="10"
 *      value="20"
 *      value-label="parentController.getSliderLabel($data)" />
 *
 *  "Range" variant with 2 handles:
 *
 *    <spectrum-slider
 *      end-value="80"
 *      start-value="20"
 *      label="Range slider"
 *      min="10"
 *      max="100"
 *      on-change="parentController.handleSliderChange($data)"
 *      step="1"
 *      variant="range"
 *      value-label="parentController.getSliderLabel($data)" />
 *
 * @ngInject
 */
function SpectrumSliderDirective() {
  return {
    scope: {},
    bindToController: {
      allowOverlap: '&',
      endValue: '&',
      startValue: '&',
      label: '@',
      onChange: '&',
      max: '&',
      min: '&',
      step: '&',
      value: '&',
      valueLabel: '&',
      variant: '@'
    },
    controller: require('./controller.js'),
    controllerAs: 'spectrumSlider',
    restrict: 'E',
    replace: true,
    templateUrl: '/angular_templates/directives/spectrum/slider.html',
    link: function(scope, el, attrs, ctrl) {
      scope.$watch(function() {
        return { startValue: ctrl.startValue(), endValue: ctrl.endValue() };
      }, ctrl.updateValues.bind(ctrl), true);
    }
  };
}

module.exports = SpectrumSliderDirective;
