module.exports = {
  convertToVariation: function(variation, cssFontStack, textSamples) {
    return {
      axes: variation.axes || [],
      cssFontStack: cssFontStack,
      defaultLanguage: variation.default_language,
      familyWebId: variation.web_id,
      fontName: variation.fontName,
      id: variation.id,
      instances: variation.instances || [],
      name: variation.name,
      postscriptName: variation.postscriptName,
      preferredSubfamilyName: variation.preferred_subfamily_name,
      style: variation.style,
      unicodeRange: textSamples[variation.default_language].unicode_range,
      weight: variation.weight
    };
  }
};
