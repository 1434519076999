const freezeframeUtil = require('../../../util/freezeframe_util.js');
/**
 * @ngInject
 */
function AdobeFontsFamilyImageCardListController() {
}

/**
 * Method to get the CSS depending on the image items length
 * @returns {string}
 * @private
 */
AdobeFontsFamilyImageCardListController.prototype._familyImageClass = function() {
  if (this.imageItemsData.length === 1) {
    return 'adobe-fonts-family-image-card-box-single-image';
  } else if (this.imageItemsData.length === 2) {
    return 'adobe-fonts-family-image-card-box-double-image';
  } else {
    return 'adobe-fonts-family-image-card-box-multiple-image';
  }
};

/**
 * Method to get the CSS for image container depending on the image items length
 * @returns {string}
 * @private
 */
AdobeFontsFamilyImageCardListController.prototype.familyImageContainerClass = function() {
  return this.imageItemsData.length === 1 ? 'adobe-font-family-image-card-list-layout' : '';
};

/**
 * Method to get the margin class for image container depending on layout
 * @returns {string}
 * @private
 */
AdobeFontsFamilyImageCardListController.prototype.cardListMargin = function() {
  return this.displayView === 'family-carousel' ? 'adobe-fonts-family-image-card-family-list-margin' : 'adobe-fonts-family-image-card-list-margin';
};

/**
 * Method to get the CSS depending on the layout
 * @returns {string}
 */
AdobeFontsFamilyImageCardListController.prototype.layoutClass = function() {
  return this.displayView === 'family-carousel' ? this._familyImageClass() : 'adobe-fonts-family-image-card-view';
};

/**
 * Checks if there are any gif filetypes within the specimen images data array
 * returns a class to indicate there is a gif
 *
 * @return {String}
 */
AdobeFontsFamilyImageCardListController.prototype.getImageClass = function(image) {
  return freezeframeUtil.getImageClass(image);
};

module.exports = AdobeFontsFamilyImageCardListController;
