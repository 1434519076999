var _ = require('underscore');
var angular = require('angular');

/**
 * A service used to filter a set of variations based on the preferred actions for resolving entitlements.
 *
 * @ngInject
 */
function FilterVariationsService() {

  return {
    filterFontsByRequiredActions: filterFontsByRequiredActions,
    filterRequiredActionsByToggles: filterRequiredActionsByToggles,
    hasUpgradeFilterOption: hasUpgradeFilterOption
  };

  /**
   * Returns an object representing which required action filters should always be included for the list of fonts.
   *   e.g. If the upgrade action is available but the upgrade filter option should not be shown, according to the logic
   *   in hasUpgradeFilterOption(), the default action filters for this list of fonts should always include fonts with
   *   an upgrade action.
   *
   * @returns {{
   *   upgrade: {Boolean}
   * }}
   */
  function defaultActionFilters(fonts) {
    return {
      upgrade: hasFontsWithAction('upgrade', fonts) && !hasUpgradeFilterOption(fonts)
    };
  }

  /**
   * Filters fonts based on the required action preferences for resolving font entitlements and returns a preferred action
   * from the list of required action preferences.
   *
   * todo: Document the algorithm
   *
   * Returns an object that contains a list of filtered fonts and a property indicating what the preferred action to show
   * should be based on either a list of default actions for the list of fonts OR a list of action filters as indicated by
   * the requiredActionFilters param.
   *
   * @returns {{
   *   filteredFonts: {Array},
   *   preferredAction: {String}
   * }}
   */
  function filterFontsByRequiredActions(fonts, requiredActionFilters) {
    var filteredActions = requiredActionFilters || filterRequiredActionsByToggles(fonts, {}).filters;

    return _.filter(fonts, function (font) {
      var includeUpgrade = filteredActions.upgrade && syncHasAction('upgrade', font);
      return syncHasAction('preview_only', font) || webOnly(font) || (!!syncRequirements(font) && (_.isEmpty(syncRequirements(font)) || includeUpgrade));
    });
  }

  /**
   * Returns a set of required action filters based on selected action toggles OR the default action filters for a given
   * list of fonts. The "filters" property will be an object representing which filtered actions should be applied:
   *  e.g. {upgrade: true}
   *
   * Also returns a computed "preferred" action based on all of the action filters that apply. The preferred action will
   * always be set to "true" in the "filters" property.
   *
   * @returns {{
   *   filters: {Object},
   *   preferred: {String}
   * }}
   */
  function filterRequiredActionsByToggles(fonts, requiredActionToggles) {
    var preferredAction;
    var selectedActions = _.pick((requiredActionToggles || {}), _.identity);
    var filteredActions = angular.extend({}, defaultActionFilters(fonts), selectedActions);

    if (_.isEmpty(selectedActions)) {
      preferredAction = (filteredActions.upgrade && 'upgrade') || null;
    } else {
      preferredAction = (selectedActions.upgrade && 'upgrade') || null;
    }

    return {
      filters: filteredActions,
      preferred: preferredAction
    };
  }

  /**
   * The given list of fonts includes some that have the specified action available to resolve an entitlement for sync
   *
   * @returns {Boolean}
   */
  function hasFontsWithAction(action, fonts) {
    return _.some(fonts, syncHasAction.bind(null, action));
  }

  /**
   * Should an option for showing fonts with an upgrade action be visible?
   * Returns true if we have a mix of fonts available in both the free and paid libraries.
   *
   * @returns {Boolean}
   */
  function hasUpgradeFilterOption(fonts) {
    return hasAvailableFonts(fonts) && hasFontsWithAction('upgrade', fonts);
  }

  /**
   * Does the given list of fonts include some fonts that are already available to sync.
   * Returns true if the list of fonts includes any fonts that have empty sync requirements.
   *
   * @returns {Boolean}
   */
  function hasAvailableFonts(fonts) {
    return _.some(fonts, isSyncAvailable) || _.some(fonts, isWebAvailable);
  }

  /**
   * Is the font already available to sync?
   * Returns true if the font's sync requirements are empty
   *
   * @returns {Boolean}
   */
  function isSyncAvailable(font) {
    return !!syncRequirements(font) && _.isEmpty(syncRequirements(font));
  }

  /**
   * Is the font already available for web?
   * Returns true if the font's web requirements are empty
   *
   * @returns {Boolean}
   */
  function isWebAvailable(font) {
    return !!webRequirements(font) && _.isEmpty(webRequirements(font));
  }

  /**
   * The list of options available in order to acquire an entitlement to sync a given font
   *
   * @returns {Array}
   */
  function syncRequirements(font) {
    return ((font.font || {}).sync || {}).required_action;
  }

  /**
   * The list of options available in order to acquire an entitlement to sync a given font INCLUDES the given action
   * Returns true if the font's sync requirements includes the specified action as an option
   *
   * @returns {Boolean}
   */
  function syncHasAction(action, font) {
    return !!syncRequirements(font) && _.contains(syncRequirements(font), action);
  }

  /**
   * Is the given font only available for web?
   * Returns true under the following conditions:
   *   - if the font has no sync option
   *   - if the font's sync requirements includes "preview_only" and a web option exists (regardless of entitlement to the web font)
   *   - if an entitlement to the web use of the font exists but a sync entitlement requires an action
   *
   * @returns {Boolean}
   */
  function webOnly(font) {
    var onlyWebExists = (!syncRequirements(font) || syncHasAction('preview_only', font)) && !!webRequirements;
    var onlyWebAvailable = isWebAvailable(font) && !isSyncAvailable(font);
    return onlyWebExists || onlyWebAvailable;
  }

  /**
   * The list of options available in order to acquire an entitlement to use the given font in a web project
   *
   * @returns {Array}
   */
  function webRequirements(font) {
    return ((font.font || {}).web || {}).required_action;
  }
}

module.exports = FilterVariationsService;
