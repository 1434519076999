var SPECTRUM_LEFT_CLASS = 'spectrum-grid-start-xs';
var SPECTRUM_CENTER_CLASS = 'spectrum-grid-center-xs';
var STICKY_CLASS = 'sticky-nav--stuck';
var STICKY_NAV_CLASS = 'sticky-nav--sticky';
var UNSTICK_CLASS = 'sticky-nav--unstuck';

/**
 * @ngInject
 */
function StickyNavDirectiveController($document, $element, $scope) {
  this.$element = $element;
  this.$document = $document;
  this.$scope = $scope;

  this._stickyElement = $element[0];
  this._pushElement = this._getPushElement();
  this._isStuck = false;

  this._updateDimensions();
  this._initWatchers();
}

/**
 * Force the element to return to its initial non-stuck position.
 */
StickyNavDirectiveController.prototype.forceUnstick = function() {
  this._stickyElement.classList.remove(SPECTRUM_LEFT_CLASS);
  this._stickyElement.classList.remove(STICKY_CLASS);
  this._stickyElement.classList.add(SPECTRUM_CENTER_CLASS);
  this._stickyElement.classList.add(UNSTICK_CLASS);
  this._pushElement.style.display = 'none';

  this._forceUnstuck = true;
  this._isStuck = false;
};

/**
 * Handle resize events by recalculating the initial total offset.
 */
StickyNavDirectiveController.prototype.handleResize = function() {
  this._updateDimensions();
};

/**
 * Handle scroll events on the page.
 */
StickyNavDirectiveController.prototype.handleScroll = function() {
  if ((this._getScrollTop() >= this._initialTotalOffset) && this.isActive()) {
    this._stickyElement.classList.add(SPECTRUM_LEFT_CLASS);
    this._stickyElement.classList.add(STICKY_CLASS);
    this._stickyElement.classList.remove(SPECTRUM_CENTER_CLASS);
    this._pushElement.style.display = 'block';
    this._isStuck = true;
  } else if (this._getScrollTop() < this._initialTotalOffset){
    this._stickyElement.classList.remove(STICKY_CLASS);
    this._stickyElement.classList.remove(UNSTICK_CLASS);
    this._stickyElement.classList.add(SPECTRUM_CENTER_CLASS);
    this._stickyElement.classList.remove(SPECTRUM_LEFT_CLASS);
    this._pushElement.style.display = 'none';

    this._forceUnstuck = false;
    this._isStuck = false;
  }
};

/**
 * Is the sticky functionality disabled, either by forcing it to unstuck or by
 * the disabled attribute.
 *
 * @returns {Boolean}
 */
StickyNavDirectiveController.prototype.isActive = function() {
  return !this._forceUnstuck && !this.disabled();
};

/**
 * Returns the current amount the document has been scrolled.
 *
 * Webkit uses `document.body.scrollTop` and other browsers use
 * `document.documentElement.scrollTop`.
 *
 * @returns {Number}
 */
StickyNavDirectiveController.prototype._getScrollTop = function() {
  return this.$document[0].documentElement.scrollTop || this.$document[0].body.scrollTop;
};

/**
 * Create push element that will force the page to stay the same height when
 * container element is fixed.
 *
 * @private
 * @returns {Element}
 */
StickyNavDirectiveController.prototype._getPushElement = function() {
  var pushElement = document.createElement('div');
  pushElement.style.display = 'none';
  pushElement.style.height = this._stickyElement.offsetHeight + 'px';
  this._stickyElement.parentNode.insertBefore(pushElement, this._stickyElement);

  return pushElement;
};

/**
 * Return the combined page offset + element offset.
 *
 * @private
 * @returns {Number}
 */
StickyNavDirectiveController.prototype._getTotalOffset = function() {
  return this._getScrollTop() + this._stickyElement.getBoundingClientRect().top;
};

/**
 * Initializes watchers that watch the element state.
 * @private
 */
StickyNavDirectiveController.prototype._initWatchers = function() {
  var self = this;

  // Keep the height of the sticky element and the rest of the element in sync.
  self.$scope.$watch(function() {
    return self._stickyElement.offsetHeight;
  }, function() {
    self._updateDimensions();
  });

  // If the active state of the element changes, make sure that the dimensions
  // are up to date.
  self.$scope.$watch(function() {
    return self.isActive();
  }, function(active) {
    self._stickyElement.classList.toggle(STICKY_NAV_CLASS, active);
    self._updateDimensions();
  });
};

/**
 * Update the total offset and the push element height.
 * @private
 */
StickyNavDirectiveController.prototype._updateDimensions = function() {
  this._initialTotalOffset = this._getTotalOffset();
  this._pushElement.style.height = this._stickyElement.offsetHeight + 'px';
};

module.exports = StickyNavDirectiveController;
