/**
 * Controller for the tk-web-project-details directive.
 * @ngInject
 */
function TkWebProjectDetailsController() {
}

TkWebProjectDetailsController.prototype.getDateString = function() {
  return Date.parse(this.webProjectDetails().dateModified);
};

module.exports = TkWebProjectDetailsController;
