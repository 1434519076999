/**
 * A directive that creates a nav bar at the top of the page.
 *
 * To implement this directive, follow this example:
 *
 * In your template:
 *
 * <tab-navigation
 *      current-tab="controller.currentTab"
 *      navigation-tabs="controller.navigationTabs"
 *      default-tab-width=INTEGER>
 * </tab-navigation>
 *
 * In your Javascript Controller:
 *
 * - currentTab: The slug of the current tab. Use this in your JS controller to determine what tab is selected.
 * - tabNames: A JSON array of objects with the id and label of each tab
 *  [
 *    { id: 'tab_id_1', label: 'First Tab'},
 *    ...
 *    { id: 'tab_id_n', label: 'Last Tab'}
 *  ]
 *
 * @ngInject
 */
function Directive() {
  return {
    restrict: 'E',
    templateUrl: '/angular_templates/directives/tab_navigation.html',
    bindToController: {
      navigationTabs: '=',
      currentTab: '=',
      defaultTabWidth: '=?',
      onTabChange: '&'
    },
    controller: require('./controller.js'),
    controllerAs: 'tabNavigation'
  };
}

module.exports = Directive;
