var angular = require('angular');
var _ = require('underscore');
const UrlService = require('../../util/url_service.js').default;
var SECTION_ID_SUFFIX = '-section';

var ALL_ACTIVE_FONTS_FILTER_VALUE = 'all';
var ACTIVATED_IN_PAST_30_DAYS_FILTER_VALUE = 'under_30_days';

var DEFAULT_SORT_ORDER = 'updated_at_desc';

var FILTER_HANDLER_DEBOUNCE_TIME_MS = 100;

/**
 * @ngInject
 */
function MyFontsController($document,
                           $scope,
                           $window,
                           DataService,
                           DialogService,
                           FilterDataService,
                           FilteredFamilyListStateService,
                           FirstMileService,
                           FontCountService,
                           I18nService,
                           MyFontsService,
                           NewrelicService,
                           NotificationService,
                           TkWebProjectEditorService,
                           UserPrefsService) {
  var self = this;
  self.DataService = DataService;
  self.DialogService = DialogService;
  self.FilterDataService = FilterDataService;
  self.FilteredFamilyListStateService = FilteredFamilyListStateService;
  self.FirstMileService = FirstMileService;
  self.FontCountService = FontCountService;
  self.I18nService = I18nService;
  self.MyFontsService = MyFontsService;
  self.NewrelicService = NewrelicService;
  self.NotificationService = NotificationService;
  self.UserPrefsService = UserPrefsService;
  self.$document = $document;
  self.$scope = $scope;
  self.$window = $window;
  self.TkWebProjectEditorService = TkWebProjectEditorService;
  self._loadSortModes();
  self._preloadData();
  self.TkWebProjectEditorService.populateCompleteWebProjectsData(1);

  // Debounce this filter change handler so that updating the filters rapidly
  // won't put the filters list in a weird state.
  self.handleFiltersChange = _.debounce(
    angular.bind(self, self.updateFilteredFamilyList), FILTER_HANDLER_DEBOUNCE_TIME_MS);
}

/**
 * Selects the current browse mode.
 * @param {Object} browseMode
 */
MyFontsController.prototype.handleBrowseModeButton = function(browseMode) {
  this.filtersModel.setBrowseMode(browseMode.id);
  this.UserPrefsService.updatePrefs({
    browseMode: browseMode.id
  });
  this.filteredFamilyList.exampleText = null;

  this.handleFiltersChange();
};

/**
 * Persist user prefs when they change
 */
MyFontsController.prototype.handleFilteredFamilyListUserPrefsChange = function() {
  this.UserPrefsService.updatePrefs(this.filteredFamilyList);
};

/**
 * Is the current tab the "Active Fonts" tab?
 * @return {Boolean}
 */
MyFontsController.prototype.isActiveFontsView = function() {
  return this.currentTab && this.currentTab.id === 'active_fonts';
};

/**
 * Is the browse mode currently the selected browse mode?
 * @param {Object} browseMode
 * @return {Boolean}
 */
MyFontsController.prototype.isBrowseModeSelected = function(browseMode) {
  return this.filtersModel && this.filtersModel.getBrowseMode() == browseMode.id;
};

/**
 * Should we display the "empty" page view
 * (i.e., no families or web projects, depending on the tab)?
 * @return {Boolean}
 */
MyFontsController.prototype.isEmptyState = function() {
  if (this.isWebProjectsView()) {
    return !this.kitsData || this.kitsData.length === 0;
  }
  return this.isEmpty || this.filteredFamilyList.families.length === 0;
};

/**
 * Is the current tab the "Previously Active" tab?
 * @return {Boolean}
 */
MyFontsController.prototype.isPreviouslyActiveView = function() {
  return this.currentTab && this.currentTab.id === 'previously_active';
};

/**
 * Is the current tab the "Purchased" tab?
 * @return {Boolean}
 */
MyFontsController.prototype.isPurchasedTab = function() {
  return this.currentTab && this.currentTab.id === 'purchased';
};

/**
 * Is the current tab the "Web Projects" tab?
 * @return {Boolean}
 */
MyFontsController.prototype.isWebProjectsView = function() {
  return this.currentTab && this.currentTab.id === 'web_projects';
};

/**
 * Return the text for the button that lets users deactivate fonts in bulk.
 * @return {String}
 */
MyFontsController.prototype.manageFontsDeactivationButtonText = function() {
  if (this.selectedManageFontsFilterValue === ALL_ACTIVE_FONTS_FILTER_VALUE) {
    return this._i18n['my_fonts.active_fonts.manage_fonts.deactivate_all_button'];
  } else {
    return this._i18n['my_fonts.active_fonts.manage_fonts.deactivate_selected_button'];
  }
};

/**
 * Update page state when a new tab is clicked.
 */
MyFontsController.prototype.onTabChanged = function() {
  this.filteredFamilyList.currentPage = 1;
  this.selectedSort = DEFAULT_SORT_ORDER;
  this.filtersModel.setSortOrder(DEFAULT_SORT_ORDER);
  this._updateFamilySortModes();
  // If it's the first page load, we already updated the filtered family list in _preloadData
  if (!this.isFirstPageLoad) {
    this.isTabChanging = true;
    if (this.isWebProjectsView()) {
      // Clear URL params for the filtered family list to show the correct URL
      UrlService.updatePath(UrlService.getWindowLocation().pathname + '#web_projects' + SECTION_ID_SUFFIX);
    } else {
      this.updateFilteredFamilyList();
    }
  }
  this.isFirstPageLoad = false;
};

/**
 * Show the confirmation dialog for deactivating fonts in bulk.
 */
MyFontsController.prototype.showManageFontsConfirmationDialog = function() {
  var self = this;
  self.DialogService.show('/angular_templates/dialogs/manage_fonts_confirmation.html').then(function(dialog) {
    dialog.handleCancelButtonClick = function() {
      dialog.close();
    };
    dialog.handleConfirmButtonClick = function() {
      var search = _.omit(_.extend(self.filtersModel.toSearchParams(), self._myFontsParams(), {
        manage_fonts_filter: self.selectedManageFontsFilterValue
      }), _.isNull);
      self.MyFontsService.deactivateFonts(search).then(function() {
        dialog.close();
        self.NewrelicService.addPageAction('typekit.click.bulk_deactivate_button', {
          activatedFontsCount: self.FontCountService.getAllActivatedFontsCount(),
          filter: self.selectedManageFontsFilterValue,
          fontsDeactivated: self._getActivatedFontsCountForSelectedFilter()
        });
        self.NotificationService.success(self._getManageFontsDeactivationSuccessMessage());
        self._clearFilters();
        self.updateFilteredFamilyList();
      }, function() {
        dialog.close();
        self.NotificationService.error(self._i18n['my_fonts.active_fonts.manage_fonts.deactivation_error_message']);
      });
    };
    dialog.heading = self._manageFontsConfirmationDialogHeading();
  });
};

MyFontsController.prototype.handleSortModeChange = function() {
  this.filtersModel.setSortOrder(this.selectedSort);
  this.filteredFamilyList.currentPage = 1;
  this.handleFiltersChange();
};

MyFontsController.prototype.handleManageFontsFilterChange = function() {
  this.filteredFamilyList.currentPage = 1;
  this.handleFiltersChange();
};

MyFontsController.prototype.getTotalFontsText = function() {
  var numFamilies = this.FontCountService.getAllFamiliesCount();
  if (this.isActiveFontsView()) {
    return this._getTotalActiveFontsText(this.FontCountService.getAllActivatedFontsCount(), numFamilies);
  }
  return this._getTotalPreviousActiveFontsText(this.FontCountService.getAllPreviouslyActivatedFontsCount(), numFamilies);
};

/**
 * Updates the list of families using the current properties set on the filtered
 * family list.
 */
MyFontsController.prototype.updateFilteredFamilyList = function() {
  var self = this;
  var search = _.omit(_.extend(self.filtersModel.toSearchParams(), self._myFontsParams()), _.isNull);
  self.filtersModel.setCurrentPage(self.filteredFamilyList.currentPage);
  self.isLoading = true;
  self.MyFontsService.get(search).then(function(response) {
    if (response.data.collection === self.currentTab.id) {
      self.filteredFamilyList.families = response.data.familiesData.families;
      if (search.page === 1) {
        self.fontFamilyAccordion.families = response.data.familiesData.families;
      } else {
        var familiesToAdd = _.reject(response.data.familiesData.families, function(family) {
          return _.any(self.fontFamilyAccordion.families, function(otherFamily) {
            return family.slug === otherFamily.slug;
          });
        });
        self.fontFamilyAccordion.families = self.fontFamilyAccordion.families.concat(familiesToAdd);
      }
      self.filteredFamilyList.totalFamilies = response.data.familiesData.totalFamilies;
      self.filteredFamilyList.totalPages = response.data.familiesData.totalPages;
      self.purchasedFonts = response.data.familiesData.purchasedFonts;
      self.textSampleData = response.data.textSampleData;
      self.FilteredFamilyListStateService.updateState(self.filteredFamilyList);

      self.manageFontsFiltersData = response.data.manageFontsFilters;
      self.manageFontsFilters = self._getManageFontsFilters(self.manageFontsFiltersData);
      self.FontCountService.setAllFamiliesCount(response.data.familiesData.totalFamilies);
      self.FontCountService.setAllPreviouslyActivatedFontsCount(response.data.allPreviouslyActivatedFontsCount);
      self.FontCountService.setAllActivatedFontsCount(response.data.allActivatedFontsCount);
      self.FontCountService.setFilteredActivatedFontsCount(self._getFilterWithValue(ALL_ACTIVE_FONTS_FILTER_VALUE).activatedFontsCount);
      self.FontCountService.setThirtyDayActivatedFontsCount(self._getFilterWithValue(ACTIVATED_IN_PAST_30_DAYS_FILTER_VALUE).activatedFontsCount);
      self.isEmpty = response.data.isEmpty;
    }
    self.filteredFamilyList.browseMode = self.filtersModel.getBrowseMode();
    self.filteredFamilyList.currentPage = self.filtersModel.getCurrentPage();
    self.isLoading = false;
    self.isTabChanging = false;
    self.FilteredFamilyListStateService.updateUrl(search);
    if (response.data.shouldShowExpiredFontsModal) {
      self.FirstMileService.showExpiredFontsDialog();
    }
  });
};

MyFontsController.prototype.loadMoreFamilies = function() {
  if (this.filteredFamilyList.currentPage >= this.filteredFamilyList.totalPages || this.isLoading) {
    return;
  }
  this.filteredFamilyList.currentPage += 1;
  this.updateFilteredFamilyList();
};

MyFontsController.prototype._getTotalActiveFontsText = function(numFonts, numFamilies) {
  if (numFonts > 1 && numFamilies > 1) {
    return this.I18nService.getMessage(this._i18n, 'my_fonts.active_fonts.font_count.plural_fonts_plural_families', {
      num_fonts: numFonts,
      num_families: numFamilies
    });
  } else if (numFonts > 1) {
    return this.I18nService.getMessage(this._i18n, 'my_fonts.active_fonts.font_count.plural_fonts_singular_family', {
      num_fonts: numFonts
    });
  } else {
    return this._i18n['my_fonts.active_fonts.font_count.singular_font_singular_family'];
  }
};

MyFontsController.prototype._getTotalPreviousActiveFontsText = function(numFonts, numFamilies) {
  if (numFonts > 1 && numFamilies > 1) {
    return this.I18nService.getMessage(this._i18n, 'my_fonts.previously_active_fonts.font_count.plural_fonts_plural_families', {
      num_fonts: numFonts,
      num_families: numFamilies
    });
  } else if (numFonts > 1) {
    return this.I18nService.getMessage(this._i18n, 'my_fonts.previously_active_fonts.font_count.plural_fonts_singular_family', {
      num_fonts: numFonts
    });
  } else {
    return this._i18n['my_fonts.previously_active_fonts.font_count.singular_font_singular_family'];
  }
};

/**
 * Changes label of the sort mode with the value provided to the new label provided
 *
 * @param {String} value
 * @param {String} newLabelI18n
 * @private
 */
MyFontsController.prototype._changeFamilySortLabel = function(value, newLabelI18n) {
  var sortMode = _.find(this.familySortModes, function(familySortMode) {
    return familySortMode.value === value;
  });

  sortMode.label = this._i18n[newLabelI18n];
};

/**
 * Clears sidebar and Manage Fonts filter values
 *
 * @private
 */
MyFontsController.prototype._clearFilters = function() {
  this.filtersModel.clearAll();
  this.selectedManageFontsFilterValue = ALL_ACTIVE_FONTS_FILTER_VALUE;
};

/**
 * This function returns the count corresponding to the filter provided.
 *
 * Font counts for the "All active fonts" and "Activated in the past 30 days"
 * filters can change when activating or deactivating fonts on the My Fonts page.
 * Other filters should have an activated fonts count associated with them.
 *
 * @param {Object} filter
 * @returns {Number}
 * @private
 */
MyFontsController.prototype._getActivatedFontsCount = function(filter) {
  if (filter.value === ALL_ACTIVE_FONTS_FILTER_VALUE) {
    return this.FontCountService.getFilteredActivatedFontsCount();
  } else if (filter.value === ACTIVATED_IN_PAST_30_DAYS_FILTER_VALUE) {
    return this.FontCountService.getThirtyDayActivatedFontsCount();
  } else if (filter.activatedFontsCount || filter.activatedFontsCount === 0) {
    return filter.activatedFontsCount;
  }
};

/**
 * Convenience function for getting the count corresponding to the currently selected filter.
 *
 * @returns {Number}
 * @private
 */
MyFontsController.prototype._getActivatedFontsCountForSelectedFilter = function() {
  return this._getActivatedFontsCount(this._getFilterWithValue(this.selectedManageFontsFilterValue));
};

/**
 * Return the filter with the value equal to the filter value provided.
 *
 * @param {String} filterValue
 *
 * @returns {Object}
 * @private
 */
MyFontsController.prototype._getFilterWithValue = function(filterValue) {
  var self = this;
  return _.find(self.manageFontsFiltersData, function(filter) {
    return filter.value === filterValue;
  });
};

/**
 * Return the message displayed after successfully deactivating fonts
 * with the Manage Fonts tool.
 *
 * @returns {String}
 * @private
 */
MyFontsController.prototype._getManageFontsDeactivationSuccessMessage = function() {
  var numFonts = this._getActivatedFontsCountForSelectedFilter();
  if (numFonts > 1) {
    return this.I18nService.getMessage(this._i18n, 'my_fonts.active_fonts.manage_fonts.deactivation_success_message_plural', {
      num_fonts: numFonts
    });
  } else {
    return this._i18n['my_fonts.active_fonts.manage_fonts.deactivation_success_message_singular'];
  }
};

/**
 * Compute the dropdown menu item label and value for the filters provided
 *
 * @param {Array} filters
 * @returns {Array}
 * @private
 */
MyFontsController.prototype._getManageFontsFilters = function(filters) {
  var self = this;
  filters = _.filter(filters, function(filter) {
    return self._getActivatedFontsCount(filter) > 0;
  });
  return _.map(filters, function(filter) {
    return {
      activatedFontsCount: filter.activatedFontsCount,
      i18nName: filter.i18nName,
      label: self._i18n['my_fonts.active_fonts.manage_fonts.filters.' + filter.i18nName]
        .replace('%{num_fonts}', self._getActivatedFontsCount(filter)),
      value: filter.value
    };
  });
};

/**
 * Update counts in Manage Fonts filters when a font is activated or deactivated
 *
 * @private
 */
MyFontsController.prototype._initActivatedFontsCountWatcher = function() {
  var self = this;
  self.$scope.$watch(function() {
    return self.FontCountService.getFilteredActivatedFontsCount();
  }, function() {
    self.manageFontsFilters = self._getManageFontsFilters(self.manageFontsFiltersData);
  });
};

/**
 * Load the list of sort modes for the web projects.
 * @private
 */
MyFontsController.prototype._loadSortModes = function() {
  var self = this;
  self.DataService.get('web-project-sort-modes').then(function(sortModes) {
    self.sortModes = sortModes;
  });
};

/**
 * Return the heading of the dialog to bulk deactivate fonts
 * via the Manage Fonts section, depending on the filter selected.
 * @private
 * @return {String}
 */
MyFontsController.prototype._manageFontsConfirmationDialogHeading = function() {
  var numFonts = this._getActivatedFontsCountForSelectedFilter();

  if (numFonts > 1) {
    return this.I18nService.getMessage(this._i18n, 'my_fonts.active_fonts.manage_fonts.confirmation_dialog.heading_plural', {
      num_fonts: numFonts
    });
  } else {
    return this._i18n['my_fonts.active_fonts.manage_fonts.confirmation_dialog.heading_singular'];
  }
};

/**
 * Return tab and page number params for making a request to /my_fonts
 * @return {Object}
 */
MyFontsController.prototype._myFontsParams = function() {
  return {
    collection: this.currentTab.id,
    manage_fonts_filter: this.selectedManageFontsFilterValue,
    page: this.filteredFamilyList.currentPage
  };
};

/**
 * Initialize the filtersModel with a language filter mode and/or a selected language.
 *
 * This method is trying to be backwards compatible with existing browse_mode params.
 *
 * @private
 */
MyFontsController.prototype._setLanguageFilters = function(browseMode) {
  var self = this;

  if (self.filteredFamilyList.selectedLanguages.length > 0) {
    self.filteredFamilyList.selectedLanguages.forEach(function(lang) {
      self.filtersModel.selectLanguageMode(lang);
    });
  } else if (browseMode === 'default') {
    // To support the existing default browse mode, if it is present, set the browse mode to default.
    self.filtersModel.setBrowseMode(browseMode);
  } else if (browseMode === 'japanese') {
    // To support the existing japanese browse mode, if it is present, set the selected language mode to "ja".
    self.filtersModel.selectLanguageMode('ja');
  } else {
    // Otherwise, just set the new language mode to match the browse mode.
    self.filtersModel.selectLanguageMode(browseMode);
  }
};

/**
 * Preload data using DataService.
 * @private
 */
MyFontsController.prototype._preloadData = function() {
  var self = this;
  self.UserPrefsService.getUserPrefs().then(function(userPrefs) {
    self.DataService.get('/spectrum/preloaded_family_data').then(function (data) {
      self.filteredFamilyList =
        self.FilteredFamilyListStateService.getState(data.user, userPrefs);
      self.filteredFamilyList.families = data.familiesData.families;
      self.fontFamilyAccordion = {
        families: data.familiesData.families
      };
      self.filteredFamilyList.totalFamilies = data.familiesData.totalFamilies;
      self.filteredFamilyList.totalPages = data.familiesData.totalPages;

      self.filtersModel = self.FilterDataService.getFilterModel({
        allFilters: data.familyFilters,
        collection: self.filteredFamilyList.collection,
        filterData: data.familyFilterData,
        hideLibraryOption: true,
        hideMarketplaceOption: true,
        languages: data.languageFilters,
        sortOrder: DEFAULT_SORT_ORDER,
        locale: data.locale
      });

      self.allBrowseModes = data.browseModes;
      self._setLanguageFilters(userPrefs.browseMode || self.allBrowseModes[0].id);
      self.filtersModel.setSelectedFilters(self.filteredFamilyList.selectedFilters);

      self.filtersModel.setShowPaidFonts(self.filteredFamilyList.showPaidFonts || userPrefs.showPaidFonts, false);

      self.manageFontsFiltersData = data.manageFontsFilters;
      self.FontCountService.setAllFamiliesCount(data.familiesData.totalFamilies);
      self.FontCountService.setAllPreviouslyActivatedFontsCount(data.allPreviouslyActivatedFontsCount);
      self.FontCountService.setAllActivatedFontsCount(data.allActivatedFontsCount);
      self.FontCountService.setFilteredActivatedFontsCount(self._getFilterWithValue(ALL_ACTIVE_FONTS_FILTER_VALUE).activatedFontsCount);
      self.FontCountService.setThirtyDayActivatedFontsCount(self._getFilterWithValue(ACTIVATED_IN_PAST_30_DAYS_FILTER_VALUE).activatedFontsCount);
      self.isEmpty = data.isEmpty;
      self.purchasedFonts = data.familiesData.purchasedFonts;
      self.textSampleData = data.textSampleData;
      self.navigationTabs = data.navigationTabs;
      self._setCurrentTab();
      self._setExampleText();

      self.DataService.get('/my_fonts/i18n').then(function(i18n) {
        self._i18n = i18n;
        self.manageFontsFilters = self._getManageFontsFilters(self.manageFontsFiltersData);
        self.selectedManageFontsFilterValue = data.manageFontsFilters[0].value;
        self._initActivatedFontsCountWatcher();
      });

      self.DataService.get('family-sort-modes').then(function(familySortModes) {
        self.familySortModes = familySortModes;
      });
      self.DataService.get('family-accordion-sort-modes').then(function(familyAccordionSortModes) {
        self.familyAccordionSortModes = familyAccordionSortModes;
      });
      self.selectedSort = self.filtersModel.getSortOrder();
      if (data.shouldShowExpiredFontsModal) {
        self.FirstMileService.showExpiredFontsDialog();
      }
    });
  });

  self.DataService.get('/spectrum/kits_data').then(function(data) {
    if (data && data.kitsData) {
      self.TkWebProjectEditorService.initTkWebProjectListModel(data);
      self.kitsData = data.kitsData;
      var i;
      for (i = 0; i < self.kitsData.length; i++) {
        var kitData = self.kitsData[i];
        self.TkWebProjectEditorService.initTkWebProjectCardModel(kitData.kit.kit.token, kitData);
        self.TkWebProjectEditorService.initEmbedCodeModel(kitData.kit.kit.token, kitData.is_dynamic, kitData.project_embed_details);

        var j = 0;
        for (j = 0; j < kitData.tkff_list.length; j++) {
          var tkffData = kitData.tkff_list[j];
          // Id which considers both the kit token and the tkff id
          var id = kitData.kit.kit.token + tkffData.tkff.typekit_font_family.id;
          self.TkWebProjectEditorService.initTkWebProjectDisplayFamilyModel(
              id,
              tkffData.display_variation,
              tkffData.text_samples,
              tkffData.tktf_slug
          );
        }
      }
    }
  });
};

/**
 * Set correct tab
 * @private
 */
MyFontsController.prototype._setCurrentTab = function() {
  // Length of '-section' should be removed from the tabHash
  var self = this;
  var tabHash = self.$window.location.hash;
  var lengthToBeStripped = SECTION_ID_SUFFIX.length + 1;
  var tabId = tabHash.substr(1, tabHash.length - lengthToBeStripped);
  if (tabId.length !== 0) {
    self.navigationTabs.forEach(function (tab) {
      if (tab.id === tabId) {
        self.currentTab = tab;
      }
    });
  }
  if (self.currentTab === undefined) {
    self.isFirstPageLoad = true;
    self.currentTab = self.navigationTabs[0];
  }
};

/*
 * Sets example text based on language
 * @private
 */
MyFontsController.prototype._setExampleText = function() {
  var self = this;
  var lang = _.first(_.uniq(_.pluck(self.purchasedFonts || [], 'default_language'))) || 'en';
  var textSample = self.textSampleData.textSamples[lang];
  self.exampleText = {
    value: self.kanaOnly ? textSample.list_kana : textSample.list,
    size: 36
  };
  self.featureSettings = textSample.feature_settings;
  self.unicodeRange = textSample.unicode_range;
  self.defaultExampleTextValue = self.exampleText.value;
};

/**
 * Toggle family sort mode labels depending on tab
 *
 */
MyFontsController.prototype._updateFamilySortModes = function() {
  if (this.isActiveFontsView()) {
    this._changeFamilySortLabel('updated_at_asc', 'my_fonts.sort.date_activated_oldest_to_newest');
    this._changeFamilySortLabel('updated_at_desc', 'my_fonts.sort.date_activated_newest_to_oldest');
  } else if (this.isPreviouslyActiveView()) {
    this._changeFamilySortLabel('updated_at_asc', 'my_fonts.sort.date_deactivated_oldest_to_newest');
    this._changeFamilySortLabel('updated_at_desc', 'my_fonts.sort.date_deactivated_newest_to_oldest');
  }
};

module.exports = MyFontsController;
