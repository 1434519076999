/**
 * @ngInject
 */
function AdobeFontsFontVariationList() {
  return {
    scope: {},
    bindToController: {
      exampleText: '=',
      textSampleData: '&',
      fonts: '='

    },
    controller: require('./controller.js'),
    controllerAs: 'fontVariationListCtrl',
    restrict: 'E',
    replace: true,
    templateUrl: '/angular_templates/directives/adobe_fonts_font_variation_list.html'
  };
}

module.exports = AdobeFontsFontVariationList;
