/**
 * Controller for the tk-web-project-family-card directive.
 * @ngInject
 */
function TkWebProjectFamilyCardController($window, TkWebProjectEditorService) {
  this.$window = $window;
  this.tkWebProjectEditorService = TkWebProjectEditorService;
  this._initEventListeners();
}

TkWebProjectFamilyCardController.prototype.deleteTkff = function() {
  if (this.tkWebProjectFamilyCardModel.isAnyFontRetired) {
    this.tkWebProjectEditorService.openRetiredFontsRemovalConfirmationDialog(this.tkWebProjectCardModel, this.tkWebProjectFamilyCardModel);
  } else {
    this.tkWebProjectCardModel.deleteTkff(this.tkWebProjectFamilyCardModel.getTkffData());
  }
};

TkWebProjectFamilyCardController.prototype.getStyleSelectorModel = function() {
  var id = this.tkWebProjectCardModel.getProjectDetails().kitToken + this.tkWebProjectFamilyCardModel.getTkffData().tkff.typekit_font_family.id;
  return this.tkWebProjectEditorService.getTkWebProjectEditorStyleSelectorModel(id);
};

TkWebProjectFamilyCardController.prototype.getFontVariations = function() {
  var selectorModel = this.getStyleSelectorModel();
  var cssFontStack = this.tkWebProjectFamilyCardModel.getFormattedCssFontStack();
  return selectorModel.webcomponents_getAvailableFontVariations(cssFontStack);
};

TkWebProjectFamilyCardController.prototype.getSampleText = function(variation) {
  return this.getStyleSelectorModel().getTextSamples()[variation.defaultLanguage].list;
};

TkWebProjectFamilyCardController.prototype.getUnicodeRange = function(variation) {
  return this.getStyleSelectorModel().getTextSamples()[variation.defaultLanguage].unicode_range;
};

TkWebProjectFamilyCardController.prototype._initEventListeners = function() {
  var self = this;
  self.$window.addEventListener('web-project.checkbox-checked', function(event) {
    if (!self.tkWebProjectFamilyCardModel.hasVariationId(event.detail.variationId)) {
      return;
    }
    if (event.detail.isChecked) {
      self.tkWebProjectFamilyCardModel.updateVariationsEnabled(event.detail.variationId);
    } else {
      self.tkWebProjectFamilyCardModel.updateVariationsDisabled(event.detail.variationId);
    }
    self.tkWebProjectCardModel.setIsModified(true);
    self.tkWebProjectCardModel.computeTotalVariations();
  });
};

module.exports = TkWebProjectFamilyCardController;
