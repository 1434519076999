/**
 * A directive that can be used to render a list of families and a set of
 * controls used to filter and control the display of the families.
 *
 * @ngInject
 */
function Directive() {
  return {
    scope: {},
    bindToController: {
      filtersModel: '&',
      onUserPrefsChange: '&',

      // showNoAccessOnEmptyState indicates that if the current view produces an empty filtered families list the
      // user can do nothing about it and therefore the "No Access" empty state should be shown. Otherwise, the
      // standard empty state will be shown indicating the user should change filters to produce a populated list of
      // filtered families.
      showNoAccessOnEmptyState: '&',
      textSampleData: '&',
      noBackground: '&',
      showCarousel: '@',
      disableSort: '&',
      isPreviouslyActiveView: '&',
      preloadedSortModes: '&',
      showReturnToFirstPage: '&'
    },
    controller: require('./controller.js'),
    controllerAs: 'filteredFamilyList',
    require: ['filteredFamilyList', 'ngModel'],
    link: function(scope, element, attrs, requiredControllers) {
      var filteredFamilyList = requiredControllers[0];
      var ngModel = requiredControllers[1];

      // Store ngModel on the filteredFamilyList controller so it's available to
      // update the view value.
      filteredFamilyList.ngModel = ngModel;
    },
    restrict: 'E',
    replace: true,
    templateUrl: '/angular_templates/directives/filtered_family_list.html'
  };
}

module.exports = Directive;
