var lory = require('lory-lesara.js').lory;
var _ = require('underscore');

var CAROUSEL_CSS_CLASS = 'adobe-fonts-carousel';
var CAROUSEL_CSS_CLASS_ACTIVE = 'adobe-fonts-carousel--active';
var CAROUSEL_CSS_CLASS_FRAME = 'adobe-fonts-carousel__frame';

var LOAD_TIMEOUT_MS = 400;
var MAX_LOAD_RETRIES = 10;
var MAX_SLIDES_IN_VIEWPORT = 3;

/**
 * @ngInject
 */
function AdobeFontsCarouselController($element, $scope, $timeout, $window) {
  var self = this;
  self.$element = $element;
  self.$timeout = $timeout;
  self.$window = $window;
  self.previousSlide = 0;

  self.currentDot = 0;
  self.hidePreviousAndNextButtons = self.hidePreviousAndNextButtons || false;
  self.hidePreviousAndNextButtonsInitialSetting = self.hidePreviousAndNextButtons;

  // How many times have we tried to load this carousel?
  self._loadAttempts = 0;
  self.areSlidesLoaded = false;
  self._currentNumSlidesInViewport = 0;

  // Don't even try to load the carousel until the next animation frame, because
  // Angular may still be loading the content inside of it.
  $window.requestAnimationFrame(function() {
    self._loadCarousel();
  });

  if (this.isFamilyImageCarousel) {
    $scope.$watch(function() {
      return self.layout;
    }, function() {
      $timeout(function() {
        if (self.isCarouselRequired()) {
          self._activateCarousel();
        }
      }, 1);
    });
  }

  // Create a new carousel if its contents change
  $scope.$watch(function() {
    return self._getSlidesHtml();
  }, function(newValue, oldValue) {
    if (newValue !== oldValue) {
      if (self.isRecommendedFontCarousel) {
        self._activateCarouselWithSamePosition();
      } else {
        self._activateCarousel();
        self.selectDot(0);
      }
    }
  });

  // Listen for resize events on the page and reevaluate if lory is required
  // once the page has been resized.
  // This sets the current slide back to zero, so it should be skipped if the carousel is always present.
  $window.addEventListener('resize', function () {
    if (!self.alwaysHasCarousel) {
      if (self.isCarouselRequired()) {
        self._activateCarousel();
      } else if (self.carousel) {
        self._destroyCarousel();
      }
    }
    if (self.isRecommendedFontCarousel && (self._currentNumSlidesInViewport !== self._numSlidesInViewport())) {
      self._activateCarouselWithSamePosition();
    }
    // Update dot navigation
    $scope.$apply(function () {
      self.isCarouselRequired();
    });
    self._currentNumSlidesInViewport = self._numSlidesInViewport();
  });
}

/**
 * Provides a range for ng-repeat to add one dot for every slide in the carousel.
 *
 * @returns {Array}
 */
AdobeFontsCarouselController.prototype.dotNumbers = function() {
  return _.range(0, this._numSlides());
};

/**
 * Return href for link
 *
 * @returns {String}
 */
AdobeFontsCarouselController.prototype.getLinkHref = function() {
  if (!this.links || (!this.currentDot && this.currentDot !== 0)) {
    return '';
  }
  return (JSON.parse(this.links)[this.currentDot] || {}).href || '';
};

/**
 * Return text for link
 *
 * @returns {String}
 */
AdobeFontsCarouselController.prototype.getLinkName = function() {
  if (!this.links || (!this.currentDot && this.currentDot !== 0)) {
    return '';
  }
  return (JSON.parse(this.links)[this.currentDot] || {}).name || '';
};

/**
 * Method to show the dot control if required along with the carousel
 *
 * @returns {Boolean|boolean}
 */
AdobeFontsCarouselController.prototype.shouldShowDotControl = function() {
  if (this.isFamilyImageCarousel) {
    return (this.itemSize > 1 && this._numSlidesInViewport() === 1) || this.itemSize > MAX_SLIDES_IN_VIEWPORT || this.layout === 'grid';
  } else if (this.isRecommendedFontCarousel) {
    return false;
  } else {
    return this.isCarouselRequired();
  }
};

/**
 * Are there enough elements that the carousel should be displayed?
 * AKA: does the content of the frame overflow past the bounds of the
 * frame?
 *
 * @returns {Boolean}
 */
AdobeFontsCarouselController.prototype.isCarouselRequired = function() {
  var frameEl = this._getFrameEl();
  if (this.isFamilyImageCarousel) {
    if (this.layout === 'grid') {
      // Add this condition to avoid any errors in activating lory
      if (this._isContentLoaded()) {
        this.areSlidesLoaded = true;
      }
      return this.areSlidesLoaded;
    } else {
      // In some cases in wide family view, the carousel is shown due to extra margin even when it is not needed.
      // Hence adding the buffer to avoid carousel when it is isn't required.
      var sizeBuffer = 40;
      return frameEl.clientWidth < frameEl.scrollWidth - sizeBuffer;
    }
  }
  return frameEl.clientWidth < frameEl.scrollWidth;
};

/**
 * Should the dot at the index provided be shown as active?
 *
 * @param {Number} index
 * @returns {Boolean}
 */
AdobeFontsCarouselController.prototype.isDotActive = function(index) {
  return this.currentDot === index;
};

/**
 * Selects the dot at the index provided.
 *
 * @param {Number} index
 */
AdobeFontsCarouselController.prototype.selectDot = function(index) {
  this.carousel.slideTo(index);
  this.currentDot = index; // Handles clicking the currently active dot or the last dot
};

/**
 * Should the dot at the index provided be visible?
 *
 * @param {Number} index
 * @returns {Boolean}
 */
AdobeFontsCarouselController.prototype.shouldShowDot = function(index) {
  return index < this._numDotsVisible();
};

/**
 * Should the previous and next buttons be visible?
 * Set them to hidden if either:
 *  1. They were originally set to hidden
 *  2. The number of visible slides is equal to the total number of slides
 *
 * @returns {Boolean}
 */
AdobeFontsCarouselController.prototype._updatePreviousAndNextButtonsVisibility = function() {
  if (!this.hidePreviousAndNextButtonsInitialSetting) {
    this.hidePreviousAndNextButtons = this._numSlidesInViewport() >= this._numSlides();
  }
};

/**
 * Create a new instance of lory that wraps the content.
 *
 * @private
 */
AdobeFontsCarouselController.prototype._activateCarousel = function() {
  const loryElement = this.$element[0].querySelector('.' + CAROUSEL_CSS_CLASS);
  if (this.isRecommendedFontCarousel){
    this.carousel = lory(loryElement, {
      slidesToScroll: this._numSlidesInViewport(),
      rewindOnResize: this.isRecommendedFontCarousel,
      forceIndexUpdate: true
    });
  } else {
    this.carousel = lory(loryElement, {
      rewind: !this.isFamilyImageCarousel
    });
  }
  this.$element.addClass(CAROUSEL_CSS_CLASS_ACTIVE);
  this.$element[0].addEventListener('before.lory.slide', this._handleSlideEvent.bind(this));
  if (this.isFamilyImageCarousel) {
    this.selectDot(0);
  }
  if (this.isFamilyImageCarousel || this.isRecommendedFontCarousel) {
    this.$element[0].addEventListener('after.lory.slide', this._handleAfterSlideEvent.bind(this));
  }
  this._updatePreviousAndNextButtonsVisibility();
  this._currentNumSlidesInViewport = this._numSlidesInViewport();
};

/**
 * Activates a new instance of the carousel while keeping the current slide position
 *
 * @private
 */
AdobeFontsCarouselController.prototype._activateCarouselWithSamePosition = function () {
  var currentIndex = 0;
  if (this.carousel) {
    currentIndex = this.carousel.returnIndex();
  }
  this._activateCarousel();
  this.selectDot(currentIndex);
};

/**
 * Destroy the current instance of lory.
 *
 * @private
 */
AdobeFontsCarouselController.prototype._destroyCarousel = function() {
  this.carousel.destroy();
  this.carousel = null;
  this.$element.removeClass(CAROUSEL_CSS_CLASS_ACTIVE);
};

/**
 * Return the element that represents the carousel's frame.
 *
 * @private
 * @returns {Element}
 */
AdobeFontsCarouselController.prototype._getFrameEl = function() {
  return this.$element[0].querySelector('.' + CAROUSEL_CSS_CLASS_FRAME);
};

/**
 * Return HTML of the slides in the carousel
 *
 * @private
 * @returns {String}
 */
AdobeFontsCarouselController.prototype._getSlidesHtml = function() {
  return [].slice.call(this.$element[0].getElementsByTagName('li')).map(function(li) {
    return li.innerHTML;
  }).join('');
};

/**
 * Updates the current dot on carousel slide.
 *
 * If the next slide is equal to the current slide, you either are currently at the end of the carousel
 * or clicked on the active dot. The latter case is handled in selectDot, so we can assume here
 * that the next button was clicked (adding a click event to determine this breaks the next button on mobile).
 * If the next slide is negative, you clicked the previous button on the first slide.
 *
 * @param {Event} event
 * @private
 */
AdobeFontsCarouselController.prototype._handleSlideEvent = function(event) {
  var nextSlide = event.detail.nextSlide;
  if (this.isFamilyImageCarousel) {
    if (nextSlide === this.previousSlide || nextSlide === this._numDotsVisible()) {
      return;
    }
    this.previousSlide = nextSlide;
  }
  this.currentDot = (this.currentDot === nextSlide || nextSlide < 0) ? 0 : nextSlide;
};

/**
 * Method to handle the after slide event of the carousel. This has been added to handle
 * the cases when chevron is not disabled after looping through the images.
 *
 * Lory only handles the cases when there are exact number of images in the screen.
 * For example, if you are going for 3 images in the carousel, then all the frames
 * should have exactly 3 images. So when in the last frame you have less than 3 images,
 * the library does not consider that as the end of the carousel and hence does not
 * disable the right chevron. So we have to do it ourselves here.
 *
 * Since grid view has just one image in a frame, it is not a problem for that
 * layout, this has to be done for other layouts.
 *
 * @param event
 * @private
 */
AdobeFontsCarouselController.prototype._handleAfterSlideEvent = function(event) {
  var currentSlide = event.detail.currentSlide + 1;
  var nextCtrl = this.$element[0].getElementsByClassName('adobe-fonts-carousel__next-button')[0];
  var previousCtrl = this.$element[0].getElementsByClassName('adobe-fonts-carousel__previous-button')[0];
  if (this.isRecommendedFontCarousel) {
    if (currentSlide >= this._numDotsVisible()) {
      nextCtrl.classList.add('disabled');
      previousCtrl.classList.remove('disabled');
    } else if (currentSlide <= 1) {
      previousCtrl.classList.add('disabled');
      nextCtrl.classList.remove('disabled');
    } else {
      nextCtrl.classList.remove('disabled');
      previousCtrl.classList.remove('disabled');
    }
  } else if (this.layout !== 'grid' && currentSlide === this._numDotsVisible()) {
    nextCtrl.classList.add('disabled');
  } else if (this.layout === 'family-carousel' && currentSlide <= this._numDotsVisible()) {
    nextCtrl.classList.remove('disabled');
  }
};

/**
 * Checks the scroll width of the frame element. If the width is zero,
 * assume that some of the content is dynamic and that it hasn't been
 * loaded yet.
 *
 * If the content's scroll width is equal to its client width it still
 * might not be fully loaded.
 *
 * @private
 * @returns {Boolean}
 */
AdobeFontsCarouselController.prototype._isContentLoaded = function() {
  var frameEl = this._getFrameEl();
  return frameEl.scrollWidth > 0 &&
    frameEl.scrollWidth !== frameEl.clientWidth;
};

/**
 * Loads the carousel if it's required for this element. The content inside of the
 * element may be loaded dynamically (with Angular, most likely), it will retry
 * several times if the content hasn't completely loaded yet.
 *
 * @private
 */
AdobeFontsCarouselController.prototype._loadCarousel = function() {
  // If the content hasn't loaded yet, schedule a retry if the max # of retry
  // attempts hasn't been exceeded.
  var self = this;
  if (!self._isContentLoaded() && self._loadAttempts < MAX_LOAD_RETRIES) {
    self._loadAttempts++;

    return self.$timeout(function() {
      self._loadCarousel();
    }, LOAD_TIMEOUT_MS);
  }

  if (self.isCarouselRequired()) {
    return self._activateCarousel();
  }
  self._updatePreviousAndNextButtonsVisibility();
};

/*
 * Returns the number of navigation dots that are visible.
 *
 * There is one dot in the DOM for each slide in the carousel.
 * However, unless the viewport is very small (for example, a mobile screen),
 * there will be multiple slides visible in the carousel.
 * If every dot were visible in that case and you have reached the end of the carousel,
 * you would have extra dots for selecting the non-leftmost slides,
 * even though the carousel would not advance when they are clicked.
 *
 * As a result, the number of visible dots should be the total number of slides,
 * minus a dot for every slide that is currently visible,
 * and plus a dot for the leftmost slide.
 *
 * @private
 * @returns {Number}
 */
AdobeFontsCarouselController.prototype._numDotsVisible = function() {
  return this._numSlides() - this._numSlidesInViewport() + 1;
};

/*
 * Returns the total number of slides in the carousel.
 *
 * @private
 * @returns {Number}
 */
AdobeFontsCarouselController.prototype._numSlides = function() {
  if (this.isRecommendedFontCarousel) {
    return this.$element[0].getElementsByClassName('adobe-fonts-carousel-item').length;
  } else {
    return this.$element[0].getElementsByTagName('li').length;
  }
};

/*
 * Returns the number of slides in the viewport (i.e., currently visible).
 * A slide that is partially in the viewport is not counted.
 *
 * @private
 * @returns {Number}
 */
AdobeFontsCarouselController.prototype._numSlidesInViewport = function() {
  if (this.$element[0].querySelector('.adobe-fonts-carousel') && this.$element[0].getElementsByTagName('li') && this.$element[0].getElementsByTagName('li')[0]) {
    var carouselWidth = this.$element[0].querySelector('.adobe-fonts-carousel').clientWidth;
    var slideWidth = this.$element[0].getElementsByTagName('li')[0].clientWidth;
    if (this.isRecommendedFontCarousel) {
      slideWidth = this.$element[0].getElementsByClassName('adobe-fonts-carousel-item')[0].clientWidth;
    }
    if (this.isFamilyImageCarousel || this.isRecommendedFontCarousel) {
      return Math.round(carouselWidth / slideWidth);
    } else {
      return Math.floor(carouselWidth / slideWidth);
    }
  }
};

/*
 * Returns either a -1 or 0
 * To remove or add focus to the carousel Previous button
 *
 * @returns {Number}
 */
AdobeFontsCarouselController.prototype.setPreviousTabIndex = function() {
  var previousCtrl = this.$element[0].getElementsByClassName('adobe-fonts-carousel__previous-button')[0];

  return previousCtrl.classList.contains('disabled') ? -1 : 0;
};

/*
 * Returns either a -1 or 0
 * To remove or add focus to the carousel Next button
 *
 * @returns {Number}
 */
AdobeFontsCarouselController.prototype.setNextTabIndex = function() {
  var nextCtrl = this.$element[0].getElementsByClassName('adobe-fonts-carousel__next-button')[0];

  return nextCtrl.classList.contains('disabled') ? -1 : 0;
};

module.exports = AdobeFontsCarouselController;
