var angular = require('angular');
var _ = require('underscore');
var TkWebProjectListModel = require('../models/tk_web_project_list_model.js');
var TkWebProjectCardModel = require('../models/tk_web_project_card_model.js');
var TkWebProjectFamilyCardModel = require('../models/tk_web_project_family_card_model.js');
var TkWebProjectEditorStyleSelectorModel = require('../models/tk_web_project_editor_style_selector_model.js');
var TkWebProjectDisplayFamilyModel = require('../models/tk_web_project_display_family_model.js');
var EmbedCodeModel = require('../models/embed_code_model.js');

const UrlService = require('../../util/url_service.js').default;

var BODY_PROGRESS_CLASS = 'body__progress';

/**
 * A service used to manage the editing of a webfont project.
 * @ngInject
 */
function TkWebProjectEditorService($http, $document, DialogService) {
  var _tkWebProjectListModel;
  var _tkWebProjectCardModelsList = {};
  var _tkWebProjectFamilyCardModelsList = {};
  var _tkWebProjectEditorStyleSelectorModelsList = {};
  var _tkWebProjectDisplayFamilyModelsList = {};
  var _embedCodeModelsList = {};
  var _bodyElement = angular.element($document.find('body'));

  /**
   * Initializes a new instance of TkWebProjectListModel if it doesn't exist.
   *
   * @param {Object} webProjectList - List of kits/web projects
   */
  var initTkWebProjectListModel = function (webProjectListResponse) {
    if (!_tkWebProjectListModel) {
      _tkWebProjectListModel = new TkWebProjectListModel(webProjectListResponse);
    }
  };

  /**
   * Creates a new instance of TkWebProjectCardModel if it doesn't exist with the id.
   *
   * @param {String} id - kit id
   * @param {Object} webProjectData - data of a particular kit
   */
  var initTkWebProjectCardModel = function (id, webProjectData) {
    var isExpanded;
    var expandedProjectId = UrlService.getSearchParam('project_id');
    if (!_tkWebProjectCardModelsList[id] && webProjectData) {
      isExpanded = webProjectData.kit.kit.token === expandedProjectId;
      _tkWebProjectCardModelsList[id] = new TkWebProjectCardModel(webProjectData, isExpanded);
    }
  };

  /**
   * Creates a new instance of getTkWebProjectFamilyCardModel if it doesn't exist with the id.
   *
   * @param {String} id - Unique identifier constructed using kit token and tkff id
   * @param {Object} tkffData - data of a particular tkff
   */
  var initTkWebProjectFamilyCardModel = function (id, tkffData) {
    if (!_tkWebProjectFamilyCardModelsList[id] && tkffData) {
      this.setTkWebProjectFamilyCardModel(id, tkffData);
    }
  };

  var setTkWebProjectFamilyCardModel = function (id, tkffData) {
    _tkWebProjectFamilyCardModelsList[id] = new TkWebProjectFamilyCardModel(tkffData);
  };

  /**
   * Returns an instance of TkWebProjectListModel.
   *
   * @param {Object} webProjectList - List of kits/web projects
   * @returns {Object}
   */
  var getTkWebProjectListModel = function () {
    return _tkWebProjectListModel;
  };

  /**
   * Returns an instance of TkWebProjectCardModel with the associated id.
   *
   * @param {String} id - kit id
   * @returns {Object}
   */
  var getTkWebProjectCardModel = function (id) {
    return _tkWebProjectCardModelsList[id];
  };

  /**
   * Returns an instance of getTkWebProjectFamilyCardModel with the associated id.
   *
   * @param {String} id - Unique identifier constructed using kit token and tkff id
   * @returns {Object}
   */
  var getTkWebProjectFamilyCardModel = function (id) {
    return _tkWebProjectFamilyCardModelsList[id];
  };

  /**
   * Sends the kitToken for the kit which needs to be deleted.
   *
   * @param {String} kitToken
   * @returns {Object}
   */
  var deleteKit = function(kitToken) {
    return $http.delete('/kit_editor/kits/' + kitToken, {
      headers: { 'X-Requested-With': 'XMLHttpRequest' }
    })
    .then(function(response){
      return response.data;
    });
  };

  /**
   * tkffData contains a lot of data that isn't needed for updating a kit, and passing all of this data results
   * in error messages being truncated in Airbrake and data is missing when querying in NewRelic.
   *
   * This method returns only the data used by the POST /kit_editor/kits/update_kit endpoint.
   *
   * @params {Array} tkffData
   * @returns {Object} tkffData
   */
  var _getTkffDataForUpdate = function(tkffData) {
    return _.map(tkffData, function(tkff) {
      return {
        ..._.pick(tkff, ['enabled_language_tags', 'font_display', 'is_ot_features_enabled', 'is_vertical_features_enabled',
          'subsetting', 'variationsEnabled', 'variationsDisabled']),
        typekit_font_family: {
          slug: ((tkff.tkff || {}).typekit_font_family || {}).slug
        }
      };
    });
  };

  /**
   * Sends the updated kit data to the backend for saving the changes.
   *
   * @params {Array} tkffData
   * @params {Array} deletedTkffs
   * @param {String} kitToken
   * @returns {Object}
   */
  var updateKit = function(tkffData, deletedTkffs, kitToken) {
    return $http.post('/kit_editor/kits/' + kitToken + '/update_kit', {
      tkffData: _getTkffDataForUpdate(tkffData),
      deletedTkffs: _getTkffDataForUpdate(deletedTkffs)
    }).then(function(response){
      return response.data;
    });
  };

  /**
   * Creates a new instance of TkWebProjectEditorStyleSelectorModel if it doesn't exist with the id.
   *
   * @param {String} id - Unique identifier constructed using kit token and tkff id
   * @params {Array} availableFontVariations
   * @params {Array} enabledFontVariationIds
   * @param {Object} textSamples
   * @param {String} exampleText
   */
  var initTkWebProjectEditorStyleSelectorModel = function(id, availableFontVariations, enabledFontVariationIds, textSamples) {
    if (!_tkWebProjectEditorStyleSelectorModelsList[id]) {
      _tkWebProjectEditorStyleSelectorModelsList[id] = new TkWebProjectEditorStyleSelectorModel(availableFontVariations, enabledFontVariationIds, textSamples);
    }
  };

  /**
   * Creates a new instance of TkWebProjectDisplayFamilyModel if it doesn't exist with the id.
   *
   * @param {String} id - Unique identifier constructed using kit token and tkff id
   * @param {Object} fontVariation
   * @param {Object} textSamples
   * @param {String} typographicFamilySlug
   */
  var initTkWebProjectDisplayFamilyModel = function(id, fontVariation, textSamples, typographicFamilySlug) {
    if (!_tkWebProjectDisplayFamilyModelsList[id]) {
      _tkWebProjectDisplayFamilyModelsList[id] = new TkWebProjectDisplayFamilyModel(fontVariation, textSamples, typographicFamilySlug);
    }
  };

  /**
   * Creates a new instance of EmbedCodeModel if it doesn't exist with the id.
   *
   * @param {Integer} Project ID
   * @param {bool} Is kit dynamic
   * @param {Object} project embed details
   */
  var initEmbedCodeModel = function(id, isDynamic, projectEmbedDetails) {
    if (!_embedCodeModelsList[id] && projectEmbedDetails) {
      _embedCodeModelsList[id] = new EmbedCodeModel(isDynamic, projectEmbedDetails);
    }
  };

  var updateWebProjects = function(searchString, pageNumber, showBusyCursor) {
    var self = this;
    if (showBusyCursor == true) {
      _bodyElement.addClass(BODY_PROGRESS_CLASS);
    }
    return $http.get('/my_fonts/web_projects_data.json', {
      params: {
        searchString: searchString,
        page: pageNumber,
        sortOrder: _tkWebProjectListModel.getSortOrder()
      }
    }).then(function(response){
      _tkWebProjectListModel.setWebProjectList(response.data.kitsData);
      _tkWebProjectListModel.setTotalPages(response.data.totalPages);
      response.data.kitsData.forEach( function(kitData) {
        initTkWebProjectCardModel(kitData.kit.kit.token, kitData);
        initEmbedCodeModel(kitData.kit.kit.token, kitData.is_dynamic, kitData.project_embed_details);

        kitData.tkff_list.forEach( function(tkffData) {
          // Id which considers both the kit token and the tkff id
          var id = kitData.kit.kit.token + tkffData.tkff.typekit_font_family.id;
          initTkWebProjectDisplayFamilyModel(id, tkffData.display_variation, tkffData.text_samples, tkffData.tktf_slug);
        });
      });
      _bodyElement.removeClass(BODY_PROGRESS_CLASS);
      self.populateCompleteWebProjectsData(pageNumber);
    });
  };

  /**
   * Returns an instance of TkWebProjectEditorStyleSelectorModel with the associated id.
   *
   * @param {String} id - Unique identifier constructed using kit token and tkff id
   * @returns {Object}
   */
  var getTkWebProjectEditorStyleSelectorModel = function(id) {
    return _tkWebProjectEditorStyleSelectorModelsList[id];
  };

  /**
   * Returns an instance of TkWebProjectDisplayFamilyModel with the associated id.
   *
   * @param {String} id - Unique identifier constructed using kit token and tkff id
   * @returns {Object}
   */
  var getTkWebProjectDisplayFamilyModel = function(id) {
    return _tkWebProjectDisplayFamilyModelsList[id];
  };

  /**
   * Returns an instance of EmbedCodeModel with the associated id.
   *
   * @param {Integer} Project ID
   * @returns {Object}
   */
  var getEmbedCodeModel = function(id) {
    return _embedCodeModelsList[id];
  };

  var openDeleteConfirmationDialog = function(tkWebProjectCardModel) {
    DialogService.show('/angular_templates/dialogs/delete_web_project_confirmation.html').then(function(dialog) {
      dialog.projectName = tkWebProjectCardModel.getProjectName();

      dialog.handleDelete = function() {
        _tkWebProjectListModel.deleteWebProject(tkWebProjectCardModel.getWebProjectData());
        deleteKit(tkWebProjectCardModel.getProjectDetails().kitToken);
        dialog.close();
      };
    });
  };

  var openRetiredFontsRemovalConfirmationDialog = function(tkWebProjectCardModel, tkWebProjectFamilyCardModel) {
    DialogService.show('/angular_templates/dialogs/remove_retired_fonts_confirmation.html').then(function(dialog) {
      dialog.familyName = tkWebProjectFamilyCardModel.getTkffData().tkff.typekit_font_family.name;
      dialog.isFamilyRetired = tkWebProjectFamilyCardModel.isFamilyRetired;

      dialog.handleDelete = function() {
        tkWebProjectCardModel.deleteTkff(tkWebProjectFamilyCardModel.getTkffData());
        dialog.close();
      };
    });
  };

  var getCompleteWebProjectData = function(kitToken) {
    return $http.get('/my_fonts/web_project_data.json', {
      params: {
        token: kitToken
      }
    }).then(function(response){
      return response.data;
    });
  };

  var getCompleteWebProjectsData = function(pageNumber) {
    // This method may get called before _tkWebProjectListModel is populated
    var sortOrder = null;
    if (_tkWebProjectListModel) {
      sortOrder = _tkWebProjectListModel.getSortOrder();
    }
    return $http.get('/my_fonts/complete_web_projects_data.json', {
      params: {
        page: pageNumber,
        sortOrder: sortOrder
      }
    }).then(function(response){
      return response.data;
    });
  };

  var _populateFamilyCardAndStyleSelectorModels = function(tkWebProjectCardModel, tkffList) {
    var j;
    for (j = 0; j < tkffList.length; j++) {
      var tkffData = tkffList[j];
      // Id which considers both the kit token and the tkff id
      var id = tkWebProjectCardModel.getProjectDetails().kitToken + tkffData.tkff.typekit_font_family.id;
      setTkWebProjectFamilyCardModel(
          id,
          tkffData
      );
      initTkWebProjectEditorStyleSelectorModel(
          id,
          tkffData.fvd_sorted_variations,
          tkffData.enabled_font_variation_ids,
          tkffData.text_samples
      );
    }
  };

  var _populateModels = function(kitToken, tkffList, shouldToggleExpanded) {
    var tkWebProjectCardModel = getTkWebProjectCardModel(kitToken);
    tkWebProjectCardModel.setTkffList(tkffList);
    _populateFamilyCardAndStyleSelectorModels(tkWebProjectCardModel, tkffList);
    tkWebProjectCardModel.setIsDataFetched(true);
    if (shouldToggleExpanded) {
      tkWebProjectCardModel.toggleExpanded();
    }
  };

  var populateCompleteWebProjectsData = function(pageNumber) {
    var self = this;
    var deferredWebProjectData = self.getCompleteWebProjectsData(pageNumber);
    deferredWebProjectData.then(function(data) {
      var i;
      for (i = 0; i < data.length; i++) {
        var tkffList = data[i].tkff_list;
        var kitToken = data[i].kit_token;
        _populateModels(kitToken, tkffList);
      }
    });
  };

  var populateCompleteTkffData = function(kitToken, callback) {
    var self = this;
    _bodyElement.addClass(BODY_PROGRESS_CLASS);
    var deferredWebProjectData = self.getCompleteWebProjectData(kitToken);
    deferredWebProjectData.then(function(data) {
      _populateModels(kitToken, data, true);
      _bodyElement.removeClass(BODY_PROGRESS_CLASS);

      // To change the cursor to pointer
      if (callback) {
        callback();
      }
    });
  };

  var resetTkffData = function() {
    _tkWebProjectCardModelsList = {};
    _tkWebProjectFamilyCardModelsList = {};
    _tkWebProjectEditorStyleSelectorModelsList = {};
    _tkWebProjectDisplayFamilyModelsList = {};
  };

  return {
    initTkWebProjectListModel: initTkWebProjectListModel,
    initTkWebProjectCardModel: initTkWebProjectCardModel,
    initTkWebProjectFamilyCardModel: initTkWebProjectFamilyCardModel,
    initTkWebProjectEditorStyleSelectorModel: initTkWebProjectEditorStyleSelectorModel,
    initTkWebProjectDisplayFamilyModel: initTkWebProjectDisplayFamilyModel,
    initEmbedCodeModel: initEmbedCodeModel,
    getTkWebProjectListModel: getTkWebProjectListModel,
    getTkWebProjectCardModel: getTkWebProjectCardModel,
    getTkWebProjectFamilyCardModel: getTkWebProjectFamilyCardModel,
    setTkWebProjectFamilyCardModel: setTkWebProjectFamilyCardModel,
    deleteKit: deleteKit,
    updateKit: updateKit,
    updateWebProjects: updateWebProjects,
    getTkWebProjectEditorStyleSelectorModel: getTkWebProjectEditorStyleSelectorModel,
    getTkWebProjectDisplayFamilyModel: getTkWebProjectDisplayFamilyModel,
    getEmbedCodeModel: getEmbedCodeModel,
    openDeleteConfirmationDialog: openDeleteConfirmationDialog,
    openRetiredFontsRemovalConfirmationDialog: openRetiredFontsRemovalConfirmationDialog,
    getCompleteWebProjectData: getCompleteWebProjectData,
    getCompleteWebProjectsData: getCompleteWebProjectsData,
    populateCompleteWebProjectsData: populateCompleteWebProjectsData,
    populateCompleteTkffData: populateCompleteTkffData,
    resetTkffData: resetTkffData
  };
}

module.exports = TkWebProjectEditorService;
