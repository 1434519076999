/**
 * A directive that can be used to wrap a list of things and display the list as
 * a carousel.
 *
 *   <adobe-fonts-carousel>
 *     <ul>
 *       <li>Item 1</li>
 *       <li>Item 2</li>
 *       <li>Item 3</li>
 *       <li>Item 4</li>
 *     </ul>
 *   </adobe-fonts-carousel>
 * @ngInject
 */
function Directive() {
  return {
    bindToController: {
      alwaysHasCarousel: '@',
      hidePreviousAndNextButtons: '=?',
      isFamilyImageCarousel: '@',
      isRecommendedFontCarousel: '@',
      layout: '@',
      links: '@',
      itemSize: '@'
    },
    controller: require('./controller.js'),
    controllerAs: 'carouselCtrl',
    scope: {},
    restrict: 'E',
    transclude: true,
    templateUrl: '/angular_templates/directives/adobe_fonts_carousel.html'
  };
}

module.exports = Directive;
