var angular = require('angular');

/**
 * Controller for the tk-subsetting-options directive.
 * @ngInject
 */
function TkSubsettingOptionsController() {
  this.tkffData = this.tkWebProjectFamilyCardModel.getTkffData();
  this.typekitFontFamily = this.tkffData.tkff.typekit_font_family;
  this.isKitDynamic = this.tkWebProjectCardModel.isDynamic();
  this.isCJK = this.tkffData.is_CJK;
  this.tkffUniqueIdentifier = this.typekitFontFamily.slug + this.tkWebProjectCardModel.getProjectId();
  this.openTypeFeatureDisplayInfo = this.tkffData.open_type_feature_display_info;
  this.enabledLanguageTags = this.tkffData.enabled_language_tags;
  this.langCheckedStates = this.constructLangCheckedStates();

  // Deep copy languages and divide into two lists for showing in our view since they need to be vertically aligned in 2 rows
  var languages = angular.copy(this.tkffData.supported_languages_all);
  this.supportedLanguagesHalves = this._reconstructLanguagesForView(languages);

  this.charSetOptionsEnum = this.tkWebProjectCardModel.getCharSetOptionsEnum();
  this.subSettingOptions = {
    all: this.charSetOptionsEnum.CHAR_SET_OPTION_ALL,
    custom: this.charSetOptionsEnum.CHAR_SET_OPTION_CUSTOM,
    default: this.charSetOptionsEnum.CHAR_SET_OPTION_DEFAULT,
    dynamic: this.charSetOptionsEnum.CHAR_SET_OPTION_DYNAMIC
  };

  this.selectedSubsetting = this.subSettingOptions[this.tkffData.subsetting];
}

TkSubsettingOptionsController.prototype.constructLangCheckedStates = function() {
  var checkedStates = {};
  var i;
  for (i = 0; i < this.tkffData.supported_languages_all.length; i++ ) {
    checkedStates[this.tkffData.supported_languages_all[i].language.iana_language_tag] = false;
  }
  for (i = 0; i < this.enabledLanguageTags.length; i++ ) {
    checkedStates[this.enabledLanguageTags[i]] = true;
  }
  return checkedStates;
};

TkSubsettingOptionsController.prototype.getTkffUniqueIdentifier = function() {
  return this.tkffUniqueIdentifier;
};

TkSubsettingOptionsController.prototype.isVerticalFeaturesOptionShown = function() {
  return this.isCJK;
};

TkSubsettingOptionsController.prototype.toggleVerticalFeaturesSelection = function() {
  this.tkWebProjectCardModel.setIsModified(true);
  this.tkffData.is_vertical_features_enabled = !this.tkffData.is_vertical_features_enabled;
};

TkSubsettingOptionsController.prototype.toggleOpenTypeFeaturesSelection = function() {
  this.tkWebProjectCardModel.setIsModified(true);
  this.tkffData.is_ot_features_enabled = !this.tkffData.is_ot_features_enabled;
};

TkSubsettingOptionsController.prototype.isDynamicSubsettingOptionShown = function() {
  return this.isKitDynamic;
};

TkSubsettingOptionsController.prototype.isAllChecked = function() {
  return this.selectedSubsetting == this.charSetOptionsEnum.CHAR_SET_OPTION_ALL;
};

TkSubsettingOptionsController.prototype.isLanguageSubsettingChecked = function() {
  return this.selectedSubsetting == this.charSetOptionsEnum.CHAR_SET_OPTION_CUSTOM;
};

TkSubsettingOptionsController.prototype.isDefaultChecked = function() {
  return this.selectedSubsetting == this.charSetOptionsEnum.CHAR_SET_OPTION_DEFAULT;
};


TkSubsettingOptionsController.prototype.isDynamicChecked = function() {
  return this.selectedSubsetting == this.charSetOptionsEnum.CHAR_SET_OPTION_DYNAMIC;
};

TkSubsettingOptionsController.prototype.handleSubsettingClick = function(option) {
  this.tkWebProjectCardModel.setIsModified(true);
  this.selectedSubsetting = option;
  this.tkffData.subsetting = option;
  this.tkWebProjectCardModel.updateIsValid();
};

TkSubsettingOptionsController.prototype.isOnlyDynamicSubsettingAllowed = function() {
  return this.isKitDynamic && this.isCJK;
};

TkSubsettingOptionsController.prototype.updateEnabledLanguages = function(lang) {
  this.tkWebProjectCardModel.setIsModified(true);
  this.langCheckedStates[lang.language.iana_language_tag] = !this.langCheckedStates[lang.language.iana_language_tag];
  this.enabledLanguageTags.splice(0, this.enabledLanguageTags.length);
  var i;
  for (i = 0; i < this.tkffData.supported_languages_all.length; i++ ) {
    if (this.langCheckedStates[this.tkffData.supported_languages_all[i].language.iana_language_tag]) {
      this.enabledLanguageTags.push(this.tkffData.supported_languages_all[i].language.iana_language_tag);
    }
  }
  this.tkWebProjectCardModel.updateIsValid();
};

/**
 * Returns two halves of alphabetically ordered languages to populate the subsetting view.
 * @private
 */
TkSubsettingOptionsController.prototype._reconstructLanguagesForView = function(languages) {
  var supportedLanguagesHalves = [];
  supportedLanguagesHalves.push(languages.slice(0, Math.ceil(languages.length / 2)));
  supportedLanguagesHalves.push(languages.slice(Math.ceil(languages.length / 2), languages.length));
  return supportedLanguagesHalves;
};

module.exports = TkSubsettingOptionsController;
