var _ = require('underscore');

var CUSTOM_MEDIUM_BREAKPOINT_WIDTH = 900;
var CUSTOM_SMALL_BREAKPOINT_WIDTH = 500;
var SPECTRUM_MEDIUM_BREAKPOINT_WIDTH = 1280;
var SPECTRUM_SMALL_BREAKPOINT_WIDTH = 768;

/**
 * @ngInject
 */
function FoundriesController($document, $scope, $timeout, $window, DataService, FreezeframeService) {
  this.$document = $document;
  this.$timeout = $timeout;
  this.$window = $window;
  this.DataService = DataService;
  this.FreezeframeService = FreezeframeService;

  this.featuredFoundries = {};
  this.foundries = [];
  this.isLoaded = false;
  this.isBiographyCollapsed = true;
  this.isBiographyCollapsible = false;
  this.selectedFoundry = null;
  this.selectedSortOption = null;
  this.sortOptions = [];
  this.specimenImages = {};

  this._preloadData();

  var self = this;
  $window.addEventListener('DOMContentLoaded', function() {
    self.isLoaded = true;
    $scope.$apply();
    self.updateIsBiographyCollapsible();
  });

  $window.addEventListener('resize', function() {
    self.updateIsBiographyCollapsible();
  });
}

/**
 * Toggle the class for the carousel depending on the number of items
 *
 * @returns String
 */
FoundriesController.prototype.getCarouselListClass = function() {
  return this.getFeaturedFamilyImages().length > 2 ? 'adobe-fonts-family__carousel-list' : '';
};

/**
 * Get an array of featured families for the selected foundry
 *
 * @returns {Array}
 */
FoundriesController.prototype.getFeaturedFamilies = function() {
  return (this.featuredFoundries[this.selectedFoundry] || {}).featuredFamilies || [];
};

/**
 * Get an array of featured family image URLs for the selected foundry
 *
 * @returns {Array}
 */
FoundriesController.prototype.getFeaturedFamilyImages = function() {
  // Memoization is needed to prevent unnecessary $digest iterations
  if (this.specimenImages[this.selectedFoundry]) {
    return this.specimenImages[this.selectedFoundry];
  }
  var featuredFamilies = this.getFeaturedFamilies();
  this.specimenImages[this.selectedFoundry] = featuredFamilies.map(function(family) {
    return family.specimenImageData;
  });
  return this.specimenImages[this.selectedFoundry];
};

/**
 * xs-6 is too small for mobile screen sizes, but xs-12 is too large for the 500-768px range,
 * so this function toggles them.
 *
 * @returns {String}
 */
FoundriesController.prototype.getSmallScreenClass = function() {
  if (this.$window.innerWidth < CUSTOM_SMALL_BREAKPOINT_WIDTH) {
    return 'spectrum-grid-col-xs-12';
  }
  if (this.$window.innerWidth < SPECTRUM_SMALL_BREAKPOINT_WIDTH) {
    return 'spectrum-grid-col-xs-6';
  }
  if (this.$window.innerWidth < CUSTOM_MEDIUM_BREAKPOINT_WIDTH) {
    return 'spectrum-grid-col-sm-4';
  }
  if (this.$window.innerWidth < SPECTRUM_MEDIUM_BREAKPOINT_WIDTH) {
    return 'spectrum-grid-col-sm-3';
  }
  return '';
};

FoundriesController.prototype.selectFoundry = function(foundrySlug) {
  var self = this;
  self.selectedFoundry = foundrySlug;
  self.isBiographyCollapsible = true;
  self.isBiographyCollapsed = true;
  self.$timeout(function() {
    self.updateIsBiographyCollapsible();
  }, 1);
  self.FreezeframeService.deleteAndUpdateFeaturedFoundries();
};

/*
 * Sort foundries by the selected sort option
 *
 */
FoundriesController.prototype.sortFoundries = function() {
  var self = this;
  self.foundries = _.sortBy(self.foundries, function(foundry) {
    return foundry[self.selectedSortOption];
  });
  var order = _.find(self.sortOptions, function(sortOption) {
    return sortOption.value === self.selectedSortOption;
  }).order;
  if (order === 'desc') {
    self.foundries.reverse();
  }
};

/*
 * Is there a "Read more" or "Read less" link that expands or collapses the foundry biography?
 *
 * @returns {Boolean}
 */
FoundriesController.prototype.updateIsBiographyCollapsible = function() {
  var leftColumn = this.$document[0].getElementById('featured-foundries--left-column');
  var rightColumn = this.$document[0].getElementById('featured-foundries--right-column');
  this.isBiographyCollapsible = leftColumn && rightColumn && leftColumn.clientHeight < rightColumn.clientHeight;
  this.isBiographyCollapsible = this.isBiographyCollapsible && this.$window.innerWidth >= SPECTRUM_SMALL_BREAKPOINT_WIDTH;
};

/**
 * Preload data using DataService.
 * @private
 */
FoundriesController.prototype._preloadData = function() {
  var self = this;
  self.DataService.get('/spectrum/foundries').then(function(data) {
    self.featuredFoundries = data.featuredFoundries || {};
    self.selectedFoundry = Object.keys(self.featuredFoundries)[0];
    self.foundries = data.foundries;
    self.sortOptions = data.sortOptions;
    if (self.sortOptions) {
      self.selectedSortOption = self.sortOptions[0].value;
    }
  });
};

module.exports = FoundriesController;
