/**
 * @ngInject
 */
function DesignerController($q,
                            DataService,
                            DesignersService,
                            FilterDataService,
                            FilteredFamilyListStateService,
                            UserPrefsService) {
  this.$q = $q;
  this.DataService = DataService;
  this.FilterDataService = FilterDataService;
  this.DesignersService = DesignersService;
  this.FilteredFamilyListStateService = FilteredFamilyListStateService;
  this.UserPrefsService = UserPrefsService;

  this._preloadData();
}

/**
 * Preload data using DataService
 *
 * @private
 * @returns {Promise}
 */
DesignerController.prototype._preloadData = function() {
  var self = this;
  return self.$q.all([
    self.UserPrefsService.getUserPrefs().then(function(userPrefs) {
      self.DataService.get('/neue/preloaded_designer_data').then(function(data) {
        self.filteredFamilyList = self.FilteredFamilyListStateService.getState(null, userPrefs);
        self.filteredFamilyList.families = data.familiesData.families;
        self.filteredFamilyList.totalFamilies = data.familiesData.totalFamilies;
        self.filteredFamilyList.totalPages = data.familiesData.totalPages;

        self.designerSlug = data.designerSlug;
        self.textSampleData = data.textSampleData;

        self.filtersModel = self.FilterDataService.getFilterModel();
      });
    })
  ]);
};

/**
 * Updates the list of families using the current properties set on the filtered
 * family list.
 *
 * @private
 */
DesignerController.prototype.updateFilteredFamilyList = function() {
  var self = this;
  self.filtersModel.setCurrentPage(self.filteredFamilyList.currentPage);
  self.DesignersService.getFamilies(self.designerSlug, self.filtersModel.toSearchParams()).then(function(response) {
    self.filteredFamilyList.families = response.data.families_data.families;
    self.filteredFamilyList.totalFamilies = response.data.families_data.totalFamilies;
    self.filteredFamilyList.totalPages = response.data.families_data.totalPages;
  });

  self.FilteredFamilyListStateService.updateState(self.filteredFamilyList);
};

module.exports = DesignerController;
