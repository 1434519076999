/**
 * A service used to retrieve foundry info from the backend.
 * @ngInject
 */
function FoundriesService($http) {
  return {
    /**
     * Get a list of typographic families.
     *
     * @param foundrySlug
     * @param {{}} params
     * @returns {Promise}
     */
    getFamilies: function(foundrySlug, params) {
      return $http.get('/foundries/' + foundrySlug + '/families', {
        params: params
      });
    }
  };
}

module.exports = FoundriesService;
