/**
 * Controller for the tk-web-project-embed-code-options directive.
 * @ngInject
 */
function TkWebProjectEmbedCodeOptionsController() {
  this.isDefaultVisible = true;
}

TkWebProjectEmbedCodeOptionsController.prototype.isDefaultSnippetVisible = function() {
  return !this.embedCodeModel.getIsDynamic() && this.isDefaultVisible;
};

TkWebProjectEmbedCodeOptionsController.prototype.isCssImportSnippetVisible = function() {
  return !this.embedCodeModel.getIsDynamic() && !this.isDefaultVisible;
};

TkWebProjectEmbedCodeOptionsController.prototype.isAsyncSnippetVisible = function() {
  return this.embedCodeModel.getIsDynamic();
};

TkWebProjectEmbedCodeOptionsController.prototype.getDefaultSnippetEmbedCode = function() {
  return this.embedCodeModel.getProjectEmbedDetails().css_code;
};

TkWebProjectEmbedCodeOptionsController.prototype.copyDefaultSnippetEmbedCode = function() {
  navigator.clipboard.writeText(this.getDefaultSnippetEmbedCode());
};

TkWebProjectEmbedCodeOptionsController.prototype.getCssImportSnippetEmbedCode = function() {
  return this.embedCodeModel.getProjectEmbedDetails().css_import_code;
};

TkWebProjectEmbedCodeOptionsController.prototype.copyCssImportSnippetEmbedCode = function() {
  navigator.clipboard.writeText(this.getCssImportSnippetEmbedCode());
};

TkWebProjectEmbedCodeOptionsController.prototype.getAsyncSnippetEmbedCode = function() {
  return this.embedCodeModel.getProjectEmbedDetails().async_javascript_code;
};

TkWebProjectEmbedCodeOptionsController.prototype.showDefaultCssCode = function() {
  this.isDefaultVisible = true;
};

TkWebProjectEmbedCodeOptionsController.prototype.showCssImportCode = function() {
  this.isDefaultVisible = false;
};

module.exports = TkWebProjectEmbedCodeOptionsController;
