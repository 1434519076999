const { getUserInfoSync, hasPaidCreativeCloud } = require('../../util/user_info.js');
const { sendFavoriteButtonAnalytics } = require('../../components/mixins/FamilyCardFavorite.js');
const { FAVORITE_EVENT_NAME } = require('@adobe-fonts/site/events/FavoriteEvent.js');

const {
  incrementSyncUsage,
  setPlan,
  setSyncLimit
} = require('../../util/user_state.js');

const registerSaveToLibraryEventHandlers = require('../../components/mixins/SaveToLibrary.js').registerEventHandlers;
const { toLibraryElement } = require('../../util/libraries.js').default;

var _ = require('underscore');
var angular = require('angular');
var ENGLISH_LANGUAGE_TAG = 'en';
var JAPANESE_LANGUAGE_TAG = 'ja';
var MIN_DESIGNERS_FOR_ALT_LAYOUT = 3;
var SCROLL_DURATION = 500;
var SECTION_ID_SUFFIX = '-section';
var SUBFAMILY_DROPDOWN_OPEN_DURATION = 3000;
var SPECTRUM_MEDIUM_BREAKPOINT_WIDTH = 1280;
var MAX_FOR_QUICK_MULTI_SYNC = 8;
var MAX_DESIGNER_NAME_LENGTH = 45;
var NAME_START_INDEX = 22;
var NAME_END_INDEX = 10;
var PAGE_NUMBER = 1;
var PER_PAGE_SIMILARITY = 24;
var PER_PAGE_PAIRING = 12;

/**
 * Controller for the family page.
 *
 * @ngInject
 */
function FamilyController($document,
                          $q,
                          $element,
                          $timeout,
                          $scope,
                          $window,
                          AddFontsToWebProjectService,
                          AuthenticationService,
                          DataService,
                          FavoritesService,
                          FilteredFamilyListStateService,
                          FilterVariationsService,
                          FirstMileService,
                          FontLoaderService,
                          I18nService,
                          NewrelicService,
                          NewrelicClickTrackerService,
                          NotificationService,
                          RecommendationsService,
                          RedirectContextService,
                          ScrollService,
                          SyncService,
                          UserPrefsService) {
  var self = this;
  self.$document = $document;
  self.$q = $q;
  self.$element = $element;
  self.$scope = $scope;
  self.$timeout = $timeout;
  self.$window = $window;
  self.AddFontsToWebProjectService = AddFontsToWebProjectService;
  self.AuthenticationService = AuthenticationService;
  self.DataService = DataService;
  self.FavoritesService = FavoritesService;
  self.FilteredFamilyListStateService = FilteredFamilyListStateService;
  self.FilterVariationsService = FilterVariationsService;
  self.FirstMileService = FirstMileService;
  self.FontLoaderService = FontLoaderService;
  self.I18nService = I18nService;
  self.NewrelicService = NewrelicService;
  self.NotificationService = NotificationService;
  self.RecommendationsService = RecommendationsService;
  self.RedirectContextService = RedirectContextService;
  self.ScrollService = ScrollService;
  self.SyncService = SyncService;
  self.UserPrefsService = UserPrefsService;
  NewrelicClickTrackerService.init({page: 'family detail'});

  self.windowIsScrolling = false;

  self.$window.addEventListener(FAVORITE_EVENT_NAME, self.handleFavoriteEvent.bind(this));

  registerSaveToLibraryEventHandlers($element[0]);

  self._preloadData().then(function() {
    self._handleContext();
  });

  this._initCrossbar();

  // select and scroll to the indicated tab in tab navigation after the page has loaded
  angular.element(document).ready(function () {
    // $timeout is used to make the watch work after the completion of current digest cycle
    self.$timeout(function () {
      self.$scope.$watch(function(){
        return location.hash;
      }, function(hash){
        if (hash !== '') {
          if (!history.state || history.state.navigationTabClicked) {
            self._scrollOnLoad(hash);
          }
        }
      });

      self.$scope.$watch(function(){
        return self.$window.pageYOffset;
      }, function(){
        if (!self.windowIsScrolling) {
          self._updateAnchor();
        }
      });
    });
  });
}

FamilyController.prototype._scrollOnLoad = function (locationHash) {
  var self = this;
  // Removes prefix '#' and suffix '-section' to get ID from hash fragment of the url
  var sectionId = locationHash.substr(1, locationHash.indexOf(SECTION_ID_SUFFIX) - 1);
  var selectedVariationSlug = locationHash.substr(locationHash.indexOf(SECTION_ID_SUFFIX) + SECTION_ID_SUFFIX.length + 1);
  if (sectionId === 'details' && selectedVariationSlug !== '') {
    var selectedVariation = self.getVariationFromSlug(selectedVariationSlug);
    if (selectedVariation) {
      self.setSelectedVariation(selectedVariation);
    }
  }
  self._selectTabFromAnchor(sectionId);
  self._scrollToAnchor(sectionId, locationHash);
};

FamilyController.prototype.getVariationFromSlug = function(variationSlug) {
  return _.find(this.filteredFonts, function(font) {
    return variationSlug === font.slug;
  });
};

FamilyController.prototype.handleSimilarFontsAndPairingsLinkClick = function(variation) {
  this.setSelectedVariation(variation);
  location.href = '#details-section+' + variation.slug;
};

FamilyController.prototype.isFlagged = function (variation) {
  return variation.selectedFeedback === 'negative';
};

/**
 * The Class to use for top actions
 * @return {String}
 */
FamilyController.prototype.topActionsClass = function() {
  return this.shouldShowCompressedTopActions() ?
    'adobe-fonts-family__top-actions-compressed' : 'adobe-fonts-family__top-actions';
};

/**
 * Are we on a mobile screen that requires top actions to be shown compressed?
 * @return {String}
 */
FamilyController.prototype.shouldShowCompressedTopActions = function() {
  return this.$window.innerWidth < SPECTRUM_MEDIUM_BREAKPOINT_WIDTH;
};

/**
 * Returns text for the label that activates the entire family
 * @returns {String}
 */
FamilyController.prototype.activateFamilyLabel = function() {
  var numFonts = this.syncableFonts.length - this.activatedFontCount;
  if (numFonts === 1) {
    return this.I18nService.getMessage(this._i18n, 'browse.families.show.activate_one_font');
  } else {
    return this.I18nService.getMessage(this._i18n, 'browse.families.show.activate_n_fonts', { num_fonts: numFonts });
  }
};

/** Activate all fonts in a subfamily.
 * @param subfamily {String} - The name of the subfamily being activated
 * @param fonts {Array} - The list of fonts in the subfamily
 */
FamilyController.prototype.activateSubfamily = function(subfamily, fonts) {
  var self = this;
  self._keepSubfamilyDropdownOpen();
  self.NewrelicService.addPageAction('typekit.click.activate-subfamily', {subfamily: subfamily, fontCount: fonts.length});
  self.AuthenticationService.handleAuthenticationWithPostLoginAction(
    'activate_fonts',
    {font_ids: _.pluck(fonts, 'id')}
  ).then(function() {
    self._sync(fonts);
  });
};

/**
 * Record variable font banner 'Learn More' clicks for analytics purposes
 *
 */
FamilyController.prototype.handleVariableFontsLearnMoreClick = function() {
  this.NewrelicService.addPageAction('typekit.vf_banner.learn_more_click');
};

/**
 * Update activated font count when a single variation is deactivated.
 * Also update selection status for the deactivated font.
 * @returns {Nothing}
 */
FamilyController.prototype.handleDeactivationClick = function (fonts) {
  this.activatedFontCount--;
  var fontIds = _.pluck(fonts, 'id');
  this._updateSelectionStatus(fontIds, false);
};

FamilyController.prototype.filterFontsByRequiredActions = function(toggles) {
  var requiredActions = this.FilterVariationsService.filterRequiredActionsByToggles(this.family.fonts, toggles);

  this.preferredAction = requiredActions.preferred;
  this.filteredNavigationTabs = this._filterTabs();
  this.filteredFonts = this.FilterVariationsService.filterFontsByRequiredActions(this.family.fonts, requiredActions.filters);
};

/**
 * The Class to use for the font variation card list
 * @return {String}
 */
FamilyController.prototype.fontVariationCardsClass = function() {
  return this.viewType === 'grid' ? 'font-variations-grid-view adobe-fonts__font-variations-container' : 'font-variation-list';
};

/**
 * Returns the designers for the currently selected variation.
 * @returns {Object}
 */
FamilyController.prototype.getDesignersForSelectedVariation = function() {
  if (!this.designerInfo) {
    return;
  }

  var self = this;
  return _.compact(_.map(self.selectedVariation.designers, function(designer) {
    return self.designerInfo[designer.slug];
  }));
};

/**
 * Handle click on "Add to Web Project" Button
 */
FamilyController.prototype.handleAddToWebProjectButton = function() {
  if (this.family.has_variable_font) {
    // If this is a variable font we need to pass along the current editor
    // state to the add to web project dialog (if it's available).
    //
    // The editor's state will be used by the confirmation dialog to display
    // advice about replicating the font settings that are current displayed
    // by the editor.
    import('../../controllers/family_page.js').then(({ getEditor }) => {
      const editor = getEditor();
      const saveState = editor && editor.saveState;

      this.AddFontsToWebProjectService.addFamilyToWebProjectWithAuthentication(
        this.family.slug, null, saveState);
    });
  } else {
    this.AddFontsToWebProjectService.addFamilyToWebProjectWithAuthentication(this.family.slug);
  }
};

/**
 * Ensures that favorite clicks on variation cards will update the family-wide "Save to Favorites" button
 * @param {{}} event
 */
FamilyController.prototype.handleFavoriteEvent = function(event) {
  if ((event.detail || {}).fontVariationId) {
    const matchingFont = this.family.fonts.find((font) => font.id === event.detail.fontVariationId);
    if (matchingFont) {
      matchingFont.favorite = (event.detail || {}).favorite;
      this.family.favorite = this.family.fonts.some((font) => font.favorite);
    }
  }
};

/**
 * Handler for the variation details button.
 * @param {{}} variation
 */

FamilyController.prototype.handleVariationDetailsClick = function(variation) {
  this.setSelectedVariation(variation);
  this._scrollToAnchor('details', '#details-section+' + this.selectedVariation.slug);
};

FamilyController.prototype.setSelectedVariation = function(variation) {
  this.selectedVariation = variation;
  this.getSelectedVariationSimilarities();
  this.getSelectedVariationPairings();
};

/**
 * Handler for clicking on variation to see its recommendations.
 * @param {{}} variation
 */
FamilyController.prototype.handleRecommendedVariationDetailsClick = function(variation) {
  var i = _.findIndex(this.variationMenuItems, function (item) {
    return item.value.id === variation.id;
  });
  this.handleVariationDetailsClick(this.variationMenuItems[i].value);
};

/**
 * Update activated font count when a single variation is activated
 * Also update selection status for the activated font.
 * @returns {Nothing}
 */
FamilyController.prototype.handleActivationClick = function (fonts) {
  this.activatedFontCount++;
  var fontIds = _.pluck(fonts, 'id');
  this._updateSelectionStatus(fontIds, true);
};

/**
 * Are all fonts in the given list of fonts currently synced?
 * @param fonts {Array}
 *
 * @return {Boolean}
 */
FamilyController.prototype.isAllSynced = function (fonts) {
  var numSynced = this.syncedFonts(fonts).length;
  return numSynced > 0 && (numSynced === fonts.length);
};

/**
 * Does the family have ANY fonts eligible a web kit?
 * @return {Boolean}
 */
FamilyController.prototype.isAddToWebProjectButtonVisible = function() {
  return this._availableForWeb() &&

         // Even if this font is available for web, if every font requires an
         // action before it can be added to a kit, don't even bother showing
         // the button.
         !this._allFontsRequireWebAction();
};

/**
 * Checks if family has variable fonts and fonts not eligible for web kit
 * @return {Boolean}
 */
FamilyController.prototype.isSubscribeToCCButtonVisible = function() {
  if (!this.isAddToWebProjectButtonVisible()) {
    return this.family.has_variable_font;
  }
};

/**
 * Is a default Japanese variation current selected?
 * @returns {Boolean}
 */
FamilyController.prototype.isDefaultJapaneseLanguageSelected = function() {
  return (this.selectedVariation.default_language == JAPANESE_LANGUAGE_TAG);
};

/**
 * Is a default English variation current selected?
 * @returns {Boolean}
 */
FamilyController.prototype.isDefaultEnglishLanguageSelected = function() {
  return (this.selectedVariation.default_language == ENGLISH_LANGUAGE_TAG);
};

/**
 * Returns the CSS Class for the carousel depending on the size of the items
 *
 * @returns String
 */
FamilyController.prototype.getCarouselListClass = function(images) {
  return images > 2 ? 'adobe-fonts-family__carousel-list' : '';
};

/** Is the quick, activate or deactivate button visible?
 *
 * @returns {Boolean}
 */
FamilyController.prototype.isQuickMultiSyncAvailable = function() {
  return this.syncableFonts.length > 0 && !this.isSubfamilyActivateAvailable();
};

/** Is the Add Family button for Use Model v2 visible
 *
 * @returns {Boolean}
 */
FamilyController.prototype.isUseModelQuickMultiSyncAvailable = function() {
  return this.syncableFonts.length > 0 ;
};

FamilyController.prototype.displayFontSemanticDescription = function() {
  var self = this;

  var display = self.family.fonts.find(function (font) {
    return font.id === self.family.display_font.id;
  });

  return display.semantic_description;
};

/** Is the sync all button visible?
 *
 * @returns {Boolean}
 */
FamilyController.prototype.isQuickMultiSyncVisible = function() {
  return !this.isAllSynced(this.syncableFonts);
};

/** Is the unsync all button visible?
 *
 * @returns {Boolean}
 */
FamilyController.prototype.isQuickMultiUnsyncVisible = function() {
  return this.isAllSynced(this.syncableFonts);
};

/** Is the quick, activate or deactivate button visible?
 *
 * @returns {Boolean}
 */
FamilyController.prototype.isSubfamilyActivateAvailable = function() {
  return this.syncableFonts.length >= MAX_FOR_QUICK_MULTI_SYNC && _.keys(this.syncableSubFamilies).length > 1 && !this.isAllSynced(this.syncableFonts);
};

/**
 * Does the current font require an upgrade for web use?
 * @return {boolean}
 */
FamilyController.prototype.requiresUpgradeForWeb = function() {
  var self = this;
  var hasUpgradeRequiredWebFont = false;
  self.family.fonts.forEach(function(variation) {
    if (!hasUpgradeRequiredWebFont && self._fontRequiresUpgradeForWeb(variation.font)) {
      hasUpgradeRequiredWebFont = true;
    }
  });

  return hasUpgradeRequiredWebFont && !self._availableForWeb();
};

/**
 * Does the currently selected variation have any designers?
 * @returns {Boolean}
 */
FamilyController.prototype.selectedVariationHasDesigners = function() {
  return !_.isEmpty(this.getDesignersForSelectedVariation());
};

/**
 * Does the currently selected variation have three or more designers?
 * @returns {Boolean}
 */
FamilyController.prototype.selectedVariationHasManyDesigners = function() {
  return this.getDesignersForSelectedVariation().length >= MIN_DESIGNERS_FOR_ALT_LAYOUT;
};

/**
 * Returns an object that represents data required to display text in the
 * selected variation.
 * @returns {}
 */
FamilyController.prototype.selectedVariationDisplayData = function() {
  var variation = this.selectedVariation;

  return {
    cssName: variation.family.web_id + '-' + variation.font.web.fvd,
    fvd: variation.font.web.fvd,
    isDynamic: true,
    opaqueId: variation.family.web_id,
    unicodeRange: this.unicodeRange,
    featureSettings: this.featureSettings
  };
};

/**
 * Does the selected variation have any filters?
 * @returns {Boolean}
 */
FamilyController.prototype.selectedVariationHasFilters = function() {
  return _.size(this.selectedVariation.filters) > 0;
};

/** Sync all given fonts and display a loading dialog with family and syncing font information
 * @param familyName {String}
 * @param fonts {Array}
 */
FamilyController.prototype.sync = function(familyName, fonts) {
  var self = this;
  self.NewrelicService.addPageAction('typekit.click.sync-all-button', {family: self.family.name, useModelEnabled: false});
  self.AuthenticationService.handleAuthenticationWithPostLoginAction(
    'activate_family',
    {slug: self.family.slug}).then(function() {
      self._sync(fonts);
    });
};

/** Sync all given fonts and display a loading dialog with family and syncing font information
 * @param familyName {String}
 * @param fonts {Array}
 */
FamilyController.prototype.useModelSync = function(familyName, fonts) {
  var self = this;
  self.NewrelicService.addPageAction('typekit.click.sync-all-button', {family: self.family.name, useModelEnabled: true});
  self.AuthenticationService.handleAuthenticationWithPostLoginAction(
    'activate_family',
    {slug: self.family.slug}).then(function() {
      self._sync(fonts, true);
    });
};

/** All fonts in given font set that are available for sync AND currently synced
 * @param fonts {Array}
 *
 * @returns {Array}
 */
FamilyController.prototype.syncedFonts = function(fonts) {
  return _.filter(fonts, function(f) {
    return 'sync' in f.font && (f.font.sync['is_selected'] || _.contains(f.font.sync.install_state.split(','), 'cc'));
  });
};

/** Either syncs or unsyncs a given list of fonts for a family depending on whether all fonts in the family
 *  are currently synced. If the list of fonts contains some fonts that are not synced, the remaining will be synced.
 *  Otherwise, all fonts will be unsynced.
 *
 * @param familyName {String}
 * @param fonts {Array}
 *
 * @returns {Array}
 */
FamilyController.prototype.toggleSync = function(familyName, fonts) {
  if (this.isAllSynced(fonts)) {
    this.unsync(this.syncedFonts(fonts));
  } else {
    this.sync(familyName, this.unsyncedFonts(fonts));
  }
};

/**
 * Toggle the favorite attribute for this given family.
 */
FamilyController.prototype.toggleFavorite = async function() {
  var self = this;
  const slug = self.family.slug;
  await self.AuthenticationService.handleAuthenticationWithPostLoginAction('favorite_fonts', {familySlug: slug});
  const familyName = self.family.name;
  if (self.family.favorite) {
    self.FavoritesService.delete(self.family.slug).then(function() {
      self.family.favorite = false;
      sendFavoriteButtonAnalytics(slug, familyName, self.family.favorite);
      self._sendFavoriteEvent(self.family.favorite, slug);
    }, function(response) {
      self.NotificationService.error(response.data.error);
    });
  } else {
    self.FavoritesService.post(self.family.slug).then(function() {
      self.family.favorite = true;
      sendFavoriteButtonAnalytics(slug, familyName, self.family.favorite);
      self._sendFavoriteEvent(self.family.favorite, slug);
    }, function(response) {
      self.NotificationService.error(response.data.error);
    });
  }
};

/** Unsync all given fonts after user confirms the selection in the modal dialog
 * @param fonts {Array}
 */
FamilyController.prototype.useModelUnsyncFamily = function(fonts) {
  var self = this;
  self.FirstMileService.shouldShowRemoveFamilyConfirmationDialog().then(function(shouldShow) {
    if (shouldShow) {
      var header = self._i18n['neue.browse.family_card']['remove_family_confirmation_header'];
      var description = self._i18n['neue.browse.family_card']['remove_family_confirmation_description'];
      return self.FirstMileService.showRemoveFamilyConfirmationDialog(header, description, function() {
        self.useModelUnsync(fonts);
      });
    } else {
      self.useModelUnsync(fonts);
    }
  });
};

/** Unsync all given fonts when use model v2 is enabled
 * @param fonts {Array}
 */
FamilyController.prototype.useModelUnsync = function(fonts) {
  var self = this;
  self.NewrelicService.addPageAction('typekit.click.unsync-all-button', {family: self.family.name, useModelEnabled: true});
  fonts.forEach(function(font) {
    font.isSyncing = true;
  });

  self.SyncService.removeSyncedVariations(_.pluck(fonts, 'id')).then(function(response) {
    var errorOccurred;
    fonts.forEach(function(font) {
      font.isSyncing = false;

      if ('data' in response && response.data['removed_font_ids'].indexOf(font.id) > -1) {
        font.font.sync['is_selected'] = false;
        font.font.sync['install_state'] = '';
        self._updateSelectionStatus([font.id], false);
      } else {
        errorOccurred = true;
      }
    });

    if (errorOccurred) {
      self.NotificationService.error(self._i18n['neue.browse.family_card']['error_occurred_removing_fonts'], {
        actionLabel: self._i18n['neue.browse.family_card']['try_again'],
        callbackAction: function() { self.useModelUnsync(fonts); }});
    } else {
      self.NotificationService.notice(self._i18n['neue.browse.family_card']['fonts_removed_success_message']);
    }
  }, function() {
    self.NotificationService.error(self._i18n['neue.browse.family_card']['error_occurred_removing_fonts'], {
      actionLabel: self._i18n['neue.browse.family_card']['try_again'],
      callbackAction: function() { self.useModelUnsync(fonts); }});
  });
};

/** Unsync all given fonts
 * @param fonts {Array}
 */
FamilyController.prototype.unsync = function(fonts) {
  var self = this;
  self._keepSubfamilyDropdownOpen();
  self.NewrelicService.addPageAction('typekit.click.unsync-all-button', {family: self.family.name, useModelEnabled: false});
  fonts.forEach(function(font) {
    font.isSyncing = true;
  });

  self.SyncService.removeSyncedVariations(_.pluck(fonts, 'id')).then(function(response) {
    var errorOccurred;
    fonts.forEach(function(font) {
      font.isSyncing = false;

      if ('data' in response && response.data['removed_font_ids'].indexOf(font.id) > -1) {
        font.font.sync['is_selected'] = false;
        font.font.sync['install_state'] = '';
        self._updateSelectionStatus([font.id], false);
      } else {
        errorOccurred = true;
      }
    });

    if (errorOccurred) {
      self.NotificationService.error(self.I18nService.getMessage(self._i18n['neue.browse.family_card'], 'error_occurred_deactivating_some_fonts'));
    } else {
      self.activatedFontCount -= response.data['removed_font_ids'].length;

      if (fonts.length > 1) {
        self.NotificationService.success(self.I18nService.getMessage(self._i18n, 'neue.families.multi_sync.loading_dialog.unsync_family_multiple_fonts_html', {
          family: self.family.name,
          num: fonts.length
        }));
      } else {
        self.NotificationService.success(self.I18nService.getMessage(self._i18n, 'neue.families.multi_sync.loading_dialog.unsync_family_one_font_html', {
          family: self.family.name
        }));
      }
    }
  }, function() {
    self.NotificationService.error(self.I18nService.getMessage(self._i18n['neue.browse.family_card'], 'error_occurred_deactivating_fonts'));
  });
};

/** All fonts in given font set that are available for sync AND NOT currently synced
 * @param fonts {Array}
 *
 * @returns {Array}
 */
FamilyController.prototype.unsyncedFonts = function(fonts) {
  return _.reject(fonts, function(f) {
    return 'sync' in f.font && f.font.sync['is_selected'];
  });
};

/**
 * Does every variation in this family require an action?
 * @returns {Boolean}
 */
FamilyController.prototype._allFontsRequireWebAction = function() {
  var self = this;
  return _.every(self.family.fonts, function(variation) {
    return self._fontRequiresWebAction(variation.font);
  });
};

/**
 * Does the current family have a web availability?
 *
 * @private
 * @returns {Boolean}
 */
FamilyController.prototype._availableForWeb = function() {
  var self = this;
  return _.some(self.family.fonts, function(variation) {
    return self.fontAvailableForWeb(variation.font);
  });
};

/**
 * Is the font available for web?
 *
 * @private
 * @param {{}} font
 * @returns {Boolean}
 */
FamilyController.prototype.fontAvailableForWeb = function(font) {
  if ((this.userPlan || {}).library) {
    return 'web' in font && !this._fontIsWebPreviewOnly(font) && !this._fontRequiresWebAction(font);
  } else {
    return 'web' in font && !this._fontIsWebPreviewOnly(font) && (font.web.library_availability.trial || font.web.library_availability.full);
  }
};

/**
 * Is the font a non-preview web font, regardless of its library availability?
 * (Preview fonts = only visible to Admins)
 *
 * @param {{}} font
 * @returns {Boolean}
 */
FamilyController.prototype.isNonPreviewWebFont = function(font) {
  return font.web && !this._fontIsWebPreviewOnly(font);
};

/**
 * Return a filtered array of navigation tabs by:
 *  - removing the recommendations tab if there are no recommendations to display.
 *
 * @private
 * @returns {Array}
 */
FamilyController.prototype._filterTabs = function() {
  var self = this;
  return _.reject(self.navigationTabs, function (tab) {
    return (tab.id === 'recommendations' && !self.shouldShowFontRecommendations) ||
        (tab.id === 'templates' && !self.shouldShowCCXTemplatesRecommendations);
  });
};

/**
 * Is the font available for sync?
 *
 * @private
 * @param {{}} font
 * @returns {Boolean}
 */
FamilyController.prototype._fontAvailableForSync = function(font) {
  return font.sync && font.sync.required_action.length == 0;
};

/**
 * Given a set of variations return back the variation that matches the given fvd value
 * @param {Array} fonts
 * @param {String} fvd
 *
 * @return {Object}
 */
FamilyController.prototype._fontByWebFvd = function (fonts, fvd) {
  return _.find(fonts, function(f) {
    return f.font.web.fvd === fvd;
  });
};

/**
 * Does this variation's web font require an action?
 *
 * @param {{}} font
 * @returns {Boolean}
 */
FamilyController.prototype._fontRequiresWebAction = function(font) {
  return font.web && font.web.required_action.length > 0;
};

/**
 * Is the font web preview only?
 *
 * @private
 * @param {{}} font
 * @returns {Boolean}
 */
FamilyController.prototype._fontIsWebPreviewOnly = function(font) {
  if (!font.web) {
    return false;
  }

  return font.web.required_action.indexOf('preview_only') !== -1;
};

/**
 * Does the font require an upgrade for web use?
 *
 * @private
 * @param {{}} font
 * @returns {Boolean}
 */
FamilyController.prototype._fontRequiresUpgradeForWeb = function(font) {
  if (!font.web) {
    return false;
  }

  return font.web.required_action.indexOf('upgrade') !== -1;
};

/**
 * Leave the dropdown open for a few seconds after activating a font to
 * 1. Show that the activation toggle switch changed to the "on" state
 * 2. Let the user activate multiple subfamilies without opening the dropdown each time
 *
 * @private
 */
FamilyController.prototype._keepSubfamilyDropdownOpen = function() {
  var self = this;
  self.isSubfamilyDropdownOpen = true;
  if (self.timer) {
    self.$timeout.cancel(self.timer);
  }
  self.timer = self.$timeout(function() {
    self.isSubfamilyDropdownOpen = false;
  }, SUBFAMILY_DROPDOWN_OPEN_DURATION);
};

/**
 * Handles the context object that is used for actions like purchase
 * that require a user to be logged in, but can be triggered when the user is
 * not logged in yet.
 */
FamilyController.prototype._handleContext = function() {
  return this.RedirectContextService.handleContext();
};

FamilyController.prototype.showPaidFontsSelected = function() {
  return this.showPaidFonts;
};

/**
 * Method to update the designer's name if the name is too long.
 * If the name is too long, it is truncated and appended with `...` in the middle.
 * The original name is kept to show in the tooltip.
 */
FamilyController.prototype.updateDesignerName = function() {
  var self = this;
  if (!self.designerInfo) {
    return;
  }
  _.each(self.designerInfo, function (designer) {
    var name = designer.name;
    if (!designer.originalName) {
      designer.originalName = name;
    }

    if (name.length >= MAX_DESIGNER_NAME_LENGTH) {
      designer.name = name.substring(0, NAME_START_INDEX) + '...' + name.substring(name.length - NAME_END_INDEX, name.length);
    }
  });
};

/**
 * Preloads data from script tags on the page.
 * @private
 */
FamilyController.prototype._preloadData = function() {
  var self = this;
  return self.$q.all([
    self.DataService.get('families-show-i18n').then(function(i18n) {
      self._i18n = i18n;
    }),

    self.UserPrefsService.getUserPrefs().then(function(userPrefs) {
      self.browseMode = userPrefs.browseMode || 'default';
      self.showPaidFonts = !!userPrefs.showPaidFonts;
      self.viewType = userPrefs.selectedViewType || 'list';

      return self.DataService.get('/neue/preloaded_family_data');
    }).then(function(data) {
      self.family = data.family;
      self.specimenImagesData = data.family.specimen_images_data;
      self.availability = data.availability;
      self.designerInfo = data.designer_info;
      self.selectedVariationSlug = data.selectedVariationSlug;
      self.locale = data.locale;

      self.userPlan = data.userPlan;
      incrementSyncUsage(self.userPlan.usage['synced_desktop_fonts'] || 0);
      setSyncLimit(self.userPlan.limits['synced_desktop_fonts'] || 0);
      setPlan(self.userPlan.name);

      self.updateDesignerName();

      self.shouldShowFontRecommendations = self._shouldShowFontRecommendations(self.family.fonts);
      self.getFamilyFontSimilarities();
      self.getFamilyFontPairings();
      self.getSelectedVariationSimilarities();
      self.getSelectedVariationPairings();

      self.libraryElements = self.family.fonts.map((font) => {
        return toLibraryElement(font, data.textSampleData.textSamples);
      });

      if (self.family.fonts.length > 0) {
        self.syncableFonts = _.filter(self.family.fonts, function(f) {
          if ((self.userPlan || {}).library) {
            return 'sync' in f.font && f.font.sync['required_action'].length === 0;
          } else {
            return 'sync' in f.font && (f.font.sync.library_availability.trial || f.font.sync.library_availability.full);
          }
        });

        self.syncableSubFamilies = _.groupBy(self.syncableFonts, function(font) {
          return font.family['web_name'];
        });

        self.availabilitySummary = data.availabilitySummary;
      }
      self.activatedFontCount = (self.family.card_state || {}).activated_font_count || 0;

      self.languagesDictionary = data.languagesDictionary;
      self.filterLabelDictionary = data.filterLabelDictionary;
      self.textSampleData = data.textSampleData;
      self.kanaOnly = data.kana_only;

      self.navigationTabs = data.navigationTabs;
      self.currentTab = self.navigationTabs[0];
      self.isSubfamilyDropdownOpen = false;

      self.variationMenuItems = _.map(self.family.fonts, function(font) {
        return {
          label: font.name,
          value: font
        };
      });

      self.showUpgradeActionToggle =
        !hasPaidCreativeCloud() &&
        self.FilterVariationsService.hasUpgradeFilterOption(self.family.fonts);

      var requiredActionToggles = {
        upgrade: self.showUpgradeActionToggle && self.showPaidFonts
      };

      self.filterFontsByRequiredActions(requiredActionToggles);

      var initialSelectedVariation = self.getVariationFromSlug(self.selectedVariationSlug) || self.filteredFonts[0];
      self.setSelectedVariation(initialSelectedVariation);
      self._setExampleText();

      if (data.shouldShowExpiredFontsModal) {
        self.FirstMileService.showExpiredFontsDialog();
      }

      // Delay load the extra variable fonts code if the current family has a variable font.
      if (data.family.has_variable_font) {
        import(
          /* webpackPreload: true */
          '../../controllers/family_page.js'
        ).then(({ loadVariableFontsData }) => {
          loadVariableFontsData({
            family: data.family, locale: self.locale, featureSettings: self.featureSettings
          }).then(function(variableFontsData) {
            self.variableFontsData = variableFontsData;
            // This ensures getFontVariationSettingsCSS gets called again
            self.$scope.$apply();
          });
        });
      }
    })
  ]);
};

FamilyController.prototype.updateFonts = function() {
  this.FontLoaderService.updateFonts(this.exampleText.value);
};

FamilyController.prototype.getFontVariationSettingsCSS = function() {
  if (!this.variableFontsData) {
    return;
  }
  const variableFontData = this.variableFontsData.filter((vfData) => vfData.postscriptName === this.selectedVariation.postscript_name)[0];
  const defaultInstance = variableFontData.instances.filter((instance) => instance.instanceName === variableFontData.defaultInstanceName)[0];
  if (!defaultInstance) {
    return;
  }
  return Object.keys(defaultInstance.coordinates).map((axis) => `"${axis}" ${defaultInstance.coordinates[axis]}`).join(', ');
};

FamilyController.prototype._selectTabFromAnchor = function(id) {
  var self = this;
  for (var i in self.filteredNavigationTabs) {
    if (self.filteredNavigationTabs[i].id === id) {
      self.currentTab = self.filteredNavigationTabs[i];
      break;
    }
  }
};

// This updates the heart icon on the variation cards
FamilyController.prototype._sendFavoriteEvent = function(favorite, slug) {
  this.$window.dispatchEvent(new CustomEvent(FAVORITE_EVENT_NAME, { detail: { favorite: favorite, slug: slug } }));
};

FamilyController.prototype._setExampleText = function() {
  var self = this;
  var lang = _.first(_.uniq(_.pluck(self.family.fonts || [], 'default_language'))) || 'en';
  var textSample = self.textSampleData.textSamples[lang];
  self.exampleText = {
    value: self.kanaOnly ? textSample.list_kana : textSample.list,
    size: 36
  };
  self.featureSettings = textSample.feature_settings;
  self.unicodeRange = textSample.unicode_range;

  self.defaultExampleTextValue = self.exampleText.value;
  self.similarityCardText = {
    value: 'Champagne Sorbet',
    size: 36
  };
};

FamilyController.prototype._scrollToAnchor = function(anchor, updateUrl) {
  var self = this;
  var navBarOffset = -1 * this._getNavBarHeight();
  var element = self.$document[0].getElementById(this._getSectionId(anchor));

  self.windowIsScrolling = true;
  this.ScrollService.scrollTo(element, SCROLL_DURATION, navBarOffset).then(function () {
    self.windowIsScrolling = false;
    if (updateUrl) {
      location.href = updateUrl;
    }
  });
};

FamilyController.prototype._sync = function(fonts, isUseModelEnabled = false) {
  var self = this;
  fonts.forEach(function(font) {
    font.isSyncing = true;
  });

  self.SyncService.addSyncedVariations(_.pluck(fonts, 'id')).then(function(response) {
    var errorOccurred;
    fonts.forEach(function(font) {
      font.isSyncing = false;

      if ('data' in response && response.data['added_font_ids'].indexOf(font.id) > -1) {
        if (isUseModelEnabled) {
          font.font.sync['install_state'] = 'cc';
        } else {
          font.font.sync['is_selected'] = true;
          self._updateSelectionStatus([font.id], true);
        }
      } else {
        errorOccurred = true;
      }
    });

    if (errorOccurred) {
      if (isUseModelEnabled) {
        self.NotificationService.error(self.I18nService.getMessage(self._i18n['neue.browse.family_card'], 'error_occurred_adding_some_fonts'), {
          actionLabel: self._i18n['neue.browse.family_card']['try_again'],
          callbackAction: function() { self._sync(fonts, isUseModelEnabled); }});
      } else {
        self.NotificationService.error(self.I18nService.getMessage(self._i18n['neue.browse.family_card'], 'error_occurred_activating_some_fonts'));
      }
    } else {
      self.activatedFontCount += response.data['added_font_ids'].length;
      if (isUseModelEnabled) {
        self._handleUseModelFontSyncSuccess(fonts);
      } else {
        self._handleFontSyncSuccess(fonts);
      }
    }
  }, function() {
    if (isUseModelEnabled) {
      self.NotificationService.error(self.I18nService.getMessage(self._i18n['neue.browse.family_card'], 'error_occurred_adding_some_fonts'), {
        actionLabel: self._i18n['neue.browse.family_card']['try_again'],
        callbackAction: function() { self._sync(fonts, isUseModelEnabled); }});
    } else {
      self.NotificationService.error(self.I18nService.getMessage(self._i18n['neue.browse.family_card'], 'error_occurred_activating_some_fonts'));
    }
  });
};

FamilyController.prototype._updateAnchor = function() {
  var sectionTag = this._getCurrentSection();
  if (sectionTag.length !== 0 && this.currentTab.id !== sectionTag) {
    var newTag = '#' + sectionTag + '-section';
    if (sectionTag === 'details'){
      newTag = newTag + '+' + this.selectedVariation.slug;
    }
    this.$window.history.replaceState({ navigationTabClicked: false }, null, newTag);
    this._selectTabFromAnchor(sectionTag);
  }
};

FamilyController.prototype._getCurrentSection = function() {
  var currentTab = '';
  for (var i in this.filteredNavigationTabs) {
    if (this._isSectionInOrAboveViewport(this.filteredNavigationTabs[i].id)) {
      currentTab = this.filteredNavigationTabs[i].id;
    }
  }
  return currentTab;
};

FamilyController.prototype._isSectionInOrAboveViewport = function(sectionId) {
  if (!this.$document[0]) {
    return false;
  }
  var element = this.$document[0].getElementById(this._getSectionId(sectionId));
  var elementBounds = element.getBoundingClientRect();
  var scrollTop = Math.min(this.$document[0].body.scrollTop, this.$document[0].documentElement.scrollTop);
  return (elementBounds.top <= scrollTop + (this._getNavBarHeight() * 2));
};

FamilyController.prototype._getNavBarHeight = function() {
  var navBar = this.$document[0].querySelector('.sticky-header');
  return navBar.getBoundingClientRect().height;
};

FamilyController.prototype._getSectionId = function(sectionId){
  return sectionId + SECTION_ID_SUFFIX;
};

/**
 * Handles a successful font sync when usemodel v2 is enabled.
 *
 * @param {Object[]} fonts
 * @private
 */
FamilyController.prototype._handleUseModelFontSyncSuccess = function(fonts) {
  if (!fonts || !fonts.length) {
    return;
  }

  var self = this;
  self.FirstMileService.onUseModelFontActivation(self._i18n, {queryParams: {tkFontId: fonts[0].font.sync.font_id}});
};

/**
 * Handles a successful font sync.
 *
 * @param {Object[]} fonts
 * @private
 */
FamilyController.prototype._handleFontSyncSuccess = function(fonts) {
  var self = this;
  // The first time a user syncs a font, they should see an onboarding modal.
  // Once they've opted out, they shouldn't see the modal anymore.
  self.FirstMileService.shouldShowFontActivationDialog().then(function(shouldShow) {
    if (shouldShow) {
      return self.FirstMileService.showFontActivationDialog();
    }

    if (fonts.length > 1) {
      self.NotificationService.success(self.I18nService.getMessage(self._i18n, 'neue.families.multi_sync.loaded_dialog.heading_multiple', {
        'fonts': fonts.length,
        'family': self.family.name
      }));
    } else {
      self.NotificationService.success(self.I18nService.getMessage(self._i18n, 'neue.families.multi_sync.loaded_dialog.heading_single', {
        'font': fonts[0].name
      }));
    }
  });
};

/**
 * Gets similarities on the font family level
 */
FamilyController.prototype.getFamilyFontSimilarities = function () {
  var self = this;
  if (!self.shouldShowFontRecommendations) {
    return;
  }
  self.RecommendationsService.familyFontSimilarities(self.family.slug, PER_PAGE_SIMILARITY, PAGE_NUMBER).then(function (response) {
    self.familyFontSimilarities = response.data;
  });
};

/**
 * Gets pairings on the font family level
 */
FamilyController.prototype.getFamilyFontPairings = function () {
  var self = this;
  if (!self.shouldShowFontRecommendations) {
    return;
  }
  self.RecommendationsService.familyFontPairings(self.family.slug, PER_PAGE_PAIRING, PAGE_NUMBER).then(function (response) {
    self.familyFontPairings = response.data;
  });
};

/**
 * Gets templates on the font family level
 */
FamilyController.prototype.getFamilyCCXTemplates = function () {
  var self = this;
  if (!self.shouldCheckCCXTemplatesRecommendations) {
    return;
  }

  self.shouldCheckCCXTemplatesRecommendations = false;

  // Delay load the CCX code
  import('@adobe-fonts/site/lib/CCX.js').then(({ fetchCCXFamilyTemplates }) => {
    const preferredFamilyName = self.family.display_font.preferred_family_name;
    const limit = 40;
    const offset = 0;
    const { isLoggedIn } = getUserInfoSync();

    fetchCCXFamilyTemplates(
      preferredFamilyName,
      offset,
      limit,
      hasPaidCreativeCloud(),
      isLoggedIn).then(data => {
        if (data.length > 0) {
          const carousel = self.$document[0].querySelector('#fdp-ccx-templates');
          if (carousel) {
            self.$scope.$apply(() => {
              self.shouldShowCCXTemplatesRecommendations = true;
              carousel.items = data;
            });
          }

          this.filteredNavigationTabs = this._filterTabs();
        }
      }).catch(error => {
        console.log(`[Crossbar]: Failed to load ${error}`);
      });
  });
};

FamilyController.prototype.handleSelectedVariationChange = function () {
  location.href = '#details-section+' + this.selectedVariation.slug;
  this.getSelectedVariationSimilarities();
  this.getSelectedVariationPairings();
};

/**
 * Gets similarities for the selected variation
 */
FamilyController.prototype.getSelectedVariationSimilarities = function () {
  var self = this;
  if (!self.shouldShowFontRecommendations) {
    return;
  }
  if (self.selectedVariation && self.selectedVariation.id) {
    self.RecommendationsService.similarFonts(self.selectedVariation.id, PER_PAGE_SIMILARITY, PAGE_NUMBER).then(function (response) {
      self.selectedVariationSimilarities = response.data;
    });
  }
};

/**
 * Gets Pairings for the selected variation
 */
FamilyController.prototype.getSelectedVariationPairings = function () {
  var self = this;
  if (!self.shouldShowFontRecommendations) {
    return;
  }
  if (self.selectedVariation && self.selectedVariation.id) {
    self.RecommendationsService.pairedFonts(self.selectedVariation.id, PER_PAGE_PAIRING, PAGE_NUMBER).then(function (response) {
      self.selectedVariationPairings = response.data;
    });
  }
};

FamilyController.prototype.shouldShowFontSimilarities = function () {
  var self = this;
  return (self.familyFontSimilarities !== undefined && self.familyFontSimilarities.length > 0);
};

FamilyController.prototype.shouldShowFontPairings = function () {
  var self = this;
  return (self.familyFontPairings !== undefined && self.familyFontPairings.length > 0);
};

FamilyController.prototype.shouldShowSelectedFontSimilarities = function () {
  var self = this;
  return (self.selectedVariationSimilarities !== undefined && self.selectedVariationSimilarities.length > 0);
};

FamilyController.prototype.shouldShowSelectedFontPairings = function () {
  var self = this;
  return (self.selectedVariationPairings !== undefined && self.selectedVariationPairings.length > 0);
};

/**
 * Recommendations are only available for English fonts at this time.
 */
FamilyController.prototype._shouldShowFontRecommendations = function (fonts) {
  var anyRecommendationsExist = false;
  var allFontsEnglish = true;
  fonts.forEach(function (font) {
    if (font.has_similar_fonts_or_pairings) {
      anyRecommendationsExist = true;
    }
    if (font.default_language !== 'en') {
      allFontsEnglish = false;
    }
  });
  return anyRecommendationsExist && allFontsEnglish;
};

/**
 * Updates all occurrences of a variation (font list, pairings, details pairings) to show selected or unselected
 */
FamilyController.prototype._updateSelectionStatus = function (fontIds, status) {
  var self = this;
  fontIds.forEach(function (fontId) {
    if (self.family && self.family.fonts){
      self.family.fonts.forEach(function (font) {
        if (font.id === fontId) {
          font.font.sync.is_selected = status;
        }
      });
    }
    if (self.selectedVariationPairings) {
      self.selectedVariationPairings.forEach(function (font) {
        if (font.body_font.id === fontId) {
          font.body_font.font.sync.is_selected = status;
        } else if (font.header_font.id === fontId) {
          font.header_font.font.sync.is_selected = status;
        }
      });
    }
    if (self.familyFontPairings) {
      self.familyFontPairings.forEach(function (font) {
        if (font.body_font.id === fontId) {
          font.body_font.font.sync.is_selected = status;
        } else if (font.header_font.id === fontId) {
          font.header_font.font.sync.is_selected = status;
        }
      });
    }
  });
};

FamilyController.prototype._initCrossbar = function() {
  const self = this;
  self.DataService.get('enableProjectCrossbar').then((enabled) => {
    if (!enabled) {
      return;
    }

    self.shouldCheckCCXTemplatesRecommendations = true;
    self.shouldShowCCXTemplatesRecommendations = false;
    self.getFamilyCCXTemplates();
  });
};

module.exports = FamilyController;
