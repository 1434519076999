/**
 * A service for shared tag functions and data
 *
 * @ngInject
 */
function TagsService() {
  var TAG_BACKGROUND_CLASS_MAP = {
    'art-deco': 'tag-background--art-deco-horror',
    'blackletter': 'tag-background--blackletter-inline',
    'brush-pen': 'tag-background--brush-pen-luxury',
    'calligraphic': 'tag-background--calligraphic-marker',
    'clean': 'tag-background--clean-ornaments',
    'college': 'tag-background--college-rough',
    'comic': 'tag-background--comic-rounded',
    'cursive': 'tag-background--cursive-school',
    'friendly': 'tag-background--friendly-shaded',
    'fun': 'tag-background--fun-stencil',
    'funky': 'tag-background--funky-typewriter',
    'futuristic': 'tag-background--futuristic-wedding',
    'geometric': 'tag-background--geometric',
    'horror': 'tag-background--art-deco-horror',
    'inline': 'tag-background--blackletter-inline',
    'luxury': 'tag-background--brush-pen-luxury',
    'marker': 'tag-background--calligraphic-marker',
    'ornaments': 'tag-background--clean-ornaments',
    'rough': 'tag-background--college-rough',
    'rounded': 'tag-background--comic-rounded',
    'school': 'tag-background--cursive-school',
    'shaded': 'tag-background--friendly-shaded',
    'stencil': 'tag-background--fun-stencil',
    'typewriter': 'tag-background--funky-typewriter',
    'wedding': 'tag-background--futuristic-wedding',
    'western': 'tag-background--western'
  };

  return {
    TAG_BACKGROUND_CLASS_MAP: TAG_BACKGROUND_CLASS_MAP
  };
}

module.exports = TagsService;
