/**
 * A directive that allows you to choose the "view type" for a list of family cards.
 * The possibly view types are "list" and "grid".
 *
 * @example
 *  A view type control that's bound to the parent controller's "viewType" model.
 *
 *  <view-type-control ng-model="browse.viewType">
 *  </view-type-control>
 *
 * @ngInject
 */
function Directive($timeout) {
  return {
    scope: {
      ngModel: '=',
      onChanged: '&'
    },

    link: function(scope) {
      scope.handleViewTypeButtonClick = function(viewType) {
        scope.ngModel = viewType;

        // Handle the change event inside of a timeout so that the change event will
        // take place after the digest loop.
        $timeout(function() {
          scope.onChanged();
        });
      };

      scope.isViewTypeSelected = function(viewType) {
        return scope.ngModel == viewType;
      };
    },

    restrict: 'E',
    replace: true,
    templateUrl: '/angular_templates/directives/view_type_control.html'
  };
}

module.exports = Directive;
