/**
 * A directive for showing the family details of a variation in the web project.
 *
 * To implement this directive, follow this example:
 *
 * In your template:
 *
 * <tk-web-project-family-card
 *    tk-web-project-card-model="controller.tkWebProjectCardModel"
 *    tk-web-project-family-card-model="controller.tkWebProjectFamilyCardModel">
 * </tk-web-project-family-card>
 *
 * In your Javascript Controller:
 *
 * - tkWebProjectCardModel: The model which contains the details of the web project/kit.
 * - tkWebProjectFamilyCardModel: The model which contains the details of the tkff.
 *
 * @ngInject
 */
function Directive() {
  return {
    restrict: 'E',
    templateUrl: '/angular_templates/directives/tk_web_project_family_card.html',
    bindToController: {
      tkWebProjectCardModel: '=',
      tkWebProjectFamilyCardModel: '='
    },
    controller: require('./controller.js'),
    controllerAs: 'tkWebProjectFamilyCardCtrl'
  };
}

module.exports = Directive;
