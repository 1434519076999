/**
 * A directive for embed code options.
 *
 * To implement this directive, follow this example:
 *
 * In your template:
 *
 * <tk-web-project-embed-code-options
 *    embed-code-model="controller.embedCodeModel">
 * </tk-web-project-embed-code-options>
 *
 * In your Javascript Controller:
 *
 * - embedCodeModel: The model which the data regarding the kit required to show the embed code options component.
 *
 * @ngInject
 */
function Directive() {
  return {
    restrict: 'E',
    templateUrl: '/angular_templates/directives/tk_web_project_embed_code_options.html',
    scope: {},
    bindToController: {
      embedCodeModel: '='
    },
    controller: require('./controller.js'),
    controllerAs: 'tkWebProjectEmbedCodeOptionsCtrl'
  };
}

module.exports = Directive;
