/**
 * A service used to retrieve variations from the backend based on image specific params.
 * @ngInject
 */
function VisualSearchService($http) {
  return {
    /**
     * Get a list of font variations that match a given set of params.
     *
     * @param {String} opaqueId
     * @param {{}} params
     * @return {Promise}
     */
    put: function(opaqueId, params) {
      return $http.put('/fonts/vs/' + opaqueId, params);
    }
  };
}

module.exports = VisualSearchService;
