const ClearFiltersEvent = require('@adobe-fonts/site/events/ClearFiltersEvent.js');
const FilterToggleEvent = require('@adobe-fonts/site/events/FilterToggleEvent.js');
const LanguageToggleEvent = require('@adobe-fonts/site/events/LanguageFilterToggleEvent.js');
const FontTechnologyToggleEvent = require('@adobe-fonts/site/events/TechnologyFilterToggleEvent.js');
const NumberOfFontsSliderEvent = require('@adobe-fonts/site/events/NumberOfFontsSliderEvent.js');
const ShowPaidFontsToggleEvent = require('@adobe-fonts/site/events/ShowPaidFontsToggleEvent.js');
const TagToggleEvent = require('@adobe-fonts/site/events/TagToggleEvent.js');
const YourFontsFilterToggleEvent = require('@adobe-fonts/site/events/YourFontsFilterToggleEvent.js');
const { addMessages } = require('@adobe-fonts/site/lib/I18N.js');

var _ = require('underscore');
var angular = require('angular');

const { handleCloseClick } = require('../../util/coachmark_helpers.js');
const scriptData = require('../../util/script_data.js');
const { hasPaidCreativeCloud, getUserInfoSync } = require('../../util/user_info.js');

// This is the screen size where the sidebar should be collapsed automatically
// if it's still open.
var SIDEBAR_SMALL_SCREEN_SIZE_PX = 880;
var FILTER_HANDLER_DEBOUNCE_TIME_MS = 100;

var TAG_HEADER_COPY_MAP = {
  'art-deco': 'neue.browse.seo_tag_header_text.art_deco.seo_blurb',
  blackletter: 'neue.browse.seo_tag_header_text.blackletter.seo_blurb',
  'brush-pen': 'neue.browse.seo_tag_header_text.brush_pen.seo_blurb',
  calligraphic: 'neue.browse.seo_tag_header_text.calligraphic.seo_blurb',
  clean: 'neue.browse.seo_tag_header_text.clean.seo_blurb',
  college: 'neue.browse.seo_tag_header_text.college.seo_blurb',
  comic: 'neue.browse.seo_tag_header_text.comic.seo_blurb',
  cursive: 'neue.browse.seo_tag_header_text.cursive.seo_blurb',
  friendly: 'neue.browse.seo_tag_header_text.friendly.seo_blurb',
  fun: 'neue.browse.seo_tag_header_text.fun.seo_blurb',
  funky: 'neue.browse.seo_tag_header_text.funky.seo_blurb',
  futuristic: 'neue.browse.seo_tag_header_text.futuristic.seo_blurb',
  geometric: 'neue.browse.seo_tag_header_text.geometric.seo_blurb',
  horror: 'neue.browse.seo_tag_header_text.horror.seo_blurb',
  inline: 'neue.browse.seo_tag_header_text.inline.seo_blurb',
  luxury: 'neue.browse.seo_tag_header_text.luxury.seo_blurb',
  marker: 'neue.browse.seo_tag_header_text.marker.seo_blurb',
  ornaments: 'neue.browse.seo_tag_header_text.ornaments.seo_blurb',
  rough: 'neue.browse.seo_tag_header_text.rough.seo_blurb',
  rounded: 'neue.browse.seo_tag_header_text.rounded.seo_blurb',
  school: 'neue.browse.seo_tag_header_text.school.seo_blurb',
  shaded: 'neue.browse.seo_tag_header_text.shaded.seo_blurb',
  stencil: 'neue.browse.seo_tag_header_text.stencil.seo_blurb',
  typewriter: 'neue.browse.seo_tag_header_text.typewriter.seo_blurb',
  wedding: 'neue.browse.seo_tag_header_text.wedding.seo_blurb',
  western: 'neue.browse.seo_tag_header_text.western.seo_blurb'
};

var MAX_NUMBER_OF_STYLES = 26;

/**
 * @ngInject
 */
function FamiliesController($cookies,
                            $document,
                            $scope,
                            $timeout,
                            $window,
                            DataService,
                            FamiliesService,
                            FeedbackService,
                            FilterDataService,
                            FilteredFamilyListStateService,
                            FreezeframeService,
                            I18nService,
                            NewrelicClickTrackerService,
                            NewrelicService,
                            NotificationService,
                            ScrollService,
                            TagsService,
                            UserPrefsService) {
  var self = this;
  self.$cookies = $cookies;
  self.$document = $document;
  self.$scope = $scope;
  self.$window = $window;
  self.DataService = DataService;
  self.FamiliesService = FamiliesService;
  self.FeedbackService = FeedbackService;
  self.FilterDataService = FilterDataService;
  self.FilteredFamilyListStateService = FilteredFamilyListStateService;
  self.FreezeframeService = FreezeframeService;
  self.I18nService = I18nService;
  self.NewrelicService = NewrelicService;
  self.NotificationService = NotificationService;
  self.ScrollService = ScrollService;
  self.TagsService = TagsService;
  self.UserPrefsService = UserPrefsService;
  NewrelicClickTrackerService.init({page: 'browse'});

  self._preloadData();
  self._initWindowSizeWatcher();

  $timeout(function() {
    self.updateFilterListVisibilityForWindowSize();
  });

  self.$window.addEventListener('primary', handleCloseClick);

  // Debounce this filter change handler so that updating the filters rapidly
  // won't put the filters list in a weird state.
  self._handleFiltersChangeWithDebounce = _.debounce(
    angular.bind(self, self._handleFiltersChangeWithoutDebounce), FILTER_HANDLER_DEBOUNCE_TIME_MS);
}

/**
 * When a tag filter is selected, this will return an extra CSS class that will be used
 * to style the extra tag header.
 * @return {String}
 */
FamiliesController.prototype.getTagBackgroundClass = function() {
  if (!this.filtersModel) {
    return;
  }

  var tag = this.filtersModel.toSearchParams().tag;

  if (!tag) {
    return;
  }

  return this.TagsService.TAG_BACKGROUND_CLASS_MAP[tag];
};

/**
 * When a tag is selected, this will return the copy for the tag header.
 * @return {String}
 */
FamiliesController.prototype.getTagHeaderCopy = function() {
  var self = this;
  if (!self._i18n || !this.filtersModel) {
    return;
  }

  var tagKey = this.filtersModel.toSearchParams().tag;
  if (!tagKey) {
    return;
  }

  var matchingTag = _.find(this.filtersModel.getTags() || [], function(tag) {
    return tag.key === tagKey;
  });
  if (!matchingTag) {
    return;
  }

  var i18nKey = TAG_HEADER_COPY_MAP[tagKey];
  if (!i18nKey) {
    return;
  }

  return self.I18nService.getMessage(self._i18n, i18nKey, {
    family_count: this.filteredFamilyList.totalFamilies,
    tag_name: matchingTag.label
  });
};

/**
 * Get the font display data needed to render the current tag in the correct
 * font.
 * @return {Object}
 */
FamiliesController.prototype.getTagHeaderFontDisplayData = function() {
  if (!this.shouldShowDisplayFont()) {
    return;
  }

  var tagData = this.getTagHeaderTagData();
  if (!tagData) {
    return;
  }

  return this.filtersModel.getFontDisplayData(tagData);
};

/**
 * Get the tag data for the currently selected tag.
 * @return {Object}
 */
FamiliesController.prototype.getTagHeaderTagData = function() {
  if (!this.filtersModel) {
    return;
  }

  var tagKey = this.filtersModel.toSearchParams().tag;
  if (!tagKey) {
    return;
  }

  return this.filtersModel.getTagByKey(tagKey);
};

/**
 * Return the tag label that should be displayed for the current tag.
 * @return {String}
 */
FamiliesController.prototype.getTagHeaderLabel = function() {
  var tagData = this.getTagHeaderTagData();
  if (tagData) {
    return tagData.label;
  }
};

/**
 * Handles changes to data that should be stored on the backend as user preferences.
 */
FamiliesController.prototype.handleFilteredFamilyListUserPrefsChange = function() {
  this.UserPrefsService.updatePrefs(this.filteredFamilyList);
};

/**
 * Pass on changes from the filtered family list to the fitler model.
 * (Eventually, the remaining controls will be moved out of filtered family list
 * and this won't be necessary).
 */
FamiliesController.prototype.handleFilteredFamilyListChange = function() {
  this.filtersModel.setCurrentPage(this.filteredFamilyList.currentPage);
  this.handleFiltersChange();
};

/**
 * Indicate in the filters model that families are loading and call a debounced version of the filter change handler.
 */
FamiliesController.prototype.handleFiltersChange = function() {
  this.filtersModel.setLoading(true);
  this._handleFiltersChangeWithDebounce();
};

/**
 * Is the browse mode currently the selected browse mode?
 * @param {Object} browseMode
 * @return {Boolean}
 */
FamiliesController.prototype.isBrowseModeSelected = function(browseMode) {
  return this.filtersModel && this.filtersModel.getBrowseMode() == browseMode.id;
};

/**
 * Is the filter list hidden?
 * @return {Boolean}
 */
FamiliesController.prototype.isFilterListHidden = function() {
  return this._isFilterListHiddenByUser || this._isFilterListHiddenForSmallScreen;
};

/**
 * The tag header should only be visible if the a tag has been selected and we have
 * a specific header for the tag.
 *
 * @return {Boolean}
 */
FamiliesController.prototype.isTagHeaderVisible = function() {
  if (!this.filtersModel) {
    return;
  }

  // Do not display tag header if variable font filter is selected. VF header takes precedence over the tag header.
  var fontTechnology = this.filtersModel.toSearchParams().font_technology;
  if (fontTechnology) {
    return false;
  }

  var tag = this.filtersModel.toSearchParams().tag;
  return !!tag && !!this.TagsService.TAG_BACKGROUND_CLASS_MAP[tag];
};

/**
 * The variable font header should only be visible if the variable font filter is selected
 *
 * @return {Boolean}
 */
FamiliesController.prototype.isVfHeaderVisible = function() {
  if (!this.filtersModel) {
    return;
  }

  var fontTechnology = this.filtersModel.toSearchParams().font_technology;
  return !!fontTechnology && fontTechnology === 'vf';
};
/**
 * Determines whether tags are shown in Adobe Clean or a display font selected
 * from the backend. In general, a tag is shown in the display font if a display
 * font is selected. Some locales have poor character support among most tag
 * display fonts, so Adobe Clean is always shown for these locales.
 * @return {Boolean}
 */
FamiliesController.prototype.shouldShowDisplayFont = function() {
  var tagData = this.getTagHeaderTagData();
  if (!tagData) {
    return;
  }

  return !!tagData.font && !['ja_JP', 'ko_KR', 'ru_RU', 'zh_CN', 'zh_TW'].includes(this.filtersModel.getLocale());
};

/**
 * Hides/shows the list of filters.
 */
FamiliesController.prototype.toggleFilters = function() {
  this._isFilterListHiddenByUser = !this.isFilterListHidden();

  // Once the visibility of the filter list has been manually toggled, override
  // the small screen setting and make it match the manually set value.
  this._isFilterListHiddenForSmallScreen = false;
};

/**
 * Updates the visibility of the filter list based on the size of the window.
 * For smaller screens, the filter list will be hidden by default.
 */
FamiliesController.prototype.updateFilterListVisibilityForWindowSize = function() {
  var self = this;
  if (self._getWindowWidth() == self._previousWindowWidth) {
    return;
  }

  self._previousWindowWidth = self._getWindowWidth();
  self.$scope.$apply(function() {
    self._isFilterListHiddenForSmallScreen =
      self._getWindowWidth() <= SIDEBAR_SMALL_SCREEN_SIZE_PX;
  });
};

/**
 * Updates the list of families using the current properties set on the filtered
 * family list.
 *
 * (This method should not be used directly. Instead use the debounced version of it)
 * @private
 */
FamiliesController.prototype._handleFiltersChangeWithoutDebounce = function() {
  var self = this;
  var search = this.filtersModel.toSearchParams();

  self.FamiliesService.get(search).then(function(response) {
    self.filteredFamilyList.families = response.data.families_data.families;

    self.filteredFamilyList.totalFamilies = response.data.families_data.totalFamilies;
    self.filteredFamilyList.totalPages = response.data.families_data.totalPages;
    self.filteredFamilyList.familyCountMessage = response.data.families_data.familyCountMessage;
    self.textSampleData = response.data.text_sample_data;
    self.filtersModel.setLoading(false);
    self.FreezeframeService.update();
  });

  self.filteredFamilyList.currentPage = self.filtersModel.getCurrentPage();
  self.filteredFamilyList.browseMode = self.filtersModel.getBrowseMode();
  self.FilteredFamilyListStateService.updateUrl(search);
  self.FeedbackService.setFiltersModel(this.filtersModel);
};

/**
 * Initializes an event handler that will watch the screen size and hide the
 * filter list for smaller screens.
 */
FamiliesController.prototype._initWindowSizeWatcher = function() {
  var self = this;

  // Make sure the sidebar state gets updated when the size of the window changes.
  self.$window.addEventListener('resize', function() {
    if (self.isFilterListHiddenByUser) {
      return;
    }

    self.updateFilterListVisibilityForWindowSize();
  });
};

/**
 * Initialize the filtersModel with a language filter mode and/or a selected language.
 *
 * This method is trying to be backwards compatible with existing browse_mode params.
 *
 * @private
 */
FamiliesController.prototype._setLanguageFilters = function(browseMode) {
  var self = this;

  if (self.filteredFamilyList.selectedLanguages.length > 0) {
    self.filteredFamilyList.selectedLanguages.forEach(function(lang) {
      self.filtersModel.selectLanguageMode(lang);
    });
  } else if (browseMode === 'default') {
    // To support the existing default browse mode, if it is present, set the browse mode to default.
    self.filtersModel.setBrowseMode(browseMode);
  } else if (browseMode === 'japanese') {
    // To support the existing japanese browse mode, if it is present, set the selected language mode to "ja".
    self.filtersModel.selectLanguageMode('ja');
  } else {
    // Otherwise, just set the new language mode to match the browse mode.
    self.filtersModel.selectLanguageMode(browseMode);
  }
};

/**
 * Preload data using DataService.
 * @private
 */
FamiliesController.prototype._preloadData = function() {
  var self = this;
  const searchParams = new URLSearchParams(self.$window.location.search);

  self.UserPrefsService.getUserPrefs().then(function(userPrefs) {
    self.DataService.get('/families/i18n').then(function(data) {
      self._i18n = data;
    });

    self.DataService.get('/neue/preloaded_family_data').then(function(data) {
      self.filteredFamilyList =
        self.FilteredFamilyListStateService.getState(data.user, userPrefs);

      self.filteredFamilyList.families = data.familiesData.families;

      self.filteredFamilyList.totalFamilies = data.familiesData.totalFamilies;
      self.filteredFamilyList.totalPages = data.familiesData.totalPages;

      self.textSampleData = data.textSampleData;

      self.filtersModel = self.FilterDataService.getFilterModel({
        allFilters: data.familyFilters,
        collection: self.filteredFamilyList.collection,
        filterData: data.familyFilterData,
        hideLibraryOption: hasPaidCreativeCloud(),
        isTagsModuleVisible: data.isTagsModuleVisible,
        languages: data.languageFilters,
        locale: data.locale,
        tags: data.tagsFilters,
        signedOutState: !data.user,
        textSamples: self.textSampleData && self.textSampleData.textSamples,
        sortOrder: searchParams.get('sort'),
        changeCallback: () => self.__webComponents_syncSidebarAttributes()
      });

      self.allBrowseModes = data.browseModes;
      self._setLanguageFilters(userPrefs.browseMode || self.allBrowseModes[0].id);

      self.filtersModel.selectFontTechnology(data.fontTechnology);
      self.filtersModel.selectTag(self.filteredFamilyList.selectedTag);
      self.filtersModel.selectYourFontsFilter(data.yourFontsFilter);
      self.filtersModel.setSelectedFilters(self.filteredFamilyList.selectedFilters);

      self.filtersModel.setHideImages(self.filteredFamilyList.hideImages);
      self.filtersModel.setShowPaidFonts(self.filteredFamilyList.showPaidFonts || userPrefs.showPaidFonts, false);

      self.filtersModel.setShowBasicLibrary(userPrefs.showBasicLibrary);
      if (self.filteredFamilyList.minStyles === MAX_NUMBER_OF_STYLES && self.filteredFamilyList.maxStyles === MAX_NUMBER_OF_STYLES) {
        // When min_styles and max_styles are both pre-loaded with the max values, the slider is rendered unusable.
        // This is an issue with the sp-slider SWC itself and is tracked here: https://github.com/adobe/spectrum-web-components/issues/1974
        // Meanwhile until this bug is fixed we need to explicitly reset the slider to its initial state in this particular case.
        self.filtersModel.clearNumberOfStyles();
        self.FilteredFamilyListStateService.updateUrl(self.filtersModel.toSearchParams());
      } else {
        self.filtersModel.setMinStyles(self.filteredFamilyList.minStyles);
        self.filtersModel.setMaxStyles(self.filteredFamilyList.maxStyles);
      }

      self.filteredFamilyList.familyCountMessage = data.familyCountMessage;

      self.FeedbackService.setFiltersModel(self.filtersModel);
      self._webComponents_initFilterSidebar();
    });
  });
};


/**
  * Helper function to access window width
  *
  * @private
  */
FamiliesController.prototype._getWindowWidth = function() {
  return this.$document[0].documentElement.clientWidth;
};

/**
 * Initialize the code that will connect the Angular code to the web components
 * based filter sidebar.
 */
FamiliesController.prototype._webComponents_initFilterSidebar = function() {
  var self = this;
  var filterSidebar = self.$document.find('af-filter-sidebar')[0];
  if (!filterSidebar) {
    return;
  }

  addMessages(self.filtersModel.webComponents_getI18nForFilters());
  self.UserPrefsService.getUserPrefs().then(function(prefs) {
    self.filtersModel.setShowPaidFonts(prefs.showPaidFonts !== false, true);
    filterSidebar.showPaidFontsChecked = self.filtersModel.getShowPaidFonts();
  });
  filterSidebar.showPaidFontsSection = self.filtersModel.getShowLibraryOption();
  filterSidebar.languages = self.filtersModel.getLanguages();
  filterSidebar.tags = self.filtersModel.webComponents_getTagDataForFilterSidebar();
  this.__webComponents_syncSidebarAttributes();

  filterSidebar.addEventListener(ClearFiltersEvent.EVENT_NAME, function() {
    self.filtersModel.clearAll();
    self.__webComponents_handleSidebarEvent();
  });

  filterSidebar.addEventListener(ShowPaidFontsToggleEvent.EVENT_NAME, function(event) {
    self.filtersModel.setShowPaidFonts(event.detail.showPaidFonts, true);
    self.__webComponents_handleSidebarEvent();
  });

  filterSidebar.addEventListener(FilterToggleEvent.EVENT_NAME, function(event) {
    self.filtersModel.toggleFilter(event.detail.filterKey);
    self.NewrelicService.addPageAction('typekit.browse.click', {filterName: event.detail.filterKey});
    self.__webComponents_handleSidebarEvent();
  });

  filterSidebar.addEventListener(NumberOfFontsSliderEvent.EVENT_NAME, function(event) {
    if (event.detail.handleName == 'min') {
      self.filtersModel.setMinStyles(event.detail.value);
    } else {
      self.filtersModel.setMaxStyles(event.detail.value);
    }
    self.__webComponents_handleSidebarEvent();
  });

  // TODO: Need to rename the filter model functions
  filterSidebar.fontsInFamilyMin = self.filtersModel.getMinStyles();
  filterSidebar.fontsInFamilyMax = self.filtersModel.getMaxStyles();

  filterSidebar.addEventListener(LanguageToggleEvent.EVENT_NAME, function(event) {
    self.filtersModel.setSelectedLanguage(event.detail.language);
    self.filtersModel.setSelectedLanguageMode(event.detail.language);

    self.__webComponents_handleSidebarEvent();
  });

  filterSidebar.addEventListener(FontTechnologyToggleEvent.EVENT_NAME, function(event) {
    self.filtersModel.setSelectedFontTechnology(event.detail.fontTechnology);
    self.__webComponents_handleSidebarEvent();
  });

  filterSidebar.addEventListener(TagToggleEvent.EVENT_NAME, function(event) {
    self.filtersModel.toggleTag(event.detail.tag);
    self.NewrelicService.addPageAction({
      tagName: event.detail.tag
    });
    self.__webComponents_handleSidebarEvent();
  });

  filterSidebar.addEventListener(YourFontsFilterToggleEvent.EVENT_NAME, function(event) {
    self.filtersModel.setSelectedYourFontsFilter(event.detail.filterType);
    self.__webComponents_handleSidebarEvent();
  });
};

/**
 * Handle events coming from the web components filters sidebar.
 */
FamiliesController.prototype.__webComponents_handleSidebarEvent = function() {
  this.handleFiltersChange();
  this.__webComponents_syncSidebarAttributes();
};

/**
 * Sync the data from the filters model to the filter sidebar's attributes.
 */
FamiliesController.prototype.__webComponents_syncSidebarAttributes = function() {
  var self = this;

  var filterSidebar = self.$document.find('af-filter-sidebar')[0];
  if (!filterSidebar) {
    return;
  }

  filterSidebar.browseMode = self.filtersModel.getBrowseMode();
  filterSidebar.selectedTags = [self.filtersModel.getSelectedTag()];
  filterSidebar.selectedLanguage = self.filtersModel.getSelectedLanguages()[0] || 'all';
  filterSidebar.selectedFilters = self.filtersModel.getSelectedFilters();
  filterSidebar.selectedTechnologyFilter = self.filtersModel.getSelectedFontTechnology();
  filterSidebar.selectedYourFontsFilter = self.filtersModel.getSelectedYourFontsFilter();
  filterSidebar.fontsInFamilyMin = self.filtersModel.getMinStyles();
  filterSidebar.fontsInFamilyMax = self.filtersModel.getMaxStyles();
};

FamiliesController.prototype._updateTemplatesForFamilies = function() {
  if (!scriptData.getData('enable-project-crossbar')) {
    return;
  }

  const self = this;
  const familyNames = self.filteredFamilyList.families.map(
    family => family.display_font.preferred_family_name);

  // Async load the CCX code for now since we don't want to load it when the flipper flag
  // isn't enabled.
  import('@adobe-fonts/site/lib/CCX.js').then(({ fetchCCXFamilyTemplates }) => {
    const { isLoggedIn } = getUserInfoSync();

    familyNames.forEach(familyName => {
      if (!self.filteredFamilyList.familyTemplates[familyName]) {
        fetchCCXFamilyTemplates(
          familyName,
          0, 1,
          hasPaidCreativeCloud(),
          isLoggedIn).
        then(response => {
          self.filteredFamilyList.familyTemplates[familyName] = response;
          self.$scope.$digest();
        });
      }
    });
  });
};

module.exports = FamiliesController;
