const dropdownUtil = require('../../../util/dropdown_util.js');

var CharacterSet = require('characterset');

var SAMPLE_CHANGED_ANALYTICS_DELAY = 3000;

/**
 * Controller for the adobe-fonts-pangram-menu directive.
 * @ngInject
 */
function PangramMenuController($window, DataService, NewrelicService, NotificationService) {
  var self = this;

  self.$window = $window;
  self.DataService = DataService;
  self.NewrelicService = NewrelicService;
  self.NotificationService = NotificationService;
  self.isOpen = false;
  self.i18n = {};
  var csRegExp = CharacterSet.parseUnicodeRange(self.sampleTextData.textSamples['en'].unicode_range).toRegExp();
  self.allowedCharset = new RegExp(csRegExp + '+');

  self.DataService.get('/neue/i18n').then(function(i18n) {
    self.i18n = i18n;
  });
}

/**
 * Handles the pangram menu item for entering your own text.
 */
PangramMenuController.prototype.handleEnterYourOwnClick = function(event) {
  if (event.key === 'Enter' || event.type == 'click') {
    this._closeMenu();
    this.userEnteredTextArea.focus();
    this.userEnteredTextArea.select();
  }
};

/**
 * Handles blur on the input section.
 */
PangramMenuController.prototype.handleInputBlur = function() {
  this.$window.dispatchEvent(new Event('sample-text.blur'));
};

/**
 * Handles blur on the input section.
 */
PangramMenuController.prototype.handleInputFocus = function() {
  this.$window.dispatchEvent(new Event('sample-text.focus'));
};

/**
 * Handles clicking on a pangram menu item.
 *
 * @param {String} text
 */
PangramMenuController.prototype.handleItemClick = function(text) {
  this.ngModel.$setViewValue(text);
  this.userEnteredText = text;
  this._closeMenu();

  this.NewrelicService.addPageActionWithDelay('typekit.ui.sampleChanged', {text: text}, SAMPLE_CHANGED_ANALYTICS_DELAY);
};

/**
 * Handles changes to sample text.
 */
PangramMenuController.prototype.handleSampleChanged = function() {
  if (this.warnNonDefaultCharacterSets && !!this.userEnteredText.replace(this.allowedCharset, '')) {
    this.NotificationService.error(this.i18n['frontend.browse_sample_text.characters_not_allowed'], { timeout: 10000 });
  }

  this.ngModel.$setViewValue(this.userEnteredText);

  this.NewrelicService.addPageActionWithDelay('typekit.ui.sampleChanged', {
    text: this.ngModel.$viewValue
  }, SAMPLE_CHANGED_ANALYTICS_DELAY);
};

/**
 * Handles the pangram menu dropdown button.
 */
PangramMenuController.prototype.handleMenuButton = function() {
  this.isOpen = !this.isOpen;

  this.popover.ariaExpanded = this.isOpen ? 'true' : 'false';
};

/**
 * Is this pangram item currently selected?
 *
 * @param {String} text
 * @return {Boolean}
 */
PangramMenuController.prototype.isItemSelected = function(text) {
  return text == this.ngModel.$viewValue;
};

/**
 * Closes the pangram menu.
 * @private
 */
PangramMenuController.prototype._closeMenu = function() {
  this.popover.ariaExpanded = 'false';

  this.isOpen = false;
};

/**
 * Make the pangram menu navigable using the dropdown util
 * The escape & tab keys should close the popover
 *
 * @param {*} event
 * @return nothing
 */
PangramMenuController.prototype.navigateOptions = function(event) {
  if (event.key === 'Escape' || event.key === 'Tab') {
    return this.handleMenuButton();
  }

  dropdownUtil.navigateDropdown(event, this.openMenuButton, this.listOptions);
};

/**
 * The pangram menu options' buttons are disabled and removed from the tab order when the menu is closed,
 * they are placed into the tab order when the menu is open
 *
 * @returns nothing
 */
PangramMenuController.prototype.isOptionDisabled = function() {
  return !this.isOpen;
};

module.exports = PangramMenuController;
