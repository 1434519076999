var _ = require('underscore');
/**
 * Controller for the font-family-accordion directive.
 * @ngInject
 */
function FontFamilyAccordionController($scope,
                                       AuthenticationService,
                                       I18nService,
                                       FontCountService,
                                       NewrelicService,
                                       NotificationService,
                                       SyncService) {
  var self = this;
  self.$scope = $scope;
  self.AuthenticationService = AuthenticationService;
  self.I18nService = I18nService;
  self.FontCountService = FontCountService;
  self.NewrelicService = NewrelicService;
  self.NotificationService = NotificationService;
  self.SyncService = SyncService;
  self._init();

  self.$scope.$watch(function() {
    return self.families;
  }, function() {
    self._init();
  });
}

FontFamilyAccordionController.prototype.loadMoreFamilies = function() {
  this.loadMoreFamilies();
};

FontFamilyAccordionController.prototype.handleFontVariationSyncButtonClick = function(variation) {
  var self = this;
  this.AuthenticationService.handleAuthenticationWithPostLoginAction(
    'activate_font', {font_variation_id: variation.id}).then(function() {
      if (self.isActiveFontsView()) {
        self._removeSyncedVariation(variation);
      } else {
        self._addSyncedVariation(variation);
      }
    });
};

FontFamilyAccordionController.prototype.handleFamilySyncButtonClick = function(family) {
  var self = this;
  this.AuthenticationService.handleAuthenticationWithPostLoginAction(
    'activate_family', {slug: family.slug}).then(function() {
      if (self.isActiveFontsView()) {
        self._removeSyncedFamily(family);
      } else {
        self._addSyncedFamily(family);
      }
    });
};

FontFamilyAccordionController.prototype.familyLink = function(family) {
  return '/fonts/' + family.slug;
};

FontFamilyAccordionController.prototype.isSelectedVariation = function(variation) {
  return this.selectedFont && this.selectedFont.id === variation.id;
};

FontFamilyAccordionController.prototype.isSelectedFamily = function(family) {
  return this.selectedFamily && this.selectedFamily.slug === family.slug;
};

FontFamilyAccordionController.prototype.isCollapsed = function(family) {
  return !!family.isCollapsed;
};

FontFamilyAccordionController.prototype.toggleAccordion = function(family) {
  family.isCollapsed = !family.isCollapsed;
};

FontFamilyAccordionController.prototype.handleFamilyRowClick = function(family) {
  this.selectedFont = family.fonts[0];
  this.selectedFamily = family;
};

FontFamilyAccordionController.prototype.handleFontVariationRowClick = function(variation, family) {
  this.selectedFont = variation;
  this.selectedFamily = family;
};

FontFamilyAccordionController.prototype.handleNextButtonClick = function() {
  var familyIndex = this._selectedFontFamilyIndex();
  var variationIndex = this._selectedFontIndex();
  var selectedFamily = this._selectedFontFamily();
  this._memoizeIndices(variationIndex, familyIndex);

  if (++variationIndex < selectedFamily.fonts.length) {
    this.selectedFont = selectedFamily.fonts[variationIndex];
    this._memoizeIndices(variationIndex, familyIndex);
  } else if (++familyIndex < this.families.length) {
    this.selectedFamily = this.families[familyIndex];
    this.selectedFont = this.selectedFamily.fonts[0];
    this._memoizeIndices(0, familyIndex);
  }
};

FontFamilyAccordionController.prototype.handlePreviousButtonClick = function() {
  var familyIndex = this._selectedFontFamilyIndex();
  var variationIndex = this._selectedFontIndex();
  this._memoizeIndices(variationIndex, familyIndex);

  if (--variationIndex >= 0) {
    this.selectedFont = this._selectedFontFamily().fonts[variationIndex];
    this._memoizeIndices(variationIndex, familyIndex);
  } else if (--familyIndex >= 0) {
    this.selectedFamily = this.families[familyIndex];
    var newVariationIndex = this.selectedFamily.fonts.length - 1;
    this.selectedFont = this.selectedFamily.fonts[newVariationIndex];
    this._memoizeIndices(newVariationIndex, familyIndex);
  }
};

FontFamilyAccordionController.prototype.isExpired = function(variation) {
  var font = variation.font;
  if (font && font.sync) {
    return !!font.sync.install_state && _.contains(font.sync.install_state.split(','), 'cc');
  }
};

FontFamilyAccordionController.prototype.reactivate = function(variation) {
  var self = this;
  this.AuthenticationService.handleAuthenticationWithPostLoginAction(
    'activate_font', {font_variation_id: variation.id}).then(function() {
      self._reactivateVariation(variation);
    });
};

FontFamilyAccordionController.prototype.getActionName = function() {
  if (this.isActiveFontsView()) {
    return this.I18nService.getMessage(this.i18n(), 'my_fonts.accordion_heading.deactivate');
  }
  return this.I18nService.getMessage(this.i18n(), 'my_fonts.accordion_heading.activate');
};

FontFamilyAccordionController.prototype.isMouseOverReinstallIcon = function(variation) {
  return variation.isMouseOverReinstallIcon;
};

FontFamilyAccordionController.prototype.handleReinstallIconMouseOver = function(variation) {
  variation.isMouseOverReinstallIcon = true;
};

FontFamilyAccordionController.prototype.handleReinstallIconMouseLeave = function(variation) {
  variation.isMouseOverReinstallIcon = false;
};

/**
 * Does the family provided have any variable fonts?
 *
 * @param {Object} family
 * @return {Boolean}
 */
FontFamilyAccordionController.prototype.hasVariableFonts = function(family) {
  return _.any(family.fonts, function(font) {
    return font.is_variable_font;
  });
};

FontFamilyAccordionController.prototype._reactivateVariation = function(variation) {
  var self = this;
  var errorOccurred;
  self.NewrelicService.addPageAction('typekit.my-fonts-page.click.reinstall-button', { variationId: variation.id });

  self.SyncService.addSyncedVariations([variation.id]).then(function(response) {
    if ('data' in response && response.data['added_font_ids'].indexOf(variation.id) > -1) {
      var family = _.findWhere(self.families, {slug: variation.family.slug});
      var fontVariation = _.findWhere(family.fonts, {id: variation.id});
      fontVariation.font.sync.install_state = 'os';
    } else {
      errorOccurred = true;
    }
  }, function() {
    errorOccurred = true;
  });

  if (errorOccurred) {
    self.NotificationService.error(self.I18nService.getMessage(self.i18n(), 'neue.browse.family_card.error_occurred_activating_fonts'));
  }
};

FontFamilyAccordionController.prototype._removeSyncedFamily = function(family) {
  var self = this;
  var fonts = family.fonts;

  self.NewrelicService.addPageAction('typekit.click.unsync-all-button', {family: family.name});

  self.SyncService.removeSyncedVariations(_.pluck(fonts, 'id')).then(function(response) {
    var errorOccurred;
    var unSyncedFontIds = [];
    fonts.forEach(function(font) {
      if ('data' in response && response.data['removed_font_ids'].indexOf(font.id) > -1) {
        unSyncedFontIds.push(font.id);
      } else {
        errorOccurred = true;
      }
    });
    self.FontCountService.updateActivatedFontsCount(-unSyncedFontIds.length);
    if (errorOccurred) {
      self.NotificationService.error(self.I18nService.getMessage(self.i18n(), 'neue.browse.family_card.error_occurred_deactivating_some_fonts'));
      family.fonts = _.filter(fonts,
        function (font) {
          return !_.contains(unSyncedFontIds, font.id);
        });
    } else {
      if (unSyncedFontIds.length > 1) {
        self.NotificationService.success(self.I18nService.getMessage(self.i18n(), 'neue.browse.family_card.fonts_deactivated_action.other', {
          count: unSyncedFontIds.length
        }));
      } else {
        self.NotificationService.success(self.I18nService.getMessage(self.i18n(), 'neue.browse.family_card.fonts_deactivated_action.one'));
      }
      self._removeFamily(family);
      self.FontCountService.updateFamiliesCount(-1);
    }
    if (self.isSelectedFamily(family)) {
      self._removeSelections();
    }
  }, function() {
    self.NotificationService.error(self.I18nService.getMessage(self.i18n(), 'neue.browse.family_card.error_occurred_deactivating_fonts'));
  });
};

FontFamilyAccordionController.prototype._addSyncedFamily = function(family) {
  var self = this;
  var fonts = family.fonts;

  self.NewrelicService.addPageAction('typekit.click.sync-all-button', {family: family.name});

  self.SyncService.addSyncedVariations(_.pluck(fonts, 'id')).then(function(response) {
    var errorOccurred;
    var syncedFontIds = [];
    fonts.forEach(function(font) {
      if ('data' in response && response.data['added_font_ids'].indexOf(font.id) > -1) {
        syncedFontIds.push(font.id);
      } else {
        errorOccurred = true;
      }
    });
    self.FontCountService.updatePreviouslyActivatedFontsCount(-syncedFontIds.length);
    if (errorOccurred) {
      self.NotificationService.error(self.I18nService.getMessage(self.i18n(), 'neue.browse.family_card.error_occurred_activating_some_fonts'));
      family.fonts = _.filter(fonts,
        function (font) {
          return !_.contains(syncedFontIds, font.id);
        });
    } else {
      if (syncedFontIds.length > 1) {
        self.NotificationService.success(self.I18nService.getMessage(self.i18n(), 'neue.browse.family_card.fonts_activated_action.other', {
          count: syncedFontIds.length
        }));
      } else {
        self.NotificationService.success(self.I18nService.getMessage(self.i18n(), 'neue.browse.family_card.fonts_activated_action.one'));
      }
      self._removeFamily(family);
      self.FontCountService.updateFamiliesCount(-1);
    }
    if (self.isSelectedFamily(family)) {
      self._removeSelections();
    }
  }, function() {
    self.NotificationService.error(self.I18nService.getMessage(self.i18n(), 'neue.browse.family_card.error_occurred_activating_fonts'));
  });
};

FontFamilyAccordionController.prototype._selectedFontFamilyIndex = function() {
  var self = this;
  return self.selectedFont.familyIndex || _.findIndex(self.families, {
    slug: self.selectedFont.family.slug
  });
};

FontFamilyAccordionController.prototype._selectedFontFamily = function() {
  return this.families[this._selectedFontFamilyIndex()];
};

FontFamilyAccordionController.prototype._selectedFontIndex = function() {
  var self = this;
  return self.selectedFont.variationIndex || _.findIndex(self._selectedFontFamily().fonts, {
    id: self.selectedFont.id
  });
};

FontFamilyAccordionController.prototype._memoizeIndices = function(variationIndex, familyIndex) {
  this.selectedFont.familyIndex = familyIndex;
  this.selectedFont.variationIndex = variationIndex;
};


FontFamilyAccordionController.prototype._removeSyncedVariation = function(variation) {
  var self = this;
  self.NewrelicService.addPageAction('typekit.click.unsync-button');

  self.SyncService.removeSyncedVariations([variation.id]).then(function() {
    self.FontCountService.updateActivatedFontsCount(-1);
    self._removeVariationFromFontFamilyList(variation);
  });
};

FontFamilyAccordionController.prototype._addSyncedVariation = function(variation) {
  var self = this;
  self.NewrelicService.addPageAction('typekit.click.sync-button');

  self.SyncService.addSyncedVariations([variation.id]).then(function() {
    self.FontCountService.updatePreviouslyActivatedFontsCount(-1);
    self._removeVariationFromFontFamilyList(variation);
  });
};

FontFamilyAccordionController.prototype._removeVariationFromFontFamilyList = function(variation) {
  var self = this;
  var familyIndex = _.findIndex(self.families, {
    slug: variation.family.slug
  });
  var family = self.families[familyIndex];
  family.fonts = _.without(family.fonts, variation);
  if (family.fonts.length === 0) {
    self._removeFamily(family);
    self.FontCountService.updateFamiliesCount(-1);
  }
  if (self.isSelectedVariation(variation)) {
    self._removeSelections();
  }
};

FontFamilyAccordionController.prototype._removeSelections = function() {
  this.selectedFont = null;
  this.selectedFamily = null;
};

FontFamilyAccordionController.prototype._removeFamily = function(family) {
  this.families = _.without(this.families, family);
  this.isFamilyRemovedFromList = true;
};

FontFamilyAccordionController.prototype._init = function() {
  if (this.isFamilyRemovedFromList) {
    this.isFamilyRemovedFromList = false;
    return;
  }

  this.selectedFamily = this.families[0];
  var firstFont = this.selectedFamily.fonts[0];
  firstFont.isFirst = true;

  this.selectedFont = firstFont;

  var lastFamily = this.families[this.families.length - 1];
  var lastFont = lastFamily.fonts[lastFamily.fonts.length - 1];
  lastFont.isLast = true;
};

module.exports = FontFamilyAccordionController;
