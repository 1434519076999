/**
 * A directive that can be used to render a font variation pairing.
 * @example
 *
 *   <font-pairing-card   variation="variation"
 *                        pairVariation="pairVariation"
 *                        example-text="family.exampleText"
 *                        ng-repeat="variation in family.fontVariations">
 *   </font-pairing-card>
 *
 * @ngInject
 */
function Directive() {
  return {
    scope: {},
    bindToController: {
      exampleText: '&',
      onActivate: '&',
      onDeactivate: '&',
      isInDetailsSection: '&',
      textSamples: '&',
      userLoggedIn: '&',
      showRequiredAction: '=',
      familySlug: '&',
      variation: '&',
      linkHeaderVariationToFamily: '&',
      pairVariation: '&',
      selectedFeedback: '=',
      onVariationHeaderClick: '&',
      onVariationBodyClick: '&',
      fontPairId: '&',
      recommendationIndex: '&'
    },
    controller: require('./controller.js'),
    controllerAs: 'fontVariation',
    restrict: 'E',
    replace: true,
    templateUrl: '/angular_templates/directives/font_pairing_card.html'
  };
}

module.exports = Directive;
