var ESC_KEY_CODE = 27;

/**
 * A directive that provides a list of sample text options that the user can choose
 * from. The last chosen option will be bound to the ng-model property. The user
 * can also enter their own sample text inside of an input that's drawn by the directive.
 *
 * @example
 *   <adobe-fonts-pangram-menu ng-model="parent.pangramValue"
 *                 sample-text-options="parent.sampleTextOptions">
 *   </adobe-fonts-pangram-menu>
 *
 * @ngInject
 */
function PangramMenuDirective($document) {
  return {
    scope: {},
    bindToController: {
      sampleTextData: '=',
      warnNonDefaultCharacterSets: '=?'
    },

    controller: require('./controller.js'),
    controllerAs: 'pangramMenu',
    require: ['adobeFontsPangramMenu', 'ngModel'],

    link: function(scope, element, attrs, requiredControllers) {
      var pangramMenu = requiredControllers[0];
      pangramMenu.ngModel = requiredControllers[1];

      // Store a reference to the user entered text area so we can focus and
      // select it later on.
      pangramMenu.userEnteredTextArea =
        element[0].querySelector('.adobe-fonts-pangram-menu__text');

      pangramMenu.listOptions = element[0].querySelector('.adobe-fonts-pangram-menu__list');
      pangramMenu.popover = element[0].querySelector('.adobe-fonts-pangram-menu__menu-popover');
      pangramMenu.openMenuButton = element[0].querySelector('.adobe-fonts-pangram-menu__open-button');

      pangramMenu.ngModel.$render = function() {
        pangramMenu.userEnteredText = pangramMenu.ngModel.$viewValue;
      };

      _initBodyListeners(scope, element, pangramMenu);
    },
    replace: true,
    restrict: 'E',
    templateUrl: '/angular_templates/directives/adobe_fonts_pangram_menu.html',
    transclude: true
  };

  /**
  * Initializes event handlers that listen on the document body.
  *
  * @private
  * @param {angular.Element} element
  * @param {Object} controller
  */
  function _initBodyListeners(scope, element, controller) {
    // Close the menu if the user clicks outside it.
    $document.on('click', function(event) {
      if (!_isClickInsideElement(element[0], event.target)) {
        scope.$apply(function() {
          controller._closeMenu();
        });
      }
    });

    // Close the menu when the user hits the esc key.
    $document.on('keyup', function(event) {
      if (event.keyCode == ESC_KEY_CODE) {
        scope.$apply(function() {
          controller._closeMenu();
        });
      }
    });
  }

  /**
  * Is the element a child of the target?
  *
  * @private
  * @param {Element} element
  * @param {Element} currentTarget
  */
  function _isClickInsideElement(element, currentTarget) {
    if (currentTarget == element) {
      return true;
    }

    if (currentTarget.parentNode) {
      return _isClickInsideElement(element, currentTarget.parentNode);
    }

    return false;
  }
}

module.exports = PangramMenuDirective;
