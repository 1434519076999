/**
 * A service for handling scrolling.
 *
 * @ngInject
 */
function ScrollService($cookies, $document, $q, $timeout, $window) {
  return {
    scrollTo: scrollTo,
    scrollUpTo: scrollUpTo,
    setCookieIfSawElement: setCookieIfSawElement
  };


  /**
   * Scroll to element anywhere on page
   *
   * @param {HTMLElement} element
   * @param {Number} duration
   * @param {Number} offset, optional
   */
  function scrollTo(element, duration, offset) {
    return $q(function (resolve) {
      offset = offset || 0;
      var elementTop = element.getBoundingClientRect().top;
      $document.scrollTop(elementTop + $window.pageYOffset + offset, duration);

      $timeout(resolve, duration);
    });
  }

  /**
   * Scroll to element only if currently below it.
   *
   * @param {HTMLElement} element
   * @param {Number} duration
   */
  function scrollUpTo(element, duration) {
    var elementTop = element.getBoundingClientRect().top;
    // elementTop is relative to the viewport, so a negative value is above the visible area
    if (elementTop < 0) {
      $document.scrollTop(elementTop + $window.pageYOffset, duration);
    }
  }

  /**
   * Set a cookie to true if the element provided is ever in the viewport
   *
   * @param {HTMLElement} element Element being watched
   * @param {String} cookie Name of cookie to set
   */
  function setCookieIfSawElement(element, cookie) {
    if (!element) {
      return;
    }

    if (_isElementInViewport(element) && !$cookies.get(cookie)) {
      $cookies.put(cookie, true);
      return;
    }

    $window.addEventListener('scroll', function() {
      if (_isElementInViewport(element) && !$cookies.get(cookie)) {
        $cookies.put(cookie, true);
      }
    });
  }

  /**
   * Is the element provided currently in the viewport?
   *
   * @param {HTMLElement} element Element being watched
   * @returns {Boolean}
   */
  function _isElementInViewport(element) {
    var rect = element.getBoundingClientRect();
    return rect.top >= 0 && rect.bottom <= $window.innerHeight;
  }
}

module.exports = ScrollService;
