const UrlService = require('../../../util/url_service.js').default;

var SCROLL_OFFSET = 500;
const PAGE_CHANGE_EVENT = 'pagination-change';

/**
 * @ngInject
 */
function AdobeFontsFontpackCardListController($q,
                                              $element,
                                              $http,
                                              DataService,
                                              FontpackService,
                                              ScrollService) {
  var self = this;
  self.$q = $q;
  self.$element = $element;
  self.$http = $http;
  self.DataService = DataService;
  self.FontpackService = FontpackService;
  self.ScrollService = ScrollService;

  self._preloadData();
  self.$element.on(PAGE_CHANGE_EVENT, function(event) {
    self.currentPage = event.detail.page;
    self.handlePageNumberChange();
  });
}

/**
 * Toggles class of a font pack item depending on its "draft" status
 *
 * @param {Object} fontpackItem
 * @returns {String}
 */
AdobeFontsFontpackCardListController.prototype.fontpackItemClass = function(fontpackItem) {
  return 'collection-item' + (fontpackItem.status === 'draft' ? ' collection-item-draft' : '');
};

/**
 * Preloads data from script tags on the page.
 *
 * @private
 * @returns {Promise}
 */
AdobeFontsFontpackCardListController.prototype._preloadData = function() {
  var self = this;

  return self.$q.all([
    self.DataService.get('/neue/fontpack_items').then(function(data) {
      self.fontpacks = data.fontpacks;
      self.FontpackService.addFontpacks(self.fontpacks);
      self.currentPage = data.currentPage;
      self.totalPages = data.totalPages;
    })
  ]);
};

/**
 * Handles Page Number change.
 */
AdobeFontsFontpackCardListController.prototype.handlePageNumberChange = function() {
  var self = this;
  self.$http.get('/collections.json', {
    params: {
      page: self.currentPage
    }
  }).then(function(response) {
    self.fontpacks = response.data.fontpacks;
    self.FontpackService.clearFontpacks();
    self.FontpackService.addFontpacks(self.fontpacks);
  });

  UrlService.updateSearchParams({page: self.currentPage});
  self.ScrollService.scrollUpTo(self.$element[0], SCROLL_OFFSET);
};

/**
 * Is the list of fontpacks empty?
 * @returns {Boolean}
 */
AdobeFontsFontpackCardListController.prototype.isEmpty = function() {
  return !this.fontpacks || this.fontpacks.length < 1;
};

module.exports = AdobeFontsFontpackCardListController;
