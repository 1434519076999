/**
 * A directive for the web project card.
 *
 * To implement this directive, follow this example:
 *
 * In your template:
 *
 * <tk-web-project-card
 *    embed-code-model="controller.embedCodeModel"
 *    tk-web-project-card-model="controller.tkWebProjectCardModel">
 * </tk-web-project-card>
 *
 * In your Javascript Controller:
 *
 * - embedCodeModel: The model which contains the data required by the embed code options component.
 * - tkWebProjectCardModel: The model which contains the details of the web project/kit.
 *
 * @ngInject
 */
function Directive() {
  return {
    restrict: 'E',
    templateUrl: '/angular_templates/directives/tk_web_project_card.html',
    bindToController: {
      embedCodeModel: '=',
      tkWebProjectCardModel: '='
    },
    controller: require('./controller.js'),
    controllerAs: 'tkWebProjectCardCtrl'
  };
}

module.exports = Directive;
