/**
 * A class that returns data for a tkff/family in a kit
 *
 * @constructor
 * @params {tkffData} data about a particular tkff.
 * @ngInject
 */
function TkWebProjectFamilyCardModel(tkffData) {
  this._tkffData = tkffData;
  this._formattedCssFontStack = this.formatCssFontStack(tkffData.css_font_stack);
  this.initializeEnableDisableVariationArrays();
  if (tkffData.fvd_sorted_variations) {
    this.initializeIsFamilyRetired();
  }
}

TkWebProjectFamilyCardModel.prototype.getTkffData = function() {
  return this._tkffData;
};

TkWebProjectFamilyCardModel.prototype.setTkffData = function(tkffData) {
  this._tkffData = tkffData;
  this.initializeEnableDisableVariationArrays();
};

TkWebProjectFamilyCardModel.prototype.hasVariationId = function(id) {
  return this._tkffData.fvd_sorted_variations.map((variation) => variation.id).includes(id);
};

TkWebProjectFamilyCardModel.prototype.initializeEnableDisableVariationArrays = function() {
  this._tkffData.variationsEnabled = [];
  this._tkffData.variationsDisabled = [];
};

TkWebProjectFamilyCardModel.prototype.updateVariationsEnabled = function(variationId) {
  var index = this._tkffData.variationsDisabled.indexOf(variationId);
  if (index > -1) {
    this._tkffData.variationsDisabled.splice(index, 1);
  }
  this._tkffData.variationsEnabled.push(variationId);
};

TkWebProjectFamilyCardModel.prototype.updateVariationsDisabled = function(variationId) {
  var index = this._tkffData.variationsEnabled.indexOf(variationId);
  if (index > -1) {
    this._tkffData.variationsEnabled.splice(index, 1);
  }
  this._tkffData.variationsDisabled.push(variationId);
};

/**
 * Returns a comma separated string of the css font stack for a variation.
 *
 * @returns {String}
 */
TkWebProjectFamilyCardModel.prototype.formatCssFontStack = function(cssFontStack) {
  return cssFontStack.replace(/['"]/g, '').replace(',', ', ');
};

TkWebProjectFamilyCardModel.prototype.getFormattedCssFontStack = function() {
  return this._formattedCssFontStack;
};

/**
 * Initialize 'isFamilyRetired' to true if all variations in the family are retired, false otherwise
 * Used to show a different warning message if the entire family is retired when we try to delete it from the web project
 *
 * Initialize 'isAnyFontRetired' to true if at least one variation in the family is retired, false otherwise
 * Used to decide if a warning dialog should be shown or not when we try to delete a family from the web project
 *
 * @returns {void}
 */
TkWebProjectFamilyCardModel.prototype.initializeIsFamilyRetired = function() {
  this.isFamilyRetired = true;
  this.isAnyFontRetired = false;
  var i;
  for (i = 0; i < this._tkffData.fvd_sorted_variations.length; i++) {
    if (!this._tkffData.fvd_sorted_variations[i].is_retired) {
      this.isFamilyRetired = false;
    } else {
      this.isAnyFontRetired = true;
    }
  }
};

module.exports = TkWebProjectFamilyCardModel;
