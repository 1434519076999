var _ = require('underscore');
const { getStyleFromFVD, getWeightFromFVD } = require('@adobe-fonts/site/lib/FVDHelper.js');

var DEFAULT_BROWSE_MODE = 'default';
var DEFAULT_PAGE = 1;
var DEFAULT_SORT_ORDER = 'featured_rank';
var DEFAULT_MIN_STYLES = 1;
var DEFAULT_MAX_STYLES = 26;

/**
 * @ngInject
 */
function FilterDataService(DialogService, UserPrefsService) {
  /**
   * Returns a new family filter model that uses the filters passed in.
   *
   * @param options {{
   *   allFilters: {Object},
   *   filterData: {Object},
   *   languages: {[]}
   * }}
   * @returns {FilterModel}
   */
  function getFilterModel(options) {
    return new FilterModel(options);
  }

  /**
   * A class that wraps the raw filter data from platform and manages a list of selected
   * filters.
   *
   * @constructor
   * @param options {{
   *   allFilters: {Object},
   *   changeCallback: {Function}
   *   filterData: {Object},
   *   languages: {[]},
   *   collection: {String},
   * }}
   */
  function FilterModel(options) {
    options = options || {};

    this._allFilters = options.allFilters;
    this._browseMode = DEFAULT_BROWSE_MODE;
    this._collection = options.collection;
    this._currentPage = DEFAULT_PAGE;
    this._filterDataIndex = options.filterData;
    this._hideImages = options.hideImages;
    this._hideLibraryOption = options.hideLibraryOption;
    this._isLoading = false;
    this._isTagsModuleVisible = options.isTagsModuleVisible;
    this._languages = options.languages;
    this._locale = options.locale;
    this._selectedFilters = [];
    this._selectedLanguages = [];
    this._selectedTag = null;
    this._selectedFontTechnology = null;
    this._selectedYourFontsFilter = null;
    this._sortOrder = options.sortOrder || DEFAULT_SORT_ORDER;
    this._showPaidFonts = options._showPaidFonts;
    this._minStyles = options.minStyles;
    this._maxStyles = options.maxStyles;
    this._signedOutState = options.signedOutState;
    this._tags = options.tags;
    this._textSamples = options.textSamples;

    if (options.changeCallback) {
      this._changeCallback = options.changeCallback;
    }
  }

  /**
   * Clear all of the selected filter data.
   */
  FilterModel.prototype.clearAll = function() {
    this.clearSelectedFilters();
    this.clearSelectedLanguages();
    this.clearSelectedTag();
    this.clearSelectedFontTechnology();
    this.clearSelectedYourFontsFilter();
    this.clearNumberOfStyles();
    this.setCurrentPage(DEFAULT_PAGE);

    this._triggerChangeCallback();
  };

  /**
   * Clears the list of selected filters.
   */
  FilterModel.prototype.clearSelectedFilters = function() {
    this._selectedFilters = [];
    this.setCurrentPage(DEFAULT_PAGE);
  };

  /**
   * Clear only the filters for the specific section passed in.
   * @param {Object} filterSection
   */
  FilterModel.prototype.clearSelectedFiltersForSection = function(filterSection) {
    var self = this;
    var selectedSectionFilters = self.getSelectedFiltersForSection(filterSection);
    self._selectedFilters = _.reject(self._selectedFilters, function(selectedFilter) {
      return _.contains(selectedSectionFilters, selectedFilter);
    });

    this.setCurrentPage(DEFAULT_PAGE);
  };

  /**
   * Clears the list of selected languages;
   */
  FilterModel.prototype.clearSelectedLanguages = function() {
    this._selectedLanguages = [];
    this.setCurrentPage(DEFAULT_PAGE);
  };

  /**
   * Clears the selected tag.
   */
  FilterModel.prototype.clearSelectedTag = function() {
    this._selectedTag = null;
    this.setCurrentPage(DEFAULT_PAGE);
  };

  /**
   * Clears the selected font technology.
   */
  FilterModel.prototype.clearSelectedFontTechnology = function() {
    this._selectedFontTechnology = null;
    this.setCurrentPage(DEFAULT_PAGE);
  };

  /**
   * Clears the selected font technology.
   */
  FilterModel.prototype.clearSelectedYourFontsFilter = function() {
    this._selectedYourFontsFilter = null;
    this.setCurrentPage(DEFAULT_PAGE);
  };

  /**
   * Clears the number of styles range slider.
   */
  FilterModel.prototype.clearNumberOfStyles = function() {
    this._minStyles = DEFAULT_MIN_STYLES;
    this._maxStyles = DEFAULT_MAX_STYLES;
    this.setCurrentPage(DEFAULT_PAGE);
  };

  /**
   * Removes the filter from the list of selected filters.
   * @returns {String[]}
   */
  FilterModel.prototype.deselectFilter = function(filterKey) {
    this._selectedFilters = _.without(this._selectedFilters, filterKey);
    this.setCurrentPage(DEFAULT_PAGE);
  };

  /**
   * Removes the language from the list of selected languages.
   * @param {String} languageKey
   */
  FilterModel.prototype.deselectLanguage = function(languageKey) {
    this._selectedLanguages = _.without(this._selectedLanguages, languageKey);
    this.setCurrentPage(DEFAULT_PAGE);
  };

  /**
   * Get the current browse mode.
   */
  FilterModel.prototype.getBrowseMode = function() {
    return this._browseMode;
  };

  /**
   * Get the current page number.
   * @return {Number}
   */
  FilterModel.prototype.getCurrentPage = function() {
    return this._currentPage;
  };

  /**
   * Returns the filter data that matches up with the key.
   *
   * @param {String} filterKey
   * @returns {{}}
   */
  FilterModel.prototype.getDataForFilter = function(filterKey) {
    return _.find(this._filterDataIndex, function(filterData) {
      return filterData.key == filterKey;
    });
  };


  /**
   * Returns the filter data that matches up with the slug.
   *
   * @param {String} filterSlug
   * @returns {{}}
   */
  FilterModel.prototype.getDataForFilterBySlug = function(filterSlug) {
    return _.find(this._filterDataIndex, function(filterData) {
      return filterData.slug == filterSlug;
    });
  };

  /**
   * Returns only the filters that match up with the current browse mode.
   * @param {String} browseMode
   * @returns {{}}
   */
  FilterModel.prototype.getFiltersForCurrentBrowseMode = function() {
    return this._allFilters[this._browseMode];
  };

  /**
   * Returns the data for rendering the display font for a tag.
   * @param tag
   * @returns {{cssName: string, fvd, isDynamic: boolean, opaqueId, unicodeRange, featureSettings, kanaOnly: boolean}}
   */
  FilterModel.prototype.getFontDisplayData = function(tag) {
    if (!tag.font) {
      return;
    }

    var lang = tag.font.default_language || 'en';
    var unicodeRange = this._textSamples[lang].unicode_range;
    var featureSettings = this._textSamples[lang].feature_settings;
    var kanaOnly = this._textSamples[lang].kana_only || false;

    return {
      cssName: tag.font.family.web_id + '-' + tag.font.font.web.fvd,
      fvd: tag.font.font.web.fvd,
      isDynamic: false,
      opaqueId: tag.font.family.web_id,
      unicodeRange: unicodeRange,
      featureSettings: featureSettings,
      kanaOnly: kanaOnly
    };
  };

  /**
   * Returns the value of the hide images filter
   * @return {Boolean}
   */
  FilterModel.prototype.getHideImages = function() {
    return this._hideImages;
  };

  /**
   * Returns the languages that can be used to filter fonts.
   */
  FilterModel.prototype.getLanguages = function() {
    return this._languages;
  };

  /**
   * Returns the minimum number of styles to use when filtering fonts.
   */
  FilterModel.prototype.getMinStyles = function() {
    return this._minStyles;
  };

  /**
   * Returns the maximum number of styles to use when filtering fonts.
   */
  FilterModel.prototype.getMaxStyles = function() {
    return this._maxStyles;
  };

  /**
   * Returns the icon url that matches up with a specific filter slug.
   *
   * @param {String} filterSlug
   * @returns {String}
   */
  FilterModel.prototype.getIconUrlForFilter = function(filterSlug) {
    var data = this.getDataForFilterBySlug(filterSlug);
    if (data) {
      return data.icon_url;
    }
  };

  /**
   * Returns the current locale selected.
   *
   * @returns {String}
   */
  FilterModel.prototype.getLocale = function() {
    return this._locale;
  };

  /**
   * Returns the current list of selected filters.
   * @returns {String[]}
   */
  FilterModel.prototype.getSelectedFilters = function() {
    return this._selectedFilters;
  };

  /**
   * Returns the selected filters for the specific section.
   * @param {Object} filterSection
   * @return {String[]}
   */
  FilterModel.prototype.getSelectedFiltersForSection = function(filterSection) {
    var self = this;
    return _.filter(self.getSelectedFilters(), function(filter) {
      var filterData = self.getDataForFilter(filter);
      return filterData.name == filterSection.name;
    });
  };

  /**
   * Returns the current list of selected languages.
   * @return {String[]}
   */
  FilterModel.prototype.getSelectedLanguages = function() {
    return this._selectedLanguages;
  };

  /**
   * Returns the currently selected tag.
   * @return {String}
   */
  FilterModel.prototype.getSelectedTag = function() {
    return this._selectedTag;
  };

  /**
   * Returns the currently selected font technology.
   * @return {String}
   */
  FilterModel.prototype.getSelectedFontTechnology = function() {
    return this._selectedFontTechnology;
  };

  /**
   * Returns the currently selected Your Fonts filter.
   * @return {String}
   */
  FilterModel.prototype.getSelectedYourFontsFilter = function() {
    return this._selectedYourFontsFilter;
  };

  /**
   * Returns the value of the show library section filters
   * @return {Boolean}
   */
  FilterModel.prototype.getShowLibraryOption = function() {
    return this._collection === 'trial' && !this._hideLibraryOption;
  };

  /**
   * Returns the value of the show basic library filter
   * @return {Boolean}
   */
  FilterModel.prototype.getShowBasicLibrary = function() {
    return this._showBasicLibrary;
  };

  /**
   * Returns the value of the show paid fonts flag.
   * @return {Boolean}
   */
  FilterModel.prototype.getShowPaidFonts = function() {
    return this._showPaidFonts;
  };

  /**
   * Returns the current sort order.
   * @returns {String}
   */
  FilterModel.prototype.getSortOrder = function() {
    return this._sortOrder;
  };

  /**
   * Returns an individual tag by its key.
   * @return {Object}
   */
  FilterModel.prototype.getTagByKey = function(key) {
    return _.find(this.getTags(), function(tag) {
      return tag.key === key;
    });
  };

  /**
   * Returns the list of tags to be used in tag filters.
   * @returns {String}
   */
  FilterModel.prototype.getTags = function() {
    return this._tags;
  };

  /**
   * Are there any filters selected?
   * @returns {Boolean}
   */
  FilterModel.prototype.isAnyFilterSelected = function() {
    return this._selectedFilters.length > 0;
  };

  /**
   * Is any filter selected for the specific section.
   * @param {Object} filterSection
   * @return {Boolean}
   */
  FilterModel.prototype.isAnyFilterSelectedForSection = function(filterSection) {
    return this.getSelectedFiltersForSection(filterSection).length > 0;
  };

  /**
   * Are there any selected languages?
   * @return {Boolean}
   */
  FilterModel.prototype.isAnyLanguageSelected = function() {
    return this._selectedLanguages.length > 0;
  };

  /**
   * Checks if any tag is selected.
   * @return {Boolean}
   */
  FilterModel.prototype.isAnyTagSelected = function() {
    return this._selectedTag !== null;
  };

  /**
   * Checks if any font technology is selected.
   * @return {Boolean}
   */
  FilterModel.prototype.isAnyFontTechnologySelected = function() {
    return this._selectedFontTechnology !== null;
  };

  /**
   * Checks if any Your Fonts filter is selected.
   * @return {Boolean}
   */
  FilterModel.prototype.isAnyYourFontsFilterSelected = function() {
    return this._selectedYourFontsFilter !== null;
  };

  /**
   * Is the number of fonts in family slider currently set.
   * @return {Boolean}
   */
  FilterModel.prototype.isNumberOfFontsSelected = function() {
    return this._minStyles !== DEFAULT_MIN_STYLES || this._maxStyles !== DEFAULT_MAX_STYLES;
  };

  /**
   * Are any of the flags or filters selected?
   * @return {Boolean}
   */
  FilterModel.prototype.isAnythingSelected = function() {
    return this.isAnyFilterSelected() ||
      this.isAnyLanguageSelected() ||
      this.isAnyTagSelected() ||
      this.isAnyFontTechnologySelected() ||
      this.isAnyYourFontsFilterSelected() ||
      this.isNumberOfFontsSelected();
  };

  /**
   * Are any of the flags or filters selected?
   * @return {Boolean}
   */
  FilterModel.prototype.isOnlyYourFontsSelected = function() {
    return this.isAnyYourFontsFilterSelected() &&
      !this.isAnyFilterSelected() &&
      !this.isAnyLanguageSelected() &&
      !this.isAnyTagSelected() &&
      !this.isAnyFontTechnologySelected() &&
      !this.isNumberOfFontsSelected();
  };

  /**
   * Do any of the selected filters fall under the name?
   *
   * @param {String} name
   * @returns {Boolean}
   */
  FilterModel.prototype.isAnyFilterSelectedForName = function(name) {
    var selectedFilters = this.getSelectedFilters();
    for (var i = 0, len = selectedFilters.length; i < len; i++) {
      var filterData = this.getDataForFilter(selectedFilters[i]);
      if (filterData && filterData.name == name) {
        return true;
      }
    }

    return false;
  };

  /**
   * Checks if the current browse mode supports browsing by tags.
   * @returns {Boolean}
   */
  FilterModel.prototype.browseModeSupportsTags = function() {
    return this.getBrowseMode() && this.getBrowseMode() === DEFAULT_BROWSE_MODE;
  };

  /**
   * Checks if the default tag module should be visible on UI.
   * Tags module should not be visible:
   * 1. Flipper flags is disabled (controlled by _isTagsModuleVisible on UI).
   * 2. There are not tags to display.
   * 3. Browse mode is japanese.
   * 4. Locale is ja_JP.
   *
   * @return {Boolean}
   */
  FilterModel.prototype.isTagsModuleVisible = function() {
    return this._isTagsModuleVisible && this.getTags() && this.getTags().length > 0
      && this.browseModeSupportsTags();
  };

  /**
   * Is the filter in the list of selected filters?
   *
   * @param {String} filterKey
   * @returns {Boolean}
   */
  FilterModel.prototype.isFilterSelected = function(filterKey) {
    return _.contains(this.getSelectedFilters(), filterKey);
  };

  /**
   * Is the language in the list of selected languages?
   *
   * @param {String} languageKey
   * @return {Boolean}
   */
  FilterModel.prototype.isLanguageSelected = function(languageKey) {
    return _.contains(this.getSelectedLanguages(), languageKey);
  };

  /**
   * Returns true if families matching the filters are currently being loaded, and false if not
   * @return {Boolean}
   */
  FilterModel.prototype.isLoading = function() {
    return this._isLoading;
  };

  /**
   * Checks if the given tag is the selected tag.
   *
   * @param {String} languageKey
   * @return {Boolean}
   */
  FilterModel.prototype.isTagSelected = function(tagKey) {
    return tagKey === this._selectedTag;
  };

  /**
   * Sets the selected filters and verifies that non-multiselect filters are only
   * selected once.
   * @param {String[]} filters
   */
  FilterModel.prototype.setSelectedFilters = function(filters) {
    var self = this;
    _.each(filters, function(filterKey) {
      self.selectFilter(filterKey);
    });
  };

  /**
   * Adds the filter to the list of selected filters. If the filter is not
   * multiselect, then any other filters in the same group will be cleared.
   */
  FilterModel.prototype.selectFilter = function(filterKey) {
    var self = this;
    var filterData = self.getDataForFilter(filterKey);
    if (!filterData) {
      return;
    }

    // If this type of filter allows multiple selections, just add it to the list.
    if (filterData.allowMultiple) {
      self._selectedFilters.push(filterKey);

      // Otherwise, find any existing filters of the same type and remove them before adding
      // the filter to the list.
    } else {
      self._selectedFilters = _.reject(self._selectedFilters, function(existingFilter) {
        var existingFilterData = self.getDataForFilter(existingFilter);
        return existingFilterData.name == filterData.name;
      });

      self._selectedFilters.push(filterKey);
    }

    this.setCurrentPage(DEFAULT_PAGE);
  };

  /**
   * Sets the current browwe mode.
   * @param {String} browseMode
   */
  FilterModel.prototype.setBrowseMode = function(browseMode) {
    this._browseMode = browseMode;
    this.clearAll();
  };

  /**
   * Adds the language to the list of selected languages.
   * @param {String} languageKey
   */
  FilterModel.prototype.selectLanguage = function(languageKey) {
    this._selectedLanguages.push(languageKey);
    this.setCurrentPage(DEFAULT_PAGE);
  };

  /**
   * Selects a language filter and sets the corresponding browse mode in case there is a specific set of filters
   * that apply to the selected language.
   *
   * @param {String} languageKey
   */
  FilterModel.prototype.selectLanguageMode = function(languageKey) {
    var browseMode;
    switch (languageKey) {
    case 'ja':
      browseMode = 'japanese';
      break;

    case 'zh-Hant':
    case 'zh-Hans':
    case 'ko':
      browseMode = languageKey;
      break;

    default:
      browseMode = 'default';
    }

    if (browseMode !== this.getBrowseMode()) {
      this.setBrowseMode(browseMode);
      UserPrefsService.updatePrefs({ browseMode: browseMode });
    }

    if (languageKey !== 'all') {
      this.selectLanguage(languageKey);
    }

    this.setCurrentPage(DEFAULT_PAGE);
  };

  /**
   * Selects the given tag.
   * @param {String} tagKey
   */
  FilterModel.prototype.selectTag = function(tagKey) {
    var self = this;
    self._selectedTag = tagKey;
    self.setCurrentPage(DEFAULT_PAGE);
  };

  /**
   * Selects the given font technology.
   * @param {String} fontTechnology
   */
  FilterModel.prototype.selectFontTechnology = function(fontTechnology) {
    var self = this;
    self._selectedFontTechnology = fontTechnology;
    self.setCurrentPage(DEFAULT_PAGE);
  };

  /**
   * Selects the given Your Fonts filter
   * @param {String} key
   */
  FilterModel.prototype.selectYourFontsFilter = function(key) {
    this._selectedYourFontsFilter = key;
    this.setCurrentPage(DEFAULT_PAGE);
  };

  /**
   * Sets the current page number.
   * @param {Number} currentPage
   */
  FilterModel.prototype.setCurrentPage = function(currentPage) {
    this._currentPage = Math.max(DEFAULT_PAGE, currentPage);
  };

  /**
   * Sets the show images value
   * @param {Boolean} state
   * @param {Boolean} shouldUpdateUserPrefs (optional)
   */
  FilterModel.prototype.setHideImages = function(state, shouldUpdateUserPrefs) {
    this._hideImages = state;

    if (shouldUpdateUserPrefs) {
      UserPrefsService.updatePrefs({ hideImages: state });
    }
  };

  /**
   * Sets a boolean indicating whether families matching the filters are currently being loaded
   * @param {Boolean} isLoading
   */
  FilterModel.prototype.setLoading = function(isLoading) {
    this._isLoading = isLoading;
  };

  /**
   * Sets a font technology filter to the selected state.
   * @param {String} fontTechnologyKey
   */
  FilterModel.prototype.setSelectedFontTechnology = function(fontTechnologyKey) {
    if (this._selectedFontTechnology === fontTechnologyKey) {
      this.clearSelectedFontTechnology();
    } else {
      this.clearSelectedFontTechnology();
      this.selectFontTechnology(fontTechnologyKey);
    }
  };

  /**
   * Sets a single language to the selected state.
   * @param {String} languageKey
   */
  FilterModel.prototype.setSelectedLanguage = function(languageKey) {
    this.clearSelectedLanguages();
    this.selectLanguage(languageKey);
  };

  /**
   * Sets a selected language and a corresponding browse mode.
   * @param {String} languageKey
   */
  FilterModel.prototype.setSelectedLanguageMode = function(languageKey) {
    this.clearSelectedLanguages();
    this.selectLanguageMode(languageKey);
  };

  /**
   * Sets a Your Fonts filter to the selected state.
   * @param {String} key
   */
  FilterModel.prototype.setSelectedYourFontsFilter = function(key) {
    if (this.getSelectedYourFontsFilter() === key) {
      this.clearSelectedYourFontsFilter();
    } else {
      this.selectYourFontsFilter(key);
    }
  };

  /**
   * Sets the show paid fonts value by replacing the user's plan collection with the full library or vice versa.
   * @param {Boolean} state
   * @param {Boolean} shouldUpdateUserPrefs (optional)
   */
  FilterModel.prototype.setShowPaidFonts = function(state, shouldUpdateUserPrefs) {
    this._showPaidFonts = state;

    if (shouldUpdateUserPrefs) {
      UserPrefsService.updatePrefs({ showPaidFonts: state });
    }

    this.setCurrentPage(DEFAULT_PAGE);
  };

  /**
   * Sets the minimum number of styles in the family filter.
   * @param {Number} minStyles
   */
  FilterModel.prototype.setMinStyles = function(minStyles) {
    this._minStyles = minStyles || DEFAULT_MIN_STYLES;
    this.setCurrentPage(DEFAULT_PAGE);
  };

  /**
   * Sets the maximum number of styles in the family filter.
   * @param {Number} maxStyles
   */
  FilterModel.prototype.setMaxStyles = function(maxStyles) {
    this._maxStyles = maxStyles || DEFAULT_MAX_STYLES;
    this.setCurrentPage(DEFAULT_PAGE);
  };

  /**
   * Sets the show paid fonts value by replacing the user's plan collection with the full library or vice versa.
   * @param {Boolean} state
   */
  FilterModel.prototype.setShowBasicLibrary = function(state) {
    this._showBasicLibrary = state;
    this.setCurrentPage(DEFAULT_PAGE);
  };

  /**
   * Sets the current sort order.
   * @param {String} sortOrder
   */
  FilterModel.prototype.setSortOrder = function(sortOrder) {
    this._sortOrder = sortOrder;
    this.setCurrentPage(DEFAULT_PAGE);
  };

  /**
   * Toggles the selected state of the filter.
   * @param {String} filterKey
   */
  FilterModel.prototype.toggleFilter = function(filterKey) {
    if (this.isFilterSelected(filterKey)) {
      this.deselectFilter(filterKey);
    } else {
      this.selectFilter(filterKey);
    }
  };

  /**
   * Toggles the selected state of the language.
   * @param {String} languageKey
   */
  FilterModel.prototype.toggleLanguage = function(languageKey) {
    if (this.isLanguageSelected(languageKey)) {
      this.deselectLanguage(languageKey);
    } else {
      this.selectLanguage(languageKey);
    }
  };

  /**
   * Toggles the selected state of the tag.
   * @param {String} tagKey
   */
  FilterModel.prototype.toggleTag = function(tagKey) {
    if (this.isTagSelected(tagKey)) {
      this.clearSelectedTag();
    } else {
      this.selectTag(tagKey);
    }
  };

  /**
   * Returns a set of search params for a url based on the data stored in a
   * filtered family list.
   *
   * @param {{}} familyListData
   * @returns {{
   *   browse_mode: {String|null},
   *   collection: {String|null},
   *   cc: {Boolean|null},
   *   mp: {Boolean|null},
   *   filters: {String|null},
   *   languages: {String|null},
   *   page: {String|null},
   *   purpose: {String|null},
   *   sort: {String|null},
   *   library: {String|null}
   * }}
   */
  FilterModel.prototype.toSearchParams = function() {
    var searchParams = this._getEmptySearchParams();
    searchParams.browse_mode = this._browseMode;

    if (this._showPaidFonts) {
      searchParams.cc = true;
    }

    if (this._minStyles) {
      searchParams.min_styles = this._minStyles;
    }

    if (this._maxStyles) {
      searchParams.max_styles = this._maxStyles;
    }

    if (this._showBasicLibrary) {
      searchParams.library = 'basic';
    }

    if (this.isAnyFilterSelected()) {
      searchParams.filters = this.getSelectedFilters().join(',');
    }

    if (this.isAnyLanguageSelected()) {
      searchParams.languages = this.getSelectedLanguages().join(',');
    }

    if (this.isAnyTagSelected()) {
      searchParams.tag = this.getSelectedTag();
    }

    if (this.isAnyFontTechnologySelected()) {
      searchParams.font_technology = this.getSelectedFontTechnology();
    }

    searchParams.your_fonts = this.getSelectedYourFontsFilter();

    if (this.getCurrentPage() > 1) {
      searchParams.page = this.getCurrentPage();
    }

    if (this._purpose && this._purpose.length > 0) {
      searchParams.purpose = this.purpose.join(',');
    }

    if (this.getSortOrder() != DEFAULT_SORT_ORDER) {
      searchParams.sort = this.getSortOrder();
    }

    if (this.getHideImages()) {
      searchParams.hide_images = true;
    }

    return searchParams;
  };

  /**
   * Returns an object with a null value for each search parameter.
   *
   * When updating the url with the current state of the filtered family list,
   * any null parameters will be cleared from the current url.
   *
   * @returns {{
   *   browse_mode: {null},
   *   collection: {null},
   *   cc: {null},
   *   mp: {null},
   *   filters: {null},
   *   hide_images: {null},
   *   languages: {null},
   *   tag: {null},
   *   page: {null},
   *   purpose: {null},
   *   sort: {null},
   *   min_styles: {null},
   *   max_styles: {null}
   * }}
   */
  FilterModel.prototype._getEmptySearchParams = function() {
    return {
      browse_mode: null,
      collection: null,
      cc: null,
      mp: null,
      filters: null,
      hide_images: null,
      languages: null,
      tag: null,
      page: null,
      purpose: null,
      sort: null,
      min_styles: null,
      max_styles: null,
      font_technology: null
    };
  };

  /**
   * Reformat the tag filter data so it's compatible with the web component
   * based filter sidebar.
   * @returns Object
   */
  FilterModel.prototype.webComponents_getTagDataForFilterSidebar = function() {
    var self = this;
    return this._tags.map(function(tag) {
      // See https://jira.corp.adobe.com/browse/FONTS-5983 for why Korean tags are untranslated.
      const tagData = {
        label: self.getLocale() === 'ko_KR' ? tag.english_label || tag.label : tag.label,
        tag: tag.key
      };

      // If the tag has an associated font, add the font information.
      if (tag.font) {
        tagData.font = {
          style: getStyleFromFVD(tag.font.font.web.fvd),
          weight: getWeightFromFVD(tag.font.font.web.fvd),
          webId: tag.font.family.web_id,
          unicodeRange: self._textSamples[tag.font.default_language || 'en'].unicode_range
        };
      }

      return tagData;
    });
  };

  /**
   * This is a temporary way of providing the translations to the filters
   * sidebar web component until we can figure out which filters need to be
   * translated and which ones should be the same in all languages.
   */
  FilterModel.prototype.webComponents_getI18nForFilters = function() {
    var messages = {};
    for (var browseMode in this._allFilters) {
      messages = _.extend(
        messages,
        this.webComponents_getI18nForFilterList(this._allFilters[browseMode]));
    }

    return messages;
  };

  /**
   * Extract the translations from the list of filters.
   */
  FilterModel.prototype.webComponents_getI18nForFilterList = function(list) {
    var messages = {};
    for (var group in list) {
      var filters = list[group].values ||
        this.__webComponents_getFiltersFromFilterGroup(list[group]);

      for (var i = 0; i < filters.length; i++) {
        var messageKey = 'filter-sidebar.filter-label.' + filters[i].slug;
        messages[messageKey] = filters[i].label;
      }
    }

    return messages;
  };

  /**
   * Extract the translations from a group of filters.
   * @returns {Object[]}
   */
  FilterModel.prototype.__webComponents_getFiltersFromFilterGroup = function(filterGroup) {
    var filters = [];
    filterGroup.filters.forEach(function(group) {
      group.values.forEach(function(filter) {
        filters.push(filter);
      });
    });

    return filters;
  };

  FilterModel.prototype._triggerChangeCallback = function() {
    if (!this._changeCallback) {
      return;
    }

    this._changeCallback();
  };

  return {
    DEFAULT_PAGE: DEFAULT_PAGE,
    getFilterModel: getFilterModel
  };

}

module.exports = FilterDataService;
