var _ = require('underscore');
var angular = require('angular');

// TODO: Import the event name from the module itself.
// We can't do this yet, because the import causes Jest tests to fail.
var IMAGE_TOGGLE_EVENT = 'images-toggle.toggled';
var PAGE_CHANGE_EVENT = 'pagination-change';

var VIEW_TYPE_CLASS = {
  grid: 'filtered-family-list--grid-view',
  list: 'filtered-family-list--wide-view'
};

var JAPANESE_ONLY_CLASS = 'filtered-family-list--japanese-only';
var SCROLL_OFFSET = 500;

const registerSaveToLibraryEventHandlers = require('../../../components/mixins/SaveToLibrary.js').registerEventHandlers;
const YourFontsFilterToggleEvent = require('@adobe-fonts/site/events/YourFontsFilterToggleEvent.js').default;
const refreshCoachmarks = require('../../../util/coachmark_helpers.js').refreshCoachmarks;
const removeCoachmark = require('../../../util/coachmark_helpers.js').removeCoachmark;

/**
 * @ngInject
 */
function FilteredFamilyListController($document, $scope, $element, $window, DataService, FilteredFamilyListStateService, ScrollService, UserPrefsService) {
  var self = this;
  self.$document = $document;
  self.$scope = $scope;
  self.$element = $element;
  self.$window = $window;

  self.DataService = DataService;
  self.FilteredFamilyListStateService = FilteredFamilyListStateService;
  self.ScrollService = ScrollService;
  self.UserPrefsService = UserPrefsService;

  registerSaveToLibraryEventHandlers($element[0]);

  // Debounce _handleDataChange so that multiple calls to it in the same digest
  // loop won't trigger multiple data change events. Only the last call in a
  // digest loop should trigger a data change.
  //
  // NOTE: multiple calls to _handleDataChange in a single digest loop can happen
  // when an event that modifies multiple types of filters occurs.
  //
  // For example: switching browse modes will clear all types of filters, which
  // will trigger multiple change events.
  self.handleDataChange =
    _.debounce(angular.bind(this, this._handleDataChange), 0);

  self.selectedSort = this.filtersModel().getSortOrder();
  self._loadSortModes();

  self.DataService.get('/families/i18n').then(function(data) {
    self.i18n = data;
  });

  self._initWebComponentPagination();
  self._initImagesToggle();
}

FilteredFamilyListController.prototype.familyCount = function() {
  if (!this.getViewValue().familyCountMessage) {
    return;
  }

  var message = this.getViewValue().familyCountMessage['message'];
  var value = this.getViewValue().familyCountMessage;

  return this.i18n['neue.browse.family_counts'][message]
    .replace('%{total_families}', value.total_families)
    .replace('%{more_families_in_cc}', value.more_families_in_cc);
};


/**
 * Clear the filter list.
 */
FilteredFamilyListController.prototype.resetAllFilters = function() {
  this.filtersModel().clearAll();
  this.handleDataChange();
};

/**
 * Clear the selected 'Your Fonts' filter.
 */
FilteredFamilyListController.prototype.resetYourFontsFilter = function() {
  // Resetting on the filtersModel directly won't update the sidebar
  const sidebar = this.$document[0].querySelector('af-filter-sidebar');
  sidebar.dispatchEvent(new YourFontsFilterToggleEvent(null));
};

/**
 * Returns the list of classes that should be displayed on the container
 * element.
 *
 * @returns {[]}
 */
FilteredFamilyListController.prototype.getContainerClasses = function() {
  var classes = [this.getViewTypeClass()];

  if (this.isJapaneseOnlyFamily()) {
    classes.push(JAPANESE_ONLY_CLASS);
  }

  return classes;
};

FilteredFamilyListController.prototype.getFamilyTemplates = function(family) {
  return this.getViewValue().
    familyTemplates[family.display_font.preferred_family_name];
};

/**
 * Return the selected tag from the filters model if families matching the tag have already loaded
 * @returns {Boolean}
 */
FilteredFamilyListController.prototype.getSelectedTag = function() {
  return this.filtersModel().isLoading() ? null : this.filtersModel().getSelectedTag();
};

/**
 * Returns the CSS class will be used for the selected view type.
 * @returns {String}
 */
FilteredFamilyListController.prototype.getViewTypeClass = function() {
  return VIEW_TYPE_CLASS[this.getViewValue().selectedViewType];
};

/**
 * Returns the current value or an empty object if the view value hasn't been
 * set yet.
 * @returns {{}}
 */
FilteredFamilyListController.prototype.getViewValue = function() {
  return (this.ngModel || {}).$viewValue || {};
};

/**
 * Some sort modes are only visible when certain filters are selected.
 * @returns {{}}
 */
FilteredFamilyListController.prototype.getVisibleSortModes = function() {
  const sortModes = this.sortModes.filter((sortMode) => {
    if (!sortMode.filter) {
      return true;
    }
    const [filterName, filterValue] = sortMode.filter.split('=');
    return filterName === 'your_fonts' && this.filtersModel().getSelectedYourFontsFilter() === filterValue;
  });
  if (!sortModes.some((sortMode) => sortMode.value === this.selectedSort)) {
    this.selectedSort = sortModes[0].value;
    this.handleSortChange();
  }
  return sortModes;
};

/**
 * Handles changes to the text preview font size.
 */
FilteredFamilyListController.prototype.handleFontSizeChange = function() {
  this._handleUserPrefsChange();
  this._hideImages();
};

/**
 * Handles Page Number change.
 */
FilteredFamilyListController.prototype.handlePageNumberChange = function() {
  this.handleDataChange();
  this.ScrollService.scrollUpTo(this.$element[0], SCROLL_OFFSET);
};

/**
 * Jump back to page 1
 */
FilteredFamilyListController.prototype.handleChangeToFirstPage = function() {
  this.getViewValue().currentPage = 1;
  this.handlePageNumberChange();
};

/**
 * Handles changes to the pangram menu.
 */
FilteredFamilyListController.prototype.handlePangramMenuChange = function() {
  this._handleUserPrefsChange();
  this._hideImages();
};

/**
 * Handles change events on the sort order menu.
 */
FilteredFamilyListController.prototype.handleSortChange = function() {
  this.filtersModel().setSortOrder(this.selectedSort);
  this.handleDataChange();
};

/**
 * Handles changes to the view type.
 */
FilteredFamilyListController.prototype.handleViewTypeChange = function() {
  this._handleUserPrefsChange();

  requestAnimationFrame(() => {
    removeCoachmark();
    refreshCoachmarks();
  });
};

/**
 * Is the list of families empty?
 * @returns {Boolean}
 */
FilteredFamilyListController.prototype.isEmpty = function() {
  return !this.getViewValue().families || this.getViewValue().families.length < 1;
};

/**
 * Is it the last Browse page and/or is user trying to reach a browse page beyond the MAX_PAGE_COUNT?
 * @returns {Boolean}
 */
FilteredFamilyListController.prototype.isLastPage = function() {
  if (this.getViewValue().totalPages === 1) {
    return false;
  }
  return (this.showReturnToFirstPage() && this.getViewValue().currentPage >= this.getViewValue().totalPages);
};

/**
 * The empty state for favorites will only show when the favorites collection is
 * selected.
 * @returns {Boolean}
 */
FilteredFamilyListController.prototype.isFavoritesEmptyStateVisible = function() {
  return this.isEmpty() && !this.showNoAccessOnEmptyState() && this.getViewValue().collection == 'favorites';
};

/**
 * Returns true if the family only contains japanese fonts
 * @returns {Boolean}
 */
FilteredFamilyListController.prototype.isJapaneseOnlyFamily = function() {
  var defaultLanguages = _.uniq(_.map(this.getViewValue().families, function (family) {
    return family.display_font.default_language;
  }));
  return defaultLanguages.length == 1 && defaultLanguages[0] == 'ja';
};

/**
 * The empty state for when a user does not have access to any families in the current view
 * @returns {Boolean}
 */
FilteredFamilyListController.prototype.isNoAccessEmptyStateVisible = function() {
  return this.isEmpty() && this.showNoAccessOnEmptyState();
};

/**
 * The standard empty state will show for everything except favorites.
 * @returns {Boolean}
 */
FilteredFamilyListController.prototype.isStandardEmptyStateVisible = function() {
  return this.isEmpty() && !(
    this.isFavoritesEmptyStateVisible() ||
    this.isYourFontsEmptyStateVisible('added') ||
    this.isYourFontsEmptyStateVisible('favorite') ||
    this.isNoAccessEmptyStateVisible()
  );
};

/**
 * Each "Your Fonts" filterType has its own empty state that is shown when it is the only filter selected.
 * @param {String} filterType
 * @returns {Boolean}
 */
FilteredFamilyListController.prototype.isYourFontsEmptyStateVisible = function(filterType) {
  // If we don't check mostRecentEmptyState, the standard empty state will flicker when the filter has been de-selected
  // and families haven't finished loading yet.
  if (this.mostRecentEmptyState === filterType && !this.filtersModel().isLoading()) {
    this.mostRecentEmptyState = null;
  }
  if (this.filtersModel().isLoading() && this.mostRecentEmptyState) {
    return this.mostRecentEmptyState === filterType;
  }
  const isVisible = this.isEmpty() &&
    !this.isNoAccessEmptyStateVisible() &&
    this.filtersModel().isOnlyYourFontsSelected() &&
    this.filtersModel().getSelectedYourFontsFilter() === filterType;
  if (isVisible) {
    this.mostRecentEmptyState = filterType;
  }
  return isVisible;
};

/**
 * Update the $viewValue after the data inside the directive has changed.
 *
 * NOTE: the constructor for the controller will debounce this method, so it
 * should never be called directly.
 *
 * @private
 */
FilteredFamilyListController.prototype._handleDataChange = function() {
  // When the $viewValue is an object, Angular won't check the object's
  // properties for changes, so make a copy of latest data so Angular sees it as
  // a new object.
  this.ngModel.$setViewValue(angular.copy(this.getViewValue()));
};

/**
 * Handles changes to data that should be stored on the backend as user preferences.
 *
 * Any changes to data that is considered part of the "user pref" data should be triggered
 * separately from the other filtered family list data, because the user pref data isn't
 * used to determine which families are loaded.
 *
 * @private
 */
FilteredFamilyListController.prototype._handleUserPrefsChange = function() {
  if (this.onUserPrefsChange()) {
    this.$scope.$eval(this.onUserPrefsChange());
  }
};

FilteredFamilyListController.prototype._hideImages = function() {
  this.filtersModel().setHideImages(true);
  this.showCarousel = 'false';
};

/**
 * Initialize event handling for the images toggle web component
 */
FilteredFamilyListController.prototype._initImagesToggle = function() {
  var self = this;
  self.UserPrefsService.getUserPrefs().then(function(prefs) {
    self.filtersModel().setHideImages(self.filtersModel().getHideImages() || prefs.hideImages);
    self.showCarousel = (!self.filtersModel().getHideImages()).toString();
  });
  self.$element.on(IMAGE_TOGGLE_EVENT, function(event) {
    self.showCarousel = (event.detail.isChecked || false).toString();
    self.filtersModel().setHideImages(self.showCarousel === 'false', true);
    self.FilteredFamilyListStateService.updateUrl(self.filtersModel().toSearchParams());
  });
};

/**
 * Initialize event handling for the new pagination web component
 * (This won't have any effect if the new web component isn't enabled on the
 * page yet).
 */
FilteredFamilyListController.prototype._initWebComponentPagination = function() {
  var self = this;
  self.$element.on(PAGE_CHANGE_EVENT, function(event) {
    self.getViewValue().currentPage = event.detail.page;
    self.handleDataChange();
    self.ScrollService.scrollUpTo(self.$element[0], SCROLL_OFFSET);
  });
};

/**
 * Load the list of sort modes for the family list.
 * @private
 */
FilteredFamilyListController.prototype._loadSortModes = function() {
  var self = this;
  self.sortModes = self.preloadedSortModes();
  if (self.sortModes) {
    return;
  }
  self.DataService.get('filtered-family-list-sort-modes').then(function(sortModes) {
    self.sortModes = sortModes;
  });
};

module.exports = FilteredFamilyListController;
