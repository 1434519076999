import { setLocale } from '@adobe-fonts/site/lib/I18N.js';
import { localizeSharedMessages } from './shared.i18n.js';

export async function switchLocale(locale) {
  const bookmarkButtonMessages = await import(
    /* webpackChunkName: "i18n-[request]" */
    `@adobe-fonts/site/components/bookmark-button/i18n/${locale}.json`);

  const carouselMessages = await import(
    /* webpackChunkName: "i18n-[request]" */
    `@adobe-fonts/site/components/carousel/i18n/${locale}.json`);

  const ccxMessages = await import(
    /* webpackChunkName: "i18n-[request]" */
    `@adobe-fonts/site/components/ccx/i18n/${locale}.json`);

  const ccxOpenButtonMessages = await import(
    /* webpackChunkName: "i18n-[request]" */
    `@adobe-fonts/site/components/ccx/open-in-ccx-button/i18n/${locale}.json`);

  const ccxSpecimenCardMessages = await import(
    /* webpackChunkName: "i18n-[request]" */
    `@adobe-fonts/site/components/ccx/ccx-specimen-card/i18n/${locale}.json`);

  const familyCardMessages = await import(
    /* webpackChunkName: "i18n-[request]" */
    `@adobe-fonts/site/components/family-card/i18n/${locale}.json`);

  const fontpackCardMessages = await import(
    /* webpackChunkName: "i18n-[request]" */
    `@adobe-fonts/site/components/fontpack-card/i18n/${locale}.json`);

  const imagesToggleMessages = await import(
    /* webpackChunkName: "i18n-[request]" */
    `@adobe-fonts/site/components/images-toggle/i18n/${locale}.json`);

  const openInCCXButtonMessages = await import(
    /* webpackChunkName: "i18n-[request]" */
    `@adobe-fonts/site/components/ccx/open-in-ccx-button/i18n/${locale}.json`);

  const paginationMessages = await import(
    /* webpackChunkName: "i18n-[request]" */
    `@adobe-fonts/site/components/pagination/i18n/${locale}.json`);

  const sidebarMessages = await import(
    /* webpackChunkName: "i18n-[request]" */
    `@adobe-fonts/site/components/filter-sidebar/i18n/${locale}.json`);

  const specimenCardMessages = await import(
    /* webpackChunkName: "i18n-[request]" */
    `@adobe-fonts/site/components/specimen-card/i18n/${locale}.json`);

  const tagCardMessages = await import(
    /* webpackChunkName: "i18n-[request]" */
    `@adobe-fonts/site/components/tag-card/i18n/${locale}.json`);

  const variationCardMessages = await import(
    /* webpackChunkName: "i18n-[request]" */
    `@adobe-fonts/site/components/variation-card/i18n/${locale}.json`);

  const variableFontSampleCardMessages = await import(
    /* webpackChunkName: "i18n-[request]" */
    `@adobe-fonts/site/components/variable-font-sample-card/i18n/${locale}.json`);

  // These are all variable font editor related messages.
  //
  // These messages should eventually be delayed loaded when the editor itself loads,
  // since the editor itself is delayed loaded but we need to rework how the locale
  // and messages are set before we can do that.
  const axisValueFieldMessages = await import(
    /* webpackChunkName: "i18n-[request]" */
    `@adobe-fonts/site/components/variable-font-editor/axis-value-field/i18n/${locale}.json`);

  const fontPropertyPanelMessages = await import(
    /* webpackChunkName: "i18n-[request]" */
    `@adobe-fonts/site/components/variable-font-editor/font-property-panel/i18n/${locale}.json`);

  const variableFontContentsMessages = await import(
    /* webpackChunkName: "i18n-[request]" */
    `@adobe-fonts/site/components/variable-font-contents/i18n/${locale}.json`);

  const variableFontEditorMessages = await import(
    /* webpackChunkName: "i18n-[request]" */
    `@adobe-fonts/site/components/variable-font-editor/variable-font-editor/i18n/${locale}.json`);

  const webProjectEditorStyleSelectorMessages = await import(
    /* webpackChunkName: "i18n-[request]" */
    `@adobe-fonts/site/components/web-project-editor/web-project-editor-style-selector/i18n/${locale}.json`);

  const webProjectEditorVariableStyleSelectorMessages = await import(
    /* webpackChunkName: "i18n-[request]" */
    `@adobe-fonts/site/components/web-project-editor/web-project-editor-variable-style-selector/i18n/${locale}.json`);

  const sharedMessages = await localizeSharedMessages(locale);

  setLocale(locale, {
    ...sharedMessages,
    ...bookmarkButtonMessages.default,
    ...carouselMessages.default,
    ...ccxMessages.default,
    ...ccxOpenButtonMessages.default,
    ...ccxSpecimenCardMessages.default,
    ...familyCardMessages.default,
    ...fontpackCardMessages.default,
    ...imagesToggleMessages.default,
    ...openInCCXButtonMessages.default,
    ...paginationMessages.default,
    ...sidebarMessages.default,
    ...specimenCardMessages.default,
    ...tagCardMessages.default,
    ...variableFontSampleCardMessages.default,
    ...variationCardMessages.default,
    ...webProjectEditorStyleSelectorMessages.default,
    ...webProjectEditorVariableStyleSelectorMessages.default,

    // Keep the variable font editor messages separate
    // (see comment where these messages are imported).
    ...axisValueFieldMessages.default,
    ...fontPropertyPanelMessages.default,
    ...variableFontContentsMessages.default,
    ...variableFontEditorMessages.default
  });
}
