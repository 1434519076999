var angular = require('angular');
var VIDEO_PLAYING_CLASS = 'video-playing';

var VIDEO_IDS = {
  adobe: {
    en_US: 'YGUe-EQWxF4',
    de_DE: 'H1HqLVNagO8',
    fr_FR: 'JpacO3Ol1nE',
    ja_JP: 'tv_IucBF_5E'
  },
  morisawa: {
    de_DE: 'o2B449k5kC4',
    en_US: 'o2B449k5kC4',
    fr_FR: 'o2B449k5kC4',
    ja_JP: 'mZsFgZH8yp4'
  }
};

/**
 * @ngInject
 */
function FoundryController($document,
                           $q,
                           $window,
                           DataService,
                           FilterDataService,
                           FilteredFamilyListStateService,
                           FoundriesService,
                           FreezeframeService,
                           NewrelicClickTrackerService,
                           UserPrefsService,
                           YouTubeService) {
  var self = this;
  self.$q = $q;
  self.$window = $window;

  self.DataService = DataService;
  self.FilterDataService = FilterDataService;
  self.FilteredFamilyListStateService = FilteredFamilyListStateService;
  self.FreezeframeService = FreezeframeService;
  self.FoundriesService = FoundriesService;
  self.UserPrefsService = UserPrefsService;
  self.YouTubeService = YouTubeService;
  self._bodyElement = angular.element($document.find('body'));

  NewrelicClickTrackerService.init({ page: 'foundry detail' });

  self._preloadData().then(function() {
    self._initVideo();
  });
}

/**
 * Handles changes to data that should be stored on the backend as user preferences.
 */
FoundryController.prototype.handleUserPrefsChange = function() {
  this.UserPrefsService.updatePrefs(this.filteredFamilyList);
};

/**
 * Updates the list of families using the current properties set on the filtered
 * family list.
 * @private
 */
FoundryController.prototype.updateFilteredFamilyList = function() {
  var self = this;
  self.filtersModel.setCurrentPage(self.filteredFamilyList.currentPage);
  self.FoundriesService.getFamilies(self.foundrySlug, self.filtersModel.toSearchParams()).then(function(response) {
    self.filteredFamilyList.families = response.data.families_data.families;
    self.filteredFamilyList.totalFamilies = response.data.families_data.totalFamilies;
    self.filteredFamilyList.totalPages = response.data.families_data.totalPages;
    self.FreezeframeService.update();
  });

  self.FilteredFamilyListStateService.updateState(self.filteredFamilyList);
};

/**
 * Preload data using DataService.
 *
 * @private
 * @returns {Promise}
 */
FoundryController.prototype._preloadData = function() {
  var self = this;

  return self.$q.all([
    self.UserPrefsService.getUserPrefs().then(function(userPrefs) {
      self.DataService.get('/neue/preloaded_foundry_data').then(function(data) {
        self.filteredFamilyList = self.FilteredFamilyListStateService.getState(null, userPrefs);
        self.filteredFamilyList.families = data.familiesData.families;
        self.filteredFamilyList.totalFamilies = data.familiesData.totalFamilies;
        self.filteredFamilyList.totalPages = data.familiesData.totalPages;

        self.foundrySlug = data.foundrySlug;
        self.locale = data.locale;
        self.textSampleData = data.textSampleData;

        self.filtersModel = self.FilterDataService.getFilterModel();

      });
    })
  ]);
};

/**
 * Handler for the main video close button.
 *
 * @listens Event
 */
FoundryController.prototype.handleVideoCloseButtonClick = function() {
  this.player.pauseVideo();
  this._bodyElement.removeClass(VIDEO_PLAYING_CLASS);
};

/**
 * Handler for the video player's state change event.
 *
 * @private
 * @listens YT.Player.Event
 */
FoundryController.prototype._handlePlayerStateChange = function(event) {
  if (event.data === 0) {
    this._bodyElement.removeClass(VIDEO_PLAYING_CLASS);
  }
};

/**
 * Initializes the main video for the page.
 * @private
 */
FoundryController.prototype._initVideo = function() {
  var self = this;

  // If no video ids exist for a foundry, don't bother initializing Youtube Service and early return.
  if (!VIDEO_IDS[self.foundrySlug]) {
    return;
  }
  // Load a locale specific video
  var videoId = VIDEO_IDS[self.foundrySlug][self.locale] || VIDEO_IDS[self.foundrySlug].en_US;
  self.YouTubeService.init().then(function() {
    self.YouTubeService.load('player', videoId, {
      height: '100%',
      width: '100%',
      onPlayerStateChangeCallback: function(event) {
        self._handlePlayerStateChange(event);
      }
    }).then(function(player) {
      self.player = player;
    });
  });
};

module.exports = FoundryController;
