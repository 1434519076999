/**
 * A directive for showing the warning for a web project having more than 8 variations.
 *
 * To implement this directive, follow this example:
 *
 * In your template:
 *
 * <large-web-project-notice>
 * </large-web-project-notice>
 *
 * In your Javascript Controller:
 *
 * @ngInject
 */
function Directive() {
  return {
    restrict: 'E',
    templateUrl: '/angular_templates/directives/large_web_project_notice.html',
    bindToController: {
    },
    controller: require('./controller.js'),
    controllerAs: 'largeWebProjectNoticeCtrl'
  };
}

module.exports = Directive;
