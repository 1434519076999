/**
 * A directive that can be used to render a font variation.
 * @example
 *
 *   <font-variation-card variation="variation"
 *                        example-text="family.exampleText"
 *                        ng-repeat="variation in family.fontVariations">
 *   </font-variation-card>
 *
 * @ngInject
 */
function Directive() {
  return {
    scope: {},
    bindToController: {
      exampleText: '&',
      isEnterpriseUser: '&',
      linkToFamily: '&?',
      onActivate: '&',
      onCodeButtonClick: '&',
      onDeactivate: '&',
      onDetailsClick: '&?',
      onSimilarFontsAndPairingsClick: '&',
      isSimilarCard: '@',
      textSamples: '&',
      userLoggedIn: '&',
      showRequiredAction: '=',
      variation: '&',
      originalVariation: '&',
      selectedFeedback: '=',
      hideFavoriteButton: '=',
      hideSaveLibraryMenu: '=',
      linkToSimilarVariation: '=?'
    },
    controller: require('./controller.js'),
    controllerAs: 'fontVariation',
    restrict: 'E',
    replace: true,
    templateUrl: '/angular_templates/directives/font_variation_card.html'
  };
}

module.exports = Directive;
