/**
 * A directive for font family display.
 *
 * To implement this directive, follow this example:
 *
 * In your template:
 *
 * <tk-web-project-display-family
 *    tk-web-project-display-family-model="controller.tkWebProjectDisplayFamilyModel">
 * </tk-web-project-display-family>
 *
 * In your Javascript Controller:
 *
 * - tkWebProjectDisplayFamilyModel: The model which the details of font variation to be displayed and family name.
 *
 * @ngInject
 */
function Directive() {
  return {
    restrict: 'E',
    templateUrl: '/angular_templates/directives/tk_web_project_display_family.html',
    scope: {},
    bindToController: {
      tkFontFamily: '&',
      tkWebProjectDisplayFamilyModel: '='
    },
    controller: require('./controller.js'),
    controllerAs: 'tkWebProjectDisplayFamilyCtrl'
  };
}

module.exports = Directive;
