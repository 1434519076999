/**
 * A directive that can be used to render an entry field with an autocomplete functionality.
 *
 * @example
 *  <adobe-fonts-auto-complete></adobe-fonts-auto-complete>
 *
 *  Attributes :
 *    autocomplete-service: Service object that implements functions to fetch suggestions.
 *                          Must implement get and getItemHyperlink functions.
 *    input-placeholder: Placeholder text for input field, that triggers auto complete list to pop up.
 *    result-div-classes: CSS classes to customize div listing auto complete items. Optional
 *    result-title: Caption for filtered results
 *    result-see-all-label: 'See All' link. Optional
 *
 *  Any child elements of this directive are displayed below the div that lists auto complete suggestions.
 *
 * @ngInject
 */
function Directive() {
  return {
    scope: {},
    bindToController: {
      autocompleteService: '=',
      inputPlaceholder: '@',
      resultDivClasses: '@',
      resultTitle: '@',
      resultSeeAllLabel: '@'
    },
    controller: require('./controller.js'),
    controllerAs: 'adobeFontsAutoCompleteCtrl',
    restrict: 'E',
    transclude: true,
    templateUrl: '/angular_templates/directives/adobe_fonts_auto_complete.html',
    link: function(scope, element, attrs, controller) {
      controller.element = element[0];
    }
  };
}

module.exports = Directive;
