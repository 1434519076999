/**
 * @ngInject
 */
function AdobeFontsFontpackCardController($q, DataService) {
  var self = this;
  self.$q = $q;
  self.DataService = DataService;

  self._preloadData();
}


/**
 * Return font pack card image style, adding a default when needed
 *
 * @param {Object} fontpackItem
 * @returns {Object}
 */
AdobeFontsFontpackCardController.prototype.fontpackCardImageStyle = function(fontpackItem) {
  var path = fontpackItem.thumbnail_url || '/thumbnails/original/missing.png';
  return {
    'background-image': 'url("' + path + '")'
  };
};

/**
 * Preloads data from script tags on the page.
 *
 * @private
 * @returns {Promise}
 */
AdobeFontsFontpackCardController.prototype._preloadData = function() {
  var self = this;

  return self.$q.all([
    self.DataService.get('/neue/fontpack_items').then(function(data) {
      self.fontpacks = data.fontpacks;
    }),

    self.DataService.get('/fontpack_items/i18n').then(function(data) {
      self.i18n = data;
    })
  ]);
};

module.exports = AdobeFontsFontpackCardController;
