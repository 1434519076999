/**
 * @ngInject
 */
function Directive() {
  return {
    scope: {},
    bindToController: {
      newrelicActionSource: '=',
      fontpackItem: '='
    },
    controller: require('./controller.js'),
    controllerAs: 'fontpackCardCtrl',
    restrict: 'E',
    replace: true,
    transclude: true,
    templateUrl: '/angular_templates/directives/adobe_fonts_fontpack_card.html'
  };
}

module.exports = Directive;
