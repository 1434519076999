/**
 * A directive for showing the kit id and last modified date for the kit as web project details.
 *
 * To implement this directive, follow this example:
 *
 * In your template:
 *
 * <tk-web-project-details
 *    web-project-details="controller.webProjectDetails">
 * </tk-web-project-details>
 *
 * In your Javascript Controller:
 *
 * - webProjectDetails: kit token and last modified date for the web project(kit).
 *
 * @ngInject
 */
function Directive() {
  return {
    restrict: 'E',
    templateUrl: '/angular_templates/directives/tk_web_project_details.html',
    bindToController: {
      webProjectDetails: '&'
    },
    controller: require('./controller.js'),
    controllerAs: 'tkWebProjectDetailsCtrl'
  };
}

module.exports = Directive;
