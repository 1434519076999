/**
 * Controller for the tk-web-project-display-family directive.
 * @ngInject
 */
function TkWebProjectDisplayFamilyController($element) {
  if (!this.tkFontFamily() || !this.tkFontFamily().is_variable) {
    return;
  }

  requestAnimationFrame(() => {
    const fontSampleCardContainer = $element[0].
      querySelector('.tk-web-project-display-family__variable-component');

    if (!fontSampleCardContainer || !this.tkFontFamily().fvd_sorted_variations) {
      return;
    }

    this.tkFontFamily().fvd_sorted_variations.forEach(variation => {
      const variableComponent = document.createElement('af-variable-font-sample-card');

      variableComponent.fontData = variation;
      const displayData = this.getVariationDisplayData();
      variableComponent.sampleString = displayData.textSample;
      variableComponent.fontId = displayData.opaqueId;
      variableComponent.unicodeRange = displayData.unicodeRange;
      variableComponent.featureSettings = displayData.featureSettings;
      variableComponent.fvd = displayData.fvd;

      return fontSampleCardContainer.appendChild(variableComponent);
    });
  });
}

TkWebProjectDisplayFamilyController.prototype.getVariationDisplayData = function() {
  return this.tkWebProjectDisplayFamilyModel.getVariationDisplayData();
};

TkWebProjectDisplayFamilyController.prototype.getVariationFamilyName = function() {
  return this.tkWebProjectDisplayFamilyModel.getVariationFamilyName();
};

TkWebProjectDisplayFamilyController.prototype.getTypographicFamilySlug = function() {
  return this.tkWebProjectDisplayFamilyModel.getTypographicFamilySlug();
};

module.exports = TkWebProjectDisplayFamilyController;
