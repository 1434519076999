/**
 * A directive that can be used as a scroll to the bottom event handler
 *
 * @example
 *  <ul scroll-event function-on-scroll="selectorCtrl.onScroll()">
 *  </ul>
 *
 * function-on-scroll will take a function as the input which will act as the scroll bottom event-handler.
 * @ngInject
 */
function Directive($timeout) {
  var TIMEOUT_DELAY_MS = 100;
  return {
    scope: {
      onScrolledToEnd: '&'
    },
    link: function (scope, element) {
      // allowScrollingEvents will prevent multiple calls to the event handler - onScrolledToEnd, when the user is scrolling.
      var allowScrollingEvents = true;
      element.on('wheel', function() {
        // User should have exhausted the scroll space and there should not be an even handler already running.
        if (element[0].scrollTop + element[0].offsetHeight == element[0].scrollHeight && allowScrollingEvents) {
          allowScrollingEvents = false;
          $timeout(function() {
            scope.onScrolledToEnd();
            allowScrollingEvents = true;
          }, TIMEOUT_DELAY_MS);
        }
      });
    }
  };
}

module.exports = Directive;
