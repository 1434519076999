/**
 * @ngInject
 */
function FontFamilyAccordion() {
  return {
    scope: {},
    bindToController: {
      families: '=',
      i18n: '&',
      textSampleData: '&',
      isActiveFontsView: '&',
      loadMoreFamilies: '&'
    },
    controller: require('./controller.js'),
    controllerAs: 'fontFamilyAccordionCtrl',
    restrict: 'E',
    replace: true,
    templateUrl: '/angular_templates/directives/font_family_accordion.html'
  };
}

module.exports = FontFamilyAccordion;
