var TK_WEB_PROJECT_FAMILIES_SELECTOR = '.tk-web-project-card__families';
var TK_WEB_PROJECT_FOOTER_SELECTOR = '.tk-web-project-card__footer';
var UNSTUCK_TO_BOTTOM_FOOTER_CLASS = 'tk-web-project-card__footer--unstuck';
var STUCK_TO_BOTTOM_FOOTER_CLASS = 'tk-web-project-card__footer--stuck';

/**
 * Controller for the tk-web-project-card directive.
 * @ngInject
 */
function TkWebProjectCardController($element, $window, DialogService, TkWebProjectEditorService, NotificationService, DataService) {
  var self = this;
  self.$element = $element;
  self.$window = $window;

  self.tkWebProjectEditorService = TkWebProjectEditorService;
  self.dialogService = DialogService;
  self.DataService = DataService;
  self.NotificationService = NotificationService;

  self.tkffList = self.tkWebProjectCardModel.getTkffList();
  self.projectInfo = {
    id: this.tkWebProjectCardModel.getProjectId(),
    isDynamic: this.tkWebProjectCardModel.isDynamic()
  };

  self.DataService.get('/neue/i18n').then(function(i18n) {
    self.i18n = i18n;
  });

  self.$window.addEventListener('scroll', function() {
    self._handleWindowScroll();
  });

  self.$window.addEventListener('resize', function() {
    self._adjustFooterWidth();
  });

  if (self.tkWebProjectCardModel.isExpanded()) {
    // First collapse the view, get the complete data and then expand the view to avoid showing angular variables in the view
    self.tkWebProjectCardModel.toggleExpanded();
    self.tkWebProjectEditorService.populateCompleteTkffData(this.tkWebProjectCardModel.getProjectDetails().kitToken);
  }
  self.busyCursor = false;
}

TkWebProjectCardController.prototype.toggleExpanded = function() {
  if (this.tkWebProjectCardModel.getIsDataFetched() == false) {
    this.toggleBusyCursor();
    this.tkWebProjectEditorService.populateCompleteTkffData(this.tkWebProjectCardModel.getProjectDetails().kitToken, this.toggleBusyCursor);
  } else {
    this.tkWebProjectCardModel.toggleExpanded();
  }
};

TkWebProjectCardController.prototype.toggleBusyCursor = function() {
  this.busyCursor = !this.busyCursor;
};

TkWebProjectCardController.prototype.openDeleteConfirmationDialog = function() {
  this.tkWebProjectEditorService.openDeleteConfirmationDialog(this.tkWebProjectCardModel);
};

TkWebProjectCardController.prototype.handleSaveClicked = function() {
  var self = this;
  var deferredLastUpdatedInfo = self.tkWebProjectEditorService.updateKit(
      self.tkWebProjectCardModel.getTkffList(),
      self.tkWebProjectCardModel.getDeletedTkffs(),
      self.tkWebProjectCardModel.getProjectDetails().kitToken);
  deferredLastUpdatedInfo.then(function(data) {
    self.tkWebProjectCardModel.setLastModified(data.last_updated);
    self.NotificationService.success(self.i18n['kit_editor.kits.publish.update.project_eta_message'].replace('%{web_project_name}', self.tkWebProjectCardModel.getProjectName()));
    self.tkWebProjectCardModel.updateTkffListBackup();
  });
  self.tkWebProjectCardModel.handleSave();
};

TkWebProjectCardController.prototype.handleCancelClicked = function() {
  this.tkWebProjectCardModel.handleCancel();
  this.tkffList = this.tkWebProjectCardModel.getTkffList();
  var i;
  for (i = 0; i < this.tkffList.length; i++) {
    var id = this.tkWebProjectCardModel.getProjectDetails().kitToken + this.tkffList[i].tkff.typekit_font_family.id;
    this.tkWebProjectEditorService.getTkWebProjectFamilyCardModel(id).setTkffData(this.tkffList[i]);
    this.tkWebProjectEditorService.getTkWebProjectEditorStyleSelectorModel(id).setEnabledFontVariationIds(
        this.tkffList[i].enabled_font_variation_ids);
  }
};

TkWebProjectCardController.prototype.getFamilyCardModel = function(tkffId) {
  var id = this.tkWebProjectCardModel.getProjectDetails().kitToken + tkffId;
  return this.tkWebProjectEditorService.getTkWebProjectFamilyCardModel(id);
};

TkWebProjectCardController.prototype.getFamilyDisplayModel = function(tkffId) {
  var id = this.tkWebProjectCardModel.getProjectDetails().kitToken + tkffId;
  return this.tkWebProjectEditorService.getTkWebProjectDisplayFamilyModel(id);
};

/**
 * Returns the sticky class that should be applied to the footer, depending on
 * the current scroll position of the window. Also, adjusts the width for the
 * footer depending on the families section dimensions.
 *
 * @return {String}
 */
TkWebProjectCardController.prototype.getFooterStickyClass = function() {
  if (!this._isFooterActive()) {
    return UNSTUCK_TO_BOTTOM_FOOTER_CLASS;
  }

  if (this._isFooterOutOfView()) {
    this._adjustFooterWidth();
    return STUCK_TO_BOTTOM_FOOTER_CLASS;
  }

  return UNSTUCK_TO_BOTTOM_FOOTER_CLASS;
};

/**
 * Get the project card's families section element.
 *
 * @private
 * @return {Element}
 */
TkWebProjectCardController.prototype._getFamiliesEl = function() {
  return this.$element[0].querySelector(TK_WEB_PROJECT_FAMILIES_SELECTOR);
};

/**
 * Get the project card's footer section element.
 *
 * @private
 * @return {Element}
 */
TkWebProjectCardController.prototype._getFooterEl = function() {
  return this.$element[0].querySelector(TK_WEB_PROJECT_FOOTER_SELECTOR);
};

/**
 * Adjusts the width for the footer depending on the families section dimensions.
 *
 * @private
 */
TkWebProjectCardController.prototype._adjustFooterWidth = function() {
  if (this._isFooterActive()) {
    this._getFooterEl().style.width = this.$window.getComputedStyle(this._getFamiliesEl()).width;
  }
};

/**
 * Handles scroll events on the window and adjusts the footer's stickiness when
 * necessary.
 * @private
 */
TkWebProjectCardController.prototype._handleWindowScroll = function() {
  if (!this._isFooterActive() || this._getFooterEl().classList.contains(this.getFooterStickyClass())) {
    return;
  }
  this._resetStickyClasses();
  this._getFooterEl().classList.add(this.getFooterStickyClass());
};

/**
 * Checks whether the entire project is below the display window or not.
 * @private
 * @return {Boolean}
 */
TkWebProjectCardController.prototype._isEntireProjectBelowDisplay = function() {
  return this._getFamiliesEl().getBoundingClientRect().top > this.$window.innerHeight;
};

/**
 * Does the project card extend beyond the display window bottom? The bottom of the
 * project card is calculated using the sum of the families section bottom
 * and the footer height.
 * @private
 * @return {Boolean}
 */
TkWebProjectCardController.prototype._isProjectBottomBelowDisplay = function() {
  return (this._getFamiliesEl().getBoundingClientRect().bottom + this._getFooterEl().getBoundingClientRect().height) >= this.$window.innerHeight;
};

/**
 * Is this project card's footer scrolled out of view? This will be used to
 * determine if the footer should stick to the bottom of the screen. A footer
 * is called out of window if the project card is on the display window but the
 * footer is not.
 * @private
 * @return {Boolean} description
 */
TkWebProjectCardController.prototype._isFooterOutOfView = function() {
  return !this._isEntireProjectBelowDisplay() && this._isProjectBottomBelowDisplay();
};

/**
 * The footer should only be active if the current project card is
 * expanded and has been modified.
 *
 * @private
 * @return {Boolean}
 */
TkWebProjectCardController.prototype._isFooterActive = function() {
  return this.tkWebProjectCardModel.isModified() && this.tkWebProjectCardModel.isExpanded() && !!this._getFooterEl();
};

/**
 * Reset the stick footer classes.
 * @private
 */
TkWebProjectCardController.prototype._resetStickyClasses = function() {
  var footerEl = this._getFooterEl();
  footerEl.classList.remove(STUCK_TO_BOTTOM_FOOTER_CLASS);
  footerEl.classList.remove(UNSTUCK_TO_BOTTOM_FOOTER_CLASS);
};

module.exports = TkWebProjectCardController;
