const SearchParamChangedEvent = require('@adobe-fonts/site/events/SearchParamChangedEvent.js');

const SPECTRUM_BREAKPOINT_SMALL = 768;
const SPECTRUM_BREAKPOINT_MEDIUM = 1280;
const SPECTRUM_BREAKPOINT_LARGE = 1768;

/**
 * @ngInject
 */
function SearchResultsController($document, $window, DataService, FamilyAutocompleteSearchService, FavoritesService, DialogService, UserPrefsService) {
  this.$document = $document;
  this.$window = $window;
  this.DataService = DataService;
  this.FamilyAutocompleteSearchService = FamilyAutocompleteSearchService;
  this.FavoritesService = FavoritesService;
  this.DialogService = DialogService;
  this.UserPrefsService = UserPrefsService;
  this._preloadData();
}

/**
 * Returns localized example text
 *
 * @return {Object}
 */
SearchResultsController.prototype.getExampleText = function(family) {
  var viewType = 'list';
  if (family.filters != null && family.filters.indexOf('ka:ko') >= 0) {
    viewType += '_kana';
  }
  return {
    size: 40,
    value: this.textSamples[family.display_font.default_language][viewType]
  };
};

SearchResultsController.prototype._webComponents_initSearchBar = function() {
  var self = this;
  var searchBar = self.$document.find('af-search-bar')[0];
  if (!searchBar) {
    return;
  }

  searchBar.addEventListener(SearchParamChangedEvent.EVENT_NAME, function(event) {
    self.FamilyAutocompleteSearchService.get(event.detail.searchParam).then(function(response) {
      searchBar.results = response.data;
    });
  });
};

/**
 * Toggles tooltip offset by window width
 *
 * @return {String}
 */
SearchResultsController.prototype.getVisualSearchTooltipOffset = function() {
  if (this.$window.innerWidth >= SPECTRUM_BREAKPOINT_LARGE) {
    return null;
  }
  if (this.$window.innerWidth >= SPECTRUM_BREAKPOINT_MEDIUM) {
    return '32';
  }
  if (this.$window.innerWidth >= SPECTRUM_BREAKPOINT_SMALL) {
    return '24';
  }
  return '16';
};

/**
 * Return GPT search similarity data for a given family when available.
 *
 * @param family
 * @returns {string}
 */
SearchResultsController.prototype.similarTo = function(family) {
  var similarity = this.similarity || {};

  if (this.gptResultType === 'variation_identifiers') {
    return similarity[family.display_font.postscript_name] || [];
  } else {
    return similarity[family.name] || [];
  }
};

SearchResultsController.prototype.openGPTOptionsDialog = function() {
  var self = this;
  self.DialogService.show('/angular_templates/dialogs/gpt_search_options.html').then(function(dialog) {
    dialog.version = self.gptOptions.version;
    dialog.availableDeployments = self.gptDeployments;
    dialog.deployment = self.gptOptions.deployment === 'completions-playground' ? 'gpt-3' : self.gptOptions.deployment;
    dialog.systemPrompt = self.gptOptions.system_prompt;
    dialog.userPromptPrefix = self.gptOptions.user_prompt_prefix;
    dialog.temperature = self.gptOptions.temperature;

    dialog.handleCancelButtonClick = function() {
      dialog.close();
    };
    dialog.handleConfirmButtonClick = function() {
      self.gptOptions.version = dialog.version;
      self.gptOptions.deployment = dialog.deployment;
      self.gptOptions.system_prompt = dialog.systemPrompt;
      self.gptOptions.user_prompt_prefix = dialog.userPromptPrefix;
      self.gptOptions.temperature = dialog.temperature;

      self.UserPrefsService.updatePrefs({
        gptSearchVersion: dialog.version,
        gptSearchDeployment: dialog.deployment,
        gptSearchSystemPrompt: dialog.systemPrompt,
        gptSearchUserPromptPrefix: dialog.userPromptPrefix,
        gptSearchTemperature: dialog.temperature
      });
      dialog.close();
    };
    dialog.handleResetButtonClick = function() {
      dialog.version = self.gptDefaults.version;
      dialog.deployment = self.gptDefaults.deployment;
      dialog.systemPrompt = self.gptDefaults.system_prompt;
      dialog.userPromptPrefix = self.gptDefaults.user_prompt_prefix;
      dialog.temperature = self.gptDefaults.temperature;
    };
    dialog.heading = 'GPT Options';
  });
};

SearchResultsController.prototype.toggleGPTRawResults = function() {
  this.isGPTRawResultsOpen = !this.isGPTRawResultsOpen;
};

/**
 * Preload data using DataService.
 * @private
 */
SearchResultsController.prototype._preloadData = function() {
  var self = this;
  self._webComponents_initSearchBar();

  self.isGPTRawResultsOpen = false;
  self.DataService.get('/neue/preloaded_family_data').then(function (data) {
    self.families = data.familiesData.families;
    self.textSamples = data.textSampleData.textSamples;
    if (data.gptSearch) {
      self.similarity = data.gptSearch.similarity;
      self.gptDefaults = data.gptSearch.defaults;
      self.gptOptions = data.gptSearch.options;
      self.gptResultType = data.gptSearch.resultType;
      self.gptDeployments = data.gptSearch.deployments;
    }
  });
  self.DataService.get('/spectrum/search_navigation_tabs').then(function (data) {
    self.navigationTabs = data.navigationTabs;
    for (var i in data.navigationTabs) {
      if (data.navigationTabs[i].id === data.currentTabId) {
        self.currentTab = data.navigationTabs[i];
        break;
      }
    }
  });
};

module.exports = SearchResultsController;
