/**
 * A directive for web projects list page.
 *
 * To implement this directive, follow this example:
 *
 * In your template:
 *
 * <tk-web-project-list
 *    tk-web-project-list-model="controller.tkWebProjectListModel">
 * </tk-web-project-list>
 *
 * In your Javascript Controller:
 *
 * - tkWebProjectListModel: The model with the list of webprojects/kits in the user's account.
 *
 * @ngInject
 */
function Directive() {
  return {
    restrict: 'E',
    templateUrl: '/angular_templates/directives/tk_web_project_list.html',
    bindToController: {
      tkWebProjectListModel: '=',
      sortModes: '='
    },
    controller: require('./controller.js'),
    controllerAs: 'tkWebProjectListCtrl'
  };
}

module.exports = Directive;
