/**
 * A class that handles the display of a font variation
 *
 * @constructor
 * @param {Object} fontVariation
 * @param {Object} textSamples
 * @param {String} typographicFamilySlug
 * @ngInject
 */
function TkWebProjectDisplayFamilyModel(fontVariation, textSamples, typographicFamilySlug) {
  this._fontVariation = fontVariation;
  this._textSamples = textSamples;
  this._typographicFamilySlug = typographicFamilySlug;
}

/**
 * Returns the family name for the display card.
 *
 * @returns {String}
 */
TkWebProjectDisplayFamilyModel.prototype.getVariationFamilyName = function() {
  return this._fontVariation.family_name;
};

/**
 * Returns the slug for the typographic family
 *
 * @returns {String}
 */
TkWebProjectDisplayFamilyModel.prototype.getTypographicFamilySlug = function() {
  return this._typographicFamilySlug;
};

/* Returns an object used to render the given font variation using the display-in-font directive.
*
* @returns {
*   cssName: string,
*   fvd: string,
*   isDynamic: boolean,
*   opaqueId: string,
*   unicodeRange: string,
*   featureSettings: string,
*   kanaOnly: boolean
  * }
*/
TkWebProjectDisplayFamilyModel.prototype.getVariationDisplayData = function() {
  var unicodeRange = this._textSamples[this._fontVariation.default_language].unicode_range;
  var featureSettings = this._textSamples[this._fontVariation.default_language].feature_settings;
  var kanaOnly = this._textSamples[this._fontVariation.default_language].kana_only;
  var textSample = this._textSamples[this._fontVariation.default_language].grid;

  if (!kanaOnly) {
    kanaOnly = false;
  }

  return {
    cssName: this._fontVariation.web_id + '-' + this._fontVariation.fvd,
    fvd: this._fontVariation.fvd,
    isDynamic: true,
    opaqueId: this._fontVariation.web_id,
    unicodeRange: unicodeRange,
    featureSettings: featureSettings,
    kanaOnly: kanaOnly,
    textSample: textSample
  };
};

module.exports = TkWebProjectDisplayFamilyModel;
