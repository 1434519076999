/**
 * @ngInject
 */
function Directive() {
  return {
    scope: {},
    bindToController: {
      familyName: '=',
      imageItemsData: '=',
      displayView: '@'
    },
    controller: require('./controller.js'),
    controllerAs: 'fontImageCardListController',
    restrict: 'E',
    replace: true,
    transclude: true,
    templateUrl: '/angular_templates/directives/adobe_fonts_family_image_card_list.html'
  };
}

module.exports = Directive;
