/**
 * A service used to retrieve families from the backend.
 * @ngInject
 */
function FavoritesService($http) {
  return {
    /**
     * Create a record for this family as users favorite.
     *
     * @slug {String} family slug
     * @return {Promise}
     */
    post: function(slug) {
      return $http.post('/fonts/' + slug + '/typographic_favorites');
    },
    /**
     * Remove a record for this family as users favorite.
     *
     * @slug {String} family slug
     * @return {Promise}
     */
    delete: function(slug){
      return $http.post('/fonts/' + slug + '/typographic_favorites/destroy');
    }
  };
}

module.exports = FavoritesService;
