var angular = require('angular');
const UrlService = require('../../util/url_service.js').default;

/**
 * @ngInject
 */
function FilteredFamilyListStateService() {
  return {
    getDefaultStateObject: getDefaultStateObject,
    getEmptySearchParams: getEmptySearchParams,
    getSearchParamsFromFamilyListData: getSearchParamsFromFamilyListData,
    getState: getState,
    getStateFromUrl: getStateFromUrl,
    updateUrl: updateUrl,
    updateState: updateState
  };

  /**
   * Returns a state object with default values.
   *
   * @returns {{
   *   browseMode: {String},
   *   collection: {String},
   *   currentPage: {Number},
   *   exampleText: {
   *     value: {String},
   *     size: {Number}
   *   },
   *   purpose: {String[]},
   *   selectedLanguages: {String[]},
   *   selectedTag: null,
   *   selectedViewType: {String},
   *   sort: {String}
   * }}
   */
  function getDefaultStateObject() {
    return {
      browseMode: 'default',
      collection: 'full',
      currentPage: 1,
      familyTemplates: {},
      hideImages: false,
      purpose: [],
      selectedLanguages: [],
      selectedTag: null,
      selectedViewType: 'grid',
      sort: 'featured_rank'
    };
  }

  /**
   * Returns an object with a null value for each search parameter.
   *
   * When updating the url with the current state of the filtered family list,
   * any null parameters will be cleared from the current url.
   *
   * @returns {{
   *   browse_mode: {null},
   *   collection: {null},
   *   cc: {null},
   *   mp: {null},
   *   filters: {null},
   *   hideImages: {null}
   *   languages: {null},
   *   page: {null},
   *   purpose: {null},
   *   sort: {null}
   * }}
   */
  function getEmptySearchParams() {
    return {
      browse_mode: null,
      cc: null,
      mp: null,
      collection: null,
      filters: null,
      hideImages: null,
      languages: null,
      page: null,
      purpose: null,
      sort: null
    };
  }

  /**
   * Returns a set of search params for a url based on the data stored in a
   * filtered family list.
   *
   * @param {{}} familyListData
   * @returns {{
   *   browse_mode: {String|null},
   *   collection: {String|null},
   *   filters: {String|null},
   *   languages: {String|null},
   *   page: {String|null},
   *   purpose: {String|null},
   *   sort: {String|null}
   * }}
   */
  function getSearchParamsFromFamilyListData(familyListData) {
    familyListData = familyListData || {};

    var searchParams = getEmptySearchParams();
    if (familyListData.browseMode &&
        familyListData.browseMode != 'default') {
      searchParams.browse_mode = familyListData.browseMode;
    }

    if (familyListData.showPaidFonts) {
      searchParams.cc = true;
    }

    if (familyListData.selectedFilters &&
        familyListData.selectedFilters.length > 0) {
      searchParams.filters =
        familyListData.selectedFilters.join(',');
    }

    if (familyListData.hideImages) {
      searchParams.hideImages = familyListData.hideImages === 'true';
    }

    if (familyListData.selectedLanguages &&
        familyListData.selectedLanguages.length > 0) {
      searchParams.languages = familyListData.selectedLanguages.join(',');
    }

    if (familyListData.currentPage &&
        familyListData.currentPage > 1) {
      searchParams.page = familyListData.currentPage;
    }

    if (familyListData.purpose &&
        familyListData.purpose.length > 0) {
      searchParams.purpose = familyListData.purpose.join(',');
    }

    if (familyListData.sort &&
        familyListData.sort != 'featured_rank') {
      searchParams.sort = familyListData.sort;
    }

    return searchParams;
  }

  /**
   * Returns the current filtered family list data, based on the search params
   * in the url.
   *
   * @returns {{
   *   browseMode: {String},
   *   cc: {Boolean},
   *   mp: {Boolean},
   *   collection: {String},
   *   currentPage: {Number},
   *   purpose: {String},
   *   selectedLanguages: {String[]},
   *   selectedTag: {String},
   *   sort: {String}
   * }}
   */
  function getStateFromUrl(user) {
    var tag = UrlService.getPathParam('/fonts/tags/:tag');
    var searchParams = UrlService.getSearchParams();
    var state = {};

    if (searchParams.browse_mode) {
      state.browseMode = searchParams.browse_mode;
    }

    if (user && user.plan) {
      state.collection = user.plan;
    }

    if (searchParams.cc) {
      state.showPaidFonts = true;
    }

    if (searchParams.filters) {
      state.selectedFilters = searchParams.filters.split(',');
    }

    if (searchParams.hide_images) {
      state.hideImages = searchParams.hide_images === 'true';
    }

    if (searchParams.languages) {
      state.selectedLanguages = searchParams.languages.split(',');
    }

    if (tag) {
      state.selectedTag = tag;
    } else if (searchParams.tag) {
      state.selectedTag = searchParams.tag;
    }

    if (searchParams.page) {
      state.currentPage = Math.max(1, searchParams.page);
    }

    if (searchParams.purpose) {
      state.purpose = searchParams.purpose.split(',');
    }

    if (searchParams.sort) {
      state.sort = searchParams.sort;
    }

    if (searchParams.max_styles) {
      state.maxStyles = searchParams.max_styles;
    }

    if (searchParams.min_styles) {
      state.minStyles = searchParams.min_styles;
    }

    return state;
  }

  /**
   * Returns the current filtered family list data, based on the search params
   * and the stored user browsing preferences.
   *
   * @param {Boolean} user
   * @param {Object} stateFromUserPrefs
   *
   * @returns {{
   *   browseMode: {String},
   *   collection: {String},
   *   currentPage: {Number},
   *   exampleText: {
   *     value: {String},
   *     size: {Number}
   *   },
   *   purpose: {String[]},
   *   selectedFilters: {String[]},
   *   selectedLanguages: {String[]},
   *   selectedViewType: {String},
   *   showPaidFonts: {Boolean},
   *   sort: {String}
   * }}
   */
  function getState(user, stateFromUserPrefs) {
    var stateFromUrl = getStateFromUrl(user);
    var savedState = angular.merge(_removeNull(stateFromUserPrefs, true), _removeNull(stateFromUrl, true));

    return angular.merge(getDefaultStateObject(), savedState);
  }

  /**
   * Updates the current url's with search parameters based on the family list
   * data passed in.
   *
   * @param {{}} familyListData
   */
  function updateUrl(urlState) {
    // In the case of the main browse page, we want to update the path to include/exclude
    // the tag in the path. In all other cases, we only want to update the search params.
    if (UrlService.pathStartsWith('/fonts')) {
      var path = urlState.tag ? '/fonts/tags/' + urlState.tag : '/fonts';
      UrlService.updatePathAndSearchParams(path, urlState);
    } else {
      UrlService.updateSearchParams(urlState);
    }
  }

  /**
   * Updates the stored browsing preferences for the current filtered family list data in both
   * the URL and the user browsing preferences.
   *
   * @param {{}} familyListData
   * @returns {Http.promise}
   */
  function updateState(familyListData) {
    updateUrl(getSearchParamsFromFamilyListData(familyListData));
  }

  /**
   * Delete all null (or undefined) properties from an object.
   *
   * @private
   */
  function _removeNull(obj, recurse) {
    for (var i in obj) {
      if (obj[i] === null || obj[i] === undefined) {
        delete obj[i];
      } else if (recurse && typeof obj[i] === 'object') {
        _removeNull(obj[i], recurse);
      }
    }
    return obj;
  }
}

module.exports = FilteredFamilyListStateService;
