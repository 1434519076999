import { updatePageUrl } from './mixins/Pagination.js';
import { addPageAction, getNewRelicName } from '../util/new_relic.js';
import { get } from '../util/secure_fetch.js';

const PAGE_CHANGE_EVENT = 'pagination-change';
const PAGE = 'fontpacks-index';

class FontpacksPage extends HTMLElement {
  connectedCallback() {
    this.addEventListener(PAGE_CHANGE_EVENT, this.handlePageChange);
    this.addEventListener('click', this.initAnalytics);
  }

  disconnectedCallback() {
    this.removeEventListener(PAGE_CHANGE_EVENT, this.handlePageChange);
    this.removeEventListener('click', this.initAnalytics);
  }

  get _fontpackListContainer() {
    return this.querySelector('#fontpack-list');
  }

  async handlePageChange(event) {
    try {
      const currentPage = event.detail.page;

      const response = await get(`/collections.json?page=${currentPage}`);
      const data = await response.json();
      const fontpackCards = new DOMParser().parseFromString(data.content.fontpackCards, 'text/html');

      this._fontpackListContainer.replaceChildren(...fontpackCards.body.children);

      updatePageUrl(currentPage);
    } catch (error) {
      console.log('There was an error', error);
    }
  }

  initAnalytics(event) {
    this.handleFontpackCardClick(event);

    window.addEventListener('beforeunload', () => {
      addPageAction(`typekit.${PAGE}.page_exit`);
    });
  }

  handleFontpackCardClick(event) {
    const trackingCard = event.target.closest('[data-analytics-class*="fontpacks-index-cards"]');
    if (!trackingCard) {
      return;
    }

    addPageAction(getNewRelicName(PAGE, event), {
      elementUrl: trackingCard.href,
      elementClass: trackingCard.dataset.analyticsClass
    });
  }
}

customElements.define('af-fontpacks-page', FontpacksPage);
