import { setupGlobals } from '../util/GlobalSetup.js';
setupGlobals();

import '../chunks/actions-webcomponents.js';
import '../chunks/polyfills.js';
import '../chunks/spectrum-webcomponents.js';
import '../chunks/shared-webcomponents.js';
import 'angular';
import '../spectrum/app.js';
import { switchLocale } from '../i18n/spectrum.i18n.js';
import { refreshCoachmarks } from '../util/coachmark_helpers.js';
import { initI18n } from '../util/i18n_util.js';
import { initFlipper } from '../util/flipper.js';

// Redirect the user to the new favorites page from the my_fonts#favorites-section page
if (window && window.location.pathname.includes('my_fonts') && window.location.hash.includes('favorites')) {
  window.location.replace('/fonts?your_fonts=favorite');
}

initI18n(switchLocale);
initFlipper();

// requestAnimationFrame is used to ensure that the coachmarks are rendered after the DOM has been updated.
requestAnimationFrame(() => {
  refreshCoachmarks();
});
