var _ = require('underscore');
const { hasPaidCreativeCloud } = require('../../../util/user_info.js');

var DEFAULT_FONT_SIZE_FOR_EXAMPLE_TEXT = 36;
var DEFAULT_FONT_SIZE_FOR_PAIRING_BODY_TEXT = 18;
var DEFAULT_FONT_SIZE_FOR_PAIRING_HEADER_TEXT = 30;

/**
 * Controller for a directive that can be used to render a font variation.
 * @ngInject
 */
function FontPairingCardDirectiveController($q,
                                              $scope,
                                              $window,
                                              AddFontsToWebProjectService,
                                              AuthenticationService,
                                              DataService,
                                              DialogService,
                                              FeedbackService,
                                              FirstMileService,
                                              I18nService,
                                              NewrelicService,
                                              NotificationService,
                                              SyncService) {
  var self = this;

  self.$q = $q;
  self.$scope = $scope;
  self.$window = $window;

  self.AddFontsToWebProjectService = AddFontsToWebProjectService;
  self.AuthenticationService = AuthenticationService;
  self.DataService = DataService;
  self.DialogService = DialogService;
  self.FeedbackService = FeedbackService;
  self.FirstMileService = FirstMileService;
  self.I18nService = I18nService;
  self.NewrelicService = NewrelicService;
  self.NotificationService = NotificationService;
  self.SyncService = SyncService;

  self.DataService.get('/neue/i18n').then(function (i18n) {
    self.i18n = i18n;
  });

  // The 2 variation names are present in multiple places in the card and we
  // need to highlight each name in both places when the user hovers. These 2
  // booleans track whether the user is hovering over one of the instances of
  // the variation names.
  self.isMainVariationNameHighlighted = false;
  self.isPairingVariationNameHighlighted = false;
}

FontPairingCardDirectiveController.prototype.areFeedbackIconsVisible = function() {
  return this.userLoggedIn();
};

/**
 * Returns the variations listed in the card.
 *
 * @return {*[]}
 */
FontPairingCardDirectiveController.prototype.variations = function () {
  return [this.variation(), this.pairVariation()];
};

FontPairingCardDirectiveController.prototype.isMainVariationInFamily = function () {
  return this.variation().family.slug === this.familySlug();
};

/**
 * Returns the current value of the example text for the card.
 *
 * @return {String}
 */
FontPairingCardDirectiveController.prototype.getExampleText = function () {
  var exampleText = this.exampleText();
  if (exampleText && exampleText.value) {
    return exampleText.value;
  } else {
    var variation = this.variation();
    var textSample = this.textSamples()[variation.default_language];

    return textSample.kana_only ? textSample.list_kana : textSample.list;
  }
};

/**
 * Returns the value of the heading text for pairing cards.
 *
 * @return {String}
 */
FontPairingCardDirectiveController.prototype.getPairingHeadingText = function () {
  return 'Fudge Brownies';
};

/**
 * Returns the value of the body text for pairing cards.
 *
 * @return {String}
 */
FontPairingCardDirectiveController.prototype.getPairingBodyText = function () {
  return 'Pure indulgence — that’s what we’re talking about here. ' +
    'A perfect balance between fudgy and cakey so everyone gets what ' +
    'they want out of these brownies. We’ll leave it to you to fight ' +
    'over who gets the crisp edges versus the ooey-gooey middles. Let’s get baking!';
};

/**
 * Returns the current styling for the example text.
 *
 * @return {
 *   fontSize: {String} A font size in pixels
 * }
 */
FontPairingCardDirectiveController.prototype.getExampleTextStyle = function () {
  var exampleText = this.exampleText();
  return {
    fontSize: (exampleText && exampleText.size ? exampleText.size : DEFAULT_FONT_SIZE_FOR_EXAMPLE_TEXT) + 'px'
  };
};

FontPairingCardDirectiveController.prototype.getPairingBodyTextStyle = function () {
  return {fontSize: DEFAULT_FONT_SIZE_FOR_PAIRING_BODY_TEXT + 'px'};
};

FontPairingCardDirectiveController.prototype.getPairingHeadingTextStyle = function () {
  return {fontSize: DEFAULT_FONT_SIZE_FOR_PAIRING_HEADER_TEXT + 'px'};
};

/**
 * Returns an object that will be used to render the family card's font.
 */
FontPairingCardDirectiveController.prototype.getFontDisplayData = function() {
  var variation = this.variation();
  var textSamples = this.textSamples();

  return this.getDisplayData(variation, textSamples);
};

/**
 * Returns an object that will be used to render pairing body font.
 */
FontPairingCardDirectiveController.prototype.getPairingBodyFontDisplayData = function () {
  var variation = this.pairVariation();
  var textSamples = this.textSamples();

  return this.getDisplayData(variation, textSamples);
};

/**
 * Helper to return an object for rendering a card's font on the
 * page using the display-in-font directive.
 *
 * @returns {{
 *   cssName: string,
 *   fvd: string,
 *   isDynamic: boolean,
 *   opaqueId: string,
 *   unicodeRange: string,
 *   featureSettings: string
 *   kanaOnly: boolean
 * }}
 */
FontPairingCardDirectiveController.prototype.getDisplayData = function (variation, textSamples) {
  var unicodeRange = textSamples[variation.default_language].unicode_range;
  var featureSettings = textSamples[variation.default_language].feature_settings;
  var kanaOnly = textSamples[variation.default_language].kana_only;

  if (!kanaOnly) {
    kanaOnly = false;
  }

  return {
    cssName: variation.family.web_id + '-' + variation.font.web.fvd,
    fvd: variation.font.web.fvd,
    isDynamic: true,
    opaqueId: variation.family.web_id,
    unicodeRange: unicodeRange,
    featureSettings: featureSettings,
    kanaOnly: kanaOnly
  };
};

/**
 * Returns the name of the font and paired font, joined by the joining string provided.
 * @param String joiningString
 * @returns String
 */
FontPairingCardDirectiveController.prototype.getFontPairName = function(joiningString) {
  return [this.variation().name, this.pairVariation().name].join([' ', ' '].join(joiningString));
};

/**
 * Returns the current class for the variation card
 * @returns String
 */
FontPairingCardDirectiveController.prototype.getTextDirectionClass = function() {
  if (this.variation().rtl) {
    return 'sample-right';
  }

  return '';
};

FontPairingCardDirectiveController.prototype.handleMainVariationNameMouseOver = function() {
  this.isMainVariationNameHighlighted = true;
};

FontPairingCardDirectiveController.prototype.handleMainVariationNameMouseOut = function() {
  this.isMainVariationNameHighlighted = false;
};

FontPairingCardDirectiveController.prototype.handlePairingVariationNameMouseOver = function() {
  this.isPairingVariationNameHighlighted = true;
};

FontPairingCardDirectiveController.prototype.handlePairingVariationNameMouseOut = function() {
  this.isPairingVariationNameHighlighted = false;
};

/**
 * Handles clicking on the sync button.
 */
FontPairingCardDirectiveController.prototype.handleSyncButtonClick = function() {
  this._handleFontsSync();
};

/**
 * Is this variation available for the user to sync?
 * @returns {Boolean}
 */
FontPairingCardDirectiveController.prototype.isAvailableForSync = function(variation) {
  if (this.userLoggedIn()) {
    return 'sync' in variation.font && variation.font.sync.required_action.length === 0;
  } else {
    return this.isUsableForSync(variation);
  }
};

/**
 * Is this variation available for web use?
 * @returns {Boolean}
 */
FontPairingCardDirectiveController.prototype.isAvailableForWeb = function(variation) {
  if (this.userLoggedIn()) {
    return 'web' in this.variation().font && variation.font.web.required_action.length === 0;
  } else {
    return this.isUsableForWeb(variation);
  }
};

/**
 * Has this variation been purchased by the user?
 * @returns {Boolean}
 */
FontPairingCardDirectiveController.prototype.isPurchased = function(variation) {
  return variation.marketplace && variation.marketplace.is_purchased_by_user;
};

/**
 * Has this variation been purchased and synced by the user?
 * @returns {Boolean}
 */
FontPairingCardDirectiveController.prototype.isPurchasedAndSynced = function() {
  var self = this;
  return _.every(self.variations(), function (variation) {
    return self.isPurchased(variation) && self.isVariationSelectedForSync(variation);
  });
};

/**
 * Is this variation synced via subscription?
 * @returns {Boolean}
 */
FontPairingCardDirectiveController.prototype.isSyncedViaSubscription = function(variation) {
  return this.isVariationSelectedForSync(variation) && !this.isPurchased(variation);
};

FontPairingCardDirectiveController.prototype.isUsableForWeb = function(variation) {
  return 'web' in variation.font &&
    (variation.font.web.library_availability.trial || variation.font.web.library_availability.full);
};

FontPairingCardDirectiveController.prototype.isUsableForSync = function(variation) {
  return 'sync' in variation.font &&
    (variation.font.sync.library_availability.trial || variation.font.sync.library_availability.full);
};

/**
 * Is this variation selected for sync?
 * @returns {Boolean}
 */
FontPairingCardDirectiveController.prototype.isVariationSelectedForSync = function(variation) {
  var font = variation.font;
  if (font && font.sync) {
    return font.sync.is_selected || (!!font.sync.install_state && _.contains(font.sync.install_state.split(','), 'cc'));
  }

  return false;
};

/**
 * Returns true if using the font for sync ONLY requires the given action for entitlement. i.e. No other actions can be
 * performed to gain entitlement to the font's sync use.
 *
 * @returns {Boolean}
 */
FontPairingCardDirectiveController.prototype.syncOnlyRequiresAction = function(variation, action) {
  var syncRequirements = ((variation.font || {}).sync || {}).required_action;

  // The font has sync requirements (i.e. desktop/sync use is available) AND the actions required before being able to
  // sync the font include the given action AND there are no other actions that can be taken in order to gain an entitlement.
  //    i.e. The given action is the only option for resolving an entitlement to the font's desktop use.
  var syncOnlyRequiresAction = !!syncRequirements && _.contains(syncRequirements, action) && _.isEmpty(_.without(syncRequirements, action));

  // Return true if syncing the font only requires the given action AND the font is not available for web.
  return syncOnlyRequiresAction && !this.isAvailableForWeb(variation);
};

/**
 * Does this variation require the user to upgrade before using the variation?
 * @returns {Boolean}
 */
FontPairingCardDirectiveController.prototype.requiresUpgrade = function(variation, fontType) {
  if (fontType in variation.font) {
    return variation.font[fontType].required_action.indexOf('upgrade') >= 0;
  }

  return false;
};

FontPairingCardDirectiveController.prototype.getFlaggedClass = function () {
  return this.hasNegativeFeedback() ? 'flagged' : '';
};

/**
 * Should we show the sync button for this card?
 * @returns {Boolean}
 */
FontPairingCardDirectiveController.prototype.shouldShowSyncButton = function() {
  var self = this;
  var everyVariationAvailable = _.every(self.variations(), function(variation) {
    return self.isAvailableForSync(variation);
  });
  var someVariationsUnselected = _.some(self.variations(), function (variation) {
    return !self.isVariationSelectedForSync(variation);
  });

  return everyVariationAvailable && someVariationsUnselected;
};

/**
 * Should we show the unsync button for this card?
 * @returns {Boolean}
 */
FontPairingCardDirectiveController.prototype.shouldShowUnsyncButton = function() {
  var self = this;
  return _.every(self.variations(), function (variation) {
    return self.isSyncedViaSubscription(variation);
  });
};

/**
 * Should we show the webOnly text for this card?
 * @returns {Boolean}
 */
FontPairingCardDirectiveController.prototype.shouldShowWebOnly = function() {
  var self = this;
  return _.some(self.variations(), function (variation) {
    return !self.shouldShowUpgradeButton() && !self.isAvailableForSync(variation) && self.isAvailableForWeb(variation);
  });
};

/**
 * Should we show the webOnly requires an upgrade text for this card?
 * @returns {Boolean}
 */
FontPairingCardDirectiveController.prototype.shouldShowWebOnlyRequiresUpgrade = function() {
  var self = this;
  return _.some(self.variations(), function (variation) {
    return !self.shouldShowUpgradeButton(variation) && !self.isUsableForSync(variation) && !self.isAvailableForWeb(variation) && self.isUsableForWeb(variation);
  });
};

FontPairingCardDirectiveController.prototype.shouldShowAdminOnly = function() {
  var self = this;
  return _.some(self.variations(), function (variation) {
    return !self.isUsableForSync(variation) && !self.isUsableForWeb(variation);
  });
};

FontPairingCardDirectiveController.prototype.shouldShowNotAvailable = function() {
  var self = this;
  return _.some(self.variations(), function (variation) {
    return !self.isAvailableForWeb(variation) && !self.isAvailableForSync(variation) && !self.shouldShowUpgradeButton();
  });
};

FontPairingCardDirectiveController.prototype.shouldShowUpgradeButton = function() {
  if (hasPaidCreativeCloud()) {
    return false;
  }

  var self = this;
  return _.some(self.variations(), function (variation) {
    return self.syncOnlyRequiresAction(variation, 'upgrade') || (self.requiresUpgrade(variation, 'sync') && self.showRequiredAction === 'upgrade');
  });
};

FontPairingCardDirectiveController.prototype.shouldShowFlagResultIcon = function () {
  return this.hasNegativeFeedback();
};

FontPairingCardDirectiveController.prototype.hasNegativeFeedback = function () {
  return this.selectedFeedback === 'negative';
};

FontPairingCardDirectiveController.prototype.handleFontPairCardClick = function () {
  this._recordEvent('recommended_font_card_click', 'positive');
};

/**
 * Handle clicking behavior for the pairing card title and text.
 * If the variation that is in the family is clicked, and this card is not in the details section,
 * the controller will call the callback method to scroll to the details section.
 */

FontPairingCardDirectiveController.prototype.handleFontClick = function (variation) {
  if (this.isMainVariationInFamily()) {
    if (variation.id === this.variation().id && !this.isInDetailsSection()) {
      this.$scope.$eval(this.onVariationHeaderClick());
    } else {
      this._recordEvent('recommended_font_card_click', 'positive');
      this.$window.location.href = `/fonts/${variation.family.slug}`;
    }
  } else if (!(variation.id === this.variation().id) && !this.isInDetailsSection()) {
    this.$scope.$eval(this.onVariationBodyClick());
  } else {
    this._recordEvent('recommended_font_card_click', 'positive');
    this.$window.location.href = `/fonts/${variation.family.slug}`;
  }
};

/**
 * Set the class for highlighting the pairing card title and text when in the details section
 * If the font is the font in the family, we set a new style on it to gray it out when highlighted.
 */
FontPairingCardDirectiveController.prototype.getDetailsHighlightClass = function (variation) {
  if (this.isInDetailsSection()) {
    if (this.isMainVariationInFamily()) {
      if (variation.id === this.variation().id) {
        return 'font-pairing-card--details-highlight';
      } else {
        return '';
      }
    } else if (variation.id === this.variation().id) {
      return '';
    } else {
      return 'font-pairing-card--details-highlight';
    }
  } else {
    return '';
  }
};

FontPairingCardDirectiveController.prototype.isSyncingDisabled = function() {
  return this.hasNegativeFeedback();
};

/**
 * Handles clicking on the add/remove family button when use model v2 flag is enabled.
 */
FontPairingCardDirectiveController.prototype.handleUseModelSyncButtonClick = function() {
  this._handleFontsSync(true);
};

/**
 * Adds the variation to the user's synced variations and sets the state for the
 * sync button.
 * @private
 */
FontPairingCardDirectiveController.prototype._addSyncedVariations = function(isUseModelEnabled = false) {
  var self = this;
  self.variations().forEach(function(variation) {
    variation.isSyncing = true;
  });
  var previousInstallStates = _.map(self.variations(), function(variation) {
    return variation.font.sync.install_state;
  });

  self.SyncService.addSyncedVariations(_.pluck(self.variations(), 'id'), {font_pair_id: self.fontPairId()}).then(function(response) {
    self.variations().forEach(function (variation) {
      variation.isSyncing = false;
    });
    if (self._isSyncSuccessResponse(response)) {
      if (isUseModelEnabled) {
        self._handleVariationUseModelSyncSuccess(previousInstallStates);
      } else {
        self._handleVariationSyncSuccess(previousInstallStates);
      }
    } else if ('data' in response) {
      return self.$q.reject(response.data);
    } else {
      return self.$q.reject();
    }
  }).catch(function(err) {
    self.variations().forEach(function (variation) {
      variation.isSyncing = false;
    });
    if (err && err.message) {
      self.NotificationService.error(err.message);
    } else if (isUseModelEnabled) {
      self.NotificationService.error(self.i18n['neue.browse.family_card']['error_occurred_adding_fonts'], {
        actionLabel: self.i18n['neue.browse.family_card']['try_again'],
        callbackAction: function() { self._addSyncedVariations(isUseModelEnabled); }});
    } else {
      self.NotificationService.error(self.i18n['neue.browse.family_card']['error_occurred_activating_fonts']);
    }
  });
};

/**
 * Handles syncing of the fonts.
 * @private
 */
FontPairingCardDirectiveController.prototype._handleFontsSync = function(isUseModelEnabled = false) {
  var self = this;
  this.AuthenticationService.handleAuthenticationWithPostLoginAction(
    'activate_fonts', {font_ids: _.pluck(self.variations(), 'id')}).then(function() {
      var allVariationsActivated = _.every(self.variations(), function (variation) {
        return self.isVariationSelectedForSync(variation);
      });
      if (allVariationsActivated) {
        self._recordEvent('deactivate-button-click', 'negative', isUseModelEnabled);
        self._handleRemoveSyncedVariation(isUseModelEnabled);
      } else {
        self._recordEvent('activate-button-click', 'positive', isUseModelEnabled);
        self._addSyncedVariations(isUseModelEnabled);
      }
    });
};

FontPairingCardDirectiveController.prototype._recordEvent = function (eventName, feedback, isUseModelEnabled = false) {
  var self = this;
  self.NewrelicService.addPageAction('typekit.browse.similarity_and_pairing.feedback', {
    category: 'pairing',
    subcategory: eventName,
    feedback: feedback,
    variation_one: self.variation().name,
    variation_two: self.pairVariation().name,
    useModelEnabled: isUseModelEnabled
  });

  var id = self.variation().id + '-' + self.pairVariation().id;

  // If the user clicks on a font pairing card we will count that as positive feedback.
  self.FeedbackService.post('pairing', eventName, id, feedback, null, self.variation().id, self.pairVariation().id);
};

/**
 * Handles a successful variation sync when use model v2 is enabled.
 * @private
 */
FontPairingCardDirectiveController.prototype._handleVariationUseModelSyncSuccess = function(previousInstallStates) {
  var self = this;
  var addedVariations = [];
  self.variations().forEach(function (variation) {
    if (!variation.font.sync.is_selected) {
      addedVariations.push(variation);
    }
    variation.font.sync.is_selected = true;
    variation.font.sync.install_state = 'cc';
  });

  if (previousInstallStates.every((state) => state === 'cc')) {
    return;
  }

  if (self.onActivate()) {
    self.$scope.$eval(self.onActivate());
  }

  if (self.recommendationIndex() !== undefined) {
    self.NewrelicService.addPageAction('typekit.recommendations.activateFontPair', {
      recommendationIndex: self.recommendationIndex()
    });
  }

  self.FirstMileService.onUseModelFontActivation(self.i18n, {queryParams: {tkFontId: self.variations()[0]?.font.sync.font_id}});
};

/**
 * Handles a successful sync of all the variations.
 * @private
 */
FontPairingCardDirectiveController.prototype._handleVariationSyncSuccess = function(previousInstallStates) {
  var self = this;
  var addedVariations = [];
  self.variations().forEach(function (variation) {
    if (!variation.font.sync.is_selected) {
      addedVariations.push(variation);
    }
    variation.font.sync.is_selected = true;
    variation.font.sync.install_state = 'os';
  });

  if (previousInstallStates.every(
      function(state) { return state === 'cc'; }
    )) {
    return;
  }

  if (addedVariations.length > 0) {
    var message = self.I18nService.getMessageWithCount(self.i18n['neue.browse.family_card']['fonts_named_activated_action'], addedVariations.length, {
      font: addedVariations[0].name,
      count: addedVariations.length
    });
    self.NotificationService.success(message);
  }

  if (self.onActivate()) {
    self.$scope.$eval(self.onActivate());
  }

  if (self.recommendationIndex() !== undefined) {
    self.NewrelicService.addPageAction('typekit.recommendations.activateFontPair', {
      recommendationIndex: self.recommendationIndex()
    });
  }

  self.FirstMileService.shouldShowFontActivationDialog().then(function(shouldShow) {
    if (shouldShow) {
      return self.FirstMileService.showFontActivationDialog();
    }
  });
};

/**
 * Checks the response data and returns true if it was a successful response.
 *
 * @param {Object} response
 * @return {Boolean}
 */
FontPairingCardDirectiveController.prototype._isSyncSuccessResponse = function(response) {
  return 'data' in response && _.every(this.variations(), function (variation) {
    return response.data['added_font_ids'].indexOf(variation.id) !== -1;
  });
};

/**
 * Removes the variation from the user's synced variations after user confirms it in the modal dialog and sets the state
 * for the sync button.
 * @private
 */
FontPairingCardDirectiveController.prototype._handleRemoveSyncedVariation = function(isUseModelEnabled = false) {
  var self = this;
  if (!isUseModelEnabled) {
    self._removeSyncedVariations();
  } else {
    self.FirstMileService.shouldShowRemoveFamilyConfirmationDialog().then(function(shouldShow) {
      if (shouldShow) {
        var header = self.i18n['neue.browse.family_card']['remove_font_confirmation_header'];
        var description = self.i18n['neue.browse.family_card']['remove_font_confirmation_description'];
        return self.FirstMileService.showRemoveFamilyConfirmationDialog(header, description, function() {
          self._removeSyncedVariations(true);
        });
      } else {
        self._removeSyncedVariations(true);
      }
    });
  }
};

/**
 * Removes the variations from the user's synced variations and sets the state
 * for the sync button.
 * @private
 */
FontPairingCardDirectiveController.prototype._removeSyncedVariations = function(isUseModelEnabled = false) {
  var self = this;
  self.NewrelicService.addPageAction('typekit.click.unsync-button', {useModelEnabled: isUseModelEnabled});
  self.variations().forEach(function (variation) {
    variation.isSyncing = true;
  });

  self.SyncService.removeSyncedVariations(_.pluck(self.variations(), 'id'), {font_pair_id: self.fontPairId()}).then(function() {
    var removedVariations = [];
    self.variations().forEach(function (variation) {
      if (variation.font.sync.is_selected) {
        removedVariations.push(variation);
      }
      variation.isSyncing = false;
      variation.font.sync.is_selected = false;
      variation.font.sync.install_state = '';
    });
    if (self.onDeactivate()) {
      self.$scope.$eval(self.onDeactivate());
    }
    if (self.recommendationIndex() !== undefined) {
      self.NewrelicService.addPageAction('typekit.recommendations.deactivateFontPair', {
        recommendationIndex: self.recommendationIndex()
      });
    }
    if (removedVariations.length > 0) {
      if (isUseModelEnabled) {
        self.NotificationService.notice(self.i18n['neue.browse.family_card']['fonts_removed_success_message']);
      } else {
        var message = self.I18nService.getMessageWithCount(self.i18n['neue.browse.family_card']['fonts_named_deactivated_action'], removedVariations.length, {
          font: removedVariations[0].name,
          count: removedVariations.length
        });
        self.NotificationService.success(message);
      }
    }
  }, function() {
    self.variations().forEach(function (variation) {
      variation.isSyncing = false;
    });
  });
};

module.exports = FontPairingCardDirectiveController;
