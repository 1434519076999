/**
 * @ngInject
 */
function FontPreview() {
  return {
    scope: {},
    bindToController: {
      textSamples: '&',
      onNext: '&',
      onPrevious: '&',
      font: '='
    },
    controller: require('./controller.js'),
    controllerAs: 'fontPreviewCtrl',
    restrict: 'E',
    replace: true,
    templateUrl: '/angular_templates/directives/font_preview.html'
  };
}

module.exports = FontPreview;
