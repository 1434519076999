/**
 * Controller for the font-display-options directive.
 * @ngInject
 */

function FontDisplayOptionsController() {
  this.tkffData = this.tkWebProjectFamilyCardModel.getTkffData();
  this.fontDisplayOptions = ['auto', 'block', 'swap', 'fallback', 'optional'];
  if (this.fontDisplayOptions.indexOf(this.tkffData.font_display) === -1) {
    this.tkffData.font_display = 'auto';
  }
  this.selectedFontDisplay = this.tkffData.font_display;
}

FontDisplayOptionsController.prototype.handleFontDisplayClick = function (option) {
  this.tkWebProjectCardModel.setIsModified(true);
  this.selectedFontDisplay = option;
  this.tkffData.font_display = option;
};

FontDisplayOptionsController.prototype.isButtonChecked = function (option) {
  return this.selectedFontDisplay === option;
};

module.exports = FontDisplayOptionsController;
