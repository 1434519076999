/**
 * Directive for a sticky nav element that will stick to the top of the page
 * instead of scrolling off of the page.
 *
 * @ngInject
 */
function StickyNavDirective($document, $window) {
  return {
    restrict: 'A',
    scope: {},
    bindToController: {
      disabled: '&?'
    },

    controller: require('./controller.js'),
    controllerAs: 'stickyNavCtrl',

    link: function(scope, el, attrs, controller) {
      // Check for an unstick button inside the element and attach a click
      // handler if there is one.
      var unstickButton = el[0].querySelector('.sticky-nav__unstick-button');
      if (unstickButton) {
        unstickButton.addEventListener('click', function() {
          scope.$apply(function() {
            controller.forceUnstick();
          });
        });
      }

      $window.addEventListener('resize', function() {
        scope.$apply(function() {
          controller.handleResize();
        });
      });

      $window.addEventListener('scroll', function() {
        scope.$apply(function() {
          controller.handleScroll();
        });
      });
    }
  };
}

module.exports = StickyNavDirective;
