/**
 * @ngInject
 */
function AdobeFontsFamilyImageCardController() {
}

/**
 * Return family image class depending on display view and item size
 */
AdobeFontsFamilyImageCardController.prototype.familyImageClass = function() {
  if (this.displayView === 'family-carousel' && this.itemSize <= 2) {
    return 'adobe-fonts-family-image-card--image-family-view';
  }
  return 'adobe-fonts-family-image-card--image';
};

/**
 * Return family image container's class depending on display view and item size
 */
AdobeFontsFamilyImageCardController.prototype.familyImageContainerClass = function() {
  if (this.displayView === 'family-carousel') {
    return this.itemSize > 2 ? 'adobe-fonts-family-image-card--container-list' : 'adobe-fonts-family-image-card--container-list-fewer-images';
  }
};

module.exports = AdobeFontsFamilyImageCardController;
