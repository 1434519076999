/**
 * A directive that can be used to render a font family.
 *
 * @example
 *  <family-card family="family"
 *               example-text="controller.exampleText"
 *               ng-repeat="family in controller.families track by family.slug">
 *  </family-card>
 *
 * @ngInject
 */
function Directive() {
  return {
    scope: {},
    bindToController: {
      cardType: '@',
      showCarousel: '@',
      exampleText: '&',
      similarTo: '&',
      family: '=',
      familyTemplates: '&',
      textSamples: '&',
      noBackground: '&',
      isPreviouslyActiveView: '&',
      recommendationIndex: '&',
      selectedTag: '&',
      viewType: '&'
    },
    controller: require('./controller.js'),
    controllerAs: 'familyCard',
    restrict: 'E',
    replace: true,
    templateUrl: '/angular_templates/directives/family_card.html',
    link: function(scope) {
      // Whenever the value of the family templates property changes
      // we need to inject the specimen cards into the placeholders in
      // the family card's carousel.
      scope.$watch('familyCard.familyTemplates()', function(templates) {
        scope.familyCard.createCCXSpecimenCards(templates);
      }, true);
    }
  };
}

module.exports = Directive;
