/**
 * A directive that supports toggling "required action" filters.
 *
 * @example
 *  <required-action-toggle show-upgrade-toggle="true" on-change-required-action-toggle="ctrl.onChanged" />
 *
 * @ngInject
 */
function Directive() {
  return {
    scope: {
      showUpgradeToggle: '&',
      upgradeSelected: '&?',
      changeRequiredActionToggle: '&onChangeRequiredActionToggle'
    },
    restrict: 'E',
    replace: true,
    templateUrl: '/angular_templates/directives/required_action_toggle.html',
    link: function(scope) {
      var upgradeSelected = scope.upgradeSelected();

      function handleActionToggled() {
        var filteredActions = {
          upgrade: !!upgradeSelected
        };

        scope.changeRequiredActionToggle({filteredActions: filteredActions});
      }

      scope.toggleUpgradeAction = function() {
        upgradeSelected = !upgradeSelected;
        handleActionToggled();
      };

      scope.isUpgradeSelected = function() {
        return scope.showUpgradeToggle() && upgradeSelected;
      };
    }
  };
}

module.exports = Directive;
