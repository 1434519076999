var _ = require('underscore');
const { hasPaidCreativeCloud } = require('../../util/user_info.js');

/**
 * Controller for the collection show page.
 *
 * @ngInject
 */
function CollectionController($scope,
                              $q,
                              $timeout,
                              DataService,
                              DialogService,
                              FilteredFamilyListStateService,
                              FontLoaderService,
                              FontpackService,
                              NewrelicClickTrackerService,
                              NotificationService,
                              SyncService) {
  var self = this;
  self.$scope = $scope;
  self.$q = $q;
  self.$timeout = $timeout;
  self.DataService = DataService;
  self.DialogService = DialogService;
  self.FilteredFamilyListStateService = FilteredFamilyListStateService;
  self.FontLoaderService = FontLoaderService;
  self.FontpackService = FontpackService;
  self.NotificationService = NotificationService;
  self.SyncService = SyncService;
  NewrelicClickTrackerService.init({page: 'collections'});

  self.viewType = 'list';

  // FontpackService.syncFontpack can be called from other places
  // (for example, FontpackItemsController) and will mark self.fontpack
  // as synced upon success. We need to watch for changes to self.fontpack
  // so that isFontpackSynced updates correctly.
  // This isn't needed in FontpackItemsController because ng-repeat
  // already adds watchers to all fontpacks in that controller.
  self.$scope.$watch(self.fontpack);

  self._preloadData();
}

/**
 * Returns the display font that will be used to render the example text.
 *
 * @param {Object} item
 * @returns {Object}
 */
CollectionController.prototype.getDisplayFont = function(item) {
  var featureSettings = this.textSampleData.textSamples[item.default_language].feature_settings;
  var kanaOnly = this.textSampleData.textSamples[item.default_language].kana_only;
  var unicodeRange = this.textSampleData.textSamples[item.default_language].unicode_range;

  return {
    cssName: item.css_name,
    featureSettings: featureSettings,
    fvd: item.fvd,
    isDynamic: true,
    kanaOnly: kanaOnly,
    opaqueId: item.opaque_id,
    unicodeRange: unicodeRange
  };
};

/**
 * Returns the current value of the example text for the card.
 * Some font packs contain a mix of fonts that do and do not support
 * Japanese, so fall back to a text sample for the font's default language
 * if the selected sample text is not supported.
 *
 * @return {String}
 */
CollectionController.prototype.getExampleText = function(font) {
  if (_.isEmpty(font) || this._isSelectedExampleTextSupported(font)) {
    return this.exampleText.value;
  } else {
    return this.textSampleData.textSamples[font.default_language].list;
  }
};

/**
 * Returns the current styling for the example text.
 *
 * @return {
 *   fontSize: {String} A font size in pixels
 * }
 */
CollectionController.prototype.getExampleTextStyle = function() {
  return {
    fontSize: this.exampleText.size + 'px'
  };
};

/**
 * Returns the current value of the example text for the card.
 *
 * @return {String}
 */
CollectionController.prototype.getHeroCardExampleText = function(item) {
  var exampleText = this.textSampleData.textSamples[item.default_language || 'en'];
  return exampleText.kana_only ? exampleText.grid_kana : exampleText.grid;
};

/**
 * Returns class of the example text for the card based on its default language
 *
 * @return {String}
 */
CollectionController.prototype.getHeroCardExampleTextClass = function(item) {
  if (item.default_language === 'ja') {
    return 'collection-show__hero-variation-sample-japanese';
  } else {
    return 'collection-show__hero-variation-sample';
  }
};

/**
 * Returns the current class for the variation card
 * @returns String
 */
CollectionController.prototype.getTextDirectionClass = function(item) {
  return item.rtl ? 'sample-right' : '';
};

/**
 * Handle click events on the unsync button.
 * @param {String} actionSource String sent to NewRelic to describe where the sync occurred
 */
CollectionController.prototype.handleUnsyncButtonClick = function(actionSource) {
  this.FontpackService.unsyncFontsInFontpack(this.fontpack, actionSource, this.i18n);
};

/**
 * Handle click events on the unsync button.
 * @param {String} actionSource String sent to NewRelic to describe where the sync/unsync occurred
 */
CollectionController.prototype.handleUseModelUnsyncButtonClick = function(actionSource) {
  this.FontpackService.unsyncFontsInFontpack(this.fontpack, actionSource, this.i18n, true);
};

/**
 * Determines whether every variation in the font pack is already synced.
 *
 * @returns Boolean
 */
CollectionController.prototype.isFontpackSynced = function() {
  return this.FontpackService.isFontpackSynced(this.fontpack.slug);
};

/**
 * Should the "Get Fonts" button be displayed?
 * @returns {Boolean}
 */
CollectionController.prototype.isGetFontsButtonVisible = function() {
  return !this.requiresUpgrade() && !this.isFontpackSynced();
};

/**
 * Should the "Unsync Fonts" button be displayed?
 * @returns {Boolean}
 */
CollectionController.prototype.isUnsyncFontsButtonVisible = function() {
  return this.isFontpackSynced();
};

/**
 * Determines whether a plan upgrade is required in order to sync the collection.
 *
 * @returns Boolean
 */
CollectionController.prototype.requiresUpgrade = function() {
  return this.FontpackService.requiresUpgrade(this.fontpack.slug);
};

/**
 * Determines whether a message indicating a plan upgrade is required, should be shown to this particular user.
 *
 * @returns Boolean
 */
CollectionController.prototype.isUpgradeMessageAvailable = function() {
  return this.requiresUpgrade() && !hasPaidCreativeCloud();
};

/**
 * Determines whether a message indicating this fontpack is not available, should be shown to this particular user.
 *
 * @returns Boolean
 */
CollectionController.prototype.isNotAvailableMessageVisible = function() {
  return this.requiresUpgrade() && !this.isUpgradeMessageAvailable();
};

/**
 * Should the grid view be displayed?
 *
 * @returns Boolean
 */
CollectionController.prototype.shouldShowGridView = function() {
  return this.viewType === 'grid';
};

/**
 * Should the list view be displayed?
 *
 * @returns Boolean
 */
CollectionController.prototype.shouldShowListView = function() {
  return this.viewType === 'list';
};

/**
 * Syncs all variations in the font pack
 *
 * @param {String} actionSource String sent to NewRelic to describe where the sync occurred
 */
CollectionController.prototype.syncFontpack = function(actionSource) {
  this.FontpackService.syncFontpack(this.fontpack, actionSource, this.i18n);
};

/**
 * Syncs all variations in the font pack
 *
 * @param {String} actionSource String sent to NewRelic to describe where the sync occurred
 */
CollectionController.prototype.useModelSyncFontpack = function(actionSource) {
  this.FontpackService.syncFontpack(this.fontpack, actionSource, this.i18n, true);
};

/**
 * Augments fonts on sample text change
 */
CollectionController.prototype.updateFonts = function() {
  this.FontLoaderService.updateFonts(this.exampleText.value);
};

/**
 * Does the font provided support all characters in the selected example text?
 *
 * @private
 * @param {Object} font
 * @returns {Boolean}
 */
CollectionController.prototype._isSelectedExampleTextSupported = function(font) {
  var supportedCharacters = _.map(font.unicode_range.split(','), function(character) {
    return String.fromCharCode(parseInt(character.replace('U+', ''), 16));
  });
  var sampleTextCharacters = this.exampleText.value.split('');
  var unsupportedCharacters = _.difference(sampleTextCharacters, supportedCharacters);
  return _.isEmpty(unsupportedCharacters);
};

/**
 * Preloads data from script tags on the page.
 *
 * @private
 * @returns {Promise}
 */
CollectionController.prototype._preloadData = function() {
  var self = this;

  return self.$q.all([
    self.DataService.get('/preloaded_collection_data').then(function(data) {
      self.fontpack = data.fontpack;
      self.groups = data.fontpack.group_metadata;
      self.items = data.fontpack.font_variations;
      self.textSampleData = data.textSampleData;
      self._setExampleText();
      self.FontpackService.addFontpacks([self.fontpack]);
    }),

    self.DataService.get('/fontpack/i18n').then(function(data) {
      self.i18n = data;
    })
  ]);
};

/*
 * Sets example text based on language and previous selection state
 * @private
 */
CollectionController.prototype._setExampleText = function() {
  var self = this;
  var language = _.first(_.uniq(_.pluck(self.items || [], 'default_language'))) || 'en';
  var textSample = self.textSampleData.textSamples[language];
  self.exampleText = {
    value: textSample.kana_only ? textSample.list_kana : textSample.list,
    size: 36
  };
  self.featureSettings = textSample.feature_settings;
  self.unicodeRange = textSample.unicode_range;
};

module.exports = CollectionController;
