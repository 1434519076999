/**
 * A service to retrieve designer info from the backend.
 * @ngInject
 */
function DesignersService($http) {
  return {
    /**
     * Get a list of typographic families.
     *
     * @param designerSlug
     * @param {{}} params
     * @returns {Promise}
     */
    getFamilies: function(designerSlug, params) {
      return $http.get('/designers/' + designerSlug + '/families', {
        params: params
      });
    }
  };
}

module.exports = DesignersService;
