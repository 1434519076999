/**
 * Controller for the tk-web-project-editor-style-selector directive.
 * @ngInject
 */
function TkWebProjectEditorStyleSelectorController() {
}

TkWebProjectEditorStyleSelectorController.prototype.getRatioOfSelectedToAvailableFonts = function() {
  return (this.tkWebProjectEditorStyleSelectorModel.getEnabledFontVariationIds().length + '/' + this.tkWebProjectEditorStyleSelectorModel.getAvailableFontVariations().length);
};

TkWebProjectEditorStyleSelectorController.prototype.getAvailableFontVariations = function() {
  return this.tkWebProjectEditorStyleSelectorModel.getAvailableFontVariations();
};

TkWebProjectEditorStyleSelectorController.prototype.getCompleteFontVariationStyle = function(fontVariation) {
  var cssFontStack = this.tkWebProjectFamilyCardModel.getFormattedCssFontStack();
  return this.tkWebProjectEditorStyleSelectorModel.getCompleteFontVariationStyle(fontVariation, cssFontStack);
};

TkWebProjectEditorStyleSelectorController.prototype.isVariationSelected = function(fontVariation) {
  return this.tkWebProjectEditorStyleSelectorModel.isVariationSelected(fontVariation);
};

TkWebProjectEditorStyleSelectorController.prototype.handleCheckboxToggled = function(fontVariation) {
  if (this.tkWebProjectEditorStyleSelectorModel.isVariationSelected(fontVariation)) {
    this.tkWebProjectFamilyCardModel.updateVariationsDisabled(fontVariation.id);
  } else {
    this.tkWebProjectFamilyCardModel.updateVariationsEnabled(fontVariation.id);
  }
  this.tkWebProjectEditorStyleSelectorModel.toggleCheckbox(fontVariation);
  this.tkWebProjectCardModel.setIsModified(true);
  this.tkWebProjectCardModel.computeTotalVariations();
};

TkWebProjectEditorStyleSelectorController.prototype.isVariationRtl = function(fontVariation) {
  return fontVariation.rtl;
};

TkWebProjectEditorStyleSelectorController.prototype.getVariationDisplayData = function(fontVariation) {
  return this.tkWebProjectEditorStyleSelectorModel.getVariationDisplayData(fontVariation);
};

TkWebProjectEditorStyleSelectorController.prototype.getExampleText = function(fontVariation) {
  return this.tkWebProjectEditorStyleSelectorModel.getExampleText(fontVariation);
};

module.exports = TkWebProjectEditorStyleSelectorController;
