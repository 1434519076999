var angular = require('angular');
var VARIATIONS_COUNT_CUTOFF_FOR_LARGE_PROJECT = 8;
var CharSetOptionsEnum = Object.freeze({
  CHAR_SET_OPTION_ALL: 'all',
  CHAR_SET_OPTION_CUSTOM: 'custom',
  CHAR_SET_OPTION_DEFAULT: 'default',
  CHAR_SET_OPTION_DYNAMIC: 'dynamic'
});

/**
 * A class that returns data for web project card component
 *
 * @constructor
 * @params {webProjectData} data about a particular kit
 * @ngInject
 */
function TkWebProjectCardModel(webProjectData, isExpanded) {
  this._webProjectData = webProjectData;
  this._isExpanded = isExpanded;
  this._projectId = webProjectData.kit.kit.id;
  this._projectName = webProjectData.kit.kit.name;
  this._projectDetails = { kitToken: webProjectData.kit.kit.token, dateModified: webProjectData.kit.kit.updated_at };
  this._tkffList = webProjectData.tkff_list;
  this._tkffListBackup = angular.copy(webProjectData.tkff_list);
  this._isDynamic = webProjectData.is_dynamic;
  this.computeTotalVariations();
  this.computeTotalFamilies();
  this._isModified = false;
  this._isValid = true;
  this._deletedTkffs = [];
  this._isDataFetched = false;
}

TkWebProjectCardModel.prototype.getIsDataFetched = function() {
  return this._isDataFetched;
};

TkWebProjectCardModel.prototype.setIsDataFetched = function(isDataFetched) {
  this._isDataFetched = isDataFetched;
};

TkWebProjectCardModel.prototype.getCharSetOptionsEnum = function() {
  return CharSetOptionsEnum;
};

TkWebProjectCardModel.prototype.getWebProjectData = function() {
  return this._webProjectData;
};

TkWebProjectCardModel.prototype.getProjectId = function() {
  return this._projectId;
};

TkWebProjectCardModel.prototype.getProjectName = function() {
  return this._projectName;
};

TkWebProjectCardModel.prototype.getProjectDetails = function() {
  return this._projectDetails;
};

TkWebProjectCardModel.prototype.setLastModified = function(newDate) {
  this._projectDetails.dateModified = newDate;
};

TkWebProjectCardModel.prototype.isDynamic = function() {
  return this._isDynamic;
};

TkWebProjectCardModel.prototype.getTotalVariations = function() {
  return this._totalVariations;
};

TkWebProjectCardModel.prototype.computeTotalVariations = function() {
  var i;
  var totalVariations = 0;
  for (i = 0; i < this._tkffList.length; i++) {
    totalVariations += this._tkffList[i].enabled_font_variation_ids.length;
  }
  this._totalVariations = totalVariations;
};

TkWebProjectCardModel.prototype.getTotalFamilies = function() {
  return this._totalFamilies;
};

TkWebProjectCardModel.prototype.computeTotalFamilies = function() {
  this._totalFamilies = this._tkffList.length;
};

TkWebProjectCardModel.prototype.getTkffList = function() {
  if (!this._tkffList) {
    return [];
  }

  return this._tkffList;
};

TkWebProjectCardModel.prototype.getVariableFonts = function() {
  return this.getTkffList().filter(font => font.is_variable);
};

TkWebProjectCardModel.prototype.getNonVariableFonts = function() {
  return this.getTkffList().filter(font => !font.is_variable);
};

TkWebProjectCardModel.prototype.hasVariableFonts = function() {
  return this.getVariableFonts().length > 0;
};

TkWebProjectCardModel.prototype.hasNonVariableFonts = function() {
  return this.getNonVariableFonts().length > 0;
};

TkWebProjectCardModel.prototype.setTkffList = function(tkffList) {
  this._tkffList = tkffList;
  this._tkffListBackup = angular.copy(tkffList);
};

TkWebProjectCardModel.prototype.isLargeWebProject = function() {
  return (this._totalVariations > VARIATIONS_COUNT_CUTOFF_FOR_LARGE_PROJECT) || false;
};

TkWebProjectCardModel.prototype.isExpanded = function() {
  return this._isExpanded;
};

TkWebProjectCardModel.prototype.isCancelDisabled = function() {
  return !this._isModified;
};

TkWebProjectCardModel.prototype.isSaveDisabled = function() {
  return !this._isValid;
};

TkWebProjectCardModel.prototype.setIsValid = function(isValid) {
  this._isValid = isValid;
};

TkWebProjectCardModel.prototype.setIsModified = function(isModified) {
  this._isModified = isModified;
};

TkWebProjectCardModel.prototype.isModified = function() {
  return this._isModified;
};

TkWebProjectCardModel.prototype.toggleExpanded = function() {
  this._isExpanded = !this._isExpanded;
};

TkWebProjectCardModel.prototype.handleSave = function() {
  this.setIsModified(false);
  this._deletedTkffs = [];
};

TkWebProjectCardModel.prototype.handleCancel = function() {
  this.toggleExpanded();
  this._tkffList = angular.copy(this._tkffListBackup);
  this.computeTotalFamilies();
  this.computeTotalVariations();
  this.setIsModified(false);
  this._isValid = true;
  this._deletedTkffs = [];
};

TkWebProjectCardModel.prototype.updateTkffListBackup = function() {
  this._tkffListBackup = angular.copy(this._tkffList);
};

TkWebProjectCardModel.prototype.deleteTkff = function(tkff) {
  this.setIsModified(true);
  this._deletedTkffs.push(tkff);
  var index = this._tkffList.indexOf(tkff);
  if (index > -1) {
    this._tkffList.splice(index, 1);
  }
  this.updateIsValid();
  this.computeTotalFamilies();
  this.computeTotalVariations();
  if (this._tkffList.length == 0) {
    this._isValid = false;
  }
};

TkWebProjectCardModel.prototype.getDeletedTkffs = function() {
  return this._deletedTkffs;
};

TkWebProjectCardModel.prototype.updateIsValid = function() {
  var isValid = true;
  this._tkffList.forEach(function(tkff) {
    if (tkff.subsetting == CharSetOptionsEnum.CHAR_SET_OPTION_CUSTOM &&
        (!tkff.enabled_language_tags || tkff.enabled_language_tags.length == 0)) {
      isValid = false;
      return true;
    }
  });
  this.setIsValid(isValid);
};

module.exports = TkWebProjectCardModel;
