const freezeframeUtil = require('../../util/freezeframe_util.js');
const UrlService = require('../../util/url_service.js').default;

var _ = require('underscore');

var DEFAULT_RECOMMENDATION_TYPE_KEY = 'all';
var SPECTRUM_BREAKPOINT_MEDIUM_WIDTH = 1280;
var SPECTRUM_BREAKPOINT_SMALL_WIDTH = 768;

var MEDIUM_WIDTH_COLUMN_COUNT = 3;
var SMALL_WIDTH_COLUMN_COUNT = 2;
var XSMALL_WIDTH_COLUMN_COUNT = 1;

var COLORS = ['B82271', 'D5661D', 'BD8B02', '9D2220', '227466', '249EAE', '2464AE', '227442', '60358B', '6B2A52', '9B4E82', '331E8B'];
var EMPTY_STATE_CLASSES = [
  'recommendations-page--empty-card-medium', 'recommendations-page--empty-card-large',
  'recommendations-page--empty-card-medium', 'recommendations-page--empty-card-medium',
  'recommendations-page--empty-card-medium', 'recommendations-page--empty-card-small'
];
var NON_FAMILY_CATEGORIES = ['font_pairs', 'tags', 'fontpacks'];
var NUM_PAGE_SCROLLS_FOR_LOAD_MORE = 4;

/**
 * @ngInject
 */
function RecommendationsController($q,
                                   $scope,
                                   $window,
                                   AuthenticationService,
                                   DataService,
                                   FirstMileService,
                                   FontpackService,
                                   I18nService,
                                   NewrelicService,
                                   NotificationService,
                                   RecommendationsService,
                                   SyncService,
                                   TagsService) {
  this.$q = $q;
  this.AuthenticationService = AuthenticationService;
  this.DataService = DataService;
  this.FirstMileService = FirstMileService;
  this.FontpackService = FontpackService;
  this.I18nService = I18nService;
  this.NewrelicService = NewrelicService;
  this.NotificationService = NotificationService;
  this.RecommendationsService = RecommendationsService;
  this.SyncService = SyncService;
  this.TagsService = TagsService;
  this.$window = $window;

  this._preloadData();

  var self = this;
  $window.addEventListener('af.activation', this.handleActivationEvent.bind(this));
  $window.addEventListener('resize', function() {
    self.updateColumns();
    $scope.$apply();
  });
  $window.addEventListener('beforeunload', function() {
    self.NewrelicService.addPageAction('typekit.recommendations.numLoaded', {
      recommendationCount: (self.recommendations || []).length
    });
  });
}

/**
 * Returns an object that will be used to render the recommendation's display font on the
 * page using the display-in-font directive.
 *
 * @param {Object} recommendation
 * @returns {Object}
 */
RecommendationsController.prototype.getDisplayFont = function(recommendation) {
  var displayFont = recommendation.font || recommendation.display_font;
  var featureSettings = this.textSampleData.textSamples[displayFont.default_language].feature_settings;
  var kanaOnly = this.textSampleData.textSamples[displayFont.default_language].kana_only;
  var unicodeRange = this.textSampleData.textSamples[displayFont.default_language].unicode_range;

  return {
    cssName: displayFont.family.web_id + '-' + displayFont.font.web.fvd,
    fvd: displayFont.font.web.fvd,
    isDynamic: true,
    kanaOnly: kanaOnly,
    opaqueId: displayFont.family.web_id,
    unicodeRange: unicodeRange,
    featureSettings: featureSettings
  };
};

/**
 * Returns example text for the font's default language.
 * To show more variety, sample text for the dropdown menu is chosen at random for languages where it exists.
 *
 * @param {Object} family
 * @return {String}
 */
RecommendationsController.prototype.getExampleText = function(family) {
  if (this.exampleText[family.slug]) {
    return this.exampleText[family.slug];
  }
  if (family.display_font.default_language === 'en') {
    this.exampleText[family.slug] = _.sample(_.pick(this.textSampleData.textSampleOptions, 'fez', 'roger', 'fox'));
  } else if (family.display_font.default_language === 'ja') {
    this.exampleText[family.slug] = _.sample(_.pick(this.textSampleData.textSampleOptions,
      'typeface', 'letters', 'train', 'bamboo', 'typography'));
  } else {
    this.exampleText[family.slug] = this.textSampleData.textSamples[family.display_font.default_language].list;
  }
  return this.exampleText[family.slug];
};

/**
 * Assigns a random color scheme for the family provided
 *
 * @param {Object} family
 * @returns {Object}
 */
RecommendationsController.prototype.getExampleTextStyle = function(family) {
  if (this.styles[family.slug]) {
    return this.styles[family.slug];
  }
  var backgroundColor = _.sample(COLORS);
  this.styles[family.slug] = {
    'background-color': '#' + backgroundColor
  };
  return this.styles[family.slug];
};

/**
 * Find the family in this.recommendations matching the 'data-family-slug' of the event target.
 *
 * @param {Event} event
 */
RecommendationsController.prototype.getFamilyFromActivationEvent = function(event) {
  var slug = event.target.getAttribute('data-family-slug');
  var matchingFamily = _.find(this.recommendations, function(family) {
    return family.slug === slug;
  });
  return matchingFamily;
};

/**
 * Return a unique identifier of the recommendation provided.
 *
 * @return String
 */
RecommendationsController.prototype.getIdentifier = function(recommendation) {
  return recommendation.slug || recommendation.key || recommendation.id.toString();
};

/**
 * Return the recommendation type that should be shown when the page initially loads.
 * This is read from the URL (for example, visiting /recommendations/for_you selects "For You"),
 * and DEFAULT_RECOMMENDATION_TYPE_KEY is returned if no recommendation type is found.
 *
 * @returns {Object}
 */
RecommendationsController.prototype.getInitialRecommendationType = function() {
  var keyToSelect;
  if (this.$window.location.href.indexOf('/recommendations/') >= 0) {
    keyToSelect = this.$window.location.href.split('/recommendations/')[1];
    keyToSelect = keyToSelect.split('/')[0];
  }
  var validKeys = _.map(this.recommendationTypes, function(recommendationType) {
    return recommendationType.key;
  });
  if (!keyToSelect || validKeys.indexOf(keyToSelect) < 0) {
    keyToSelect = DEFAULT_RECOMMENDATION_TYPE_KEY;
  }
  return _.find(this.recommendationTypes, function(recommendationType) {
    return recommendationType.key === keyToSelect;
  });
};

/**
 * Return the type description corresponding to the selected recommendation type
 *
 * @returns {String}
 */
RecommendationsController.prototype.getRecommendationTypeDescription = function() {
  if (!this.selectedRecommendationType) {
    return '';
  }
  return this.I18nService.getMessage(this.i18n, 'recommendations.type_descriptions.' + this.selectedRecommendationType.key);
};

/**
 * Return the subtitle of family specimen cards
 *
 * @param {Object} recommendation
 */
RecommendationsController.prototype.getSpecimenCardSubtitle = function(recommendation) {
  if (recommendation.type !== 'families' || !recommendation.foundry || !recommendation.foundry.name) {
    return '';
  }
  return this.I18nService.getMessage(this.i18n, 'recommendations.family_card_foundry_subheading', {
    foundry: recommendation.foundry.name
  });
};

/**
 * Return the CSS class for the TagCard component background that matches the slug provided
 *
 * @param {String} tagSlug
 * @returns {String}
 */
RecommendationsController.prototype.getTagBackgroundClass = function(tagSlug) {
  return this.TagsService.TAG_BACKGROUND_CLASS_MAP[tagSlug];
};

/**
 * Toggle a class for right-to-left scripts
 *
 * @param {Object} family
 * @returns {String}
 */
RecommendationsController.prototype.getTextDirectionClass = function(family) {
  if (family.display_font.rtl) {
    return 'sample-right';
  }
  return '';
};

/**
 * Handles af.activation event from af-specimen-card Spectrum web component (activation or deactivation
 * of families or font packs).
 *
 * @param {Event} event
 */
RecommendationsController.prototype.handleActivationEvent = function(event) {
  var self = this;
  var fontpackSlug = event.target.getAttribute('data-fontpack-slug');
  if (fontpackSlug) {
    var fontpack = self.FontpackService.findFontpackBySlug(fontpackSlug);
    var actionSource = 'recommendations.' + self.selectedRecommendationType.key;
    if (self.FontpackService.isFontpackSynced(fontpackSlug)) {
      var unsyncFontpack = self.FontpackService.useModelUnsyncFontpack(fontpack, actionSource, self.i18n);
      unsyncFontpack.then(function() {
        self.NewrelicService.addPageAction('typekit.recommendations.deactivateFontpack', {
          recommendationIndex: self._getRecommendationIndexBySlug(fontpackSlug, 'fontpacks')
        });
      });
    } else {
      self.FontpackService.syncFontpack(fontpack, actionSource, self.i18n, true).then(function() {
        self.NewrelicService.addPageAction('typekit.recommendations.activateFontpack', {
          recommendationIndex: self._getRecommendationIndexBySlug(fontpackSlug, 'fontpacks')
        });
      });
    }
  }
  self.handleFamilyActivationSwitchChange(self.getFamilyFromActivationEvent(event));
};

/**
 * Handle authentication and activate or deactivate fonts in the family provided
 *
 * @param {Object} family
 */
RecommendationsController.prototype.handleFamilyActivationSwitchChange = function(family) {
  var self = this;
  if (!family) {
    return;
  }
  self.AuthenticationService.handleAuthenticationWithPostLoginAction(
    'activate_family', {slug: family.slug}
  ).then(function() {
    if (self.isActivated(family)) {
      self.useModelUnsyncFamily(family);
    } else {
      self._activateFamily(family);
    }
  });
};

/**
 * Track recommendation clicks in NewRelic.
 * The index is sent to assess whether more highly-ranked recommendations receive more clicks.
 *
 * @param {Object} recommendation
 */
RecommendationsController.prototype.handleRecommendationClick = function(recommendation) {
  this.NewrelicService.addPageAction('typekit.recommendations.click', {
    recommendationIndex: this.recommendations.indexOf(recommendation),
    recommendationType: recommendation.type
  });
};

/**
 * Are all fonts in the recommendation provided activated?
 *
 * @param {Object} recommendation
 * @returns {Boolean}
 */
RecommendationsController.prototype.isActivated = function(recommendation) {
  if (recommendation.type === 'fontpacks') {
    return this.FontpackService.isFontpackSynced(recommendation.slug);
  }
  return _.all(recommendation.fonts, function(font) {
    return font.font.sync && (font.font.sync.is_selected || _.contains(font.font.sync.install_state.split(','), 'cc'));
  });
};

/**
 * Get all recommendations on the next page
 * @param {Boolean} isLoadMoreClick
 */
RecommendationsController.prototype.loadMoreRecommendations = function(isLoadMoreClick) {
  if (!this.currentPage || !this.selectedRecommendationType) {
    return;
  }
  if (isLoadMoreClick) {
    this.NewrelicService.addPageAction('typekit.recommendations.loadMoreClick');
  }
  this.selectRecommendationType(this.selectedRecommendationType, this.currentPage + 1, isLoadMoreClick);
};

/**
 * Update the page to view only the recommendation type provided.
 *
 * @param {String} recommendationType
 * @param {Number} page
 * @param {Boolean} forceLoad
 */
RecommendationsController.prototype.selectRecommendationType = function(recommendationType, page, forceLoad) {
  var self = this;
  page = page || 1;
  self.recommendationTypeChanged = false;
  if (!self.isInfiniteScrollEnabled && !forceLoad) {
    return;
  }
  if (self.currentPage > 0 && self.currentPage % NUM_PAGE_SCROLLS_FOR_LOAD_MORE === 0 && !forceLoad) {
    self.isInfiniteScrollEnabled = false;
    return;
  }
  // ng-infinite-scroll sends several scroll events, so this check prevents duplicate requests
  if (self.isLoading) {
    return;
  }
  if ((self.selectedRecommendationType || {}).key !== (recommendationType || {}).key) {
    self.recommendationTypeChanged = true;
  }
  if (self.loadedAllRecommendations && !self.recommendationTypeChanged) {
    return;
  }
  self.selectedRecommendationType = recommendationType;
  var path = self.$window.location.pathname.split('/').slice(0, 2).join('/');
  UrlService.updatePath(path + (recommendationType.key === 'all' ? '' : '/' + recommendationType.key));
  self.isLoading = true;
  self.RecommendationsService.get(self.selectedRecommendationType.key, page, self.familiesPerPage, self.isPersonalized).then(function(data) {
    self.isInfiniteScrollEnabled = true;
    if (self.recommendationTypeChanged) {
      self.recommendations = [];
      self.recommendationsByColumn = {};
      self.FontpackService.clearFontpacks();
      self.recommendationTypeChanged = false;
      self.loadedAllRecommendations = false;
      self.NewrelicService.addPageAction('typekit.recommendations.typeSelected', {
        recommendationType: recommendationType.key
      });
    }
    var maxCategoryLength = Math.max.apply(null, _.map(NON_FAMILY_CATEGORIES, function(category) {
      return data.data[category].length;
    }));
    if (maxCategoryLength === 0 && data.data.families.length === 0) {
      self.loadedAllRecommendations = true;
    }
    // Alternate recommendations between families and other categories in NON_FAMILY_CATEGORIES
    // The non-family categories have different heights (for example, font pairs have a larger height than tags),
    // which will result in uneven column heights if they are all placed in the same column, so they are cycled
    // through these using categoriesOffset:
    // ['font_pairs', 'fontpacks', 'tags'] -> ['fontpacks', 'tags', 'font_pairs'] -> ['tags', 'font_pairs', 'fontpacks']
    var familiesIndex = 0;
    var categoriesOffset = 0;
    var categories;
    for (var i = 0; i < maxCategoryLength; i++) {
      categories = NON_FAMILY_CATEGORIES.slice(categoriesOffset, NON_FAMILY_CATEGORIES.length).concat(NON_FAMILY_CATEGORIES.slice(0, categoriesOffset));
      for (var j = 0; j < categories.length; j++) {
        if (data.data[categories[j]][i]) {
          if (data.data.families[familiesIndex]) {
            self.recommendations.push(_.assign(data.data.families[familiesIndex], {type: 'families'}));
            familiesIndex++;
          }
          self.recommendations.push(_.assign(data.data[categories[j]][i], {type: categories[j]}));
        }
      }
      categoriesOffset += 1;
      categoriesOffset %= NON_FAMILY_CATEGORIES.length;
    }
    var additionalFamilies = _.map(data.data.families.slice(familiesIndex, data.data.families.length), function(family) {
      return _.assign(family, {type: 'families'});
    });
    self.recommendations = self.recommendations.concat(additionalFamilies);
    self.recommendations = _.uniq(self.recommendations, self.getIdentifier);
    _.each(self.columns, function(column) {
      self.recommendationsByColumn[column] = self._dataForColumn(self.recommendations, column);
    });
    self.FontpackService.addFontpacks(data.data.fontpacks);
    self.textSampleData = data.data.textSampleData;
    self.currentPage = page;
    self.isLoading = false;
  });
};

/**
 * Data is shown in either one, two, or three columns. The Spectrum grid cannot be used here because items need to
 * stack in a masonry layout.
 *
 * Data must be partitioned into each column so that it reads in the correct order from left to right.
 * The documentation for _dataForColumn has examples describing this.
 *
 * This function is called on resize and is responsible for setting the number of columns and partitioning the data
 * if the number of columns changed.
 *
 */
RecommendationsController.prototype.updateColumns = function() {
  var self = this;
  var prevColumnCount = this.columns ? this.columns.length : null;
  var columnCount;
  if (self.$window.innerWidth >= SPECTRUM_BREAKPOINT_MEDIUM_WIDTH) {
    columnCount = MEDIUM_WIDTH_COLUMN_COUNT;
  } else if (self.$window.innerWidth >= SPECTRUM_BREAKPOINT_SMALL_WIDTH) {
    columnCount = SMALL_WIDTH_COLUMN_COUNT;
  } else {
    columnCount = XSMALL_WIDTH_COLUMN_COUNT;
  }
  self.columns = _.range(columnCount);
  if (columnCount !== prevColumnCount) {
    self.emptyStateClassesByColumn = {};
    self.recommendationsByColumn = {};
    _.each(self.columns, function(column) {
      self.emptyStateClassesByColumn[column] = self._dataForColumn(EMPTY_STATE_CLASSES, column);
      self.recommendationsByColumn[column] = self._dataForColumn(self.recommendations, column);
    });
  }
};

/**
 * Activate all fonts in the family provided
 *
 * @private
 * @param {Object} family
 * @returns {Promise}
 */
RecommendationsController.prototype._activateFamily = function(family) {
  var self = this;
  self.SyncService.addSyncedVariations(_.pluck(family.fonts, 'id')).then(function() {
    family.fonts.forEach(function(font) {
      if (font.font.sync) {
        font.font.sync.install_state = 'cc';
      }
    });
    self.NewrelicService.addPageAction('typekit.recommendations.activateFamily', {
      recommendationIndex: self._getRecommendationIndexBySlug(family.slug, 'families')
    });
    self.FirstMileService.onUseModelFontActivationSuccess(self.i18n, {queryParams: {familyName: family.name}});
  }, function() {
    self.NotificationService.error(self.I18nService.getMessage(self.i18n['neue.browse.family_card'], 'error_occurred_adding_some_fonts'), {
      actionLabel: self.i18n['neue.browse.family_card']['try_again'],
      callbackAction: function() { self._activateFamily(family); }});
  });
};

/**
 * Partition data based on the column count and the column provided.
 * For example, if there were three columns, the data would be split up like this:
 * _dataForColumn(['a', 'b', 'c', 'd', 'e'], 0)
 * => ['a', 'd']
 * _dataForColumn(['a', 'b', 'c', 'd', 'e'], 1)
 * => ['b', 'e']
 * _dataForColumn(['a', 'b', 'c', 'd', 'e'], 2)
 * => ['c']
 *
 * @private
 * @param {Array} data
 * @param {Integer} column
 * @returns {Promise}
 */
RecommendationsController.prototype._dataForColumn = function(data, column) {
  var self = this;
  var columnCount = self.columns.length;
  return _.filter(data, function(item, index) {
    return index % columnCount === column;
  });
};

/** Unsync all given fonts in family after user confirms the selection in the modal dialog
 * @param family {Array}
 */
RecommendationsController.prototype.useModelUnsyncFamily = function(family) {
  var self = this;
  self.FirstMileService.shouldShowRemoveFamilyConfirmationDialog().then(function(shouldShow) {
    if (shouldShow) {
      var header = self.i18n['neue.browse.family_card']['remove_family_confirmation_header'];
      var description = self.i18n['neue.browse.family_card']['remove_family_confirmation_description'];
      return self.FirstMileService.showRemoveFamilyConfirmationDialog(header, description, function() {
        self._deactivateFamily(family);
      });
    } else {
      self._deactivateFamily(family);
    }
  });
};

/**
 * Deactivate all fonts in the family provided
 *
 * @private
 * @param {Object} family
 * @returns {Promise}
 */
RecommendationsController.prototype._deactivateFamily = function(family) {
  var self = this;
  self.SyncService.removeSyncedVariations(_.pluck(family.fonts, 'id')).then(function() {
    family.fonts.forEach(function(font) {
      if (font.font.sync) {
        font.font.sync.is_selected = false;
      }
    });
    self.NewrelicService.addPageAction('typekit.recommendations.deactivateFamily', {
      recommendationIndex: self._getRecommendationIndexBySlug(family.slug, 'families')
    });
    self.NotificationService.notice(self.i18n['neue.browse.family_card']['fonts_removed_success_message']);
  }, function() {
    self.NotificationService.error(self.i18n['neue.browse.family_card']['error_occurred_removing_fonts'], {
      actionLabel: self.i18n['neue.browse.family_card']['try_again'],
      callbackAction: function() { self._deactivateFamily(family); }});
  });
};

/**
 * Get the index of the recommendation with the slug and type provided
 *
 * @private
 * @param {String} slug
 * @param {String} type
 * @returns {Number}
 */
RecommendationsController.prototype._getRecommendationIndexBySlug = function(slug, type) {
  return _.findIndex(this.recommendations, {slug: slug, type: type});
};

/**
 * Preload data using DataService
 *
 * @private
 * @returns {Promise}
 */
RecommendationsController.prototype._preloadData = function() {
  var self = this;
  self.currentPage = 0;
  self.exampleText = {};
  self.isInfiniteScrollEnabled = true;
  self.emptyStateClassesByColumn = {};
  self.loadedAllRecommendations = false;
  self.recommendationsByColumn = {};
  self.recommendationTypeChanged = false;
  self.styles = {};
  self.currentPage = null;
  self.selectedRecommendationType = null;
  self.updateColumns();

  return self.$q.all([
    self.DataService.get('/recommendations/preloaded').then(function(data) {
      self.familiesPerPage = data.familiesPerPage;
      self.isPersonalized = data.isPersonalized;
      self.recommendationTypes = data.recommendationTypes;
      self.textSampleData = data.textSampleData;

      self.selectRecommendationType(self.getInitialRecommendationType(), 1, false);
    }),
    self.DataService.get('/recommendations/i18n').then(function(data) {
      self.i18n = data;
    })
  ]);
};

/**
 * Check if there is a still image to show
 *
 * @private
 * @returns {Boolean}
 */
RecommendationsController.prototype.showSpecimen = function(recommendation) {
  return this.showSpecimenImageUrl(recommendation) !== '';
};


/**
 * Returns the first still image url if it exists
 *
 * @private
 * @returns {String}
 */
RecommendationsController.prototype.showSpecimenImageUrl = function(recommendation) {
  return freezeframeUtil.getStillImageUrl(recommendation.specimen_images_data);
};

module.exports = RecommendationsController;
