/**
 * A directive for font style selector in web project editor.
 *
 * To implement this directive, follow this example:
 *
 * In your template:
 *
 * <tk-web-project-editor-style-selector
 *    tk-web-project-card-model="controller.tkWebProjectCardModel"
 *    tk-web-project-editor-style-selector-model="controller.tkWebProjectEditorStyleSelectorModel"
 *    tk-web-project-family-card-model="controller.tkWebProjectFamilyCardModel">
 * </tk-web-project-editor-style-selector>
 *
 * In your Javascript Controller:
 *
 * - tkWebProjectCardModel: The model which contains the details of the web project/kit.
 * - tkWebProjectEditorStyleSelectorModel: The model which the list of font variations and the data required for displaying it.
 * - tkWebProjectFamilyCardModel: The model which contains the details of the tkff.
 *
 * @ngInject
 */
function Directive() {
  return {
    restrict: 'E',
    templateUrl: '/angular_templates/directives/tk_web_project_editor_style_selector.html',
    scope: {},
    bindToController: {
      tkWebProjectCardModel: '=',
      tkWebProjectEditorStyleSelectorModel: '=',
      tkWebProjectFamilyCardModel: '='
    },
    controller: require('./controller.js'),
    controllerAs: 'tkWebProjectEditorStyleSelectorCtrl'
  };
}

module.exports = Directive;
