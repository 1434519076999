const PAGE_CHANGE_EVENT = 'pagination-change';
/**
 * Controller for the adobe-fonts-font-variation-list directive.
 * @ngInject
 */
function AdobeFontsFontVariationListController($element, $scope, $timeout, FontLoaderService) {
  var self = this;
  self.viewType = 'list';
  self.FontLoaderService = FontLoaderService;
  self.currentPage = 1;
  self.fontsPerPage = 12;
  self.$element = $element;
  self.$scope = $scope;
  self.$timeout = $timeout;
  $scope.$watch(function () {
    return self.fonts;
  }, function(newVal, oldVal) {
    if (newVal !== oldVal) {
      self.totalPages = Math.ceil(self.fonts.length / self.fontsPerPage);
      self.handlePageNumberChange();
    }
  });
  self.$element.on(PAGE_CHANGE_EVENT, function(event) {
    self.currentPage = event.detail.page;
    self.handlePageNumberChange();
  });
}

/**
 * The Class to use for the font variation card list
 * @return {String}
 */
AdobeFontsFontVariationListController.prototype.fontVariationCardsClass = function() {
  return this.viewType === 'grid' ? 'font-variations-grid-view' : 'font-variation-list';
};

AdobeFontsFontVariationListController.prototype.updateFonts = function() {
  this.FontLoaderService.updateFonts(this.exampleText.value);
};

AdobeFontsFontVariationListController.prototype.familyLink = function(variation) {
  return '/fonts/' + variation.family.slug;
};

AdobeFontsFontVariationListController.prototype.handlePageNumberChange = function() {
  var self = this;
  var startIndex = self.currentPage * self.fontsPerPage - self.fontsPerPage;
  var endIndex = self.currentPage * self.fontsPerPage;
  self.visibleFonts = self.fonts.slice(startIndex, endIndex);
  self.$timeout(function() {
    self.$scope.$apply();
  }, 1);
};

module.exports = AdobeFontsFontVariationListController;
