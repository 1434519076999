var angular = require('angular');
var _ = require('underscore');

var DEBOUNCE_MS = 300;
var CLASS_RESULT_LINK_SELECTOR = '.adobe-fonts-auto-complete__item a';
var CLASS_SEARCH_INPUT = 'adobe-fonts-auto-complete__search-field';

var KEY_CODE_ARROW_DOWN = 40;
var KEY_CODE_ARROW_UP = 38;
var KEY_CODE_ESC = 27;

var NAVIGATION_KEY_CODES = [
  KEY_CODE_ARROW_DOWN,
  KEY_CODE_ARROW_UP,
  KEY_CODE_ESC
];

/**
 * @ngInject
 */
function AdobeFontsAutoCompleteDirectiveController($document, $scope, $timeout) {
  this.$document = $document;
  this.$scope = $scope;
  this.$timeout = $timeout;

  this.handleInputKeyup = _.debounce(
    _.bind(this._handleInputKeyup, this), DEBOUNCE_MS);

  this.isResultListVisible = false;
  this.results = [];
  this._selectedIndex = -1;

  this._initBodyEvents();
}

/**
 * Handles keydown events on the directive wrapper element.
 * Keydown events are used to handle navigation key events (arrow up, arrow
 * down, etc), so that holding down a key will continuously trigger this handler.
 *
 * @listens Event
 * @param {Event} event
 */
AdobeFontsAutoCompleteDirectiveController.prototype.handleElementKeyDown = function(event) {
  if (this._isNavigationKeyEvent(event) && this._hasResults()) {
    this._handleNavigationEvent(event);
  }
};

/**
 * Handles focus events on the search result elements.
 *
 * @listens Event
 * @param {Number} index
 */
AdobeFontsAutoCompleteDirectiveController.prototype.handleResultFocus = function(index) {
  this._selectedIndex = index;
};

/**
 * Returns hyperlink text for given search result item
 * @param resultItem
 * @return {String}
 */
AdobeFontsAutoCompleteDirectiveController.prototype.getSearchResultItemLink = function(resultItem) {
  return this.autocompleteService.getItemHyperlink(resultItem);
};

/**
 * Get the list of search result elements.
 *
 * @private
 * @returns {Element[]}
 */
AdobeFontsAutoCompleteDirectiveController.prototype._getCurrentResultElements = function() {
  return this.element.querySelectorAll(CLASS_RESULT_LINK_SELECTOR);
};

/**
 * Results the search input element.
 *
 * @private
 * @returns {Element}
 */
AdobeFontsAutoCompleteDirectiveController.prototype._getInput = function() {
  return this.element.getElementsByClassName(CLASS_SEARCH_INPUT)[0];
};

/**
 * Handles navigation key events.
 *
 * @private
 * @param {Event} event
 */
AdobeFontsAutoCompleteDirectiveController.prototype._handleNavigationEvent = function(event) {
  if (event.keyCode == KEY_CODE_ESC) {
    this.isResultListVisible = false;
    this.results = [];
    this._selectedIndex = -1;
    this._getInput().focus();
    return;
  }

  event.preventDefault();
  if (event.keyCode == KEY_CODE_ARROW_DOWN) {
    this._selectedIndex = this._selectedIndex + 1 < this.results.length ?
                          this._selectedIndex + 1 : 0;

  } else if (event.keyCode == KEY_CODE_ARROW_UP) {
    this._selectedIndex = this._selectedIndex > 0 ?
                          this._selectedIndex - 1 : this.results.length - 1;
  }

  this._getCurrentResultElements()[this._selectedIndex].focus();
};

/**
 * Handles keyup events from the search input.
 * This method is not meant to be used directly, rather a debounced version will
 * be used to prevent excessive request to the backend.
 *
 * @private
 * @listens Event
 */
AdobeFontsAutoCompleteDirectiveController.prototype._handleInputKeyup = function(event) {
  var self = this;
  if (self._isNavigationKeyEvent(event)) {
    return;
  }

  self._selectedIndex = -1;
  self.autocompleteService.get(self.searchTerm).then(function(response) {
    // When <adobe-fonts-auto-complete> was without form tag, show/hide behaviour of result view was inconsistent
    // Using $scope.$apply causes duplicate digest cycle when used in form tag. So using $timeout here
    // which seems to work in all cases - Ram
    self.$timeout(function() {
      self.results = response.data;
      self.isResultListVisible = self.results.length > 0;
    });
  });
};

/**
 * Does the autocomplete list have any results?
 *
 * @private
 * @returns {Boolean}
 */
AdobeFontsAutoCompleteDirectiveController.prototype._hasResults = function() {
  return this.results.length > 0;
};

/**
 * Initializes body listener events that will be used to set the visibilty of
 * the result list.
 * @private
 */
AdobeFontsAutoCompleteDirectiveController.prototype._initBodyEvents = function() {
  var self = this;
  angular.element(self.$document[0].body).on('click focus keyup', function(event) {
    // If the target of the event is not a child of the directive, hide the result list.
    if (!self._isChildOfDirective(event.target)) {
      self.$scope.$apply(function() {
        self.isResultListVisible = false;
      });
    }
  });
};

/**
 * Returns true if the element is a child of the directive's wrapper element, or if the
 * element itself is the directive's wrapper element.
 *
 * @private
 * @param {Element} element
 * @returns {Boolean}
 */
AdobeFontsAutoCompleteDirectiveController.prototype._isChildOfDirective = function(element) {
  return element == this.element ||
         element.parentNode == this.element ||
         (element.parentNode && this._isChildOfDirective(element.parentNode));
};

/**
 * Is the keycode for this event a navigation key?
 *
 * @param {Event} event
 * @returns {Boolean}
 */
AdobeFontsAutoCompleteDirectiveController.prototype._isNavigationKeyEvent = function(event) {
  return _.contains(NAVIGATION_KEY_CODES, event.keyCode);
};

module.exports = AdobeFontsAutoCompleteDirectiveController;
