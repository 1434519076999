var DEFAULT_PAGE = 1;
var DEFAULT_SORT_ORDER = 'last_updated';

/**
 * A class that returns data for all the kits
 *
 * @constructor
 * @params {webProjectList} List of kits/web projects in the user's account.
 * @ngInject
 */
function TkWebProjectListModel(webProjectListResponse) {
  this._webProjectList = webProjectListResponse.kitsData;
  this._totalPages = webProjectListResponse.totalPages;
  this.currentPage = DEFAULT_PAGE;
  this._sortOrder = DEFAULT_SORT_ORDER;
}

TkWebProjectListModel.prototype.getWebProjectList = function() {
  return this._webProjectList;
};

TkWebProjectListModel.prototype.getTotalPages = function() {
  return this._totalPages;
};

TkWebProjectListModel.prototype.setWebProjectList = function(webProjectList) {
  this._webProjectList = webProjectList;
};

TkWebProjectListModel.prototype.setTotalPages = function(totalPages) {
  this._totalPages = totalPages;
};

/**
 * Sets the current page number.
 * @param {Number} currentPage
 */
TkWebProjectListModel.prototype.setCurrentPage = function(pageNumber) {
  this.currentPage = pageNumber;
};

TkWebProjectListModel.prototype.deleteWebProject = function(webProject) {
  var index = this._webProjectList.indexOf(webProject);
  if (index > -1) {
    this._webProjectList.splice(index, 1);
  }
};

/**
 * Returns the current sort order.
 * @returns {String}
 */
TkWebProjectListModel.prototype.getSortOrder = function() {
  return this._sortOrder;
};

/**
 * Sets the current sort order.
 * @param {String} sortOrder
 */
TkWebProjectListModel.prototype.setSortOrder = function(sortOrder) {
  this._sortOrder = sortOrder;
  this.setCurrentPage(DEFAULT_PAGE);
};

module.exports = TkWebProjectListModel;
