/**
 * A service used to retrieve recommendations from the backend.
 * @ngInject
 */
function RecommendationsService($http, $q) {
  return {
    /**
     * Get recommendations matching the type provided
     *
     * @param {String} type of recommendation
     * @param {Number} page
     * @param {Number} familiesPerPage
     * @param {Boolean} isPersonalized
     * @return {Promise}
     */
    get: function(type, page, familiesPerPage, isPersonalized) {
      var self = this;
      self.cache = self.cache || {};
      self.cache[type] = self.cache[type] || {};
      if (self.cache[type][page]) {
        return $q(function(resolve) {
          resolve(self.cache[type][page]);
        });
      }
      var queryString = '?page=' + page + '&families_per_page=' + familiesPerPage + '&personalized=' + isPersonalized;
      return $http.get('/recommendations/' + type + queryString).then(function(response) {
        self.cache[type][page] = response;
        return response;
      });
    },

    /**
     * Get recommendations matching the font variation provided
     *
     * @param {String} fontVariationId of font to get pairs for
     * @param {Number} perPage number of results per page
     * @param {Number} page of results for pagination
     * @return {Promise}
     */
    pairedFonts: function (fontVariationId, perPage, page) {
      return $http.get('/font_recommendations/paired_fonts?font_variation_id=' + fontVariationId + '&per_page=' + perPage + '&page=' + page);
    },

    /**
     * Get similar fonts matching the font variation provided
     *
     * @param {String} fontVariationId of font to get similar fonts for
     * @param {Number} perPage number of results per page
     * @param {Number} page of results for pagination
     * @return {Promise}
     */
    similarFonts: function (fontVariationId, perPage, page) {
      return $http.get('/font_recommendations/similar_fonts?font_variation_id=' + fontVariationId + '&per_page=' + perPage + '&page=' + page);
    },

    /**
     * Get fonts pairs matching the font family provided
     *
     * @param {String} slug of font family to get pairs for
     * @param {Number} perPage number of results per page
     * @param {Number} page of results for pagination
     * @return {Promise}
     */
    familyFontPairings: function (slug, perPage, page) {
      return $http.get('/font_recommendations/family_font_pairings?slug=' + slug + '&per_page=' + perPage + '&page=' + page);
    },

    /**
     * Get similar fonts matching the font family provided
     *
     * @param {String} slug of font family to get similar fonts for
     * @param {Number} perPage number of results per page
     * @param {Number} page of results for pagination
     * @return {Promise}
     */
    familyFontSimilarities: function (slug, perPage, page) {
      return $http.get('/font_recommendations/family_font_similarities?slug=' + slug + '&per_page=' + perPage + '&page=' + page);
    }
  };
}

module.exports = RecommendationsService;
