var DEFAULT_FONT_SIZE_FOR_EXAMPLE_TEXT = 36;
/**
 * Controller for the font-preview directive.
 * @ngInject
 */
function FontPreviewController() {
  this.exampleText = {
    size: DEFAULT_FONT_SIZE_FOR_EXAMPLE_TEXT
  };
}

FontPreviewController.prototype.getExampleTextStyle = function () {
  return {
    fontSize: this.exampleText.size + 'px'
  };
};

FontPreviewController.prototype.getExampleText = function() {
  var textSample = this.textSamples()[this.font.default_language];
  this.exampleText.value = textSample.kana_only ? textSample.list_kana : textSample.list;
  return this.exampleText;
};


FontPreviewController.prototype.getFontDisplayData = function() {
  var textSamples = this.textSamples();
  var unicodeRange = textSamples[this.font.default_language].unicode_range;
  var featureSettings = textSamples[this.font.default_language].feature_settings;
  var kanaOnly = textSamples[this.font.default_language].kana_only || false;
  return {
    cssName: this.font.family.web_id + '-' + this.font.font.web.fvd,
    fvd: this.font.font.web.fvd,
    isDynamic: true,
    opaqueId: this.font.family.web_id,
    unicodeRange: unicodeRange,
    featureSettings: featureSettings,
    kanaOnly: kanaOnly
  };
};

FontPreviewController.prototype.getTextDirectionClass = function() {
  return this.font.rtl ? 'sample-right' : '';
};

FontPreviewController.prototype.handlePreviousButtonClick = function() {
  this.onPrevious();
};

FontPreviewController.prototype.handleNextButtonClick = function() {
  this.onNext();
};

module.exports = FontPreviewController;
