const _ = require('underscore');
const freezeframeUtil = require('../../../util/freezeframe_util.js');
const { sendFavoriteButtonAnalytics } = require('../../../components/mixins/FamilyCardFavorite.js');
const DEFAULT_FONT_SIZE_FOR_EXAMPLE_TEXT = 36;

const { toLibraryElement } = require('../../../util/libraries.js').default;

/**
 * @ngInject
 */
function FamilyCardDirectiveController($element,
                                       $timeout,
                                       $window,
                                       AddFontsToWebProjectService,
                                       AuthenticationService,
                                       DataService,
                                       DialogService,
                                       FavoritesService,
                                       FirstMileService,
                                       FontCountService,
                                       FontLoaderService,
                                       NewrelicService,
                                       NotificationService,
                                       FamiliesService,
                                       RedirectContextService,
                                       SyncService) {
  var self = this;

  self.$element = $element;
  self.$timeout = $timeout;
  self.$window = $window;
  self.AddFontsToWebProjectService = AddFontsToWebProjectService;
  self.AuthenticationService = AuthenticationService;
  self.DataService = DataService;
  self.DialogService = DialogService;
  self.FamiliesService = FamiliesService;
  self.FavoritesService = FavoritesService;
  self.FontCountService = FontCountService;
  self.FontLoaderService = FontLoaderService;
  self.FirstMileService = FirstMileService;
  self.NewrelicService = NewrelicService;
  self.NotificationService = NotificationService;
  self.RedirectContextService = RedirectContextService;
  self.SyncService = SyncService;

  self.DataService.get('/neue/i18n').then(function(i18n) {
    self.i18n = i18n;
  });

  self._handleContext();
}

/**
 * Returns true if at least some variations in the family are available for web use by
 * an already-authenticated user or true if no user is authenticated given entitlements can't be checked.
 *
 * If a user is logged out, required_actions are empty. So, this method will return true to show web use
 * as "available" until a user logs in and actual entitlements can be calculated.
 *
 * @returns Boolean
 */
FamilyCardDirectiveController.prototype.isAvailableForWeb = function() {
  return _.some(this.family.fonts, function (v) {
    var ra = ((v.font || {}).web || {}).required_action;
    return !!ra && _.isEmpty(ra);
  });
};

/**
 * Returns the specified card type for each family or the default 'responsive'
 *
 * @returns String
 */
FamilyCardDirectiveController.prototype.getCardType = function () {
  return this.cardType || 'responsive';
};

/**
 * Returns an object that will be used to render the family card's font on the
 * page using the display-in-font directive.
 *
 * @returns {}
 */
FamilyCardDirectiveController.prototype.getDisplayFont = function() {
  var textSamples = this.textSamples();

  var unicodeRange = textSamples[this.family.display_font.default_language].unicode_range;
  var featureSettings = textSamples[this.family.display_font.default_language].feature_settings;

  return {
    cssName: this.family.display_font.family.web_id + '-' + this.family.display_font.font.web.fvd,
    fvd: this.family.display_font.font.web.fvd,
    isDynamic: true,
    opaqueId: this.family.display_font.family.web_id,
    unicodeRange: unicodeRange,
    featureSettings: featureSettings
  };
};

FamilyCardDirectiveController.prototype.getTemplates = function() {
  return this.familyTemplates() && this.familyTemplates().slice(0, 1);
};

/**
 * Returns the CSS classes that should be added to the card's sample text.
 * @returns String
 */
FamilyCardDirectiveController.prototype.getSampleTextClass = function() {
  var classes = [];

  if (this.family.display_font.rtl) {
    classes.push('sample-right');
  }

  if (this.noBackground()) {
    classes.push('adobe-fonts-family-card__no-background');
  }

  return classes.join(' ');
};

/**
 * Returns the current class for the variation card
 *
 * @returns String
 */
FamilyCardDirectiveController.prototype.getTextDirectionClass = function() {
  if (this.family.display_font.rtl) {
    return 'sample-right';
  }
  return '';
};

/**
 * Returns the CSS Class for the carousel depending on the size of the items
 *
 * @returns String
 */
FamilyCardDirectiveController.prototype.getCarouselListClass = function() {
  return this.family.specimen_images_data.length > 2 ? 'adobe-fonts-family-card__carousel-list' : '';
};

/**
 * Method to get the slides to display for grid view.
 * Apart from the images, a text slide will also be shown at the end.
 *
 * @returns String
 */
FamilyCardDirectiveController.prototype.getSlidesForGrid = function() {
  var imageArray = [];
  if (this.family.specimen_images_data && this.family.specimen_images_data.length > 0) {
    imageArray = this.family.specimen_images_data.slice(0);
    imageArray.push('TextSlide');
  }
  return imageArray;
};

/**
 * Method to indidcate if the slide is a text slide.
 *
 * @param slide
 * @returns {boolean}
 */
FamilyCardDirectiveController.prototype.isTextSlide = function(slide) {
  return slide === 'TextSlide';
};

/**
 * Method to get the length of slides in grid view
 * It'll be one more than the number of images
 *
 * @returns {number}
 */
FamilyCardDirectiveController.prototype.getSlideLengthForGrid = function() {
  if (this.family.specimen_images_data && this.family.specimen_images_data.length > 0) {
    return this.family.specimen_images_data.length + 1;
  }
  return 0;
};

/**
 * Returns the current value of the example text for the card.
 *
 * @return {String}
 */
FamilyCardDirectiveController.prototype.getExampleText = function() {
  var viewType = 'list';
  if (this.family.filters != null && this.family.filters.indexOf('ka:ko') >= 0) {
    viewType += '_kana';
  }

  var exampleText = this.exampleText();
  var returnText = exampleText && exampleText.value ? exampleText.value : this.textSamples()[this.family.display_font.default_language][viewType];
  this.FontLoaderService.updateFonts(returnText);
  return returnText;
};

/**
 * Returns an array of count messages
 *
 * @return {Array}
 */
FamilyCardDirectiveController.prototype.getCountMessages = function() {
  return this.family.card_state.count_messages.join(' • ');
};

/**
 * Returns a family card sync message with the count placeholder filled in.
 *
 * @param {String} message the message id
 * @param {Number} count
 * @return {String}
 */
FamilyCardDirectiveController.prototype.getMessage = function(message, count) {
  var value = count === 1 ? 'one' : 'other';
  return this.i18n['neue.browse.family_card'][message][value].replace('%{count}', count);
};

/**
 * Returns a boolean representing if the carousel is visible or not
 *
 * @return {Boolean}
 */
FamilyCardDirectiveController.prototype.isCarouselVisible = function() {
  return this.family.specimen_images_data && this.family.specimen_images_data.length > 0 && this.showCarousel === 'true';
};

/**
 * Returns class for card info when the carousel and images are visible
 *
 * @return {string}
 */
FamilyCardDirectiveController.prototype.getCardInfoClass = function() {
  if (this.showCarousel === 'true' && (!this.family.specimen_images_data || this.family.specimen_images_data.length === 0)) {
    return 'adobe-fonts-family-card__info_with_image';
  }
};

/**
 * Returns class for grid card container when the carousel is visible
 *
 * @return {string}
 */
FamilyCardDirectiveController.prototype.getCardContainerClass = function() {
  return this.isCarouselVisible() ? 'adobe-fonts-family-card--grid-view-with-carousel' : 'adobe-fonts-family-card--grid-view';
};

/**
 * Return the fields are documented on https://wiki.corp.adobe.com/pages/viewpage.action?spaceKey=ccexperience&title=Font,
 * along with any additional fields needed for the component to render.
 *
 * @return {Array}
 */
FamilyCardDirectiveController.prototype.getLibraryElements = function() {
  return this.family.fonts.map((font) => toLibraryElement(font, this.textSamples()));
};

/**
 * Send the selected tag to New Relic when clicking on a family card
 */
FamilyCardDirectiveController.prototype.handleFamilyCardClick = function() {
  this.NewrelicService.addPageAction('typekit.family_card.click', {
    selectedTag: this.selectedTag(),
    slug: this.family.slug
  });
};

/**
 * Does the family have any variable fonts?
 *
 * @return {Boolean}
 */
FamilyCardDirectiveController.prototype.hasVariableFonts = function() {
  return _.any(this.family.fonts, function(font) {
    return font.is_variable_font;
  });
};

/**
 * Returns a family card sync message with the count placeholder filled in.
 *
 * @param {String} message the message id
 * @param {Number} count
 * @return {String}
 */
FamilyCardDirectiveController.prototype.hasUnsyncedFontpacks = function() {
  return !!this.family.unsynced_fontpacks && this.family.unsynced_fontpacks.length > 0;
};

/**
 * Returns true if toggle control should be on
 *
 * @return {Boolean}
 */
FamilyCardDirectiveController.prototype.isToggleOn = function() {
  return this.family.card_state.toggle_control && this.family.card_state.toggle_control.value === true;
};

/**
 * Returns true if toggle control should be show
 *
 * @return {Boolean}
 */
FamilyCardDirectiveController.prototype.showToggle = function() {
  return this.family.card_state.toggle_control && this.family.card_state.toggle_control.value !== null;
};

/**
 * Returns toggle control message
 *
 * @return {String}
 */
FamilyCardDirectiveController.prototype.toggleMessage = function() {
  if (this.family.card_state.toggle_control) {
    return this.family.card_state.toggle_control.message;
  }
};

/**
 * Returns the current styling for the example text.
 *
 * @returns {
 *   fontSize: {String} A font size in pixels
 * }
 */
FamilyCardDirectiveController.prototype.getExampleTextStyle = function () {
  var exampleText = this.exampleText();
  return {
    fontSize: (exampleText && exampleText.size ? exampleText.size : DEFAULT_FONT_SIZE_FOR_EXAMPLE_TEXT) + 'px'
  };
};

/**
 * Returns the total number of fonts.
 * @returns {Number}
 */
FamilyCardDirectiveController.prototype.getFontCount = function() {
  return this.family.total_font_count;
};

/**
 * All the syncable fonts available for the family
 *
 * @return {Array}
 */
FamilyCardDirectiveController.prototype.syncableFonts = function() {
  return _.filter(this.family.fonts, function (f) {
    return 'sync' in f.font && f.font.sync['required_action'].length === 0;
  });
};

/** Determines whether 'Add Family' button should be shown
 *
 * @returns {Boolean}
 */
FamilyCardDirectiveController.prototype.isAddFamilyButtonAvailable = function() {
  return !this.isAllSynced(this.syncableFonts());
};

/** Is the Add Family button for Use Model v2 visible
 *
 * @returns {Boolean}
 */
FamilyCardDirectiveController.prototype.isUseModelQuickMultiSyncAvailable = function() {
  return this.syncableFonts().length > 0 ;
};

/** Determines whether 'Remove' button should be shown
 *
 * @returns {Boolean}
 */
FamilyCardDirectiveController.prototype.isRemoveButtonAvailable = function() {
  return this.isAllSynced(this.syncableFonts());
};

/**
 * Are all fonts in the given set of fonts currently synced?
 * @param fonts {Array}
 *
 * @return {Boolean}
 */
FamilyCardDirectiveController.prototype.isAllSynced = function (fonts) {
  var numSynced = this.syncedFonts(fonts).length;
  return numSynced > 0 && (numSynced === fonts.length);
};


/** All fonts in given font set that are available for sync AND currently synced
 * @param fonts {Array}
 *
 * @returns {Array}
 */
FamilyCardDirectiveController.prototype.syncedFonts = function(fonts) {
  return _.filter(fonts, function(f) {
    return 'sync' in f.font && (f.font.sync['is_selected'] || (f.font.sync.install_state.includes('os') || f.font.sync.install_state.includes('cc')));
  });
};

/**
 * Toggle the favorite attribute for this given family
 *
 * @return Nothing
 */
FamilyCardDirectiveController.prototype.favoriteToggle = async function($event) {
  $event.preventDefault();
  var self = this;
  const slug = this.family.slug;
  const familyName = this.family.name;
  await this.AuthenticationService.handleAuthenticationWithPostLoginAction('favorite_fonts', { familySlug: slug });
  if (this.family.favorite) {
    self.FavoritesService.delete(slug).then(function() {
      self.family.favorite = false;
      sendFavoriteButtonAnalytics(slug, familyName, self.family.favorite);
    }, function(response) {
      self.NotificationService.error(response.data.error);
    });

  } else {
    self.FavoritesService.post(slug).then(function() {
      self.family.favorite = true;
      sendFavoriteButtonAnalytics(slug, familyName, self.family.favorite);
    }, function(response) {
      self.NotificationService.error(response.data.error);
    });
  }
};

/**
 * Handle the click on code button for the given family
 */
FamilyCardDirectiveController.prototype.handleCodeButtonClick = function($event) {
  if ($event) {
    $event.preventDefault();
  }

  this.AddFontsToWebProjectService.addFamilyToWebProjectWithAuthentication(this.family.slug);
};

/** Either syncs or unsyncs a given list of fonts for a family depending on whether all fonts in the family
 *  are currently synced. If the list of fonts contains some fonts that are not synced, the remaining will be synced.
 *  Otherwise, all fonts will be unsynced.
 *
 */
FamilyCardDirectiveController.prototype.toggleFamilySync = function(event) {
  if (event) {
    event.preventDefault();
  }

  if (!this.showToggle()) {
    return;
  }

  if (this.isToggleOn()) {
    this.unsyncFamily();
  } else {
    this.syncFamily();
  }
};

FamilyCardDirectiveController.prototype.useModelSyncFamily = function() {
  this.syncFamily(true);
};

FamilyCardDirectiveController.prototype.syncFamily = function(isUseModelEnabled = false) {
  var self = this;

  self.AuthenticationService.handleAuthenticationWithPostLoginAction('activate_family', {slug: self.family.slug}).then(function() {
    self.NewrelicService.addPageAction('typekit.click.sync-family-button', {family: self.family.name, useModelEnabled: isUseModelEnabled});

    var fontIdsToSync = _.pluck(self._deactivatedAvailableDesktopFonts(), 'id');
    self.SyncService.addSyncedVariations(fontIdsToSync).then(function(response) {
      var fontsIdsNotSynced = _.difference(fontIdsToSync, response.data['added_font_ids']);
      var errorOccurred = fontsIdsNotSynced.length > 0;
      if (errorOccurred) {
        if (isUseModelEnabled) {
          self.NotificationService.error(
              self.i18n['neue.browse.family_card']['error_occurred_adding_some_fonts'], {
                actionLabel: self.i18n['neue.browse.family_card']['try_again'],
                callbackAction: function() { self.syncFamily(isUseModelEnabled); }});
        } else {
          self.NotificationService.error(
              self.i18n['neue.browse.family_card']['error_occurred_activating_some_fonts']);
        }
      } else if (isUseModelEnabled) {
        self._handleUseModelFamilySyncSuccess(response);
      } else {
        self._handleFamilySyncSuccess(response);
      }
    }, function() {
      if (isUseModelEnabled) {
        self.NotificationService.error(
            self.i18n['neue.browse.family_card']['error_occurred_adding_some_fonts'], {
              actionLabel: self.i18n['neue.browse.family_card']['try_again'],
              callbackAction: function() { self.syncFamily(isUseModelEnabled); }});
      } else {
        self.NotificationService.error(
            self.i18n['neue.browse.family_card']['error_occurred_activating_some_fonts']);
      }
    });
  });
};

/** Unsync all given fonts in the family after user confirms the selection in the modal dialog
 */
FamilyCardDirectiveController.prototype.useModelUnsyncFamily = function() {
  var self = this;
  self.FirstMileService.shouldShowRemoveFamilyConfirmationDialog().then(function(shouldShow) {
    if (shouldShow) {
      var header = self.i18n['neue.browse.family_card']['remove_family_confirmation_header'];
      var description = self.i18n['neue.browse.family_card']['remove_family_confirmation_description'];
      return self.FirstMileService.showRemoveFamilyConfirmationDialog(header, description, function() {
        self.useModelRemoveFamily();
      });
    } else {
      self.useModelRemoveFamily();
    }
  });
};

/** Unsync all given fonts when use model v2 is enabled
 */
FamilyCardDirectiveController.prototype.useModelRemoveFamily = function() {
  var self = this;
  self.NewrelicService.addPageAction('typekit.click.unsync-family-button', {family: self.family.name, useModelEnabled: true});
  var fontsToUnsync = _.pluck(self._activatedDesktopFonts(), 'id');

  self.SyncService.removeSyncedVariations(fontsToUnsync).then(function(response) {
    var diff = _.difference(fontsToUnsync, response.data['removed_font_ids']);
    var errorOccurred = diff.length > 0;

    if (errorOccurred) {
      self.NotificationService.error(self.i18n['neue.browse.family_card']['error_occurred_removing_some_fonts'], {
        actionLabel: self.i18n['neue.browse.family_card']['try_again'],
        callbackAction: function() { self.useModelRemoveFamily(); } });
    } else {
      self._markFontsAsDeactivated();
      self.FontCountService.updateActivatedFontsCount(-response.data.removed_font_ids.length);

      self.NotificationService.notice(self.i18n['neue.browse.family_card']['fonts_removed_success_message']);
    }
  }, function() {
    self.NotificationService.error(self.i18n['neue.browse.family_card']['error_occurred_removing_fonts'], {
      actionLabel: self.i18n['neue.browse.family_card']['try_again'],
      callbackAction: function() { self.useModelRemoveFamily(); } });
  });
};

/** Unsync all given fonts
 */
FamilyCardDirectiveController.prototype.unsyncFamily = function() {
  var self = this;
  self.NewrelicService.addPageAction('typekit.click.unsync-family-button', {family: self.family.name, useModelEnabled: false});

  var fontsToUnsync = _.pluck(self._activatedDesktopFonts(), 'id');

  self.SyncService.removeSyncedVariations(fontsToUnsync).then(function(response) {
    var diff = _.difference(fontsToUnsync, response.data['removed_font_ids']);

    var errorOccurred = diff.length > 0;

    if (errorOccurred) {
      self.NotificationService.error(self.i18n['neue.browse.family_card']['error_occurred_deactivating_some_fonts']);
    } else {
      self._markFontsAsDeactivated();

      self._updateToggleControl(false, self.i18n['neue.browse.family_card']['activate_fonts']);
      self.FontCountService.updateActivatedFontsCount(-response.data.removed_font_ids.length);

      self.NotificationService.success(self.getMessage('fonts_deactivated_action', response.data['removed_font_ids'].length));
    }
  }, function() {
    self.NotificationService.error(self.i18n['neue.browse.family_card']['error_occurred_deactivating_fonts']);
  });
};

/**
 * We can't directly render the CCX specimen cards within Angular because the attributes
 * for the specimen cards need to be objects, instead of just text strings.
 *
 * To get around this problem, the directive watches the vlue of `familyTemplates()` and
 * whenever it changes, we look for a the `ccx-speciment-card-container` elements within
 * the element and populate them with `af-ccx-specimen-card` components that we create
 * programmatically.
 *
 * This is somewhat of a hack, and it might be slightly cleaner to just put empty
 * `af-ccx-specimen-card` components on the page and then search for those and populate
 * them with the data, but we first need to update `af-ccx-specimen-card` so it can gracefully
 * handle rendering without any of its attributes being set.
 */
FamilyCardDirectiveController.prototype.createCCXSpecimenCards = function(data) {
  if (!data) {
    return;
  }

  const self = this;

  // Do this after Angular has fully run its digest loop so we know the container
  // elements will be available.
  requestAnimationFrame(() => {
    const specimenCardContainers =
      self.$element[0].querySelectorAll('.ccx-specimen-card-container');

    // For each of the containers, we create a specimen card and populate it with
    // the matching data.
    specimenCardContainers.forEach((container, i) => {
      const item = data[i];
      const card = document.createElement('af-ccx-specimen-card');
      card.templateLink = item.templateLink;
      card.fontsInfo = item.fonts;
      card.templateDimensions = item.templateDimensions;
      card.imageMode = true;
      card.premium = item.premium;
      card.fitMode = 'constantHeightAndWidth';

      const img = document.createElement('img');
      img.src = item.src;
      card.appendChild(img);

      container.appendChild(card);
    });
  });
};

FamilyCardDirectiveController.prototype._activatedDesktopFonts = function() {
  return _.filter(this.family.fonts, function (v) {
    var syncableFont = (v.font || {}).sync || {};
    return !!syncableFont && (syncableFont.is_selected || (!!syncableFont.install_state && _.contains(syncableFont.install_state.split(','), 'cc')));
  });
};

FamilyCardDirectiveController.prototype._deactivatedAvailableDesktopFonts = function() {
  return _.filter(this.family.fonts, function (v) {
    var syncableFont = (v.font || {}).sync || {};
    return !!syncableFont.required_action && _.isEmpty(syncableFont.required_action) && !syncableFont.is_selected;
  });
};

FamilyCardDirectiveController.prototype._markFontsAsActivated = function(isUseModelEnabled = false) {
  var self = this;

  _.each(self._deactivatedAvailableDesktopFonts(), function(f) {
    if (isUseModelEnabled) {
      f.font.sync.install_state = 'cc';
    } else {
      f.font.sync.is_selected = true;
    }
  });
};

FamilyCardDirectiveController.prototype._markFontsAsDeactivated = function() {
  var self = this;

  _.each(self._activatedDesktopFonts(), function(f) {
    f.font.sync.is_selected = false;
    f.font.sync.install_state = '';
  });
};

/**
 * Handles the context object that is used for actions like purchase
 * that require a user to be logged in, but can be triggered when the user is
 * not logged in yet.
 */
FamilyCardDirectiveController.prototype._handleContext = function() {
  return this.RedirectContextService.handleContext();
};

/**
 * Handles a successful family sync response when usemodel v2 is enabled.
 *
 * @private
 * @param {Object} response
 */
FamilyCardDirectiveController.prototype._handleUseModelFamilySyncSuccess = function(response) {
  var self = this;
  self._markFontsAsActivated(true);
  self.FontCountService.updateActivatedFontsCount(response.data.added_font_ids.length);

  self.FirstMileService.onUseModelFontActivation(self.i18n, {queryParams: {familyName: self.family.name}});
};

/**
 * Handles a successful family sync response.
 *
 * @private
 * @param {Object} response
 */
FamilyCardDirectiveController.prototype._handleFamilySyncSuccess = function(response) {
  var self = this;
  self._markFontsAsActivated();
  self._updateToggleControl(true,
    self.getMessage('fonts_active_count', response.data['added_font_ids'].length));
  self.FontCountService.updateActivatedFontsCount(response.data.added_font_ids.length);

  self.FirstMileService.shouldShowFontActivationDialog().then(function(shouldShow) {
    if (shouldShow) {
      return self.FirstMileService.showFontActivationDialog();
    }
    self.NotificationService.success(self.getMessage('fonts_activated_action', response.data['added_font_ids'].length));
  });
};

FamilyCardDirectiveController.prototype._updateToggleControl = function(value, message) {
  this.family.card_state.toggle_control = {
    'value': value,
    'message': message
  };
};

/**
 * Checks if there are any gif filetypes within the specimen images data array
 * returns a class to indicate there is a gif
 *
 * @return {String}
 */
FamilyCardDirectiveController.prototype.getImageClass = function(slide) {
  return freezeframeUtil.getImageClass(slide);
};

FamilyCardDirectiveController.prototype.hasSimilarTo = function() {
  return this.similarTo() && this.similarTo().length > 0;
};

FamilyCardDirectiveController.prototype.similarToHasFile = function() {
  return this.similarTo() && typeof this.similarTo()[0] === 'object' && !!this.similarTo()[0].link;
};

FamilyCardDirectiveController.prototype.download = function(font) {
  var self = this;

  self.DialogService.show('/angular_templates/dialogs/gpt_search_file_downloading.html').then(function(dialog) {
    fetch(font.link).then(function(response) {
      if (!response.ok) {
        return response.json().then(data => {
          // eslint-disable-next-line no-alert
          alert(data.error);
          dialog.close();
        });
      }

      const disposition = response.headers.get('Content-Disposition');
      const filename = disposition.match(/filename="([^"]*)"/)[1];
      return response.blob().then(blob => {
        const url = self.$window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        self.$window.URL.revokeObjectURL(url);
        dialog.close();
      });
    });
  });
};

module.exports = FamilyCardDirectiveController;
