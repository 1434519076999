/**
 * A service used to retrieve families from the backend.
 * @ngInject
 */
function FamiliesService($http) {
  return {
    get: get
  };

  /**
   * Get a list of typographic families.
   *
   * @param {{}} params
   * @return {Promise}
   */
  function get(params) {
    return $http.get('/fonts.json', {
      params: params
    });
  }
}

module.exports = FamiliesService;
