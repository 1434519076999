var EMPTY_SEARCH_STRING = '';
const PAGE_CHANGE_EVENT = 'pagination-change';

/**
 * Controller for the tk-web-project-list directive.
 * @ngInject
 */
function TkWebProjectListController($element, TkWebProjectEditorService) {
  var self = this;
  self.$element = $element;
  self.tkWebProjectEditorService = TkWebProjectEditorService;
  self.currentPage = this.tkWebProjectEditorService.getTkWebProjectListModel().currentPage;
  self.searchString = '';
  self.selectedSort = this.tkWebProjectEditorService.getTkWebProjectListModel().getSortOrder();

  self.$element.on(PAGE_CHANGE_EVENT, function(event) {
    self.currentPage = event.detail.page;
    self.handlePageNumberChange();
  });
}

TkWebProjectListController.prototype.handlePageNumberChange = function() {
  this.tkWebProjectEditorService.updateWebProjects(this.searchString, this.currentPage, true);
};

TkWebProjectListController.prototype.clearAndGetSearchResults = function() {
  this.searchString = EMPTY_SEARCH_STRING;
  this.getSearchResults();
};

TkWebProjectListController.prototype.getSearchResults = function() {
  this.tkWebProjectEditorService.getTkWebProjectListModel().setCurrentPage(1);
  this.tkWebProjectEditorService.updateWebProjects(this.searchString, 1, true);
};

TkWebProjectListController.prototype.isSearchBoxShown = function() {
  return this.tkWebProjectEditorService.getTkWebProjectListModel().getWebProjectList().length != 0 ||
      this.isEmptyResultsState();
};

TkWebProjectListController.prototype.isEmptyResultsState = function() {
  return this.tkWebProjectEditorService.getTkWebProjectListModel().getWebProjectList().length == 0 && this.searchString != EMPTY_SEARCH_STRING;
};

/**
 * Handles change events on the sort order menu.
 */
TkWebProjectListController.prototype.handleSortChange = function() {
  this.tkWebProjectEditorService.getTkWebProjectListModel().setSortOrder(this.selectedSort);
  this.tkWebProjectEditorService.updateWebProjects(this.searchString, this.currentPage, true);
};

module.exports = TkWebProjectListController;
