/**
 * @ngInject
 */
function Directive() {
  return {
    scope: {},
    require: 'ngModel',
    link: function(scope, elem, attr, ctrl) {
      scope.handleSizeChange = function() {
        ctrl.$setViewValue(scope.fontSize);
      };

      // We need to init the value due to the issue Angular has with input[type="range"]
      // see here: https://github.com/angular/angular.js/issues/6726
      ctrl.$render = function() {
        scope.fontSize = ctrl.$viewValue;
      };
    },
    restrict: 'E',
    replace: true,
    templateUrl: '/angular_templates/directives/font_size_control.html'
  };
}

module.exports = Directive;
