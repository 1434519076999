/**
 * A service used to retrieve families from the backend.
 * @ngInject
 */
function MyFontsService($http) {
  return {
    /**
     * Deactivate fonts
     *
     * @param String filter
     * @return {Promise}
     */
    deactivateFonts: function(params) {
      return $http.put('/my_fonts/deactivate_fonts.json', params);
    },
    /**
     * Get a list of typographic families.
     *
     * @param {{}} params
     * @return {Promise}
     */
    get: function(params) {
      return $http.get('/my_fonts/families_data.json', {
        params: params
      });
    }
  };
}

module.exports = MyFontsService;
