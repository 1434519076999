var _ = require('underscore');

var DEFAULT_STEP = 1;
var HANDLE_ID_END = 'end-handle';
var HANDLE_ID_START = 'start-handle';
var SLIDER_HANDLE_SELECTOR = '.spectrum-Slider-handle';
var VARIANT_RANGE = 'range';

/**
 * @ngInject
 */
function SpectrumSliderController($element, $scope, $window) {
  this.$element = $element;
  this.$scope = $scope;
  this.$window = $window;

  this.minValue = parseInt(this.min(), 10);
  this.maxValue = parseInt(this.max(), 10);

  this.handleValues = {};

  this.initEvents();
}

/**
 * Sets the currently selected range values, start and end. The values param should have a
 * startValue and/or an endValue.
 *
 * @param {Object} values
 */
SpectrumSliderController.prototype.updateValues = function(values) {
  if (this.isRange()) {
    this.handleValues[HANDLE_ID_START] = (values.startValue || 1);
  }

  this.handleValues[HANDLE_ID_END] = this.isRange() ?
    (values.endValue || 1) : this.value();
};

/**
 * Calculate the value for the handle based on the current client x value.
 *
 * @param {Number} clientX
 * @return {Number}
 */
SpectrumSliderController.prototype.calculateHandleValue = function(clientX) {
  var controlRect = this.$element[0].getBoundingClientRect();

  // Calculate the percentage location of the handle
  var percent = (clientX - controlRect.left) / controlRect.width;

  // Use the percentage to calculate real handle value based in min and max
  return this.minValue + (this.maxValue - this.minValue) * percent;
};

/**
 * Find the closest handle to the page x and page y position.
 *
 * @param {Number} pageX
 * @param {Number} pageY
 * @return {Element}
 */
SpectrumSliderController.prototype.findClosestHandle = function(pageX, pageY) {
  var self = this;

  var handles = self.getHandles();
  var closestHandle = handles[0];

  // Start with an incredibly large start value
  var closestDistance = Infinity;

  // Then find the closest handle to spot where the user clicked
  _.forEach(handles, function(handle) {
    var rect = handle.getBoundingClientRect();
    var top = rect.top + self.$window.pageYOffset;
    var left = rect.left + self.$window.pageXOffset;

    var distance = Math.floor(
      Math.sqrt(Math.pow(pageX - (left + (rect.width / 2)), 2) +
        Math.pow(pageY - (top + (rect.height / 2)), 2))
    );

    if (distance < closestDistance) {
      closestDistance = distance;
      closestHandle = handle;
    }
  });

  return closestHandle;
};

/**
 * Get the style for the center track element.
 * @return {Object}
 */
SpectrumSliderController.prototype.getCenterTrackStyle = function() {
  return {
    left:
      this.getRelativePercent(this.getStartHandleValue()) * 100 + '%',
    right:
      (1 - this.getRelativePercent(this.getEndHandleValue())) * 100 + '%'
  };
};

/**
 * Returns the data that will be returned to callback methods.
 * @return {Object}
 */
SpectrumSliderController.prototype.getData = function() {
  if (this.isRange()) {
    return {
      end: this.getEndHandleValue(),
      start: this.getStartHandleValue()
    };
  }

  return this.getEndHandleValue();
};

/**
 * Returns the current value for the end handle.
 * @return {Number}
 */
SpectrumSliderController.prototype.getEndHandleValue = function() {
  return this.handleValues[HANDLE_ID_END];
};

/**
 * Get the style for the end track element.
 * @return {Object}
 */
SpectrumSliderController.prototype.getEndTrackStyle = function() {
  var percent = 100 * this.getRelativePercent(this.getEndHandleValue());
  return {
    left: percent + '%',
    width: (100 - percent) + '%'
  };
};

/**
 * Returns all of the handle elements.
 * @return {Element[]}
 */
SpectrumSliderController.prototype.getHandles = function() {
  return this.$element[0].querySelectorAll(SLIDER_HANDLE_SELECTOR);
};

/**
 * Returns the style for the handle that matches the id.
 *
 * @param {String} handleId
 * @return {Object}
 */
SpectrumSliderController.prototype.getHandleStyle = function(handleId) {
  return {
    left: 100 * (this.getRelativePercent(this.handleValues[handleId])) + '%'
  };
};

/**
 * Get the relative percentage based on the value passed in.
 *
 * @param {Number} value
 * @return {Number}
 */
SpectrumSliderController.prototype.getRelativePercent = function(value) {
  return (value - this.minValue) / (this.maxValue - this.minValue);
};

/**
 * Returns the current value of the start handle.
 * @return {Number}
 */
SpectrumSliderController.prototype.getStartHandleValue = function() {
  return this.handleValues[HANDLE_ID_START];
};

/**
 * Get the style for the start track element.
 * @return {Object}
 */
SpectrumSliderController.prototype.getStartTrackStyle = function() {
  if (this.isRange()) {
    return {
      width: (100 * this.getRelativePercent(this.getStartHandleValue())) + '%'
    };
  }

  return {
    left: 0,
    right: 0
  };
};

/**
 * Returns the step value or the default step if one wasn't set.
 * @return {Number}
 */
SpectrumSliderController.prototype.getStep = function() {
  return this.step() || DEFAULT_STEP;
};

/**
 * Return the label that will display the current values.
 * The directive can set a custom label method with the `value-label` method.
 * @return {String} description
 */
SpectrumSliderController.prototype.getValueLabel = function() {
  var customLabel = this.valueLabel({ $data: this.getData() });
  if (customLabel) {
    return customLabel;
  }

  if (this.isRange()) {
    return (
      this.getStartHandleValue()
      + '–' +
      this.getEndHandleValue()
    );
  }

  return this.getEndHandleValue();
};

/**
 * Handles the mouse down event on a handle.
 * @param {Element} handle
 */
SpectrumSliderController.prototype.handleMouseDown = function(handle) {
  var self = this;

  var handleMouseMove = function(event) {
    self.handleMouseMove(handle, event);
  };

  self.$window.addEventListener('mousemove', handleMouseMove);

  self.$window.addEventListener('mouseup', function() {
    self.$window.removeEventListener('mousemove', handleMouseMove);
  });
};

/**
 * Handles the mouse move event when a handle moves.
 *
 * @param {Element} handle
 * @param {Event} event
 */
SpectrumSliderController.prototype.handleMouseMove = function(handle, event) {
  var self = this;
  event.preventDefault();

  var handleId = handle.dataset.handleId;
  var handlePosition = self.calculateHandleValue(event.clientX);

  // If the start handle is being moved, make sure if doesn't move past the end
  // handle.
  if (
    handleId === HANDLE_ID_START &&
    handlePosition > self.getEndHandleValue()
  ) {
    handlePosition = self.getEndHandleValue();

    // Make sure the handles can't overlap if overlapping isn't allowed.
    if (!this.allowOverlap()) {
      handlePosition -= self.getStep();
    }
  }

  // If the end handle is being moved, make sure if doesn't move past the start
  // handle.
  if (
    handleId === HANDLE_ID_END &&
    handlePosition < self.getStartHandleValue()
  ) {
    handlePosition = self.getStartHandleValue();

    // Make sure the handles can't overlap if overlapping isn't allowed.
    if (!this.allowOverlap()) {
      handlePosition += self.getStep();
    }
  }

  // Make sure the handle can't move below the minimum value.
  if (handlePosition < self.minValue) {
    handlePosition = self.minValue;
  }

  // Make sure the handle can't move above the maximum value.
  if (handlePosition > self.maxValue) {
    handlePosition = self.maxValue;
  }

  self.$scope.$apply(function() {
    self.handleValues[handle.dataset.handleId] =
      self.snapToStep(handlePosition);

    self.onChange({ $data: self.getData() });
  });
};

/**
 * Are the handles currently overlapping with each other?
 * @return {Boolean}
 */
SpectrumSliderController.prototype.handlesAreOverlapping = function() {
  return this.handleValues[HANDLE_ID_START] === this.handleValues[HANDLE_ID_END];
};

/**
 * Initialize event handling.
 */
SpectrumSliderController.prototype.initEvents = function() {
  var self = this;
  self.$element[0].addEventListener('mousedown', function(event) {
    event.stopPropagation();

    var handle = self.findClosestHandle(event.pageX, event.pageY);
    if (handle) {
      self.handleMouseMove(handle, event);
      self.handleMouseDown(handle);
    }
  });
};

/**
 * Is this a range style slider?
 * @return {Boolean}
 */
SpectrumSliderController.prototype.isRange = function() {
  return this.variant === VARIANT_RANGE;
};

SpectrumSliderController.prototype.isStartHandleVisible = function() {
  return this.isRange();
};

/**
 * Rounds the value to nearest step value.
 *
 * @param {Number} value
 * @return {Number}
 */
SpectrumSliderController.prototype.snapToStep = function(value) {
  var step = this.getStep();
  if (step) {
    return Math.round(value / step) * step;
  }

  return value;
};

module.exports = SpectrumSliderController;
