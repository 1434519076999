var SPECTRUM_LEFT_MARGIN_WIDTH = 24;
var MAX_LOAD_RETRIES = 7;

/**
 * Controller for the tab-navigation directive.
 * @ngInject
 */
function TabNavigationController($scope, $timeout, $window) {
  var self = this;
  self.$scope = $scope;
  self.$timeout = $timeout;
  self.$window = $window;
  self.indicatorWidth = this.defaultTabWidth || 0;
  self.indicatorLeft = 0;
  self._loadAttempts = 0;
  self._loadDefaultTab(this.currentTab);

  self.$scope.$watch(function(){
    return self.currentTab;
  }, function(){
    self._updateTabStyling();
  });
}

/**
 * Returns the style CSS for the indicator, allowing it to be dynamic based on controller variables.
 */

TabNavigationController.prototype.indicatorStyle = function() {
  return { width: this.indicatorWidth + 'px', left: this.indicatorLeft + 'px', bottom: '0' };
};


/**
 * Checks if the tab passed in is the currently selected tab.
 */

TabNavigationController.prototype.isTabSelected = function(tab) {
  return this.currentTab === tab;
};

/**
 * Selects the passed in tab.
 */

TabNavigationController.prototype.selectTab = function(newTab) {
  var self = this;
  self.currentTab = newTab;
  self._updateTabStyling();
  /* A timeout is needed to ensure that the parent controller callback is invoked after the digest cycle completes */
  self.$timeout(function(){
    self.onTabChange();
  }, 0, false);
};

/**
 * Handles the click on a tab to make it current.
 */

TabNavigationController.prototype.handleTabChange = function(newTab) {
  if (newTab.link) {
    this.$window.history.replaceState(null, null, newTab.link);
    this.$window.location.reload();
  } else {
    this.$window.history.replaceState({ navigationTabClicked: true }, null, '#' + newTab.id + '-section');
  }
  this.selectTab(newTab);
};

/**
 * Gets the width of the tab element.
 */

TabNavigationController.prototype.getWidthOfTab = function(tab) {
  var width = 0;
  try {
    width = document.getElementById(tab.id).clientWidth;
  } catch (error) {
    return width;
  }
  return width;
};

TabNavigationController.prototype._isTabLoaded = function(tab){
  return this.getWidthOfTab(tab) > 0;
};

TabNavigationController.prototype._loadDefaultTab = function(tab) {
  // In order to get the correct left offset and width for the selected tab indicator
  // the tab has to be loaded by Angular in the DOM. Angular does not provide a reliable way to know when that occurs.
  // Sticking  function in a 0 second timeout schedules the execution for the end of the queue,
  // which if done a few times, will place it after the DOM loads.
  // This checks if the content has loaded yet, and if not, schedules a retry if the max # of retry
  // attempts hasn't been exceeded.
  var self = this;
  if (!self._isTabLoaded(tab) && self._loadAttempts < MAX_LOAD_RETRIES) {
    self._loadAttempts++;

    return self.$timeout(function() {
      self._loadDefaultTab();
    });
  } else {
    self.selectTab(this.currentTab);
    self._updateTabStyling();
  }
};

/**
 * Moves indicator over by the size of all previous tabs + margins between them.
 * Changes indicator size to be same size as new tab.
 */

TabNavigationController.prototype._updateTabStyling = function() {
  var self = this;
  self.indicatorWidth = self.getWidthOfTab(self.currentTab);

  var left = 0;
  for (var oldTab in this.navigationTabs) {
    if (self.navigationTabs[oldTab] === self.currentTab) {
      break;
    }
    left += SPECTRUM_LEFT_MARGIN_WIDTH;
    left += self.getWidthOfTab(this.navigationTabs[oldTab]);
  }

  this.indicatorLeft = left;
};

module.exports = TabNavigationController;
