var angular = require('angular');

/**
 * A wrapper service for the YouTube player API.
 * @ngInject
 */
function YouTubeService($document, $q, $window) {
  var IS_INITIALIZED = false;
  var YOUTUBE_API_URL = 'https://www.youtube.com/player_api';

  return {
    init: init,
    load: load
  };

  /**
   * Initializes the YouTube player API.
   * @returns {Promise}
   */
  function init() {
    return $q(function(resolve) {
      if (IS_INITIALIZED) {
        resolve();
      } else {
        _includeYouTubeAPI().then(function() {
          IS_INITIALIZED = true;
          resolve();
        });
      }
    });
  }

  /**
   * Loads a YouTube video.
   *
   * @param {String} playerId
   * @param {String} videoId
   * @param {{
   *   height: {String},
   *   onStateChange: {String},
   *   width: {String}
   * }}
   */
  function load(playerId, videoId, options) {
    return $q(function(resolve) {
      var player = new $window.YT.Player(playerId, {
        height: options.height,
        events: {
          'onReady': function() {
            resolve(player);
          },

          'onStateChange': function(event) {
            if (options.onPlayerStateChangeCallback) {
              options.onPlayerStateChangeCallback(event);
            }
          }
        },
        playerVars: {
          'rel': 0
        },
        videoId: videoId,
        width: options.width
      });
    });
  }

  /**
   * Includes the YouTube player API script.
   *
   * @private
   * @returns {Promise}
   */
  function _includeYouTubeAPI() {
    return $q(function(resolve) {
      // Make this function globally available for the YouTube API to call when
      // the player API is ready.
      $window.onYouTubeIframeAPIReady = resolve;

      var scriptTag = angular.element('<script></script>');
      scriptTag.attr('src', YOUTUBE_API_URL);
      $document.find('head').append(scriptTag);
    });
  }
}

module.exports = YouTubeService;
