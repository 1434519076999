var fvdUtil = require('../../util/fvd.js');
var typeConverter = require('../../util/webcomponents_type_converter.js');

/**
 * A class that manages a list of selected styles of a tkff.
 *
 * @constructor
 * @params {Array} availableFontVariations
 * @params {Array} enabledFontVariationIds
 * @param {Object} textSamples
 * @ngInject
 */
function TkWebProjectEditorStyleSelectorModel(availableFontVariations, enabledFontVariationIds, textSamples) {
  this._availableFontVariations = availableFontVariations.sort(function(variation1, variation2) {
    return fvdUtil.compareFvds(variation1.fvd, variation2.fvd);
  });
  this._enabledFontVariationIds = enabledFontVariationIds;
  this._textSamples = textSamples;
}

/**
 * Returns the enabled font variation ids.
 *
 * @returns {Array}
 */
TkWebProjectEditorStyleSelectorModel.prototype.getEnabledFontVariationIds = function() {
  return this._enabledFontVariationIds;
};

/**
 * Sets the enabled font variation ids.
 *
 * @returns {Array}
 */
TkWebProjectEditorStyleSelectorModel.prototype.setEnabledFontVariationIds = function(enabledFontVariationIds) {
  this._enabledFontVariationIds = enabledFontVariationIds;
};

/**
 * Returns the available font variations.
 *
 * @returns {Array}
 */
TkWebProjectEditorStyleSelectorModel.prototype.getAvailableFontVariations = function() {
  return this._availableFontVariations;
};

/**
 * Is the given variation selected?
 *
 * @param {Object} fontVariation
 * @return {boolean}
 */
TkWebProjectEditorStyleSelectorModel.prototype.isVariationSelected = function(fontVariation) {
  return (this._enabledFontVariationIds.indexOf(fontVariation.id) > -1);
};

/**
 * Toggles the selection state for the given variation.
 *
 * @param {Object} fontVariation
 */
TkWebProjectEditorStyleSelectorModel.prototype.toggleCheckbox = function(fontVariation) {
  var index = this._enabledFontVariationIds.indexOf(fontVariation.id);
  if (index > -1) {
    this._enabledFontVariationIds.splice(index, 1);
  } else {
    this._enabledFontVariationIds.push(fontVariation.id);
  }
};

/**
 * Returns an object used to render the given font variation using the display-in-font directive.
 *
 * @returns {
 *   cssName: string,
 *   fvd: string,
 *   isDynamic: boolean,
 *   opaqueId: string,
 *   unicodeRange: string,
 *   featureSettings: string,
 *   kanaOnly: boolean
 * }
 */
TkWebProjectEditorStyleSelectorModel.prototype.getVariationDisplayData = function(fontVariation) {
  var unicodeRange = this._textSamples[fontVariation.default_language].unicode_range;
  var featureSettings = this._textSamples[fontVariation.default_language].feature_settings;
  var kanaOnly = this._textSamples[fontVariation.default_language].kana_only;

  if (!kanaOnly) {
    kanaOnly = false;
  }

  return {
    cssName: fontVariation.web_id + '-' + fontVariation.fvd,
    fvd: fontVariation.fvd,
    isDynamic: false,
    opaqueId: fontVariation.web_id,
    unicodeRange: unicodeRange,
    featureSettings: featureSettings,
    kanaOnly: kanaOnly
  };
};

/**
 * Returns the example text.
 *
 * @returns {String}
 */
TkWebProjectEditorStyleSelectorModel.prototype.getExampleText = function(fontVariation) {
  return this._textSamples[fontVariation.default_language].list;
};

/**
 * Returns a string that contains the CSS syntax for using a variation. It consists of the css-font-stack, font weight and font style.
 *
 * @returns {String}
 */
TkWebProjectEditorStyleSelectorModel.prototype.getCompleteFontVariationStyle = function(fontVariation, cssFontStack) {
  return ('font-family: ' + cssFontStack + ';\nfont-weight: ' + fontVariation.weight + ';\nfont-style: ' + fontVariation.style + ';');
};

/**
 * Returns all example text data.
 *
 * @returns {Object}
 */
TkWebProjectEditorStyleSelectorModel.prototype.getTextSamples = function() {
  return this._textSamples;
};

/**
 * Are any of the variations a variable font?
 *
 * @returns {Boolean}
 */
TkWebProjectEditorStyleSelectorModel.prototype.hasVariableFont = function() {
  if (typeof this._hasVariableFont === 'undefined') {
    this._hasVariableFont = this._availableFontVariations.map((variation) => variation.is_variable).includes(true);
  }
  return this._hasVariableFont;
};

/**
 * Returns the available font variations.
 *
 * @returns {Array}
 */
TkWebProjectEditorStyleSelectorModel.prototype.webcomponents_getAvailableFontVariations = function(cssFontStack) {
  if (typeof this._webcomponentsAvailableFontVariations === 'undefined') {
    this._webcomponentsAvailableFontVariations = this._availableFontVariations.map((variation) => typeConverter.convertToVariation(variation, cssFontStack, this._textSamples));
  }
  return this._webcomponentsAvailableFontVariations;
};

module.exports = TkWebProjectEditorStyleSelectorModel;
