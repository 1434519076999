/**
 * The angular app for Neue.
 * All modules should be registered here.
 */
const angular = require('angular');
angular.module('NeueApp', [
  require('angular-sanitize'),
  require('angular-scroll'),
  require('angular-touch'),
  require('angular-cookies'),
  require('ng-infinite-scroll')
]).
  // Configuration
  // https://jira.corp.adobe.com/browse/FONTS-11110
  // There is a bug with the current version of angular-touch where click event handlers are not fired on mobile devices
  // if there is a parent element with an ng-click. This is a workaround.
  // See: https://github.com/angular/angular.js/issues/12082 (code adapted from thread) and
  // https://stackoverflow.com/questions/25813080/ng-click-and-ng-touch-mobile-device for more details.
  config(['$provide', function($provide) {
    $provide.decorator('ngClickDirective', ['$delegate', '$parse', function($delegate, $parse) {
      $delegate[0].compile = function($element, attr) {
        const fn = $parse(attr.ngClick, null, true);
        return function (scope, element) {
          element.on('click', function(event) {
            scope.$apply(function() {
              fn(scope, {$event: event});
            });
          });
        };
      };
      return $delegate;
    }]);
  }]).
  config(require('./config.js')).

  // Controllers
  controller('CollectionController',
    require('./controllers/collection_controller.js')).
  controller('DesignerController',
    require('./controllers/designer_controller.js')).
  controller('FamiliesController',
    require('./controllers/families_controller.js')).
  controller('FamilyAutoCompleteSearchController',
    require('./controllers/family_autocomplete_search_controller.js')).
  controller('FamilyController',
    require('./controllers/family_controller.js')).
  controller('FoundriesController',
    require('./controllers/foundries_controller.js')).
  controller('FoundryController',
    require('./controllers/foundry_controller.js')).
  controller('HeaderController',
    require('./controllers/header_controller.js')).
  controller('MarketplaceHeaderController',
    require('./controllers/marketplace_header_controller.js')).
  controller('MarketplaceHomeController',
    require('./controllers/marketplace_home_controller.js')).
  controller('MyFontsController',
    require('./controllers/my_fonts_controller.js')).
  controller('NotificationController',
    require('./controllers/notification_controller.js')).
  controller('RecommendationsController',
    require('./controllers/recommendations_controller.js')).
  controller('SearchResultsController',
    require('./controllers/search_results_controller.js')).
  controller('VisualSearchesController',
    require('./controllers/visual_searches_controller.js')).

  // Spectrum Component Directives
  directive('dropdownBase',
    require('./directives/dropdown_base/directive.js')).
  directive('dropdownBaseButton',
    require('./directives/dropdown_base/button.js')).
  directive('dropdownBaseContent',
    require('./directives/dropdown_base/content.js')).
  directive('dropdownBaseItem',
    require('./directives/dropdown_base/item.js')).
  directive('feedbackIcons',
    require('./directives/feedback_icons/directive.js')).
  directive('largeWebProjectNotice',
    require('./directives/large_web_project_notice/directive.js')).
  directive('spectrumDropdown',
    require('./directives/spectrum_dropdown/directive.js')).
  directive('spectrumSlider',
    require('./directives/spectrum_slider/directive.js')).
  directive('tkAlignedSpecimen',
    require('./directives/tk_aligned_specimen/directive.js')).
  directive('tkSubsettingOptions',
    require('./directives/tk_subsetting_options/directive.js')).
  directive('tkWebProjectCard',
    require('./directives/tk_web_project_card/directive.js')).
  directive('tkWebProjectDetails',
    require('./directives/tk_web_project_details/directive.js')).
  directive('tkWebProjectDisplayFamily',
    require('./directives/tk_web_project_display_family/directive.js')).
  directive('tkWebProjectEditorStyleSelector',
    require('./directives/tk_web_project_editor_style_selector/directive.js')).
  directive('tkWebProjectEmbedCodeOptions',
    require('./directives/tk_web_project_embed_code_options/directive.js')).
  directive('tkWebProjectFamilyCard',
    require('./directives/tk_web_project_family_card/directive.js')).
  directive('tkWebProjectList',
    require('./directives/tk_web_project_list/directive.js')).

  // Adobe Fonts Directives
  directive('adobeFontsAutoComplete',
    require('./directives/adobe_fonts_auto_complete/directive.js')).
  directive('adobeFontsCarousel',
    require('./directives/adobe_fonts_carousel/directive.js')).
  directive('adobeFontsFontVariationList',
    require('./directives/adobe_fonts_font_variation_list/directive.js')).
  directive('adobeFontsPangramMenu',
    require('./directives/adobe_fonts_pangram_menu/directive.js')).
  directive('adobeFontsFontpackCardList',
    require('./directives/adobe_fonts_fontpack_card_list/directive.js')).
  directive('adobeFontsFontpackCard',
    require('./directives/adobe_fonts_fontpack_card/directive.js')).
  directive('adobeFontsFamilyImageCardList',
    require('./directives/adobe_fonts_family_image_card_list/directive.js')).
  directive('adobeFontsFamilyImageCard',
    require('./directives/adobe_fonts_family_image_card/directive.js'))
  .directive('fontDisplayOptions',
    require('./directives/font_display_options/directive.js')).
  directive('fontFamilyAccordion',
    require('./directives/font_family_accordion/directive.js')).
  directive('fontPreview',
    require('./directives/font_preview/directive.js')).
  directive('requiredActionToggle',
    require('./directives/required_action_toggle/directive.js')).

  // Legacy Neue Directives
  directive('displayInFont',
    require('./directives/display_in_font/directive.js')).
  directive('familyCard',
    require('./directives/family_card/directive.js')).
  directive('filteredFamilyList',
    require('./directives/filtered_family_list/directive.js')).
  directive('fontpackHero',
    require('./directives/fontpack_hero/directive.js')).
  directive('fontpackHeroVariation',
    require('./directives/fontpack_hero/variation.js')).
  directive('fontpackHeroZoomInButton',
    require('./directives/fontpack_hero/zoom_in_button.js')).
  directive('fontpackHeroZoomOutButton',
    require('./directives/fontpack_hero/zoom_out_button.js')).
  directive('fontpackHeroZoomResetButton',
    require('./directives/fontpack_hero/zoom_reset_button.js')).
  directive('fontVariationCard',
    require('./directives/font_variation_card/directive.js')).
  directive('fontPairingCard',
    require('./directives/font_pairing_card/directive.js')).
  directive('fontSizeControl',
    require('./directives/font_size_control/directive.js')).
  directive('foundryLogo',
    require('./directives/foundry_logo/directive.js')).
  directive('stickyNav',
    require('./directives/sticky_nav/directive.js')).
  directive('tabNavigation',
    require('./directives/tab_navigation/directive.js')).
  directive('viewTypeControl',
    require('./directives/view_type_control/directive.js')).
  directive('scrollEventHandler',
    require('./directives/scroll_event_handler/directive.js')).

  // Services
  factory('AddFontsToWebProjectService',
    require('./services/add_fonts_to_web_project_service.js')).
  factory('AuthenticationService',
    require('./services/authentication_service.js')).
  factory('DataService',
    require('./services/data_service.js')).
  factory('DesignersService',
    require('./services/designers_service.js')).
  factory('DialogService',
    require('./services/dialog_service.js')).
  factory('FamiliesService',
    require('./services/families_service.js')).
  factory('FamilyAutocompleteSearchService',
    require('./services/family_autocomplete_search_service.js')).
  factory('FavoritesService',
    require('./services/favorites_service.js')).
  factory('FeedbackService',
    require('./services/feedback_service.js')).
  factory('FirstMileService',
    require('../spectrum/services/first_mile_service.js')).
  factory('FilterDataService',
    require('./services/filter_data_service.js')).
  factory('FilterVariationsService',
    require('./services/filter_variations_service.js')).
  factory('FilteredFamilyListStateService',
    require('./services/filtered_family_list_state_service.js')).
  factory('FloodgateService',
    require('./services/floodgate_service.js')).
  factory('FontCountService',
    require('./services/font_count_service.js')).
  factory('FontLoaderService',
    require('./services/font_loader_service.js')).
  factory('FontpackService',
    require('./services/fontpack_service.js')).
  factory('FoundriesService',
    require('./services/foundries_service.js')).
  factory('FreezeframeService',
    require('./services/freezeframe_service.js')).
  factory('I18nService',
    require('./services/i18n_service.js')).
  factory('ImsEventsService',
    require('./services/ims_events_service.js')).
  factory('MyFontsService',
    require('./services/my_fonts_service.js')).
  factory('NewrelicService',
    require('./services/newrelic_service.js')).
  factory('NewrelicClickTrackerService',
    require('./services/newrelic_click_tracker_service.js')).
  factory('NotificationService',
    require('./services/notification_service.js')).
  factory('PostLoginActionCreatorService',
    require('./services/post_login_action_creator_service.js')).
  factory('RecommendationsService',
    require('./services/recommendations_service.js')).
  factory('RedirectContextService',
    require('./services/redirect_context_service.js')).
  factory('VisualSearchService',
    require('./services/visual_search_service.js')).
  factory('ScrollService',
    require('./services/scroll_service.js')).
  factory('SuspendedAccountService',
    require('./services/suspended_account_service.js')).
  factory('SyncService',
    require('./services/sync_service.js')).
  factory('TagsService',
    require('./services/tags_service.js')).
  factory('TkWebProjectEditorService',
    require('./services/tk_web_project_editor_service.js')).
  factory('UserPrefsService',
    require('./services/user_prefs_service.js')).
  factory('YouTubeService',
    require('./services/youtube_service.js')).

  // Use DataService to preload data from script tags on the page.
  run(require('./run.js'));
